import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Upload } from "antd";
import FormFieldFormItem from "../FormFieldFormItem";

function FormFieldFileBuilder(props) {
  const { name, disabled } = props;

  return (
    <FormFieldFormItem {...props} displayForInput={false}>
      <div>
        <Upload className="form-field-file-upload" name={name} disabled={disabled}>
          <Button>
            <Icon type="upload" /> Click to Upload
          </Button>
        </Upload>
      </div>
    </FormFieldFormItem>
  );
}

FormFieldFileBuilder.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default FormFieldFileBuilder;
