import React from "react";
import PropTypes from "prop-types";
import FormFieldRadioButton from "./FormFieldRadioButton";

const BOOL_TYPE = {
  YES: true,
  NO: false,
};

const BOOL_TYPE_LABEL = {
  [BOOL_TYPE.YES]: "Yes",
  [BOOL_TYPE.NO]: "No",
};

const BOOL_OPTIONS = [
  { value: BOOL_TYPE.YES, label: BOOL_TYPE_LABEL[BOOL_TYPE.YES] },
  { value: BOOL_TYPE.NO, label: BOOL_TYPE_LABEL[BOOL_TYPE.NO] },
];

function FormFieldBool(props) {
  const { meta } = props;

  return <FormFieldRadioButton {...props} meta={{ ...meta, options: BOOL_OPTIONS }} />;
}

FormFieldBool.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default FormFieldBool;
