import React from "react";
import PropTypes from "prop-types";
import { Alert, Typography } from "antd";
import SectionHeaderContainer from "../SectionHeaderContainer";
import TextEditorPreview, { getRichTextContentIsEmpty } from "../TextEditor/TextEditorPreview";
import RegistrationUserDetailActions from "./RegistrationUserDetailActions";
import "./RegistrationUserLandingPage.scss";

function RegistrationUserLandingPage({ bannerImageUrl, descriptionContent, title, regId }) {
  const richTextContentIsEmpty = getRichTextContentIsEmpty(descriptionContent);

  return (
    <div className="registration-user-landing-page__flex-container">
      <div className="registration-user-landing-page__content">
        {!!bannerImageUrl && (
          <div className="registration-user-landing-page__banner-container">
            <div
              className="registration-user-landing-page__banner-container__inside"
              style={{ backgroundImage: `url(${bannerImageUrl})` }}
            />
          </div>
        )}

        <SectionHeaderContainer>
          <Typography.Title level={3}>{title}</Typography.Title>
        </SectionHeaderContainer>

        <div className="registration-user-landing-page__description">
          <TextEditorPreview defaultValue={descriptionContent} />

          {richTextContentIsEmpty && (
            <Alert
              type="info"
              message="No description"
              description="There is no introductory information for this registration."
              showIcon
            />
          )}
        </div>

        <div className="registration-user-landing-page__bottom-section">
          <RegistrationUserDetailActions regId={regId} buttonSize="large" />
        </div>
      </div>
    </div>
  );
}

RegistrationUserLandingPage.propTypes = {
  bannerImageUrl: PropTypes.string,
  descriptionContent: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  regId: PropTypes.string.isRequired,
};

RegistrationUserLandingPage.defaultProps = {
  bannerImageUrl: null,
};

export default RegistrationUserLandingPage;
