const ADMIN = "/admin";

export const COUNTRIES = `${ADMIN}/countries`;
export const COUNTRY_CREATE = `${COUNTRIES}/create`;
export const FIELDS = `${ADMIN}/fields`;
export const FIELD_CREATE = `${FIELDS}/create`;
export const FORMATS = `${ADMIN}/formats`;
export const FORMAT_CREATE = `${FORMATS}/create`;
export const FORMS = `${ADMIN}/forms`;
export const GROUPS = `${ADMIN}/groups`;
export const GROUP_CREATE = `${GROUPS}/create`;
export const GROUP_TYPES = `${ADMIN}/group-types`;
export const GROUP_TYPE_CREATE = `${GROUP_TYPES}/create`;
export const PEOPLE = `/people`;
export const REGISTRATIONS = `${ADMIN}/registrations`;
export const ROLES = `${ADMIN}/roles`;
export const ROLE_CREATE = `${ROLES}/create`;
export const SCORE_TYPES = `${ADMIN}/score-types`;
export const SCORE_TYPE_CREATE = `${SCORE_TYPES}/create`;
export const SPORTS = `${ADMIN}/sports`;
export const SPORT_CREATE = `${SPORTS}/create`;
export const RESULT_STATUSES = `${ADMIN}/result-statuses`;
export const RESULT_STATUS_CREATE = `${ADMIN}/result-statuses/create`;
export const USER_REGISTRATIONS = `/registrations`;

export const MY_REGISTRATIONS = `${USER_REGISTRATIONS}/my-registrations`;
export const MY_REGISTRATIONS_CURRENT_REGISTRATIONS = `${MY_REGISTRATIONS}/current-registrations`;
export const MY_REGISTRATIONS_COMPLETED_REGISTRATIONS = `${MY_REGISTRATIONS}/completed-registrations`;
export const MY_REGISTRATIONS_COMPLETED_PROFILE = `${MY_REGISTRATIONS}/profile`;

export const USER_REGISTRATION_DETAIL_PATH = `${USER_REGISTRATIONS}/:id`;
export const USER_REGISTRATION_SUBMIT_PATH = `${USER_REGISTRATIONS}/:id/submit`;

/* Competition Management */
export const COMPETITION_MANAGEMENT = `${ADMIN}/competition-management`;
export const CM_CREATE_PATH = "create";
// /competition-management/create
export const COMPETITION_MANAGEMENT_CREATE = `${COMPETITION_MANAGEMENT}/${CM_CREATE_PATH}`;

// /competition-management/edit
export const CM_EDIT_PATH = "edit";

// EVENTS
// /competition-management/event
export const EVENT = `${COMPETITION_MANAGEMENT}/event`;
// /competition-management/event/create
export const EVENT_CREATE = `${EVENT}/${CM_CREATE_PATH}`;
// /competition-management/event/:event_id
export const EVENT_HOME = `${EVENT}/:event_id`;
// /competition-management/event/:event_id/create
export const EVENT_HOME_CREATE = `${EVENT_HOME}/${CM_CREATE_PATH}`;
// /competition-management/event/:event_id/edit
export const EVENT_EDIT = `${EVENT}/:event_id/${CM_EDIT_PATH}`;
// /competition-management/event/:event_id/edit/permissions
export const EVENT_EDIT_PERMISSIONS = `${EVENT}/:event_id/${CM_EDIT_PATH}/permissions`;

// EVENT - SPORT MEET
// /competition-management/event/:event_id/sportmeet
export const EVENT_SPORTMEET = `${EVENT_HOME}/sportmeet`;
// /competition-management/event/:event_id/sportmeet/:sportmeet_id
export const EVENT_SPORTMEET_HOME = `${EVENT_SPORTMEET}/:sportmeet_id`;
// /competition-management/event/:event_id/sportmeet/sport/create
export const EVENT_SPORTMEET_HOME_CREATE_SPORT = `${EVENT_SPORTMEET}/sport/create`;
// /competition-management/event/:event_id/sportmeet/sport/:sport_id/create
export const EVENT_SPORTMEET_CREATE = `${EVENT_SPORTMEET}/sport/:sport_id/create`;
// /competition-management/event/:event_id/sportmeet/edit/sportmeet_id
export const EVENT_SPORTMEET_EDIT = `${EVENT_SPORTMEET}/:sportmeet_id/${CM_EDIT_PATH}`;

// EVENT - SPORTMEET - COMPETITION
export const EVENT_SPORTMEET_COMPETITION = `${EVENT_SPORTMEET_HOME}/competition`;
export const EVENT_SPORTMEET_COMPETITION_HOME = `${EVENT_SPORTMEET_COMPETITION}/:competition_id`;
export const EVENT_SPORTMEET_COMPETITION_COMPETITORS = `${EVENT_SPORTMEET_COMPETITION}/:competition_id/competitors`;
export const EVENT_SPORTMEET_COMPETITION_ROUNDS = `${EVENT_SPORTMEET_COMPETITION}/:competition_id/rounds`;
export const EVENT_SPORTMEET_COMPETITION_DIVISIONING = `${EVENT_SPORTMEET_COMPETITION}/:competition_id/divisioning`;
// /competition-management/event/:event_id/sportmeet/:sportmeet_id/create
export const EVENT_SPORTMEET_COMPETITION_HOME_CREATE = `${EVENT_SPORTMEET_HOME}/${CM_CREATE_PATH}`;
// /competition-management/event/:event_id/sportmeet/:sportmeet_id/competition/create
export const EVENT_SPORTMEET_COMPETITION_CREATE = `${EVENT_SPORTMEET_COMPETITION}/create`;
// /competition-management/event/:event_id/sportmeet/:sportmeet_id/competition/:competition_id/edit
export const EVENT_SPORTMEET_COMPETITION_EDIT = `${EVENT_SPORTMEET_COMPETITION}/:competition_id/${CM_EDIT_PATH}`;
// /competition-management/event/:event_id/sportmeet/:sportmeet_id/competition/:competition_id/edit/teams
export const EVENT_SPORTMEET_COMPETITION_EDIT_TEAMS = `${EVENT_SPORTMEET_COMPETITION_EDIT}/teams`;

// PERMISSIONS
export const USER_GROUP_ROLE_PERMISSIONS = "/admin/permissions";

// TENANT / SYSTEM SETTINGS
export const SYSTEM_SETTINGS = "/admin/settings";
export const SYSTEM_LEGALS = `${SYSTEM_SETTINGS}/legals`;
export const SYSTEM_THEME = `${SYSTEM_SETTINGS}/theme`;
export const SYSTEM_COMMUNICATIONS = `${SYSTEM_SETTINGS}/communications`;
export const TENANT = `${SYSTEM_SETTINGS}/tenants`;
export const TENANT_CREATE = `${TENANT}/create`;

// REPORTS - SUMMARY REPORT
export const SUMMARY_REPORT = "/reports/summary-report";

// Dashboard
export const DASHBOARD = "/dashboard";

// Profile
export const PROFILE = "/profile";

// Download
export const DOWNLOAD = "/download/:id";

// File
export const PRIVATE_FILE = "/file/download/:id";

// Login and Register
export const LOGIN = "/login";
export const REGISTER_NEW_USER = "/register";
