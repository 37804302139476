import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import { connect } from "formik";
import get from "lodash/get";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import { FIELD_TYPES_VALID_FOR_CONDITION } from "../../../constants/conditionConstants";
import { FIELD_TYPES } from "../../../constants/fieldConstants";
import { getConditionFieldValueComponent } from "../../../utils/formConditionUtils";
import FormFieldSelect from "../../FormFields/FormFieldSelect";

export const CONDITION_TYPES = {
  showOnMatch: "showOnMatch",
  hideOnMatch: "hideOnMatch",
};

const CONDITION_TYPE_OPTIONS = [
  { value: CONDITION_TYPES.showOnMatch, label: "Show when field matches value" },
  { value: CONDITION_TYPES.hideOnMatch, label: "Hide when field matches value" },
];

export function getFormName(formLabel, formOrder) {
  return `${formLabel} (#${formOrder})`;
}

function FormFieldCondition(props) {
  const {
    condition,
    conditionIndex,
    formFields,
    formField,
    formFieldIndex,
    submitCount,
    handleClickRemoveCondition,
    handleChangeConditionType,
    hasBorderBottom,
    formik,
    disabled,
  } = props;

  const namePrefix = useMemo(() => `FormFields.${formFieldIndex}.conditions.${conditionIndex}`, [
    formFieldIndex,
    conditionIndex,
  ]);

  const nameFieldValue = useMemo(() => `${namePrefix}.fieldValue`, [namePrefix]);
  const nameFieldType = useMemo(() => `${namePrefix}.fieldType`, [namePrefix]);
  const nameFieldCondition = useMemo(() => `${namePrefix}.hasFieldCondition`, [namePrefix]);

  const allowedFormFieldOptions = useMemo(() => {
    return formFields
      .filter(formFieldItem => {
        const isNew = get(formFieldItem, "_state.isNew", false);

        return (
          formFieldItem.id !== formField.id && !isNew && includes(FIELD_TYPES_VALID_FOR_CONDITION, formFieldItem.type)
        );
      })
      .map(formFieldItem => {
        const label = get(formFieldItem, "meta.label", "");

        return { value: formFieldItem.id, label: getFormName(label, formFieldItem.order), type: formFieldItem.type };
      });
  }, [formFields, formField]);

  const getCurrentFormField = useCallback(
    fieldId => {
      return fieldId ? formFields.find(formFieldOption => formFieldOption.id === fieldId) : undefined;
    },
    [formFields],
  );

  const getChoices = useCallback(
    fieldId => {
      return get(getCurrentFormField(fieldId), "meta.choices", []);
    },
    [getCurrentFormField],
  );

  const conditionFieldValueComponent = useMemo(
    () => getConditionFieldValueComponent(condition, nameFieldValue, submitCount, getChoices, disabled),
    [condition, nameFieldValue, submitCount, getChoices, disabled],
  );

  function handleChangeField(fieldId) {
    const currentFormField = getCurrentFormField(fieldId);

    if (currentFormField) {
      const type = get(currentFormField, "type", undefined);

      if (type) {
        const newFieldValue = type === FIELD_TYPES.bool ? false : undefined;
        const hasFieldCondition = !isEmpty(currentFormField.conditions);

        formik.setFieldValue(nameFieldValue, newFieldValue);
        formik.setFieldValue(nameFieldType, type);
        formik.setFieldValue(nameFieldCondition, hasFieldCondition);
      }
    }
  }

  function renderConditionOptions() {
    switch (condition.type) {
      case CONDITION_TYPES.showOnMatch:
      case CONDITION_TYPES.hideOnMatch: {
        return (
          <>
            <div style={{ margin: conditionFieldValueComponent ? "12px 0" : "12px 0 0 0" }}>
              <FormFieldSelect
                name={`FormFields.${formFieldIndex}.conditions.${conditionIndex}.field`}
                meta={{
                  displayDefaultLabel: false,
                  placeholder: "Select a field",
                  options: allowedFormFieldOptions,
                  handleSelectChange: handleChangeField,
                  style: { width: "100%" },
                  helpText: "You can only select saved fields",
                }}
                submitCount={submitCount}
                disabled={disabled}
              />
            </div>

            <div>{conditionFieldValueComponent}</div>
          </>
        );
      }
      default: {
        return null;
      }
    }
  }

  return (
    <div
      className="form-field-condition-wrap"
      style={{ padding: 12, borderBottom: hasBorderBottom ? "1px solid #d9d9d9" : "none" }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>
          <FormFieldSelect
            name={`FormFields.${formFieldIndex}.conditions.${conditionIndex}.type`}
            meta={{
              placeholder: "Select a type",
              style: { minWidth: 150, width: "100%" },
              handleSelectChange: handleChangeConditionType,
              options: CONDITION_TYPE_OPTIONS,
              displayDefaultLabel: false,
            }}
            submitCount={submitCount}
            disabled={disabled}
          />

          {renderConditionOptions()}
        </div>

        {!disabled && (
          <Icon
            type="close"
            style={{ fontSize: 18, marginLeft: 5, cursor: "pointer", height: 32, padding: "7px 0 7px 7px" }}
            onClick={handleClickRemoveCondition}
          />
        )}
      </div>
    </div>
  );
}

FormFieldCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  conditionIndex: PropTypes.number.isRequired,
  formFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  formField: PropTypes.object.isRequired,
  formFieldIndex: PropTypes.number.isRequired,
  submitCount: PropTypes.number.isRequired,
  handleClickRemoveCondition: PropTypes.func.isRequired,
  handleChangeConditionType: PropTypes.func.isRequired,
  hasBorderBottom: PropTypes.bool.isRequired,
  formik: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

FormFieldCondition.defaultProps = {
  disabled: false,
};

export default connect(FormFieldCondition);
