import React from "react";
import PropTypes from "prop-types";
import { PageHeader } from "antd";

function LayoutPageHeader({ title, subTitle, extra, tags, onBack, style, className }) {
  return (
    <PageHeader
      className={className || undefined}
      title={title}
      subTitle={subTitle}
      tags={tags}
      extra={extra}
      onBack={onBack}
      style={{ padding: 0, background: "transparent", ...style }}
    />
  );
}

LayoutPageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tags: PropTypes.node,
  extra: PropTypes.node,
  onBack: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  className: PropTypes.string,
};

LayoutPageHeader.defaultProps = {
  subTitle: null,
  extra: null,
  tags: null,
  onBack: null,
  style: {},
  className: null,
};

export default LayoutPageHeader;
