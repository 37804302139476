import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";

function SpinPageContent({ style }) {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "50px 0", ...style }}>
      <Spin size="large" />
    </div>
  );
}

SpinPageContent.defaultProps = {
  style: {},
};

SpinPageContent.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

export default SpinPageContent;
