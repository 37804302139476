import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import { parseDateTime } from "../../../utils/dateTimeUtils";
import DateTimeValueWithTimezone from "../../DateTimeValueWithTimezone";

function RegistrationClosedStatusAlert({ reg, style }) {
  const alert = useMemo(() => {
    return {
      description: (
        <>
          Registration open from{" "}
          <DateTimeValueWithTimezone
            value={parseDateTime(parseDateTime(reg.openAt))}
            referenceTimezoneName={reg.timezoneName}
          />{" "}
          to <DateTimeValueWithTimezone value={parseDateTime(reg.closeAt)} referenceTimezoneName={reg.timezoneName} />.
        </>
      ),
      type: "warning",
      message: "Registration is not open",
    };
  }, [reg]);

  return (
    <Alert
      type={alert.type}
      message={alert.message}
      description={alert.description}
      showIcon={false}
      className="ant-alert-with-description alert-registration-status"
      style={style}
    />
  );
}

RegistrationClosedStatusAlert.propTypes = {
  reg: PropTypes.shape({
    openAt: PropTypes.string,
    closeAt: PropTypes.string,
    timezoneName: PropTypes.string,
  }).isRequired,
  style: PropTypes.any,
};

RegistrationClosedStatusAlert.defaultProps = {
  style: undefined,
};

export default RegistrationClosedStatusAlert;
