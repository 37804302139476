import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Avatar } from "antd";
import gql from "fraql";
import isEmpty from "lodash/isEmpty";

const PERSON_AVATAR_FILE_URL = gql`
  query HeaderUserAvatar_PersonAvatarFileUrl($auth0Id: String!) {
    User(where: { auth0Id: { _eq: $auth0Id } }) {
      id
      Person {
        id
        AvatarFile {
          id
          url
        }
      }
    }
  }
`;

function HeaderUserAvatar({ user }) {
  const auth0Id = user.sub;

  const { loading, error, data } = useQuery(PERSON_AVATAR_FILE_URL, {
    variables: { auth0Id },
  });

  const avatarFileUrl = useMemo(() => {
    if ((loading && !data) || error || isEmpty(data.User) || !data.User[0].Person?.AvatarFile?.url) {
      return user.picture;
    }

    return data.User[0].Person.AvatarFile.url;
  }, [loading, error, data, user]);

  return <Avatar src={avatarFileUrl} />;
}

HeaderUserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
};

export default HeaderUserAvatar;
