import React from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import { Radio } from "formik-antd";
import find from "lodash/find";
import get from "lodash/get";
import isNil from "lodash/isNil";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldReadOnlyContent from "./FormFieldReadOnlyFields/FormFieldReadOnlyContent";

function FormFieldRadioButton(props) {
  const { name, meta, disabled, readOnly, formik } = props;

  const options = get(meta, "options", []);
  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const buttonSize = get(meta, "buttonSize", "default");

  function getReadOnlyContent() {
    const value = get(formik, `values.${name}`, null);

    if (isNil(value) || value === "") {
      return null;
    }

    const matchingOption = find(options, option => option.value === value);

    if (!matchingOption) {
      return null;
    }

    return matchingOption.label;
  }

  if (readOnly) {
    return <FormFieldReadOnlyContent name={name} meta={meta} content={getReadOnlyContent()} />;
  }

  return (
    <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel}>
      <Radio.Group name={name} disabled={disabled} size={buttonSize} buttonStyle="solid">
        {options.map(option => (
          <Radio.Button key={option.value} value={option.value}>
            {option.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </FormFieldFormItem>
  );
}

FormFieldRadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

FormFieldRadioButton.defaultProps = {
  disabled: false,
  readOnly: false,
};

export default connect(FormFieldRadioButton);
