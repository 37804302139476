// eslint-disable-next-line import/prefer-default-export
export const FORM_ITEM_LAYOUT_PROPS_BY_FORM_LAYOUT = {
  horizontal: {
    layout: "horizontal",
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  },
  vertical: {
    layout: "vertical",
  },
  inline: {
    layout: "inline",
  },
};
