import React from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import get from "lodash/get";
import omit from "lodash/omit";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../StatusText";
import TextEditor from "../TextEditor";
import TextEditorPreview from "../TextEditor/TextEditorPreview";
import FormFieldFormItem from "./FormFieldFormItem";

function FormFieldContent(props) {
  const { name, meta, disabled, formik, builder, showFormFieldContentAsPreview } = props;
  const { setFieldValue } = formik;

  let defaultValue;

  if (showFormFieldContentAsPreview) {
    defaultValue = get(meta, "content", "");
  } else {
    defaultValue = get(formik, `values.${name}`, "");
  }

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);

  let label = get(meta, "label", "");

  if (!label) {
    label = "Unnamed Field";
  }

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Enter a value";
  }

  if (showFormFieldContentAsPreview) {
    if (!defaultValue) {
      return null;
    }

    return <TextEditorPreview defaultValue={defaultValue} />;
  }

  if (builder) {
    return (
      <div
        style={{
          display: "block",
          lineHeight: "40px",
        }}
      >
        {label} <StatusText status={STATUS_TEXT_STATUS_OPTIONS.DEFAULT}>[Content]</StatusText>
      </div>
    );
  }

  return (
    <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel}>
      <TextEditor
        name={name}
        defaultValue={defaultValue}
        setFieldValue={setFieldValue}
        placeholder={placeholder}
        disabled={disabled}
      />
    </FormFieldFormItem>
  );
}

FormFieldContent.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  builder: PropTypes.bool,
  showFormFieldContentAsPreview: PropTypes.bool,
};

FormFieldContent.defaultProps = {
  disabled: false,
  builder: false,
  showFormFieldContentAsPreview: false,
};

export default connect(FormFieldContent);
