import React from "react";
import PropTypes from "prop-types";
import isString from "lodash/isString";
import isBlank from "../utils/isBlank";
import EmptyValueText from "./EmptyValueText";

// Note: we need to spread any other props passed so that this component works as the child of an Ant Design Tooltip.
function ColumnValueText({ upperCase, emptyText, children, ...restProps }) {
  if (isBlank(children)) {
    return <EmptyValueText text={emptyText} {...restProps} />;
  }

  const content = upperCase && isString(children) ? children.toUpperCase() : children;

  return (
    <span style={{ wordWrap: "break-word", wordBreak: "break-word" }} {...restProps}>
      {content}
    </span>
  );
}

ColumnValueText.propTypes = {
  upperCase: PropTypes.bool,
  emptyText: PropTypes.string,
  children: PropTypes.any,
};

ColumnValueText.defaultProps = {
  upperCase: false,
  emptyText: undefined,
  children: null,
};

export default ColumnValueText;
