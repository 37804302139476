import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";

function AlertFailedLoading({ message }) {
  return (
    <Alert
      message={message}
      description="Sorry, there was an issue loading the data for this page."
      type="error"
      showIcon
    />
  );
}

AlertFailedLoading.propTypes = {
  message: PropTypes.string.isRequired,
};

export default AlertFailedLoading;
