import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Modal } from "antd";
import gql from "fraql";
import * as yup from "yup";
import get from "lodash/get";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import isBlank from "../../../../utils/isBlank";
import { schemaNullable } from "../../../../utils/yupUtils";
import ActionForm from "../../../ActionForm";
import AlertFailedLoading from "../../../AlertFailedLoading";
import SpinPageContent from "../../../SpinPageContent";
import TextEditorPreview from "../../../TextEditor/TextEditorPreview";
import { ConnectedFormBottomComponent } from "../FormBottomComponent";

const GET_REGISTRATION_SUBMISSION = gql`
  query RegistrationStepAlternate_GetRegistrationSubmission($PersonId: String!, $RegId: String!) {
    RegSubmission(where: { PersonId: { _eq: $PersonId }, RegId: { _eq: $RegId } }) {
      id
      alternate
      RoleId
    }
  }
`;

const UPDATE_REGISTRATION_SUBMISSION = gql`
  mutation RegistrationStepAlternate_UpdateRegistrationSubmission(
    $RegId: String!
    $PersonId: String!
    $alternate: Boolean!
  ) {
    update_RegSubmission(
      where: { PersonId: { _eq: $PersonId }, RegId: { _eq: $RegId } }
      _set: { alternate: $alternate }
    ) {
      returning {
        id
        alternate
      }
    }
  }
`;

const FIELDS = {
  alternate: {
    name: "alternate",
    type: "bool",
    label: "Are you an alternate?",
    required: true,
  },
};

const RegistrationStepAlternate = ({
  step,
  stepIndex,
  updateRegStepStatus,
  nextStep,
  prevStep,
  regIsReadOnly,
  personId,
  regId,
  showFormBottomComponent,
  readOnly,
}) => {
  const content = get(step, "meta.content", "");
  const forcedAlternateRoles = get(step, "meta.forcedAlternateRoles", []);

  const { loading: regSubmissionLoading, error: regSubmissionError, data: regSubmissionData } = useQuery(
    GET_REGISTRATION_SUBMISSION,
    {
      variables: { PersonId: personId, RegId: regId },
    },
  );

  const regSubmission = useMemo(() => get(regSubmissionData, "RegSubmission.0", null), [regSubmissionData]);
  const savedAlternateValue = useMemo(() => get(regSubmission, "alternate", null), [regSubmission]);
  const participationRoleId = useMemo(() => get(regSubmission, "RoleId", ""), [regSubmission]);
  const expectedAlternateValue = useMemo(
    () =>
      !isEmpty(forcedAlternateRoles) && !isBlank(participationRoleId)
        ? includes(forcedAlternateRoles, participationRoleId)
        : null,
    [forcedAlternateRoles, participationRoleId],
  );

  const initialValues = useMemo(() => {
    if (!isNil(savedAlternateValue)) {
      return { alternate: savedAlternateValue };
    }

    return { alternate: expectedAlternateValue };
  }, [savedAlternateValue, expectedAlternateValue]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      alternate: schemaNullable(
        yup
          .bool()
          .required("Please select whether you are an alternate.")
          .test({
            test: alternate => {
              if (expectedAlternateValue === null) {
                return true;
              }
              return alternate === expectedAlternateValue;
            },
            message: expectedAlternateValue
              ? "Due to the role you've selected, you are required to be an alternate."
              : "Due to the role you've selected, you are not allowed to be an alternate.",
          }),
      ),
    });
  }, [expectedAlternateValue]);

  const [updateRegistrationSubmission] = useMutation(UPDATE_REGISTRATION_SUBMISSION);

  const handleSubmit = useCallback(
    async (values, actions) => {
      try {
        const { alternate } = values;

        await updateRegistrationSubmission({
          variables: {
            RegId: regId,
            PersonId: personId,
            alternate,
          },
        });

        await updateRegStepStatus(step);

        actions.setSubmitting(false);

        nextStep(true);
      } catch (updateRegStepStatusError) {
        console.error(updateRegStepStatusError);

        actions.setSubmitting(false);
        actions.setStatus({ type: "error" });

        Modal.error({
          title: "Something went wrong",
          content: "Failed to save the step.",
        });

        Sentry.captureException(updateRegStepStatusError);
      }
    },
    [nextStep, personId, regId, step, updateRegStepStatus, updateRegistrationSubmission],
  );

  if (regSubmissionLoading && !regSubmissionData) {
    return <SpinPageContent />;
  }

  if (regSubmissionError || !regSubmission) {
    return <AlertFailedLoading message="Registration submission information failed to load" />;
  }

  return (
    <>
      <TextEditorPreview defaultValue={content} style={{ marginBottom: 24 }} />

      <ActionForm
        fields={FIELDS}
        validationSchema={validationSchema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        submitText="Submit"
        formLayout="vertical"
        useFormValidityForSubmitDisabled
        disabled={regIsReadOnly}
        FormBottomComponent={props => (
          <ConnectedFormBottomComponent
            step={step}
            stepIndex={stepIndex}
            prevStep={prevStep}
            nextStep={nextStep}
            {...props}
          />
        )}
        showFormBottomComponent={showFormBottomComponent}
        readOnly={readOnly}
      />
    </>
  );
};

RegistrationStepAlternate.propTypes = {
  step: PropTypes.object.isRequired,
  stepIndex: PropTypes.number.isRequired,
  updateRegStepStatus: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  regIsReadOnly: PropTypes.bool.isRequired,
  personId: PropTypes.string.isRequired,
  regId: PropTypes.string.isRequired,
  showFormBottomComponent: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default RegistrationStepAlternate;
