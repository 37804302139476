import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import moment from "moment";
import isNull from "lodash/isNull";
import isBlank from "../../../utils/isBlank";

function RegistrationAgeRequirementStatusAlert({ reg, regAgeRequirementIsMet, style }) {
  const alert = useMemo(() => {
    // Confirm the registration has an age requirement before evaluating any logic below, so that we can allow the code
    // below to make assumptions about what fields will have values. This also ensures we do not show any alert in the
    // case where a registration does not have an age requirement.
    if (!reg.ageRequirement) {
      return null;
    }

    const ageAsAtDateFormatted = moment.parseZone(reg.ageAsAtDate, "YYYY-MM-DD").format("DD MMM YYYY");

    let ageRequirementText;

    if (!isBlank(reg.minimumAge) && !isBlank(reg.maximumAge)) {
      ageRequirementText = `You must be between ${reg.minimumAge} and ${reg.maximumAge} years old on ${ageAsAtDateFormatted}`;
    } else if (!isBlank(reg.minimumAge)) {
      ageRequirementText = `You must be ${reg.minimumAge} or older on ${ageAsAtDateFormatted}`;
    } else if (!isBlank(reg.maximumAge)) {
      ageRequirementText = `You must ${reg.maximumAge} or younger on ${ageAsAtDateFormatted}`;
    } else {
      throw new Error("Reg has missing or unexpected age requirement values.");
    }

    if (isNull(regAgeRequirementIsMet)) {
      // Case where person's DOB is not yet known.
      return {
        type: "warning",
        message: "Age requirement",
        description: `${ageRequirementText} to submit this registration. Please ensure you enter your date of birth correctly.`,
      };
    }

    if (regAgeRequirementIsMet === false) {
      // Case where minimum or maximum age requirement is not met.
      return {
        type: "error",
        message: "Age requirement not met",
        description: `${ageRequirementText} to submit this registration.`,
      };
    }

    // Case where age requirements are met, or where we're still waiting on data to be fetched to evaluate the correct
    // `regAgeRequirementIsMet` value.
    return null;
  }, [reg, regAgeRequirementIsMet]);

  if (!alert) {
    return null;
  }

  return (
    <Alert
      type={alert.type}
      message={alert.message}
      description={alert.description}
      showIcon={false}
      className="ant-alert-with-description alert-registration-status"
      style={style}
    />
  );
}

RegistrationAgeRequirementStatusAlert.propTypes = {
  regAgeRequirementIsMet: PropTypes.bool,
  reg: PropTypes.shape({
    ageRequirement: PropTypes.bool.isRequired,
    ageAsAtDate: PropTypes.string,
    minimumAge: PropTypes.number,
    maximumAge: PropTypes.number,
  }).isRequired,
  style: PropTypes.any,
};

RegistrationAgeRequirementStatusAlert.defaultProps = {
  regAgeRequirementIsMet: null,
  style: undefined,
};

export default RegistrationAgeRequirementStatusAlert;
