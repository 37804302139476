import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./TableWithTruncationBodyCell.scss";

function TableWithTruncationBodyCell({ column, children, className, ...restProps }) {
  // Ant Design may render the selection column automatically, but we will not receive any column information for it.
  if (!column) {
    return <td {...restProps}>{children}</td>;
  }

  const tableCellStyle = {};

  if (column.width) {
    tableCellStyle.maxWidth = column.width;
  } else if (column.minWidth) {
    tableCellStyle.minWidth = column.minWidth;
    tableCellStyle.maxWidth = column.minWidth;
  }

  const combinedClassName = classnames(className, {
    "table-with-truncation-cell": true,
    "table-with-truncation-cell--truncate": !!tableCellStyle.maxWidth,
  });

  return (
    <td {...restProps} className={combinedClassName} style={tableCellStyle}>
      {children}
    </td>
  );
}

TableWithTruncationBodyCell.propTypes = {
  column: PropTypes.shape({
    width: PropTypes.number,
    minWidth: PropTypes.number,
  }),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  className: PropTypes.string,
};

TableWithTruncationBodyCell.defaultProps = {
  column: null,
  className: "",
};

export default TableWithTruncationBodyCell;
