import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Alert, message } from "antd";
import { Formik } from "formik";
import { Form } from "formik-antd";
import gql from "fraql";
import * as yup from "yup";
import each from "lodash/each";
import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isInteger from "lodash/isInteger";
import isNil from "lodash/isNil";
import isNull from "lodash/isNull";
import { COMPETITION_SCORE_TYPE } from "../../../../../constants/competitionConstants";
import { FORM_ITEM_LAYOUT_PROPS_BY_FORM_LAYOUT } from "../../../../../constants/formConstants";
import { REG_STEP_STATUSES } from "../../../../../constants/regStatusConstants";
import { isPersonEligibleForCompetition } from "../../../../../utils/competitionUtils";
import FormikPersist from "../../../../../utils/formikPersist";
import isBlank from "../../../../../utils/isBlank";
import {
  formatCompetitionScoreForSaving,
  schemaQualificationScoreValue,
  schemaCompetitionScoreValue,
  formatCompetitionScoreForEdit,
} from "../../../../../utils/scoreUtils";
import { PERMISSION, useGetUserHasPermission } from "../../../../../utils/useGetUserHasPermission";
import { useRegContext } from "../../../../../utils/useRegContext";
import { schemaNullable } from "../../../../../utils/yupUtils";
import { GET_STEP_REG_STEP_VALUE, SAVE_STEP_REG_STEP_VALUE } from "../../../../../schemas/regStepValueGql";
import AlertFailedLoading from "../../../../AlertFailedLoading";
import FormStatusMessage from "../../../../FormStatusMessage";
import FormValidationMessage from "../../../../FormValidationMessage";
import PromptDirtyForm from "../../../../PromptDirtyForm";
import SpinPageContent from "../../../../SpinPageContent";
import TextEditorPreview from "../../../../TextEditor/TextEditorPreview";
import { ConnectedFormBottomComponent } from "../../FormBottomComponent";
import RegistrationStepCompetitionsFormFieldCompetitions from "./RegistrationStepCompetitionsFormFieldCompetitions";
import {
  schemaRegistrationCompetitionTeamPositionSelectionRequired,
  schemaRegistrationCompetitionTeamSelectionRequired,
  shouldDisplayIndividualQualificationScore,
} from "./utils";
import "./RegistrationStepCompetitions.scss";

const GET_COMPETITIONS_STEP_COMPETITION_LIST = gql`
  query RegistrationStepCompetitions_CompetitionsStepCompetitionList($CompetitionsIds: [String!]!) {
    Competition(where: { id: { _in: $CompetitionsIds } }, order_by: { name: asc }) {
      id
      name
      qualificationScoreRequired
      qualificationScoreLabel
      qualificationScoreType
      qualificationScoreLimitMin
      qualificationScoreLimitMax
      registrantsCanCreateTeams
      managersCanCreateTeams
      teamSelectionRequired
      registrantsCanCreateTeams
      teamCompetition
      teamQualificationScoreDetermination
      teamPositionRequired
      teamPositions
      formatGender
      ageGroups
      ageCutoffAt
      CompetitionCreateTeamRoles {
        id
        Role {
          id
        }
      }
      SportMeet {
        id
        Event {
          id
          ageCutoffAt
        }
      }
    }
  }
`;

const fields = {
  competitions: {
    name: "competitions",
    label: "Competitions",
  },
};

const RegistrationStepCompetitions = ({
  step,
  stepIndex,
  updateRegStepStatus,
  nextStep,
  prevStep,
  regIsReadOnly,
  PersonId,
  showFormBottomComponent,
  readOnly,
}) => {
  const regContext = useRegContext();
  const showIneligibleCompetitions = useGetUserHasPermission(PERMISSION.SHOW_INELIGIBLE_COMPETITIONS);
  const personDateOfBirth = get(regContext, "personData.dob", null);
  const personGender = get(regContext, "personData.gender", null);

  const content = get(step, "meta.content", "");
  const CompetitionsIds = get(step, "meta.competitions", []);

  const validationSchema = useMemo(() => {
    const minCompetitions = get(step, "meta.minCompetitions", 0);
    const maxCompetitions = get(step, "meta.maxCompetitions", null);

    const competitionsText = minCompetitions === 1 ? "competition" : "competitions";

    return yup.object().shape({
      competitions: yup
        .array()
        .of(
          yup.object().shape({
            selected: yup
              .bool()
              .required()
              .when("isEligible", (isEligible, schema) => {
                return schema.test({
                  test: selected => !selected || isEligible,
                });
              }),
            team: schemaRegistrationCompetitionTeamSelectionRequired(yup.string().default("")),
            teamPosition: schemaRegistrationCompetitionTeamPositionSelectionRequired(yup.string().default("")).when(
              ["teamPositions"],
              (teamPositions, _schema) => {
                if (isBlank(teamPositions)) {
                  return _schema;
                }

                return _schema.test(
                  "teamPositionInvalid",
                  "This team position is not valid.",
                  function checkTeamPositionIsValid(teamPositionValue) {
                    if (isEmpty(teamPositionValue)) {
                      // We don't need to check if a value has been set here.
                      return true;
                    }
                    return teamPositions.includes(teamPositionValue);
                  },
                );
              },
            ),

            qualificationScoreValue: schemaQualificationScoreValue(
              schemaCompetitionScoreValue("qualificationScoreType"),
            ),

            // Hidden fields
            qualificationScoreType: schemaNullable(yup.string()),

            // Data fields used only for validation logic. Note: every field which can be present for a competition
            // (including hidden fields) should have a validation rule specified. This allows us to detect when there
            // is a problem with the values for any of these fields.
            qualificationScoreInputDisplayed: yup.bool().required(),
            qualificationScoreRequired: yup.bool().required(),
            qualificationScoreLabel: yup
              .string()
              .trim()
              .required(),
            qualificationScoreLimitMin: schemaNullable(yup.number()),
            qualificationScoreLimitMax: schemaNullable(yup.number()),
            teamSelectionRequired: yup.bool().required(),
            teamPositionRequired: yup.bool().required(),
          }),
        )
        // Generate the dynamic schema based on Mix/Max Competitions
        .test(
          "minCompetitions",
          `Please select at minimum ${minCompetitions} ${competitionsText}.`,
          function checkMinCompetitionsSelection(values) {
            if (!isNil(minCompetitions) && isInteger(minCompetitions)) {
              const selectedCompetitionsCount = values.filter(value => {
                return value.selected === true;
              }).length;

              return selectedCompetitionsCount >= minCompetitions;
            }

            return true;
          },
        )
        .test(
          "maxCompetitions",
          `The maximum number of competitions you can select is ${maxCompetitions}.`,
          function checkMaxCompetitionsSelection(values) {
            if (!isNil(maxCompetitions) && isInteger(maxCompetitions)) {
              const selectedCompetitionsCount = values.filter(value => {
                return value.selected === true;
              }).length;

              return selectedCompetitionsCount <= maxCompetitions;
            }

            return true;
          },
        ),
    });
  }, [step]);

  const {
    loading: regStepValueLoading,
    error: regStepValueError,
    data: regStepValueData,
    refetch: refetchRegStepValue,
  } = useQuery(GET_STEP_REG_STEP_VALUE, {
    variables: {
      PersonId,
      RegId: step.RegId,
      StepId: step.id,
    },
  });

  const {
    loading: competitionsLoading,
    error: competitionsError,
    data: competitionsData,
  } = useQuery(GET_COMPETITIONS_STEP_COMPETITION_LIST, { variables: { CompetitionsIds } });

  const [saveStepRegStepValue] = useMutation(SAVE_STEP_REG_STEP_VALUE);

  const initialValues = useMemo(() => {
    const competitionOptions = get(competitionsData, "Competition", []);
    const regStepValue = get(regStepValueData, "RegStepValue.0", null);
    const competitionsSavedValue = get(regStepValue, "value.competitions", []);

    const competitionsFormValue = [];

    each(competitionOptions, competition => {
      const competitionSavedValue = find(
        competitionsSavedValue,
        _competitionSavedValue => _competitionSavedValue.id === competition.id,
      );

      const competitionQualificationScoreRequired = get(competition, "qualificationScoreRequired", false);
      const competitionQualificationScoreLabel =
        get(competition, "qualificationScoreLabel", "") || "Qualification score";
      const competitionQualificationScoreType = get(competition, "qualificationScoreType", null);
      const competitionQualificationScoreLimitMinValue = get(competition, "qualificationScoreLimitMin.value", null);
      const competitionQualificationScoreLimitMaxValue = get(competition, "qualificationScoreLimitMax.value", null);
      const competitionTeamSelectionRequired = get(competition, "teamSelectionRequired", false);
      const competitionTeamPositionRequired = get(competition, "teamPositionRequired", false);
      const competitionTeamPositions = get(competition, "teamPositions", []);
      const qualificationScoreInputDisplayed = shouldDisplayIndividualQualificationScore(competition);

      const competitionAgeGroups = get(competition, "ageGroups", []);
      const competitionGender = get(competition, "formatGender", null);
      const ageCutoffAt = get(competition, "ageCutoffAt") || get(competition, "SportMeet.Event.ageCutoffAt");

      const isEligible = isPersonEligibleForCompetition(
        { ageCutoffAt, ageGroups: competitionAgeGroups, gender: competitionGender },
        {
          dob: personDateOfBirth,
          gender: personGender,
        },
      );

      if (!competitionSavedValue) {
        // If we don't have a saved value for this competition, use the default values for it.
        competitionsFormValue.push({
          id: competition.id,
          selected: false,
          isEligible,
          team: undefined,
          teamPosition: undefined,
          qualificationScoreValue: "",

          // Hidden fields
          qualificationScoreType: competitionQualificationScoreType,
          teamPositions: competitionTeamPositions,

          // Data fields used only for validation logic
          qualificationScoreInputDisplayed,
          qualificationScoreRequired: competitionQualificationScoreRequired,
          qualificationScoreLabel: competitionQualificationScoreLabel,
          qualificationScoreLimitMin: competitionQualificationScoreLimitMinValue,
          qualificationScoreLimitMax: competitionQualificationScoreLimitMaxValue,
          teamSelectionRequired: competitionTeamSelectionRequired,
          teamPositionRequired: competitionTeamPositionRequired,
        });

        return;
      }

      const competitionHasValidQualificationScoreType = !isNil(
        get(COMPETITION_SCORE_TYPE, competitionQualificationScoreType, null),
      );

      let qualificationScoreValue;

      if (competitionHasValidQualificationScoreType) {
        qualificationScoreValue = formatCompetitionScoreForEdit(
          competitionSavedValue.qualificationScore,
          competitionQualificationScoreType,
        );
      }

      if (isNil(qualificationScoreValue)) {
        qualificationScoreValue = "";
      }

      competitionsFormValue.push({
        id: competition.id,
        selected: true,
        isEligible,
        team: competitionSavedValue.team,
        teamPosition: competitionSavedValue.teamPosition,
        qualificationScoreValue,

        // Hidden fields
        qualificationScoreType: competitionQualificationScoreType,
        teamPositions: competitionTeamPositions,

        // Data fields used only for validation logic
        qualificationScoreInputDisplayed,
        qualificationScoreRequired: competitionQualificationScoreRequired,
        qualificationScoreLabel: competitionQualificationScoreLabel,
        qualificationScoreLimitMin: competitionQualificationScoreLimitMinValue,
        qualificationScoreLimitMax: competitionQualificationScoreLimitMaxValue,
        teamSelectionRequired: competitionTeamSelectionRequired,
        teamPositionRequired: competitionTeamPositionRequired,
      });
    });

    return { competitions: competitionsFormValue };
  }, [regStepValueData, competitionsData, personGender, personDateOfBirth]);

  const areThereAnyEligibleCompetitionsToDisplay = useMemo(() => {
    const { competitions } = initialValues;

    if (competitions.length === 0) {
      return false;
    }

    if (showIneligibleCompetitions) {
      return true;
    }

    return competitions.some(competition => competition.isEligible || competition.selected);
  }, [initialValues, showIneligibleCompetitions]);

  const handleSubmit = useCallback(
    async (values, actions) => {
      try {
        const competitions = values.competitions
          // Only save data for competitions that the user has selected.
          .filter(competitionValue => competitionValue.selected)
          .map(competitionValue => {
            const qualificationScoreValue = formatCompetitionScoreForSaving(
              competitionValue.qualificationScoreValue,
              competitionValue.qualificationScoreType,
            );

            const newCompetitionValue = {
              id: competitionValue.id,
              team: competitionValue.team,
              teamPosition: competitionValue.teamPosition,
            };

            let qualificationScore;

            if (isNull(qualificationScoreValue)) {
              qualificationScore = null;
            } else {
              qualificationScore = {
                type: competitionValue.qualificationScoreType,
                value: qualificationScoreValue,
                rawValue: competitionValue.qualificationScoreValue,
              };
            }

            newCompetitionValue.qualificationScore = qualificationScore;

            return newCompetitionValue;
          });

        const regStepValueObjects = [
          {
            PersonId,
            RegId: step.RegId,
            StepId: step.id,
            value: {
              competitions,
            },
          },
        ];

        await saveStepRegStepValue({ variables: { objects: regStepValueObjects } });

        await updateRegStepStatus(step, REG_STEP_STATUSES.completed);

        await refetchRegStepValue();

        actions.setSubmitting(false);

        message.success("Competitions selection saved.");

        nextStep(true);
      } catch (submitError) {
        console.error(submitError);

        actions.setSubmitting(false);
        actions.setStatus({ type: "error" });

        message.error("Failed to save competitions.");

        Sentry.captureException(submitError);
      }
    },
    [step, PersonId, saveStepRegStepValue, updateRegStepStatus, nextStep, refetchRegStepValue],
  );

  if ((regStepValueLoading && !regStepValueData) || (competitionsLoading && !competitionsData)) {
    return <SpinPageContent />;
  }

  if (regStepValueError || competitionsError) {
    return <AlertFailedLoading message="Competitions data failed to load" />;
  }

  if (!areThereAnyEligibleCompetitionsToDisplay) {
    return (
      <Alert
        message="Not available"
        description="There are currently no competitions for which you are eligible to register."
        type="warning"
        showIcon
      />
    );
  }

  return (
    <>
      <TextEditorPreview defaultValue={content} style={{ marginBottom: 24 }} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(validationSchema.cast(values), actions)}
        isInitialValid={validationSchema.isValidSync(initialValues)}
        enableReinitialize
      >
        {({ status, errors, submitCount, submitForm }) => {
          return (
            <Form {...FORM_ITEM_LAYOUT_PROPS_BY_FORM_LAYOUT.vertical}>
              <RegistrationStepCompetitionsFormFieldCompetitions
                name="competitions"
                disabled={regIsReadOnly}
                competitionsData={competitionsData}
                readOnly={readOnly}
                showIneligibleCompetitions={showIneligibleCompetitions}
              />

              <FormValidationMessage fields={fields} errors={errors} submitCount={submitCount} />

              <FormStatusMessage status={status} />

              {showFormBottomComponent && (
                <ConnectedFormBottomComponent
                  step={step}
                  stepIndex={stepIndex}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  handleSubmit={submitForm}
                  disableSubmit={regIsReadOnly}
                />
              )}

              <PromptDirtyForm />

              <FormikPersist uuid={step.id} setFormikState={false} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

RegistrationStepCompetitions.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.string.isRequired,
    RegId: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      content: PropTypes.string.isRequired,
      sportMeet: PropTypes.string.isRequired,
      competitions: PropTypes.arrayOf(PropTypes.string).isRequired,
      minCompetitions: PropTypes.number.isRequired,
      maxCompetitions: PropTypes.number,
    }),
  }).isRequired,
  stepIndex: PropTypes.number.isRequired,
  updateRegStepStatus: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  regIsReadOnly: PropTypes.bool.isRequired,
  PersonId: PropTypes.string.isRequired,
  showFormBottomComponent: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
};

RegistrationStepCompetitions.defaultProps = {
  readOnly: false,
};

export default RegistrationStepCompetitions;
