import React, { useMemo } from "react";
import { Menu, Dropdown, Button, Tooltip } from "antd";
import { Link, useLocation, matchPath, useHistory } from "react-router-dom";
import each from "lodash/each";
import * as ROUTES from "../../constants/routeConstants";
import isBlank from "../../utils/isBlank";
import useDefaultHomePath from "../../utils/useDefaultHomePath";
import { useAuth0 } from "../Auth0";
import HeaderUserAvatar from "./HeaderUserAvatar";
import "./HeaderUser.scss";

// Paths for which the authentication actions (log in / sign up) should not be shown. These paths are then responsible
// for rendering these actions themselves, as needed.
const HIDE_AUTHENTICATION_ACTIONS_PATHS = [ROUTES.USER_REGISTRATION_DETAIL_PATH];

function HeaderUser() {
  const { loading: authLoading, isAuthenticated, user, loginWithRedirect, logout } = useAuth0();
  const defaultHomePath = useDefaultHomePath();

  const { pathname } = useLocation();
  const history = useHistory();

  const isUserInMyRegistrations = matchPath(pathname, { path: ROUTES.MY_REGISTRATIONS });

  const hideAuthenticationActions = useMemo(() => {
    let shouldHide = false;

    // Check whether the current path matches one of the paths for which we should hide authentication actions.
    each(HIDE_AUTHENTICATION_ACTIONS_PATHS, path => {
      const isPathActive = !!matchPath(pathname, {
        path,
        exact: true,
      });

      if (isPathActive) {
        shouldHide = true;

        return false;
      }

      return null;
    });

    return shouldHide;
  }, [pathname]);

  function handleClickLogOut() {
    logout({
      returnTo: `${window.location.origin}/logout`,
    });
  }

  if (authLoading) {
    return null;
  }

  if (!isAuthenticated) {
    if (hideAuthenticationActions) {
      return null;
    }

    return (
      <div className="header-user__authentication">
        <Button
          onClick={() =>
            loginWithRedirect({
              appState: { targetUrl: window.location.pathname },
            })
          }
          type="primary"
          className="header-user__log-in"
        >
          Log In
        </Button>

        <Button
          onClick={() =>
            loginWithRedirect({
              appState: { targetUrl: window.location.pathname },
              screen_hint: "signup",
            })
          }
        >
          Sign Up
        </Button>
      </div>
    );
  }

  function renderHomeButton() {
    if (isUserInMyRegistrations && !isBlank(defaultHomePath)) {
      return (
        <Tooltip placement="bottomRight" title="Back to administration">
          <Button
            icon="left"
            onClick={() => {
              history.push(defaultHomePath);
            }}
          />
        </Tooltip>
      );
    }

    return (
      <Tooltip placement="bottomRight" title="My registrations">
        <Button
          icon="home"
          onClick={() => {
            history.push(ROUTES.MY_REGISTRATIONS_CURRENT_REGISTRATIONS);
          }}
        />
      </Tooltip>
    );
  }

  function renderMyRegistrationsMenuItem() {
    if (isUserInMyRegistrations) {
      if (!isBlank(defaultHomePath)) {
        return (
          <Menu.Item key="my-registrations">
            <Link to={defaultHomePath}>Administration</Link>
          </Menu.Item>
        );
      }
      return null;
    }

    return (
      <Menu.Item key="my-registrations">
        <Link to={ROUTES.MY_REGISTRATIONS_CURRENT_REGISTRATIONS}>My Registrations</Link>
      </Menu.Item>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <Dropdown
        overlay={
          <Menu className="header-user__menu">
            <Menu.ItemGroup key="account" title={`My Account (${user.email ?? user.sub})`}>
              <Menu.Item key="profile">
                <Link to={ROUTES.PROFILE}>Profile</Link>
              </Menu.Item>
              {renderMyRegistrationsMenuItem()}
              <Menu.Item key="log-out" onClick={handleClickLogOut}>
                Log Out
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        }
        placement="bottomRight"
      >
        <div className="header-user__avatar-container">
          <HeaderUserAvatar user={user} />
        </div>
      </Dropdown>
      <div className="header-user__home-button-container">{renderHomeButton()}</div>
    </>
  );
}

export default HeaderUser;
