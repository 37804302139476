import React, { useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Alert } from "antd";
import gql from "fraql";
import { Route, Switch } from "react-router-dom";
import get from "lodash/get";
import isNil from "lodash/isNil";
import * as ROUTES from "../../../constants/routeConstants";
import { useAuth0 } from "../../Auth0";
import LayoutPageHeader from "../../LayoutPageHeader";
import NoMatchPath from "../../NoMatchPath";
import SpinPageContent from "../../SpinPageContent";
import MyProfile from "./MyProfile";
import { REGISTRATION_LIST_MODE } from "./MyRegistrationsConstants";
import MyRegistrationsList from "./MyRegistrationsList";
import MyRegistrationsNavigation from "./MyRegistrationsNavigation";
import "./MyRegistrationsPage.scss";

const GET_PERSON = gql`
  query MyRegistrationsPage_GetPerson($auth0Id: String!) {
    User(where: { auth0Id: { _eq: $auth0Id } }) {
      id
      Person {
        id
        firstName
      }
    }
  }
`;

const NAVIGATION_ITEMS = {
  CURRENT_REGISTRATIONS: {
    path: ROUTES.MY_REGISTRATIONS_CURRENT_REGISTRATIONS,
    title: "Current Registrations",
  },
  COMPLETED_REGISTRATIONS: {
    path: ROUTES.MY_REGISTRATIONS_COMPLETED_REGISTRATIONS,
    title: "Completed Registrations",
  },
  PROFILE: {
    path: ROUTES.MY_REGISTRATIONS_COMPLETED_PROFILE,
    title: "Profile",
  },
};

function MyRegistrationsPage() {
  const { user } = useAuth0();
  const auth0Id = get(user, "sub", null);

  const { loading: personLoading, error: personError, data: personData } = useQuery(GET_PERSON, {
    variables: { auth0Id },
    skip: !user,
  });

  const parsonName = get(personData, "User.0.Person.firstName", "");
  const PersonId = get(personData, "User.0.Person.id", null);

  const navigationItems = useMemo(() => Object.values(NAVIGATION_ITEMS), []);

  if (personLoading && !personData) {
    return <SpinPageContent style={{ height: "100%" }} />;
  }

  if (personError || !personData || isNil(PersonId)) {
    return (
      <div style={{ padding: 24 }}>
        <Alert
          message="Person failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <>
      <div className="my-registrations-page__header-container">
        <div className="my-registrations-page__header">
          <LayoutPageHeader title={`Welcome, ${parsonName}`} className="my-registrations-page__page-header" />
        </div>
      </div>
      <div className="my-registrations-page__container">
        <div className="my-registrations-page__aside">
          <MyRegistrationsNavigation navigationItems={navigationItems} />
        </div>
        <div className="my-registrations-page__content">
          <Switch>
            <Route
              exact
              path={NAVIGATION_ITEMS.CURRENT_REGISTRATIONS.path}
              component={() => (
                <MyRegistrationsList
                  headerTitle={NAVIGATION_ITEMS.CURRENT_REGISTRATIONS.title}
                  PersonId={PersonId}
                  listMode={REGISTRATION_LIST_MODE.CURRENT}
                />
              )}
            />
            <Route
              exact
              path={NAVIGATION_ITEMS.COMPLETED_REGISTRATIONS.path}
              component={() => (
                <MyRegistrationsList
                  headerTitle={NAVIGATION_ITEMS.COMPLETED_REGISTRATIONS.title}
                  PersonId={PersonId}
                  listMode={REGISTRATION_LIST_MODE.COMPLETED}
                />
              )}
            />
            <Route
              exact
              path={NAVIGATION_ITEMS.PROFILE.path}
              component={() => <MyProfile headerTitle={NAVIGATION_ITEMS.PROFILE.title} PersonId={PersonId} />}
            />
            <Route component={NoMatchPath} />
          </Switch>
        </div>
      </div>
    </>
  );
}

export default MyRegistrationsPage;
