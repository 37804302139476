import React from "react";
import PropTypes from "prop-types";

function EmptyValueText({ text, ...restProps }) {
  // Note: we need to spread any other props passed so that this component works as the child of an Ant Design Tooltip.
  return (
    <span style={{ wordWrap: "break-word", wordBreak: "break-word", color: "#bbb" }} {...restProps}>
      {text}
    </span>
  );
}

EmptyValueText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

EmptyValueText.defaultProps = {
  text: "[Not set]",
};

export default EmptyValueText;
