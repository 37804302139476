import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Menu, Typography } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import SectionHeaderContainer from "../../SectionHeaderContainer";
import "./MyRegistrationsNavigation.scss";

function MyRegistrationsNavigation({ navigationItems }) {
  const { pathname } = useLocation();
  const history = useHistory();

  const handleMenuClick = useCallback(
    menuItem => {
      history.push(menuItem.key);
    },
    [history],
  );

  return (
    <div className="my-registrations-navigation">
      <SectionHeaderContainer>
        <Typography.Title level={3}>My Registrations</Typography.Title>
      </SectionHeaderContainer>
      <Menu onClick={handleMenuClick} selectedKeys={[pathname]} mode="inline" inlineIndent={0}>
        {navigationItems.map(navigationItem => {
          return (
            <Menu.Item key={navigationItem.path} className="my-registrations-navigation__menu-item">
              <div className="my-registrations-navigation__menu-item-label-container">
                <div className="my-registrations-navigation__menu-item-label">{navigationItem.title}</div>
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    </div>
  );
}

MyRegistrationsNavigation.propTypes = {
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default MyRegistrationsNavigation;
