/* eslint-disable import/prefer-default-export */
import get from "lodash/get";
import includes from "lodash/includes";
import {
  COMPETITION_SCORE_TYPE,
  TEAM_QUALIFICATION_SCORE_DETERMINATION,
} from "../../../../../constants/competitionConstants";
import isBlank from "../../../../../utils/isBlank";

/**
 * Generate the validation schema for the team field dynamically based on the `teamSelectionRequired` value.
 */
export function schemaRegistrationCompetitionTeamSelectionRequired(
  fieldSchema,
  isCompetitionSelectedFieldName = "selected",
  teamSelectionRequiredFieldName = "teamSelectionRequired",
) {
  return fieldSchema.when(
    [isCompetitionSelectedFieldName, teamSelectionRequiredFieldName],
    (isCompetitionSelected, teamSelectionRequired, _schema) => {
      if (!isCompetitionSelected || !teamSelectionRequired) {
        return _schema;
      }

      return _schema.test("teamSelectionIsRequired", "A team must be selected.", function checkTeamSelectionIsRequired(
        teamValue,
      ) {
        return !isBlank(teamValue);
      });
    },
  );
}

/**
 * Generate the validation schema for the team position field dynamically based on the `teamPositionRequired` value.
 */
export function schemaRegistrationCompetitionTeamPositionSelectionRequired(
  fieldSchema,
  isCompetitionSelectedFieldName = "selected",
  teamPositionSelectionRequiredFieldName = "teamPositionRequired",
  teamFieldName = "team",
) {
  return fieldSchema.when(
    [isCompetitionSelectedFieldName, teamPositionSelectionRequiredFieldName, teamFieldName],
    (isCompetitionSelected, teamPositionSelectionRequired, team, _schema) => {
      if (!isCompetitionSelected || !teamPositionSelectionRequired || isBlank(team)) {
        return _schema;
      }

      return _schema.test(
        "teamPositionSelectionIsRequired",
        "A team position must be selected.",
        function checkTeamPositionSelectionIsRequired(teamPositionValue) {
          return !isBlank(teamPositionValue);
        },
      );
    },
  );
}

export function shouldDisplayIndividualQualificationScore(competition) {
  const competitionQualificationScoreType = get(competition, "qualificationScoreType", null);

  if (!includes(Object.values(COMPETITION_SCORE_TYPE), competitionQualificationScoreType)) {
    return false;
  }

  if (
    get(competition, "teamCompetition", false) &&
    get(competition, "teamQualificationScoreDetermination", "") ===
      TEAM_QUALIFICATION_SCORE_DETERMINATION.PROVIDED_EXPLICITLY
  ) {
    return false;
  }

  return true;
}
