import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { TimePicker } from "antd";
import { connect } from "formik";
import moment from "moment";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { TIME_FORMAT, VIEW_TIME_FORMAT } from "../../constants/dateTimeConstants";
import { parseDateTime } from "../../utils/dateTimeUtils";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldReadOnlyContent from "./FormFieldReadOnlyFields/FormFieldReadOnlyContent";

function FormFieldTime(props) {
  const { name, meta, disabled, formik, readOnly } = props;

  const value = get(formik, `values.${name}`, "");
  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);

  const defaultValue = useMemo(() => (!isEmpty(value) ? parseDateTime(value, TIME_FORMAT) : null), [value]);

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Select a time";
  }

  function handleChangeTime(momentObject) {
    const newValue = moment.isMoment(momentObject) ? momentObject.format(TIME_FORMAT) : "";

    formik.setFieldValue(name, newValue);
    formik.setFieldTouched(name, true);
  }

  function handleOpenPanel() {
    formik.setFieldTouched(name, true);
  }

  function getReadOnlyContent() {
    return moment.isMoment(defaultValue) ? defaultValue.format(VIEW_TIME_FORMAT) : null;
  }

  if (readOnly) {
    return <FormFieldReadOnlyContent name={name} meta={meta} content={getReadOnlyContent()} />;
  }

  return (
    <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel}>
      <TimePicker
        use12Hours={false}
        name={name}
        value={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        format={VIEW_TIME_FORMAT}
        minuteStep={1}
        onChange={handleChangeTime}
        onOpenChange={handleOpenPanel}
      />
    </FormFieldFormItem>
  );
}

FormFieldTime.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

FormFieldTime.defaultProps = {
  disabled: false,
  readOnly: false,
};

export default connect(FormFieldTime);
