import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { ApolloProvider } from "@apollo/react-hooks";
import apolloManager from "../utils/ApolloClientManager";
import { useAuth0 } from "./Auth0";

function ApolloProviderWrapper({ children }) {
  const { token, role } = useAuth0();

  const client = useMemo(() => apolloManager.createApolloClient(token, role), [token, role]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

ApolloProviderWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default ApolloProviderWrapper;
