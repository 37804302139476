import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import useAuthenticatedRoute from "../utils/useAuthenticatedRoute";
import { useGetUserHasPermission } from "../utils/useGetUserHasPermission";
import { useAuth0 } from "./Auth0";
import AuthenticationRequiredPage from "./AuthenticationRequiredPage";
import SpinPageContent from "./SpinPageContent";
import UnauthorisedPage from "./UnauthorisedPage";

const PrivateRoute = ({ component: Component, path, location, permission, ...rest }) => {
  useAuthenticatedRoute(location);

  const { loading: authLoading, isAuthenticated } = useAuth0();

  const canAccessRoute = useGetUserHasPermission(permission);

  const render = props => {
    if (authLoading) {
      return <SpinPageContent style={{ height: "100%" }} />;
    }

    if (!isAuthenticated) {
      return <AuthenticationRequiredPage />;
    }

    if (!canAccessRoute) {
      return <UnauthorisedPage />;
    }

    return <Component {...props} />;
  };

  return <Route path={path} location={location} render={render} {...rest} />;
};

PrivateRoute.propTypes = {
  location: PropTypes.object,
  permission: PropTypes.string,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

PrivateRoute.defaultProps = {
  location: undefined,
  permission: null,
};

export default PrivateRoute;
