import gql from "fraql";

// eslint-disable-next-line import/prefer-default-export
export const GET_PERSON_REG_SUBMISSION = gql`
  query RegistrationUserSubmit_GetPersonRegSubmission($regId: String!, $personId: String!) {
    RegSubmission(where: { RegId: { _eq: $regId }, PersonId: { _eq: $personId } }) {
      id
      status

      Reg {
        id
        archived
      }
    }
  }
`;
