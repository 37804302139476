import { FIELD_TYPES } from "./fieldConstants";

export const DEFAULT_REQUIRED_MSG = "Please enter a value.";

export const REQUIRED_MESSAGES = {
  [FIELD_TYPES.text]: DEFAULT_REQUIRED_MSG,
  [FIELD_TYPES.textarea]: DEFAULT_REQUIRED_MSG,
  [FIELD_TYPES.select]: "Please select a value.",
  [FIELD_TYPES.bool]: "Please select a value.",
  [FIELD_TYPES.date]: "Please select a date.",
  [FIELD_TYPES.time]: "Please select a time.",
  [FIELD_TYPES.datetime]: "Please select a date/time.",
  [FIELD_TYPES.number]: DEFAULT_REQUIRED_MSG,
  [FIELD_TYPES.currency]: "Please enter a currency amount.",
  [FIELD_TYPES.file]: "Please select a file.",
  [FIELD_TYPES.image]: "Please select an image.",
  [FIELD_TYPES.phone]: "Please enter a phone number.",
  [FIELD_TYPES.email]: "Please enter an email address.",
  [FIELD_TYPES.country]: "Please select a country.",
  [FIELD_TYPES.language]: "Please select a language.",
  [FIELD_TYPES.gender]: "Please select a gender.",
  [FIELD_TYPES.group]: "Please select a group.",
};

export const TYPE_ERROR_MESSAGES = {
  [FIELD_TYPES.number]: "Please enter a value that is a number.",
  [FIELD_TYPES.email]: "Please enter a valid email address.",
};
