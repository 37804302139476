import gql from "fraql";
import { map, get, find, filter, each, isEmpty, isEqual, omit, uniq, includes } from "lodash";
import intersection from "lodash/intersection";
import { REG_STEP_STATUSES, SUBMISSION_STATUS_STEP_ID } from "../../../constants/regStatusConstants";
import { STEP_TYPE } from "../../../constants/stepsConstants";
import { getAgeRequirementIsMetFromAge } from "../../../utils/ageUtils";
import { hasPermission, PERMISSION } from "../../../utils/useGetUserHasPermission";
import { CONDITION_TYPES } from "../../MenuAdmin/Registrations/RegistrationCondition";

export const UPDATE_REG_SUBMISSION_STATUS = gql`
  mutation PersonDetailRegistrations_UpdateRegSubmissionStatus(
    $RegSubmissionId: ID!
    $status: String!
    $stepIds: [String!]!
  ) {
    updateRegSubmissionStatus(RegSubmissionId: $RegSubmissionId, status: $status, stepIds: $stepIds) {
      updated {
        id
        status
        lastSubmittedAt
      }
    }
  }
`;

export const GET_VALUES_FOR_CONDITION_CUSTOM_FIELDS = gql`
  query PersonDetailRegistrations_GetCustomFieldValues(
    $customFieldIds: [String!]!
    $formIds: [String!]!
    $GroupId: String!
    $PersonId: String!
  ) {
    FormField(
      where: { _and: { FormId: { _in: $formIds }, FieldId: { _in: $customFieldIds }, deleted: { _eq: false } } }
    ) {
      id
      FieldId
      Values(where: { GroupId: { _eq: $GroupId }, PersonId: { _eq: $PersonId } }) {
        id
        value
      }
    }
  }
`;

export function mapDataToRegStatuses(data) {
  if (!data) {
    return [];
  }

  return data.RegStatus.map(regStatus => omit(regStatus, ["__typename"]));
}

export function getPersonRolesAndGroupsFromMemberData(data) {
  if (!data) {
    return [];
  }

  const personRoles = [];
  const personGroups = [];

  data.Member.forEach(Member => {
    const RoleId = get(Member, "RoleId", null);

    if (RoleId) {
      personRoles.push(RoleId);
    }

    personGroups.push(Member.GroupId);
  });

  return {
    personRoles,
    personGroups,
  };
}

/**
 * Get values from RegStepValue records to use for evaluating whether Step conditions are met. Note that this function
 * needs to specifically handle different kinds of values that can appear in RegStepValue records, since different
 * kinds of values may take different forms (e.g. one kind could have a field with an array value, another kind could
 * have a field with an object value, etc.). Because of this, it's not safe to have a generic solution here that tries
 * to produce a return value under the assumption that the value in every RegStepValue record will have the same shape
 * or format.
 */
export function getPersonRegStepValues(regStepValuesData) {
  const regStepValues = get(regStepValuesData, "RegStepValue", []);

  const personRegStepValues = { sports: [] };

  each(regStepValues, regStepValue => {
    const value = get(regStepValue, "value", {});
    const sports = get(value, "sports", []);

    personRegStepValues.sports = [...personRegStepValues.sports, ...sports];
  });

  personRegStepValues.sports = uniq(personRegStepValues.sports);

  return personRegStepValues;
}

export function getRegChargesValues(regChargesValuesData) {
  const regChargeValues = get(regChargesValuesData, "RegStepValue", []);

  return map(regChargeValues, "value.charge").flat();
}

export function getPreparedSteps(
  steps,
  regStatuses,
  personRolesIds,
  personGroupsIds,
  personRegStepValues,
  regFormFieldValuesForConditionCustomFields,
  personAge,
  userRole,
  regSubmission,
) {
  const preparedSteps = steps
    .filter(step => {
      // All "Fee" steps are hidden from the registrants view.
      const stepType = get(step, "meta.type", []);
      if (stepType === STEP_TYPE.FEE) {
        return false;
      }

      const conditions = get(step, "meta.conditions", []);
      if (isEmpty(conditions)) {
        return true;
      }
      const conditionResults = conditions.map(condition => {
        // Assume that the condition has not been met by default. The logic below then tries to determine whether the
        // condition has been met, and will only do this if the necessary data is available and the condition logic
        // determines that the condition is met.
        let conditionIsMet = false;

        const { type } = condition;

        if (type === CONDITION_TYPES.selectedRoles) {
          const stepRolesIds = get(condition, "roles", []);

          if (!isEmpty(intersection(stepRolesIds, personRolesIds))) {
            conditionIsMet = true;
          }
        } else if (type === CONDITION_TYPES.selectedGroups) {
          const stepGroupsIds = get(condition, "groups", []);

          if (!isEmpty(intersection(stepGroupsIds, personGroupsIds))) {
            conditionIsMet = true;
          }
        } else if (type === CONDITION_TYPES.selectedSports) {
          const stepSportsIds = get(condition, "sports", []);
          const personSelectedSportsIds = get(personRegStepValues, "sports", []);

          if (!isEmpty(intersection(stepSportsIds, personSelectedSportsIds))) {
            conditionIsMet = true;
          }
        } else if (type === CONDITION_TYPES.fieldValue) {
          const { field: conditionCustomFieldId, fieldValue: conditionFieldValue } = condition;

          const regFormFieldValue = find(
            regFormFieldValuesForConditionCustomFields,
            ({ customFieldId }) => customFieldId === conditionCustomFieldId,
          );

          if (regFormFieldValue) {
            const { value: savedFieldValue } = regFormFieldValue;

            if (isEqual(conditionFieldValue, savedFieldValue)) {
              conditionIsMet = true;
            }
          }
        } else if (type === CONDITION_TYPES.ageRange) {
          const { minAge, maxAge } = condition;

          const conditionAgeRequirementIsMet = getAgeRequirementIsMetFromAge(personAge, minAge, maxAge);
          const conditionAgeRequirementCheckedAndIsMet = conditionAgeRequirementIsMet === true;

          if (conditionAgeRequirementCheckedAndIsMet) {
            conditionIsMet = true;
          }
        } else if (type === CONDITION_TYPES.participationDelegation) {
          const delegationGroupId = get(regSubmission, "DelegationGroupId", null);
          const groups = get(condition, "groups", []);

          if (groups.includes(delegationGroupId)) {
            conditionIsMet = true;
          }
        } else if (type === CONDITION_TYPES.participationRole) {
          const roleId = get(regSubmission, "RoleId", null);
          const roles = get(condition, "roles", []);

          if (roles.includes(roleId)) {
            conditionIsMet = true;
          }
        } else {
          console.error(`Unknown condition type: ${type}`);
        }

        return conditionIsMet;
      });

      // We do not display the step if a single condition has not been met (i.e. its result is `false`). In other words,
      // we only display the step if all conditions have been met. So for now, we are using "AND" logic for the
      // conditions, rather than "OR" logic. In the future, we may make it possible to configure for a step whether
      // that step's conditions should use "AND" logic or "OR" logic, and would need to take that into account here to
      // return the correct result.
      return !includes(conditionResults, false);
    })
    .map(step => {
      const regStepStatus = regStatuses.find(regStatusItem => regStatusItem.StepId === step.id);
      const stepStatusId = get(regStepStatus, "id", undefined);
      const stepStatus = get(regStepStatus, "status", undefined);

      return { ...step, RegStatus: { id: stepStatusId, status: stepStatus } };
    });

  // Add "Submission status" as a 'fake' step that should be rendered along with the real ones.
  if (preparedSteps.length && hasPermission({ permission: PERMISSION.SHOW_SUBMISSION_STATUS_STEP, userRole })) {
    preparedSteps.push({
      id: SUBMISSION_STATUS_STEP_ID,
      name: "Submission status",
      RegStatus: { status: REG_STEP_STATUSES.statusNotApplicable },
    });
  }

  return preparedSteps;
}

export function getFeeSteps(steps, regStatuses) {
  const preparedFeeSteps = steps
    .filter(step => {
      const stepType = get(step, "meta.type", []);
      if (stepType === STEP_TYPE.FEE) {
        return true;
      }
      return false;
    })
    .map(step => {
      const regStepStatus = regStatuses.find(regStatusItem => regStatusItem.StepId === step.id);
      const stepStatusId = get(regStepStatus, "id", undefined);
      const stepStatus = get(regStepStatus, "status", undefined);

      return { ...step, RegStatus: { id: stepStatusId, status: stepStatus } };
    });

  return preparedFeeSteps;
}
export const preparedStepsWithoutSubmissionsStatusStep = steps => {
  return filter(steps, step => step.id !== SUBMISSION_STATUS_STEP_ID);
};
