import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Alert, Typography } from "antd";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { REG_SUBMISSION_STATUS_LABEL, REG_SUBMISSION_STATUSES } from "../../../constants/regStatusConstants";
import { parseDateTime } from "../../../utils/dateTimeUtils";
import DateTimeValueWithTimezone from "../../DateTimeValueWithTimezone";

const ALERT_CONTENT_MAP = {
  [REG_SUBMISSION_STATUSES.submitted]: {
    description: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.submitted],
    type: "info",
  },
  [REG_SUBMISSION_STATUSES.approved]: {
    description: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.approved],
    type: "success",
  },
  [REG_SUBMISSION_STATUSES.rejected]: {
    description: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.rejected],
    type: "warning",
  },
  [REG_SUBMISSION_STATUSES.notSubmitted]: {
    description: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.notSubmitted],
    type: "info",
  },
  [REG_SUBMISSION_STATUSES.reverted]: {
    description: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.notSubmitted],
    type: "info",
  },
  [REG_SUBMISSION_STATUSES.deregistered]: {
    description: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.deregistered],
    type: "warning",
  },
};

function getLastSubmittedAtDescription(description, lastSubmittedAt, regTimezoneName) {
  return (
    <>
      <div>{description}</div>
      <Typography.Text type="secondary" className="person-registration-submission-status-alert__last-submitted-at">
        Last submitted:{" "}
        <DateTimeValueWithTimezone value={parseDateTime(lastSubmittedAt)} referenceTimezoneName={regTimezoneName} />
      </Typography.Text>
    </>
  );
}

function PersonRegistrationSubmissionStatusAlert({ status, style, lastSubmittedAt, regTimezoneName }) {
  const alert = useMemo(() => {
    const alertContent = {
      ...get(ALERT_CONTENT_MAP, status, {
        type: "error",
        description: "[Unknown status]",
      }),
    };

    if (isNil(lastSubmittedAt)) {
      return alertContent;
    }

    if (
      ![REG_SUBMISSION_STATUSES.submitted, REG_SUBMISSION_STATUSES.approved, REG_SUBMISSION_STATUSES.rejected].includes(
        status,
      )
    ) {
      return alertContent;
    }

    alertContent.description = getLastSubmittedAtDescription(
      alertContent.description,
      lastSubmittedAt,
      regTimezoneName,
    );

    return alertContent;
  }, [status, lastSubmittedAt, regTimezoneName]);

  return (
    <Alert
      message="Registration"
      description={alert.description}
      type={alert.type}
      showIcon={false}
      className="ant-alert-with-description alert-registration-status"
      style={style}
    />
  );
}

PersonRegistrationSubmissionStatusAlert.propTypes = {
  status: PropTypes.string,
  lastSubmittedAt: PropTypes.string,
  style: PropTypes.any,
  regTimezoneName: PropTypes.string.isRequired,
};

PersonRegistrationSubmissionStatusAlert.defaultProps = {
  status: null,
  lastSubmittedAt: null,
  style: undefined,
};

export default PersonRegistrationSubmissionStatusAlert;
