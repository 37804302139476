import React from "react";
import gql from "fraql";
import { useRegGroupContext } from "../../utils/useRegGroupContext";
import FormFieldGroup from "./FormFieldGroup";

// Fetches Groups based on GroupID presence in a groups Path + the top level Group.
// The effectively fetches the hierachy structure of the target Group.
const GET_REG_GROUPS_IN_PATH = gql`
  query FormFieldGroup_GetGroupsInPath($GroupId: String!, $WildCardGroupId: String!) {
    Group(where: { _or: [{ id: { _eq: $GroupId } }, { path: { _like: $WildCardGroupId } }] }, order_by: { name: asc }) {
      id
      name
      parentId
      path
    }
  }
`;

// Creates a FormFieldGroup picker based on the target Groups Hierachy.
function FormFieldRegGroup(props) {
  // Prepare queryOptions variables list
  const groupId = useRegGroupContext();
  // Hasura won't handle wildcard (%) insertion. We have to insert it ourselves via template string before sending request.
  const wildCardGroupId = `%${groupId}%`;
  const queryOptions = { variables: { GroupId: groupId, WildCardGroupId: wildCardGroupId } };

  // Return the usual FormFieldGroup component, but override the Query + Query Options with our targeted hierachy query.
  return (
    <FormFieldGroup
      {...props}
      query={GET_REG_GROUPS_IN_PATH}
      queryOptions={queryOptions}
    />
  );
};

export default FormFieldRegGroup;
