import filter from "lodash/filter";
import get from "lodash/get";

export const REG_SUBMISSION_STATUSES = {
  notSubmitted: "NOT_SUBMITTED",
  submitted: "SUBMITTED",
  approved: "APPROVED",
  rejected: "REJECTED",
  reverted: "REVERTED",
  deregistered: "DEREGISTERED",
  payment: "PAYMENT",
};

export const REG_STEP_STATUSES = {
  completed: "COMPLETED",
  notCompleted: "NOT_COMPLETED",
  statusNotApplicable: "STATUS_NOT_APPLICABLE",
};

export const SUBMISSION_STATUS_STEP_ID = "SUBMISSION_STATUS";

export const REG_SUBMISSION_STATUS_LABEL = {
  [REG_SUBMISSION_STATUSES.notSubmitted]: "Not submitted",
  [REG_SUBMISSION_STATUSES.submitted]: "Submitted",
  [REG_SUBMISSION_STATUSES.approved]: "Approved",
  [REG_SUBMISSION_STATUSES.rejected]: "Rejected",
  [REG_SUBMISSION_STATUSES.reverted]: "Reverted",
  [REG_SUBMISSION_STATUSES.deregistered]: "Deregistered",
  [REG_SUBMISSION_STATUSES.payment]: "Awaiting Payment",
};

export const REG_SUBMISSION_STATUS_OPTIONS = [
  {
    text: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.notSubmitted],
    value: REG_SUBMISSION_STATUSES.notSubmitted,
  },
  {
    text: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.submitted],
    value: REG_SUBMISSION_STATUSES.submitted,
  },
  {
    text: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.approved],
    value: REG_SUBMISSION_STATUSES.approved,
  },
  {
    text: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.rejected],
    value: REG_SUBMISSION_STATUSES.rejected,
  },
  {
    text: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.reverted],
    value: REG_SUBMISSION_STATUSES.reverted,
  },
  {
    text: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.deregistered],
    value: REG_SUBMISSION_STATUSES.deregistered,
  },
  {
    text: REG_SUBMISSION_STATUS_LABEL[REG_SUBMISSION_STATUSES.payment],
    value: REG_SUBMISSION_STATUSES.payment,
  },
];

export const PERSON_TABLE_REG_SUBMISSION_STATUS_OPTIONS = filter(
  REG_SUBMISSION_STATUS_OPTIONS,
  option => get(option, "value") !== REG_SUBMISSION_STATUSES.deregistered,
);

const getStatusOptionsForValues = statusValues => {
  return REG_SUBMISSION_STATUS_OPTIONS.filter(option => statusValues.includes(get(option, "value")));
};

export const LIMITED_REG_SUBMISSION_STATUS_TRANSITIONS_OPTIONS = {
  [REG_SUBMISSION_STATUSES.notSubmitted]: getStatusOptionsForValues([
    REG_SUBMISSION_STATUSES.submitted,
    REG_SUBMISSION_STATUSES.deregistered,
  ]),
  [REG_SUBMISSION_STATUSES.submitted]: getStatusOptionsForValues([REG_SUBMISSION_STATUSES.deregistered]),
  [REG_SUBMISSION_STATUSES.rejected]: getStatusOptionsForValues([REG_SUBMISSION_STATUSES.deregistered]),
  [REG_SUBMISSION_STATUSES.approved]: getStatusOptionsForValues([REG_SUBMISSION_STATUSES.deregistered]),
  [REG_SUBMISSION_STATUSES.reverted]: getStatusOptionsForValues([
    REG_SUBMISSION_STATUSES.submitted,
    REG_SUBMISSION_STATUSES.deregistered,
  ]),
  [REG_SUBMISSION_STATUSES.deregistered]: getStatusOptionsForValues([REG_SUBMISSION_STATUSES.notSubmitted]),
};

export const ALL_REG_SUBMISSION_STATUS_TRANSITIONS_OPTIONS = {
  [REG_SUBMISSION_STATUSES.notSubmitted]: getStatusOptionsForValues([
    REG_SUBMISSION_STATUSES.submitted,
    REG_SUBMISSION_STATUSES.deregistered,
  ]),
  [REG_SUBMISSION_STATUSES.submitted]: getStatusOptionsForValues([
    REG_SUBMISSION_STATUSES.approved,
    REG_SUBMISSION_STATUSES.rejected,
    REG_SUBMISSION_STATUSES.reverted,
    REG_SUBMISSION_STATUSES.deregistered,
  ]),
  [REG_SUBMISSION_STATUSES.rejected]: getStatusOptionsForValues([
    REG_SUBMISSION_STATUSES.reverted,
    REG_SUBMISSION_STATUSES.deregistered,
  ]),
  [REG_SUBMISSION_STATUSES.approved]: getStatusOptionsForValues([
    REG_SUBMISSION_STATUSES.rejected,
    REG_SUBMISSION_STATUSES.reverted,
    REG_SUBMISSION_STATUSES.deregistered,
  ]),
  [REG_SUBMISSION_STATUSES.reverted]: getStatusOptionsForValues([
    REG_SUBMISSION_STATUSES.submitted,
    REG_SUBMISSION_STATUSES.deregistered,
  ]),
  [REG_SUBMISSION_STATUSES.deregistered]: getStatusOptionsForValues([REG_SUBMISSION_STATUSES.notSubmitted]),
};

export const REGISTRATION_STATUS = {
  UPCOMING: "UPCOMING",
  LIVE: "LIVE",
  FINISHED: "FINISHED",
  ARCHIVED: "ARCHIVED",
};

export const REGISTRATION_STATUS_LABEL = {
  [REGISTRATION_STATUS.UPCOMING]: "Upcoming",
  [REGISTRATION_STATUS.LIVE]: "Live",
  [REGISTRATION_STATUS.FINISHED]: "Finished",
  [REGISTRATION_STATUS.ARCHIVED]: "Archived",
};
