import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../StatusText";
import { FORM_FIELD_COMPONENT_MAP } from "./constants";

function FormField({
  name,
  type,
  meta,
  submitCount,
  builder,
  showFormFieldContentAsPreview,
  relatedRecordsData,
  readOnly,
}) {
  const FormFieldComponent = get(FORM_FIELD_COMPONENT_MAP, type, null);

  if (!FormFieldComponent) {
    if (builder) {
      return (
        <div style={{ lineHeight: "40px" }}>
          <StatusText status={STATUS_TEXT_STATUS_OPTIONS.ERROR}>[Unsupported Form Field]</StatusText>
        </div>
      );
    }

    return (
      <div style={{ lineHeight: "40px", marginBottom: 24 }}>
        <StatusText status={STATUS_TEXT_STATUS_OPTIONS.ERROR}>[Unsupported Form Field]</StatusText>
      </div>
    );
  }

  const loading = get(meta, "loading", false);
  const alwaysDisabled = get(meta, "disabled", false);
  const query = get(meta, "query", undefined);
  const queryOptions = get(meta, "queryOptions", undefined);

  const disabled = loading || alwaysDisabled || builder;

  return (
    <FormFieldComponent
      name={name}
      meta={meta}
      disabled={disabled}
      submitCount={submitCount}
      showFormFieldContentAsPreview={showFormFieldContentAsPreview}
      builder={builder}
      query={query}
      queryOptions={queryOptions}
      relatedRecordsData={relatedRecordsData}
      readOnly={readOnly}
    />
  );
}

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  submitCount: PropTypes.number,
  builder: PropTypes.bool,
  showFormFieldContentAsPreview: PropTypes.bool,
  relatedRecordsData: PropTypes.object,
  readOnly: PropTypes.bool,
};

FormField.defaultProps = {
  submitCount: 0,
  builder: false,
  showFormFieldContentAsPreview: false,
  relatedRecordsData: null,
  readOnly: false,
};

export default FormField;
