import React from "react";
import PropTypes from "prop-types";
import { Alert, Icon } from "antd";
import { SELECT_FIELD_MODES } from "../../../constants/fieldConstants";
import FormFieldRole, { DISPLAY_MODES as FORM_FIELD_ROLE_DISPLAY_MODES } from "../FormFieldRole";
import FormFieldSelect from "../FormFieldSelect";
import FormFieldText from "../FormFieldText";

export const CONDITION_TYPES = {
  PARTICIPATION_ROLE: "PARTICIPATION_ROLE",
  SIZE_LIMIT: "SIZE_LIMIT",
  TEAM_POSITION_MANDATORY: "TEAM_POSITION_MANDATORY",
};

const CONDITION_TYPE_OPTIONS = [{ value: CONDITION_TYPES.PARTICIPATION_ROLE, label: "Participation role" }];

function TeamCondition(props) {
  const { index, condition, submitCount, handleClickRemoveCondition, handleChangeConditionType } = props;

  function renderConditionOptions() {
    switch (condition.type) {
      case CONDITION_TYPES.PARTICIPATION_ROLE: {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "33%", marginRight: 12 }}>
              <FormFieldRole
                name={`teamConditions.${index}.role`}
                meta={{
                  label: "Role",
                  placeholder: "Select role",
                  displayMode: FORM_FIELD_ROLE_DISPLAY_MODES.select,
                  mode: SELECT_FIELD_MODES.default,
                  allowClear: true,
                  required: true,
                }}
                submitCount={submitCount}
              />
            </div>

            <div style={{ width: "33%", marginRight: 12 }}>
              <FormFieldText
                name={`teamConditions.${index}.minCount`}
                meta={{ label: "Min", placeholder: "Enter a number" }}
                submitCount={submitCount}
              />
            </div>

            <div style={{ width: "33%" }}>
              <FormFieldText
                name={`teamConditions.${index}.maxCount`}
                meta={{ label: "Max", placeholder: "Enter a number" }}
                submitCount={submitCount}
              />
            </div>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  }

  function renderConditionAlert() {
    let message = null;

    if (condition.type === CONDITION_TYPES.PARTICIPATION_ROLE) {
      message = (
        <span>
          Team is eligible if the number of members with the selected role is the same or less than the maximum and the
          same or greater than the minimum.
        </span>
      );
    }

    if (!message) {
      return null;
    }

    return (
      <div style={{ marginTop: 12 }}>
        <Alert type="info" message={message} showIcon />
      </div>
    );
  }

  return (
    <div style={{ padding: 12, borderBottom: "1px solid #d9d9d9", width: "100%" }}>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ marginRight: 12 }}>
          <FormFieldSelect
            name={`teamConditions.${index}.type`}
            meta={{
              placeholder: "Select a type",
              label: "Type",
              style: { minWidth: 200 },
              handleSelectChange: handleChangeConditionType,
              options: CONDITION_TYPE_OPTIONS,
              allowClear: true,
              required: true,
            }}
            submitCount={submitCount}
          />
        </div>

        <div style={{ flexGrow: 1, display: "flex" }}>
          <div style={{ flexGrow: 1 }}>{renderConditionOptions()}</div>

          <Icon
            type="close"
            style={{
              fontSize: 18,
              marginLeft: 5,
              cursor: "pointer",
              height: 32,
              padding: "7px 0 7px 7px",
              marginTop: 29,
            }}
            onClick={handleClickRemoveCondition}
          />
        </div>
      </div>

      {renderConditionAlert()}
    </div>
  );
}

TeamCondition.propTypes = {
  index: PropTypes.number.isRequired,
  condition: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired,
  handleClickRemoveCondition: PropTypes.func.isRequired,
  handleChangeConditionType: PropTypes.func.isRequired,
};

export default TeamCondition;
