export const TRAVEL_GROUP_TYPE = {
  ARRIVAL: "ARRIVAL",
  DEPARTURE: "DEPARTURE",
};

export const TRAVEL_GROUP_TYPE_LABEL = {
  [TRAVEL_GROUP_TYPE.ARRIVAL]: "Arrival",
  [TRAVEL_GROUP_TYPE.DEPARTURE]: "Departure",
};

export const REG_TRAVEL_STATUS = {
  INCOMPLETE: "INCOMPLETE",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETE: "COMPLETE",
};

export const REG_TRAVEL_STATUS_LABEL = {
  [REG_TRAVEL_STATUS.INCOMPLETE]: "Not submitted",
  [REG_TRAVEL_STATUS.IN_PROGRESS]: "In progress",
  [REG_TRAVEL_STATUS.COMPLETE]: "Submitted",
};

export const REG_TRAVEL_STATUS_OPTIONS = [
  {
    text: REG_TRAVEL_STATUS_LABEL[REG_TRAVEL_STATUS.INCOMPLETE],
    value: REG_TRAVEL_STATUS.INCOMPLETE,
  },
  {
    text: REG_TRAVEL_STATUS_LABEL[REG_TRAVEL_STATUS.IN_PROGRESS],
    value: REG_TRAVEL_STATUS.IN_PROGRESS,
  },
  {
    text: REG_TRAVEL_STATUS_LABEL[REG_TRAVEL_STATUS.COMPLETE],
    value: REG_TRAVEL_STATUS.COMPLETE,
  },
];
