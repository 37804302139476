export const GENDER_TYPE = {
  male: "Male",
  female: "Female",
  x: "X",
  prefer_not_to_answer: "prefer_not_to_answer",
};

export const GENDER_TYPE_LABEL = {
  [GENDER_TYPE.male]: "Male",
  [GENDER_TYPE.female]: "Female",
  [GENDER_TYPE.x]: "X",
  [GENDER_TYPE.prefer_not_to_answer]: "Prefer not to answer",
  // Fallback in case we somehow have values that are lowercase when they aren't meant to be.
  male: "Male",
  female: "Female",
  x: "X",
};

export const GENDER_TYPE_DISPLAY_LABEL = {
  [GENDER_TYPE.male]: "Male",
  [GENDER_TYPE.female]: "Female",
  [GENDER_TYPE.x]: "X",
  [GENDER_TYPE.prefer_not_to_answer]: "Won't say",
  // Fallback in case we somehow have values that are lowercase when they aren't meant to be.
  male: "Male",
  female: "Female",
  x: "X",
};

export const AGE_RANGE_LABEL = {
  age_under_18: "Under 18",
  age_18_24: "18 - 24",
  age_25_34: "25 - 34",
  age_35_and_over: "35 and over",
  age_unknown: "Unknown",
};

// Create Competition
export const COMPETITION_GENDER_FORMAT = {
  male: "Male",
  female: "Female",
  mixed: "Mixed",
  open: "Open",
};

export const COMPETITION_GENDER_FORMAT_LABEL = {
  [COMPETITION_GENDER_FORMAT.male]: "Male",
  [COMPETITION_GENDER_FORMAT.female]: "Female",
  [COMPETITION_GENDER_FORMAT.mixed]: "Mixed",
  [COMPETITION_GENDER_FORMAT.open]: "Open",
};

export const GENDER_TYPE_OPTIONS = [
  { value: GENDER_TYPE.male, label: GENDER_TYPE_LABEL[GENDER_TYPE.male] },
  { value: GENDER_TYPE.female, label: GENDER_TYPE_LABEL[GENDER_TYPE.female] },
  { value: GENDER_TYPE.x, label: GENDER_TYPE_LABEL[GENDER_TYPE.x] },
  { value: GENDER_TYPE.prefer_not_to_answer, label: GENDER_TYPE_LABEL[GENDER_TYPE.prefer_not_to_answer] },
];

export const GENDER_TYPE_OPTIONS_DISPLAY = [
  {
    text: GENDER_TYPE_DISPLAY_LABEL[GENDER_TYPE.male],
    value: GENDER_TYPE.male,
  },
  {
    text: GENDER_TYPE_DISPLAY_LABEL[GENDER_TYPE.female],
    value: GENDER_TYPE.female,
  },
  {
    text: GENDER_TYPE_DISPLAY_LABEL[GENDER_TYPE.x],
    value: GENDER_TYPE.x,
  },
  {
    text: GENDER_TYPE_DISPLAY_LABEL[GENDER_TYPE.prefer_not_to_answer],
    value: GENDER_TYPE.prefer_not_to_answer,
  },
];
