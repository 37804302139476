import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { connect } from "formik";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import FormFieldFormItem from "../FormFieldFormItem";
import TeamCondition, { CONDITION_TYPES } from "./TeamCondition";
import "../../MenuAdmin/Registrations/RegistrationConditionList.scss";

function FormFieldTeamConditions(props) {
  const { name, formik } = props;

  const { values, setFieldValue, submitCount, errors } = formik;

  const conditionsValue = get(values, name, []);

  function handleClickAddCondition() {
    const newConditions = [...conditionsValue, { type: null }];
    setFieldValue(name, newConditions, true);
  }

  function handleClickRemoveCondition(index) {
    const newConditions = [...conditionsValue];
    newConditions.splice(index, 1);
    setFieldValue(name, newConditions, true);
  }

  function handleChangeConditionType(index, type) {
    const newConditions = [...conditionsValue];

    newConditions[index] = {};
    newConditions[index].type = type || null;

    if (type) {
      switch (type) {
        case CONDITION_TYPES.PARTICIPATION_ROLE: {
          newConditions[index].role = [];
          break;
        }
        default: {
          console.error("Unsupported condition type", type);
        }
      }
    }

    setFieldValue(name, newConditions, true);
  }

  const hasConditions = !isEmpty(conditionsValue);

  return (
    <FormFieldFormItem {...props} className="form-field-team-conditions">
      <div className="registration-condition-list">
        <div>
          <div className="registration-condition-list__container">
            {hasConditions && (
              <div>
                {conditionsValue.map((condition, index) => {
                  const key = `${condition.type}-${index}`;

                  return (
                    <TeamCondition
                      key={key}
                      index={index}
                      condition={condition}
                      submitCount={submitCount}
                      errors={errors}
                      name={name}
                      handleClickRemoveCondition={() => handleClickRemoveCondition(index)}
                      handleChangeConditionType={type => handleChangeConditionType(index, type)}
                    />
                  );
                })}
              </div>
            )}

            <div className="registration-condition-list__add-button-container">
              <Button icon="plus" onClick={handleClickAddCondition}>
                Add Condition
              </Button>
            </div>
          </div>
        </div>
      </div>
    </FormFieldFormItem>
  );
}

FormFieldTeamConditions.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
};

export default connect(FormFieldTeamConditions);
