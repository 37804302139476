import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Col, Divider, Row } from "antd";
import each from "lodash/each";
import get from "lodash/get";
import isNull from "lodash/isNull";
import pick from "lodash/pick";
import startsWith from "lodash/startsWith";
import useCurrentUser from "../../utils/useCurrentUser";
import { PERSON_FETCH } from "../../schemas/personGql";
import AlertFailedLoading from "../AlertFailedLoading";
import AvatarUpload from "../AvatarUpload/AvatarUpload";
import FormFieldHeading from "../FormFields/FormFieldHeading";
import SpinPageContent from "../SpinPageContent";
import PersonEditForm from "./PersonEditForm";
import PersonProfileHeaderInfo from "./PersonProfileHeaderInfo";

export const PROFILE_MODES = {
  default: "default",
  headerInfo: "headerInfo",
  registrationStepProfile: "registrationStepProfile",
};

function PersonDetailProfile({
  PersonId,
  mode,
  handleSuccess,
  rootStyle,
  spinStyle,
  persistId,
  saveContext,
  displayAvatarUpload,
  formControls,
  disabled,
  showFormBottomComponent,
  readOnly,
  useFormValidityForSubmitDisabled,
}) {
  const currentUser = useCurrentUser();
  const currentUserPersonId = useMemo(() => get(currentUser, "personId"), [currentUser]);

  const skip = !PersonId;

  const { loading, error, data } = useQuery(PERSON_FETCH, {
    variables: { id: PersonId },
    skip,
  });

  const person = useMemo(() => get(data, "Person_by_pk", null), [data]);

  const initialValues = useMemo(() => {
    const personValues = pick(person, [
      "firstName",
      "middleNames",
      "lastName",
      "dob",
      "gender",
      "email",
      "phone",
      "timezoneName",
    ]);

    const values = {
      isOwnProfile: !!PersonId && !!currentUserPersonId && PersonId === currentUserPersonId,
    };

    each(personValues, (value, key) => {
      // Convert any `null` values into an empty string.
      let parsedValue = isNull(value) ? "" : value;

      // Special handling for existing phone values in the DB which do not start with a "+". These values are no longer
      // considered valid, which means that when trying to edit a person's profile, if you change a different field and
      // try to save, you would get a validation error message for the phone field. This would be especially confusing
      // because the 3rd party PhoneInput component will automatically *display* the value with a "+" at the start, even
      // though the real value in the Formik state does not have a "+" at the start, so the value looks valid to the
      // user even though they are seeing a validation error for it. As a simple workaround for this, when constructing
      // the initial values we add the "+" at the start if it's not there in the current saved value for the person.
      if (key === "phone" && !!parsedValue && !startsWith(parsedValue, "+")) {
        parsedValue = `+${parsedValue}`;
      }

      values[key] = parsedValue;
    });

    return values;
  }, [person, PersonId, currentUserPersonId]);

  if (skip || (loading && !data)) {
    return <SpinPageContent style={{ padding: 24, height: "100%", ...rootStyle, ...spinStyle }} />;
  }

  if (error || !person) {
    return (
      <div style={{ padding: 24, ...rootStyle }}>
        <AlertFailedLoading message="Person failed to load" />
      </div>
    );
  }

  const form = (
    <div style={{ padding: 24, ...rootStyle }}>
      <PersonEditForm
        PersonId={PersonId}
        initialValues={initialValues}
        handleSuccess={handleSuccess}
        formControls={formControls}
        persistId={persistId}
        saveContext={saveContext}
        disabled={disabled}
        showFormBottomComponent={showFormBottomComponent}
        readOnly={readOnly}
        useFormValidityForSubmitDisabled={useFormValidityForSubmitDisabled}
      />
    </div>
  );

  switch (mode) {
    // Personal Profile page
    case PROFILE_MODES.headerInfo: {
      return (
        <>
          <PersonProfileHeaderInfo
            PersonId={PersonId}
            rootStyle={{ padding: "15px 24px" }}
            infoStyle={{ padding: "0 24px 0 0" }}
            disableAvatarUpload={disabled}
          />

          {form}
        </>
      );
    }
    // "Profile" Registration Step
    case PROFILE_MODES.registrationStepProfile: {
      if (displayAvatarUpload) {
        return (
          <>
            <div style={{ paddingTop: 9 }}>
              <FormFieldHeading meta={{ label: "Photo" }} />
            </div>

            <Row type="flex" align="middle" justify="center">
              <Col>
                <AvatarUpload PersonId={PersonId} disableUpload={disabled} />
              </Col>
            </Row>

            <Divider />

            {form}
          </>
        );
      }

      return form;
    }
    // Person Detail page "General" tab
    default: {
      return form;
    }
  }
}

PersonDetailProfile.propTypes = {
  PersonId: PropTypes.string,
  mode: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func,
  persistId: PropTypes.string,
  saveContext: PropTypes.bool,
  formControls: PropTypes.object,
  rootStyle: PropTypes.object,
  spinStyle: PropTypes.object,
  displayAvatarUpload: PropTypes.bool,
  disabled: PropTypes.bool,
  showFormBottomComponent: PropTypes.bool,
  readOnly: PropTypes.bool,
  useFormValidityForSubmitDisabled: PropTypes.bool,
};

PersonDetailProfile.defaultProps = {
  PersonId: null,
  handleSuccess: null,
  saveContext: false,
  persistId: "",
  formControls: null,
  rootStyle: {},
  spinStyle: {},
  displayAvatarUpload: false,
  disabled: false,
  showFormBottomComponent: true,
  readOnly: false,
  useFormValidityForSubmitDisabled: false,
};

export default PersonDetailProfile;
