import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { message, Alert } from "antd";
import gql from "fraql";
import * as yup from "yup";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { REG_STEP_STATUSES } from "../../../../constants/regStatusConstants";
import { CREATE_MEMBER, UPDATE_MEMBER, GET_MEMBER } from "../../../../schemas/memberGql";
import ActionForm from "../../../ActionForm";
import AlertFailedLoading from "../../../AlertFailedLoading";
import { DISPLAY_MODES as FORM_FIELD_GROUP_DISPLAY_MODES } from "../../../FormFields/FormFieldGroup";
import { DISPLAY_MODES as FORM_FIELD_ROLE_DISPLAY_MODES } from "../../../FormFields/FormFieldRole";
import SpinPageContent from "../../../SpinPageContent";
import { ConnectedFormBottomComponent } from "../FormBottomComponent";

const GET_GROUPS = gql`
  query RegistrationStepGroup_GetGroups($GroupsIds: [String!]!) {
    Group(where: { id: { _in: $GroupsIds } }, order_by: { name: asc }) {
      id
      name
      parentId
      path
    }
  }
`;

const GET_ROLES = gql`
  query RegistrationStepGroup_GetRoles($RolesIds: [String!]!) {
    Role(where: { id: { _in: $RolesIds } }, order_by: { name: asc }) {
      id
      name
      archived
    }
  }
`;

const validationSchema = yup.object().shape({
  group: yup.string().required("Please select a group."),
  role: yup.string().required("Please select a role."),
});

const formFieldBuilder = (stepRolesIds, stepGroupsIds, stepStatus, isStepRequired, membersData, stepSubmitting) => {
  // Person's Member records which have been filtered by the Step's Groups
  const membersForStep = get(membersData, "Member", []);

  /**
   * TODO: Can we improve this logic?
   * Ideally we would allow the user to change someone's role if their current role is one of the options available
   * to choose for the step.
   *
   * @returns {boolean}
   */
  function memberChangeNotAllowed() {
    // business rule: a Person can only belong to 1 role in a group.

    // if the Person does not belong to any Groups, then return false
    if (!membersForStep) {
      return false;
    }

    // if the Person does not have any roles, then return false
    if (!membersForStep.some(m => !isNil(m.role))) {
      return false;
    }

    // if registration is for more than 1 role, then we don't want to offer the choice to register in something else
    // since the Person already has a membership in one of the step groups
    if (stepRolesIds.length > 1) {
      return true;
    }

    const stepRoleId = stepRolesIds[0];

    // if there is a member role that does not match the step role then it might get reassigned unwittingly, so return true
    return membersForStep.findIndex(m => m.role !== stepRoleId) >= 0;
  }

  const initialValues = {};

  if (isEmpty(membersForStep)) {
    initialValues.group = "";
    initialValues.role = "";
  } else {
    const { id, group, role } = membersForStep[0];

    initialValues.id = id;
    initialValues.group = group;
    initialValues.role = !isNil(role) ? role : "";
  }

  const additionalFieldParams = { required: isStepRequired };
  let showAlreadyRegisteredError = false;

  if (!stepSubmitting && stepStatus !== REG_STEP_STATUSES.completed && memberChangeNotAllowed()) {
    additionalFieldParams.disabled = true;
    additionalFieldParams.required = false;
    showAlreadyRegisteredError = true;
  }

  const fields = {
    group: {
      type: "group",
      label: "Group",
      placeholder: "Select a group",
      helpText: "You will become a member of the selected group",
      displayMode: FORM_FIELD_GROUP_DISPLAY_MODES.select,
      query: GET_GROUPS,
      queryOptions: { variables: { GroupsIds: stepGroupsIds } },
      ...additionalFieldParams,
    },
    role: {
      type: "role",
      label: "Group role",
      placeholder: "Select a role",
      helpText: "Your role in the selected group",
      displayMode: FORM_FIELD_ROLE_DISPLAY_MODES.select,
      query: GET_ROLES,
      queryOptions: { variables: { RolesIds: stepRolesIds } },
      ...additionalFieldParams,
    },
  };

  return { fields, initialValues, showAlreadyRegisteredError };
};

const RegistrationStepGroup = ({
  step,
  stepIndex,
  PersonId: personId,
  updateRegStepStatus,
  nextStep,
  prevStep,
  regIsReadOnly,
  showFormBottomComponent,
  readOnly,
}) => {
  const [createMember] = useMutation(CREATE_MEMBER);
  const [updateMember] = useMutation(UPDATE_MEMBER);

  const stepRolesIds = get(step, "meta.roles", []);
  const stepGroupsIds = get(step, "meta.groups", []);
  const stepStatus = get(step, "RegStatus.status", "");
  const isStepRequired = get(step, "meta.required", false);

  const { loading: membersLoading, error: membersError, data: membersData } = useQuery(GET_MEMBER, {
    variables: { personId, groupIds: stepGroupsIds },
  });

  const [stepSubmitting, setStepSubmitting] = useState(false);

  const { fields, initialValues, showAlreadyRegisteredError } = useMemo(
    () => formFieldBuilder(stepRolesIds, stepGroupsIds, stepStatus, isStepRequired, membersData, stepSubmitting),
    [stepRolesIds, stepGroupsIds, stepStatus, isStepRequired, membersData, stepSubmitting],
  );

  // TODO: Fix issue with ActionForm not showing loading state correctly during submission.
  // For some reason, ActionForm stops showing a loading state when this `handleSubmit` function is only part way
  // through execution. It appears to stop showing a loading state after `updateMember` or `createMember` successfully
  // finish execution, when it should continue showing a loading state until all steps in this `handleSubmit` function
  // have finished (i.e. until after `updateRegStepStatus` finishes).
  const handleSubmit = useCallback(
    async (values, actions) => {
      try {
        setStepSubmitting(true);

        const { id = null, role, group } = values;

        let successMessage;

        if (id) {
          await updateMember({ variables: { id, changes: { RoleId: role, GroupId: group, PersonId: personId } } });

          successMessage = "Group membership updated.";
        } else {
          await createMember({ variables: { role, group, person: personId } });

          successMessage = "Group membership added.";
        }

        await updateRegStepStatus(step);

        message.success(successMessage);

        actions.setSubmitting(false);

        setStepSubmitting(false);

        nextStep(true);
      } catch (submitError) {
        actions.setSubmitting(false);
        actions.setStatus({ type: "error" });

        message.error("Failed to save membership.");
        console.error(submitError);

        Sentry.captureException(submitError);

        setStepSubmitting(false);
      }
    },
    [step, updateRegStepStatus, nextStep, createMember, updateMember, personId],
  );

  if (membersLoading && !membersData) {
    return <SpinPageContent />;
  }

  if (membersError) {
    return <AlertFailedLoading message="Member information failed to load" />;
  }

  const submitText = initialValues.id ? "Update" : "Save";

  return (
    <>
      <ActionForm
        fields={fields}
        FormBottomComponent={props => (
          <ConnectedFormBottomComponent
            step={step}
            stepIndex={stepIndex}
            prevStep={prevStep}
            nextStep={nextStep}
            {...props}
          />
        )}
        validationSchema={validationSchema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        submitText={submitText}
        persistId={step.id}
        disabled={regIsReadOnly}
        formLayout="vertical"
        saveContext
        showFormBottomComponent={showFormBottomComponent}
        readOnly={readOnly}
        useFormValidityForSubmitDisabled
      />

      {showAlreadyRegisteredError && (
        <Alert message="Error" description="You have already registered a role in this group." type="error" showIcon />
      )}
    </>
  );
};

RegistrationStepGroup.propTypes = {
  step: PropTypes.object.isRequired,
  stepIndex: PropTypes.number.isRequired,
  updateRegStepStatus: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  regIsReadOnly: PropTypes.bool.isRequired,
  PersonId: PropTypes.string.isRequired,
  showFormBottomComponent: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default RegistrationStepGroup;
