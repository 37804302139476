import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import { isNil } from "lodash";
import { Link } from "react-router-dom";
import { useGetUserHasPermission, PERMISSION } from "../../utils/useGetUserHasPermission";
import GlobalSearch from "../GlobalSearch/GlobalSearch";
import SpinPageContent from "../SpinPageContent";
import { useTheme } from "../Theme/ThemeContext";
import GlobalNavMenu from "./GlobalNavMenu";
import HeaderUser from "./HeaderUser";
import "./NavHeader.scss";

const { Header } = Layout;

function NavHeader({ authLoading }) {
  const themeContext = useTheme();
  const displaySearchBar = useGetUserHasPermission(PERMISSION.SHOW_GLOBAL_SEARCH_BAR);

  const logoPath = useMemo(() => {
    if (isNil(themeContext)) {
      return `${window.location.origin}/`;
    }
    return themeContext.logo_path;
  }, [themeContext]);

  return (
    <Header>
      {isNil(themeContext) && <SpinPageContent />}
      {!isNil(themeContext) && (
        <Link to="/" className="nav-header__home-link">
          <img src={logoPath} alt={themeContext.siteName} height={40} />
        </Link>
      )}

      {!authLoading && (
        <>
          <GlobalNavMenu />

          <div className="nav-header__other">
            {displaySearchBar && <GlobalSearch />}

            <HeaderUser />
          </div>
        </>
      )}
    </Header>
  );
}

NavHeader.propTypes = {
  authLoading: PropTypes.bool,
};

NavHeader.defaultProps = {
  authLoading: true,
};

export default NavHeader;
