import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import {
  COMPETITION_SCORE_TYPE,
  TEAM_QUALIFICATION_SCORE_DETERMINATION,
} from "../../../constants/competitionConstants";
import isBlank from "../../../utils/isBlank";

function getQualificationScoreNotProvidedAlertVisible(team) {
  // Handle case where team record has not been loaded yet.
  if (isBlank(team)) {
    return false;
  }

  // Check that the team record has all the necessary fields for itself and associated records. This is a safeguard
  // against the possibility that the team record gets loaded but we fail to fetch all necessary fields that are
  // required to evaluate this logic.
  if (
    isUndefined(get(team, ["Competition", "qualificationScoreType"])) ||
    isUndefined(get(team, ["Competition", "qualificationScoreRequired"])) ||
    isUndefined(get(team, ["Competition", "teamQualificationScoreDetermination"])) ||
    isUndefined(get(team, "qualificationScore"))
  ) {
    throw new Error("Missing fields for team which are required for getQualificationScoreNotProvidedAlertVisible.");
  }

  return (
    !isNull(team.Competition.qualificationScoreType) &&
    team.Competition.qualificationScoreRequired &&
    team.Competition.teamQualificationScoreDetermination ===
      TEAM_QUALIFICATION_SCORE_DETERMINATION.PROVIDED_EXPLICITLY &&
    isEmpty(team.qualificationScore)
  );
}

function TeamQualificationScoreNotProvidedAlert({ showFullDescription, style, team }) {
  let description;

  if (!getQualificationScoreNotProvidedAlertVisible(team)) {
    return null;
  }

  if (showFullDescription) {
    description = (
      <span>
        The qualification score for this team has not yet been provided. This team is part of a competition where
        it&apos;s mandatory to explicitly provide the qualification score for each team.
      </span>
    );
  } else {
    description = "The qualification score for this team has not yet been provided.";
  }

  return (
    <Alert
      type="warning"
      message="Team qualification score not yet provided"
      description={description}
      showIcon
      style={style}
    />
  );
}

TeamQualificationScoreNotProvidedAlert.propTypes = {
  showFullDescription: PropTypes.bool,
  style: PropTypes.any,
  team: PropTypes.shape({
    Competition: PropTypes.shape({
      qualificationScoreType: PropTypes.oneOf([...Object.values(COMPETITION_SCORE_TYPE), ""]),
      qualificationScoreRequired: PropTypes.bool,
      teamQualificationScoreDetermination: PropTypes.oneOf([
        ...Object.values(TEAM_QUALIFICATION_SCORE_DETERMINATION),
        "",
      ]),
    }),
  }),
};

TeamQualificationScoreNotProvidedAlert.defaultProps = {
  showFullDescription: true,
  style: undefined,
  team: null,
};

export default TeamQualificationScoreNotProvidedAlert;
