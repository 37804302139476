import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import { connect } from "formik";
import gql from "fraql";
import get from "lodash/get";
import omit from "lodash/omit";
import useUpdateEffect from "../../utils/useUpdateEffect";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";

export const GET_EVENTS_FOR_GROUP = gql`
  query GetEventsForGroup($GroupId: String!) {
    Event(where: { Group: { id: { _eq: $GroupId } } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;

function FormFieldGroupEventSelect(props) {
  const {
    name,
    disabled,
    meta,
    formik: { values, setFieldValue },
  } = props;

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);

  const formGroupIdValue = get(values, meta.groupFieldId, null);

  useUpdateEffect(() => {
    // Reset this field's value whenever the Group field's value changes, since the list of Events (and the set of
    // valid Events) is dependent on the Group selected, and therefore the current value for this field may no longer
    // be valid for the new value of the Group field.
    setFieldValue(name, null);
  }, [formGroupIdValue]);

  const { loading: groupEventListLoading, error: groupEventListError, data: groupEventListData } = useQuery(
    GET_EVENTS_FOR_GROUP,
    {
      variables: { GroupId: formGroupIdValue },
      skip: !formGroupIdValue,
    },
  );

  const options = useMemo(() => {
    const events = get(groupEventListData, "Event", []);

    return events.map(event => ({
      value: event.id,
      label: event.name,
    }));
  }, [groupEventListData]);

  if (!formGroupIdValue) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="An event can be selected after selecting a group" type="info" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  if (groupEventListLoading && !groupEventListData) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (groupEventListError) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Events failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  return (
    <FormFieldSelect
      {...omit(props, ["formik"])}
      disabled={disabled || groupEventListLoading}
      loading={groupEventListLoading}
      meta={{ ...meta, options }}
    />
  );
}

FormFieldGroupEventSelect.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    groupFieldId: PropTypes.string.isRequired,
  }).isRequired,
  formik: PropTypes.object.isRequired,
};

FormFieldGroupEventSelect.defaultProps = {
  disabled: false,
};

export default connect(FormFieldGroupEventSelect);
