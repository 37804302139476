import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { FieldArray } from "formik";
import { AddRowButton, RemoveRowButton, Table } from "formik-antd";
import uuidv4 from "uuid/v4";
import padStart from "lodash/padStart";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldGroup, { DISPLAY_MODES as FORM_FIELD_GROUP_DISPLAY_MODES } from "./FormFieldGroup";
import FormFieldText from "./FormFieldText";
import "./FieldArrayTable.scss";
import "./FormFieldCompetitionTeams.scss";

function FormFieldCompetitionTeams({ name, meta, submitCount }) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="form-field-competition-teams ant-row ant-form-item">
      <div className="header-text">Create teams for this competition.</div>

      {!isVisible && <Button onClick={() => setIsVisible(true)}>Create Teams</Button>}

      {isVisible && (
        <>
          <AddRowButton
            name={name}
            className="form-field-competition-teams__add-button"
            createNewRow={() => ({
              id: uuidv4(),
              name: "",
              GroupId: null,
            })}
          >
            Add Team
          </AddRowButton>

          <FormFieldFormItem
            name={name}
            meta={{ ...meta, label: null }}
            submitCount={submitCount}
            displayDefaultLabel={false}
            displayForInput={false}
          >
            <FieldArray
              name={name}
              render={() => (
                <Table
                  name={name}
                  scroll={{
                    // Height which shows 5 full rows and half of the 6th row (so user knows they can scroll to see more).
                    y: 296,
                  }}
                  locale={{
                    emptyText: "No teams added",
                  }}
                  className="field-array-table"
                  size="small"
                  rowKey={row => row.id}
                  bordered={false}
                  pagination={false}
                  columns={[
                    {
                      title: "",
                      dataIndex: "index",
                      width: 30,
                      render: (value, record, index) => {
                        const rowNumber = `${index + 1}`;

                        return <span>{padStart(rowNumber, 2, "0")}</span>;
                      },
                    },
                    {
                      title: "Name",
                      dataIndex: "name",
                      render: (text, record, index) => (
                        <FormFieldText
                          name={`${name}.${index}.name`}
                          submitCount={submitCount}
                          meta={{ ...meta, placeholder: "Enter a name", displayDefaultLabel: false, maxLength: 255 }}
                        />
                      ),
                    },
                    {
                      title: "Group",
                      dataIndex: "group",
                      width: 300,
                      render: (text, record, index) => (
                        <FormFieldGroup
                          name={`${name}.${index}.GroupId`}
                          submitCount={submitCount}
                          meta={{
                            ...meta,
                            placeholder: "Select a group (optional)",
                            displayDefaultLabel: false,
                            enableGetPopupContainer: false,
                            allowClear: true,
                            displayMode: FORM_FIELD_GROUP_DISPLAY_MODES.treeSelect,
                          }}
                        />
                      ),
                    },
                    {
                      title: "",
                      dataIndex: "actions",
                      width: 44,
                      render: (text, record, index) => <RemoveRowButton icon="close" name={name} index={index} />,
                    },
                  ]}
                />
              )}
            />
          </FormFieldFormItem>
        </>
      )}
    </div>
  );
}

FormFieldCompetitionTeams.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired,
};

export default FormFieldCompetitionTeams;
