import React from "react";
import PropTypes from "prop-types";

function ReadOnlyText({ children }) {
  return <span style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{children}</span>;
}

ReadOnlyText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default ReadOnlyText;
