import each from "lodash/each";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { FIELD_TYPES } from "../constants/fieldConstants";
import getFieldDefaultValue from "./getFieldDefaultValue";

function getFormFieldCurrentValue(formField) {
  const { type } = formField;

  if (type === FIELD_TYPES.content) {
    return get(formField, "meta.content", undefined);
  }

  return get(formField, "Values.0.value", undefined);
}

/**
 * Converts FormField data (with Values) to the attributes that an ActionForm expects.
 *
 * Note that logic needs to be kept in sync with rules enforced in `useInitialValuesFromFields` and
 * `useSchemaFromFields`.
 *
 */
function mapFormFieldsToActionFormData(formFields) {
  if (isEmpty(formFields)) {
    return { fields: {}, initialValues: {} };
  }

  const fields = {};
  const initialValues = {};

  each(formFields, formField => {
    const { id, type } = formField;

    fields[id] = { type, ...formField.meta, conditions: formField.conditions };

    const mode = get(formField, "meta.mode", null);

    const fieldCurrentValue = getFormFieldCurrentValue(formField);
    const fieldDefaultValue = getFieldDefaultValue(type, { mode });

    if (!isUndefined(fieldCurrentValue)) {
      if (fieldCurrentValue === "" && fieldDefaultValue !== "") {
        // Ignore a value of `""` for fields which do not use `""` as their default value. This allows us to handle
        // a case such as a Number field which has been left blank, which gets saved as `""` when submitting the form
        // (see `mapFormValuesToDbValue`). It also provides a safe-guard for potential situations where we have a value
        // of `""` (which we assume represents an empty value) and this is not the correct default value for this field
        // type. In this situation we do nothing here, and allow the logic below to set the initial value based on the
        // default value for this field type.
      } else {
        initialValues[id] = fieldCurrentValue;

        return;
      }
    }

    // Note: old behaviour appears to be that for any field which doesn't have a default value (including fields like
    // `FIELD_TYPES.heading` which seem like they wouldn't need a value), we use `""` as the default value. This has
    // been changed so that we instead leave `undefined` default values rather than converting them to `""`, as this
    // seems more appropriate.
    initialValues[id] = fieldDefaultValue;
  });

  return { fields, initialValues };
}

export default mapFormFieldsToActionFormData;
