import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import { connect } from "formik";
import { Prompt } from "react-router-dom";
import { DEFAULT_CONFIRMATION_MSG } from "../constants/messageConstants";

export const getUserConfirmation = (message, callback) => {
  Modal.confirm({
    title: message,
    onOk() {
      callback(true);
    },
    onCancel() {
      callback(false);
    },
  });
};

const PromptDirtyForm = ({ formik }) => {
  const handleBeforeUnload = useCallback(
    event => {
      if (formik.dirty && formik.submitCount === 0) {
        event.preventDefault();

        // eslint-disable-next-line no-param-reassign
        event.returnValue = true;
      }
    },
    [formik],
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formik, handleBeforeUnload]);

  return <Prompt when={formik.dirty && formik.submitCount === 0} message={DEFAULT_CONFIRMATION_MSG} />;
};

PromptDirtyForm.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default connect(PromptDirtyForm);
