import React from "react";
import PropTypes from "prop-types";
import ColumnValueText from "./ColumnValueText";
import PersonGender from "./MenuPeople/PersonGender";

function ColumnValueGender({ gender }) {
  return (
    <ColumnValueText>
      <PersonGender gender={gender} />
    </ColumnValueText>
  );
}

ColumnValueGender.propTypes = {
  gender: PropTypes.string,
};

ColumnValueGender.defaultProps = {
  gender: null,
};

export default ColumnValueGender;
