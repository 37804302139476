import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import PersonDetailProfile, { PROFILE_MODES } from "../../MenuPeople/PersonDetailProfile";
import SectionHeaderContainer from "../../SectionHeaderContainer";
import "./MyRegistrationsNavigation.scss";

function MyProfile({ PersonId, headerTitle }) {
  return (
    <div className="my-profile">
      <SectionHeaderContainer>
        <Typography.Title level={3}>{headerTitle}</Typography.Title>
      </SectionHeaderContainer>
      <PersonDetailProfile PersonId={PersonId} mode={PROFILE_MODES.registrationStepProfile} displayAvatarUpload />
    </div>
  );
}

MyProfile.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  PersonId: PropTypes.string,
};

MyProfile.defaultProps = {
  PersonId: null,
};

export default MyProfile;
