import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import { message } from "antd";
import get from "lodash/get";
import { useRegContext } from "../../../../utils/useRegContext";
import PersonDetailProfile, { PROFILE_MODES } from "../../PersonDetailProfile";

const RegistrationStepProfile = ({
  step,
  stepIndex,
  updateRegStepStatus,
  nextStep,
  prevStep,
  PersonId,
  regIsReadOnly,
  showFormBottomComponent,
  readOnly,
}) => {
  const regContext = useRegContext();
  const displayAvatarUpload = get(regContext, "displayAvatarUpload", false);

  const handleSuccess = useCallback(async () => {
    try {
      await updateRegStepStatus(step);

      nextStep(true);
    } catch (submitError) {
      message.error("Failed to submit step.");
      console.error(submitError);

      Sentry.captureException(submitError);
    }
  }, [step, updateRegStepStatus, nextStep]);

  const formControls = useMemo(
    () => ({
      step,
      stepIndex,
      nextStep,
      prevStep,
    }),
    [step, nextStep, prevStep, stepIndex],
  );

  return (
    <PersonDetailProfile
      PersonId={PersonId}
      mode={PROFILE_MODES.registrationStepProfile}
      formControls={formControls}
      handleSuccess={handleSuccess}
      rootStyle={{ padding: 0 }}
      spinStyle={{ height: "calc(100% - 58px)" }}
      displayAvatarUpload={displayAvatarUpload}
      persistId={step.id}
      saveContext
      disabled={regIsReadOnly}
      showFormBottomComponent={showFormBottomComponent}
      readOnly={readOnly}
      useFormValidityForSubmitDisabled
    />
  );
};

RegistrationStepProfile.propTypes = {
  step: PropTypes.object.isRequired,
  stepIndex: PropTypes.number.isRequired,
  updateRegStepStatus: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  PersonId: PropTypes.string,
  regIsReadOnly: PropTypes.bool,
  showFormBottomComponent: PropTypes.bool,
  readOnly: PropTypes.bool,
};

RegistrationStepProfile.defaultProps = {
  PersonId: null,
  regIsReadOnly: false,
  showFormBottomComponent: true,
  readOnly: false,
};

export default RegistrationStepProfile;
