import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Modal } from "antd";
import gql from "fraql";
import * as yup from "yup";
import get from "lodash/get";
import { schemaNullable } from "../../../../utils/yupUtils";
import ActionForm from "../../../ActionForm";
import AlertFailedLoading from "../../../AlertFailedLoading";
import FormFieldGroup, { DISPLAY_MODES as FORM_FIELD_GROUP_DISPLAY_MODES } from "../../../FormFields/FormFieldGroup";
import FormFieldRole, { DISPLAY_MODES as FORM_FIELD_ROLE_DISPLAY_MODES } from "../../../FormFields/FormFieldRole";
import SpinPageContent from "../../../SpinPageContent";
import { ConnectedFormBottomComponent } from "../FormBottomComponent";

const GET_GROUPS = gql`
  query RegistrationStepParticipation_GetGroups($GroupsIds: [String!]!) {
    Group(where: { id: { _in: $GroupsIds } }, order_by: { name: asc }) {
      id
      name
      parentId
      path
    }
  }
`;

const GET_ROLES = gql`
  query RegistrationStepParticipation_GetRoles($RolesIds: [String!]!) {
    Role(where: { id: { _in: $RolesIds } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;

const GET_REGISTRATION_SUBMISSION = gql`
  query RegistrationStepParticipation_GetRegistrationSubmission($PersonId: String!, $RegId: String!) {
    RegSubmission(where: { PersonId: { _eq: $PersonId }, RegId: { _eq: $RegId } }) {
      id
      DelegationGroupId
      RoleId
    }
  }
`;

const SAVE_PARTICIPATION_STEP = gql`
  mutation RegistrationStepParticipation_SaveParticipationStep(
    $StepId: ID!
    $RegId: ID!
    $PersonId: ID!
    $DelegationGroupId: ID!
    $RoleId: ID!
  ) {
    saveParticipationStep(
      StepId: $StepId
      RegId: $RegId
      PersonId: $PersonId
      DelegationGroupId: $DelegationGroupId
      RoleId: $RoleId
    ) {
      returning {
        id
        DelegationGroupId
        RoleId
      }
    }
  }
`;

const FIELDS = {
  delegationGroup: {
    name: "delegationGroup",
    type: "group",
    label: "Delegation",
    placeholder: "Select your delegation",
    helpText: "Your delegation for this registration",
    required: true,
    allowClear: true,
    displayMode: FORM_FIELD_GROUP_DISPLAY_MODES.select,
  },
  role: {
    name: "role",
    type: "role",
    label: "Role",
    placeholder: "Select your role",
    helpText: "Your role for this registration",
    required: true,
    allowClear: true,
    displayMode: FORM_FIELD_ROLE_DISPLAY_MODES.select,
  },
};

const VALIDATION_SCHEMA = yup.object().shape({
  delegationGroup: schemaNullable(yup.string().required("Please select a group.")),
  role: schemaNullable(yup.string().required("Please select a role.")),
});

const RegistrationStepParticipation = ({
  step,
  stepIndex,
  updateRegStepStatus,
  nextStep,
  prevStep,
  regIsReadOnly,
  personId,
  regId,
  showFormBottomComponent,
  readOnly,
}) => {
  // TODO: Is this necessary? Can we rely on Formik/ActionForm to handle this automatically?
  const [stepSubmitting, setStepSubmitting] = useState(false);

  const stepRolesIds = get(step, "meta.roles", []);
  const stepGroupsIds = get(step, "meta.groups", []);

  const { loading: regSubmissionLoading, error: regSubmissionError, data: regSubmissionData } = useQuery(
    GET_REGISTRATION_SUBMISSION,
    {
      variables: { PersonId: personId, RegId: regId },
    },
  );

  const regSubmission = useMemo(() => get(regSubmissionData, "RegSubmission.0", null), [regSubmissionData]);

  const initialValues = useMemo(() => {
    return {
      delegationGroup: get(regSubmission, "DelegationGroupId", null),
      role: get(regSubmission, "RoleId", null),
    };
  }, [regSubmission]);

  const [saveParticipationStep] = useMutation(SAVE_PARTICIPATION_STEP);

  const handleSubmit = useCallback(
    async (values, actions) => {
      try {
        setStepSubmitting(true);

        const { delegationGroup, role } = values;

        await saveParticipationStep({
          variables: {
            StepId: step.id,
            RegId: regId,
            PersonId: personId,
            DelegationGroupId: delegationGroup,
            RoleId: role,
          },
        });

        await updateRegStepStatus(step);

        actions.setSubmitting(false);

        setStepSubmitting(false);
        nextStep(true);
      } catch (saveParticipationStepStatusError) {
        console.error(saveParticipationStepStatusError);

        actions.setSubmitting(false);
        actions.setStatus({ type: "error" });

        Modal.error({
          title: "Something went wrong",
          content: "Failed to save the step.",
        });

        Sentry.captureException(saveParticipationStepStatusError);

        setStepSubmitting(false);
      }
    },
    [step, updateRegStepStatus, nextStep, saveParticipationStep, regId, personId],
  );

  if (regSubmissionLoading && !regSubmissionData) {
    return <SpinPageContent />;
  }

  if (regSubmissionError || !regSubmission) {
    return <AlertFailedLoading message="Registration submission information failed to load" />;
  }

  return (
    <ActionForm
      fields={FIELDS}
      validationSchema={VALIDATION_SCHEMA}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      submitText="Submit"
      formLayout="vertical"
      useFormValidityForSubmitDisabled
      disabled={regIsReadOnly || stepSubmitting}
      FormBottomComponent={props => (
        <ConnectedFormBottomComponent
          step={step}
          stepIndex={stepIndex}
          prevStep={prevStep}
          nextStep={nextStep}
          {...props}
        />
      )}
      renderField={({ name, meta, disabled, submitCount }) => {
        if (name === FIELDS.delegationGroup.name) {
          return (
            <FormFieldGroup
              key={FIELDS.delegationGroup.name}
              name={FIELDS.delegationGroup.name}
              meta={meta}
              disabled={disabled}
              submitCount={submitCount}
              query={GET_GROUPS}
              queryOptions={{ variables: { GroupsIds: stepGroupsIds } }}
              readOnly={readOnly}
            />
          );
        }

        if (name === FIELDS.role.name) {
          return (
            <FormFieldRole
              key={FIELDS.role.name}
              name={FIELDS.role.name}
              meta={meta}
              disabled={disabled}
              submitCount={submitCount}
              query={GET_ROLES}
              queryOptions={{ variables: { RolesIds: stepRolesIds } }}
              readOnly={readOnly}
            />
          );
        }

        return undefined;
      }}
      showFormBottomComponent={showFormBottomComponent}
      readOnly={readOnly}
    />
  );
};

RegistrationStepParticipation.propTypes = {
  step: PropTypes.object.isRequired,
  stepIndex: PropTypes.number.isRequired,
  updateRegStepStatus: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  regIsReadOnly: PropTypes.bool.isRequired,
  personId: PropTypes.string.isRequired,
  regId: PropTypes.string.isRequired,
  showFormBottomComponent: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default RegistrationStepParticipation;
