import { useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "fraql";

const DEFAULT_CONTENT_TYPE = "application/octet-stream";

const INIT_FILE_UPLOAD = gql`
  mutation useFileUploader_InitiateFileUpload(
    $fileName: String!
    $contentType: String!
    $PersonId: ID
    $TravelGroupId: ID
    $isPublic: Boolean!
  ) {
    initiateFileUpload(
      fileName: $fileName
      contentType: $contentType
      PersonId: $PersonId
      TravelGroupId: $TravelGroupId
      isPublic: $isPublic
    ) {
      url
      key
      policy
      AWSAccessKeyId
      signature
    }
  }
`;

const COMPLETE_FILE_UPLOAD = gql`
  mutation useFileUploader_CompleteFileUpload($policy: String!) {
    completeFileUpload(policy: $policy) {
      returning {
        id
        url
      }
    }
  }
`;

function uploadToS3({ url, key, policy, signature, AWSAccessKeyId, contentType }, file) {
  const formData = new FormData();
  formData.append("key", key);
  formData.append("policy", policy);
  formData.append("signature", signature);
  formData.append("AWSAccessKeyId", AWSAccessKeyId);
  formData.append("Content-Type", contentType);
  formData.append("file", file);

  return fetch(url, {
    method: "POST",
    body: formData,
  });
}

export default function useFileUploader({ PersonId, TravelGroupId }) {
  const [initiateFileUpload] = useMutation(INIT_FILE_UPLOAD);
  const [completeFileUpload] = useMutation(COMPLETE_FILE_UPLOAD);

  const uploader = useCallback(
    async (file, isPublic = false) => {
      const fileName = file.name;
      const contentType = file.type || DEFAULT_CONTENT_TYPE;

      const {
        data: {
          initiateFileUpload: { url, key, policy, signature, AWSAccessKeyId },
        },
      } = await initiateFileUpload({
        variables: { fileName, contentType, PersonId, TravelGroupId, isPublic },
      });

      const response = await uploadToS3({ url, key, policy, signature, AWSAccessKeyId, contentType }, file);

      if (!response.ok) {
        throw new Error("Error uploading file to S3");
      }

      const {
        data: {
          completeFileUpload: {
            returning: { id: uploadedFileId, url: uploadedFileUrl },
          },
        },
      } = await completeFileUpload({
        variables: { policy },
      });

      return {
        id: uploadedFileId,
        url: uploadedFileUrl,
      };
    },
    [initiateFileUpload, completeFileUpload, PersonId, TravelGroupId],
  );

  return { uploader };
}
