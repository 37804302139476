import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Tooltip } from "antd";
import gql from "fraql";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import isNil from "lodash/isNil";
import keyBy from "lodash/keyBy";
import keys from "lodash/keys";
import map from "lodash/map";
import { REG_SUBMISSION_STATUSES } from "../../../../constants/regStatusConstants";
import { PEOPLE } from "../../../../constants/routeConstants";
import ColumnValueGender from "../../../ColumnValueGender";
import ColumnValueText from "../../../ColumnValueText";
import EmptyValueText from "../../../EmptyValueText";
import SpinPageContent from "../../../SpinPageContent";
import TableWithTruncation from "../../../TableWithTruncation/TableWithTruncation";
import PersonName, { getFullName } from "../../PersonName";
import TeamConditions from "./TeamConditions";

const PENDING_COLUMNS = [
  {
    key: "name",
    title: "Name",
    render: (text, record) => {
      return (
        <Tooltip
          placement="topLeft"
          trigger="hover"
          title={getFullName(record.Person.firstName, record.Person.lastName)}
        >
          <ColumnValueText>
            <PersonName firstName={record.Person.firstName} lastName={record.Person.lastName} />
          </ColumnValueText>
        </Tooltip>
      );
    },
    minWidth: 190,
  },
  {
    title: "Gender",
    key: "gender",
    render: (text, record) => {
      return <ColumnValueGender gender={record.Person.gender} />;
    },
    width: 170,
  },
  {
    key: "role",
    title: "Role",
    render: (text, record) => {
      const roleName = get(record, "Role.name", "");

      return (
        <Tooltip placement="topLeft" trigger="hover" title={roleName}>
          <ColumnValueText>{roleName}</ColumnValueText>
        </Tooltip>
      );
    },
    width: 190,
  },
  {
    title: "Alternate",
    key: "alternate",
    render: (text, record) => {
      const alternate = get(record, "alternate", null);

      if (isNil(alternate)) {
        return <EmptyValueText />;
      }

      return <ColumnValueText>{alternate ? "Yes" : "No"}</ColumnValueText>;
    },
    width: 190,
  },
];

export const GET_TEAM = gql`
  query ViewTeamDrawerPendingTab_GetTeam($teamId: String!) {
    Team_by_pk(id: $teamId) {
      id
      TeamPeople {
        id
        PersonId
      }
    }
  }
`;

const GET_PENDING_REG_SUBMISSIONS = gql`
  query ViewTeamDrawerPendingTab_GetPendingRegSubmissions($regId: String!, $excludePeopleIds: [String!]!) {
    RegSubmission(where: {
      RegId: {_eq: $regId},
      PersonId: {_nin: $excludePeopleIds},
      _or: [
        {status: {_in: ["${REG_SUBMISSION_STATUSES.notSubmitted}", "${REG_SUBMISSION_STATUSES.reverted}"]}},
        {_and: [{alternate: {_eq: true}}, {status: {_in: ["${REG_SUBMISSION_STATUSES.submitted}", "${REG_SUBMISSION_STATUSES.approved}"]}}]}
      ]
    }) {
      id
      status
      alternate
      Person {
        id
        firstName
        lastName
        gender
      }
      Role {
        id
        name
      }
    }
  }
`;

const GET_PENDING_REG_STEP_VALUES = gql`
  query ViewTeamDrawerPendingTab_GetPendingRegStepValues(
    $regId: String!
    $teamFilter: jsonb
    $pendingPersonIds: [String!]!
  ) {
    RegStepValue(
      where: { RegId: { _eq: $regId }, value: { _contains: $teamFilter }, Person: { id: { _in: $pendingPersonIds } } }
    ) {
      id
      Person {
        id
      }
    }
  }
`;

function ViewTeamDrawerPendingTab({ teamId, regId }) {
  const history = useHistory();

  const { loading: teamLoading, error: teamError, data: teamData } = useQuery(GET_TEAM, {
    variables: { teamId },
    skip: isNil(teamId),
  });

  const teamPeopleIds = useMemo(
    () => get(teamData, "Team_by_pk.TeamPeople", []).map(teamPerson => get(teamPerson, "PersonId")),
    [teamData],
  );

  const {
    loading: pendingRegSubmissionsLoading,
    error: pendingRegSubmissionsError,
    data: pendingRegSubmissionsData,
  } = useQuery(GET_PENDING_REG_SUBMISSIONS, {
    variables: { regId, excludePeopleIds: teamPeopleIds },
    skip: isNil(regId) || isNil(teamData),
  });

  const pendingRegSubmissionPersonIds = useMemo(
    () => map(get(pendingRegSubmissionsData, "RegSubmission"), person => get(person, "Person.id")),
    [pendingRegSubmissionsData],
  );

  const teamFilter = useMemo(() => {
    return {
      competitions: [{ team: teamId }],
    };
  }, [teamId]);

  const {
    loading: pendingRegStepValueLoading,
    error: pendingRegStepValueError,
    data: pendingRegStepValueData,
  } = useQuery(GET_PENDING_REG_STEP_VALUES, {
    variables: { regId, teamFilter, pendingPersonIds: pendingRegSubmissionPersonIds },
    skip: isNil(regId) || !pendingRegSubmissionPersonIds,
  });

  const pendingDataSource = useMemo(() => {
    if (isNil(pendingRegSubmissionsData) || isNil(pendingRegStepValueData)) {
      return [];
    }

    const regSubmissionsByPersonId = keyBy(get(pendingRegSubmissionsData, "RegSubmission", []), regSubmission =>
      get(regSubmission, "Person.id"),
    );

    const regStepValuesByPersonId = keyBy(get(pendingRegStepValueData, "RegStepValue", []), teamPerson =>
      get(teamPerson, "Person.id"),
    );

    return map(keys(regStepValuesByPersonId), personId => get(regSubmissionsByPersonId, personId));
  }, [pendingRegStepValueData, pendingRegSubmissionsData]);

  const onRow = useCallback(
    record => ({
      onClick: () => {
        history.push(`${PEOPLE}/${record.Person.id}/registrations`);
      },
    }),
    [history],
  );

  if (
    (teamLoading && !teamData) ||
    (pendingRegSubmissionsLoading && !pendingRegSubmissionsData) ||
    (pendingRegStepValueLoading && !pendingRegStepValueData)
  ) {
    return <SpinPageContent />;
  }

  if (teamError || pendingRegSubmissionsError || pendingRegStepValueError) {
    return (
      <div style={{ padding: 24 }}>
        <Alert
          message="Team failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <div>
      <TeamConditions teamId={teamId} regId={regId} />

      <TableWithTruncation
        columns={PENDING_COLUMNS}
        includeIndexColumn
        dataSource={pendingDataSource}
        loading={pendingRegSubmissionsLoading || pendingRegStepValueLoading}
        onRow={onRow}
        rowKey="id"
        rowClassName="cursor-pointer"
        size="small"
        pagination={false}
        bordered={false}
        scroll={{ y: 54 * 10 }}
        locale={{
          emptyText: "No pending team members",
        }}
      />
    </div>
  );
}

ViewTeamDrawerPendingTab.propTypes = {
  teamId: PropTypes.string,
  regId: PropTypes.string,
};

ViewTeamDrawerPendingTab.defaultProps = {
  teamId: undefined,
  regId: undefined,
};

export default ViewTeamDrawerPendingTab;
