import gql from "fraql";

export const GET_REG_LIST_WITH_STEPS = gql`
  query GetRegListWithSteps($offset: Int!, $limit: Int!, $where: Reg_bool_exp) {
    Reg(offset: $offset, limit: $limit, where: $where, order_by: { name: asc }) {
      id
      name
      archived
      openAt
      closeAt

      Steps(where: { deleted: { _eq: false } }) {
        id
      }

      Group {
        id
        name
      }
    }

    Reg_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_REG_RULES = gql`
  query GetRegRules($RegId: String!) {
    Rule(where: { RegId: { _eq: $RegId } }) {
      id
      created
      description
      isWarning
      name
      source
    }
  }
`;

export const GET_REG_RULE = gql`
  query GetRegRule($RuleId: String!) {
    Rule(where: { id: { _eq: $RuleId } }) {
      id
      created
      description
      isWarning
      name
      source
    }
  }
`;

export const CREATE_REG_RULE = gql`
  mutation CreateRegRule($values: [Rule_insert_input!]!) {
    insert_Rule(objects: $values) {
      affected_rows
    }
  }
`;

export const UPDATE_REG_RULE = gql`
  mutation UpdateRegRule($id: String!, $values: Rule_set_input!) {
    update_Rule(where: { id: { _eq: $id } }, _set: $values) {
      affected_rows
    }
  }
`;

export const DELETE_REG_RULE = gql`
  mutation DeleteRegRule($id: String!) {
    delete_Rule(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const GET_REG_STEPS = gql`
  query GetRegSteps($RegId: String!) {
    Step(where: { RegId: { _eq: $RegId }, deleted: { _eq: false } }) {
      id
      name
      RegId
      meta
      deleted
    }
  }
`;

export const UPDATE_REG_STEPS = gql`
  mutation UpdateRegSteps($RegId: ID!, $RegStepObjects: [RegStepInput!]!, $DeletedRegStepIds: [ID!]!) {
    upsertSteps(RegId: $RegId, RegStepObjects: $RegStepObjects) {
      affected_rows
    }

    deleteSteps(RegId: $RegId, DeletedRegStepIds: $DeletedRegStepIds) {
      affected_rows
    }
  }
`;
