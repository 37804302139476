import moment from "moment";
import isNil from "lodash/isNil";
import { parseDateTime } from "./dateTimeUtils";

/**
  @param openAt - must not be nil
  @param closeAt - must not be nil
 */
export function isRegistrationClosed(openAt, closeAt) {
  if (isNil(openAt) || isNil(closeAt)) {
    throw new Error("Both openAt and closeAt must not be nil.");
  }

  return moment().isBefore(parseDateTime(openAt)) || moment().isAfter(parseDateTime(closeAt));
}

/**
  @param openAt - must not be nil
 */
export function isRegistrationNotOpenYet(openAt) {
  if (isNil(openAt)) {
    throw new Error("OpenAt must not be nil.");
  }

  return moment().isBefore(parseDateTime(openAt));
}
