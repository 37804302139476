import { validateYupSchema, yupToFormErrors } from "formik";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import isUndefined from "lodash/isUndefined";

export function formFieldHasError(formFieldName, errors) {
  const error = get(errors, formFieldName, undefined);

  return !isUndefined(error) && isString(error);
}

export function formFieldIsInErrorState(formFieldName, errors, touched, submitCount) {
  const hasError = formFieldHasError(formFieldName, errors);

  if (!hasError) {
    return false;
  }

  const isTouched = !!get(touched, formFieldName, false);
  const isSubmitted = !!submitCount;

  return isTouched || isSubmitted;
}

/**
 * Check whether the values are valid based on the Yup validation schema. If there are any errors, we take this to mean
 * that the values are not valid. The call to `validateYupSchema` will return a promise rejection if one or more fields
 * has a validation error, and we can take the promise rejection value and convert this into an errors object. This is
 * heavily modeled on how the `runValidationSchema` function of the `Formik` component works.
 */
export async function checkValuesValidForYupSchema(values, validationSchema) {
  if (!validationSchema) {
    return { isValid: true, errors: {} };
  }

  let errors;

  try {
    await validateYupSchema(values, validationSchema);

    errors = {};
  } catch (error) {
    errors = yupToFormErrors(error);
  }

  return { isValid: isEmpty(errors), errors };
}
