import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import gql from "fraql";
import get from "lodash/get";
import { isRegistrationClosed } from "../../utils/registrationUtil";
import LayoutPageHeader from "../LayoutPageHeader";
import SpinPageContent from "../SpinPageContent";
import RegistrationArchivedAlert from "./RegistrationArchivedAlert";
import RegistrationClosedAlert from "./RegistrationClosedAlert";
import RegistrationLoadingError from "./RegistrationLoadingError";
import RegistrationSubmissionUnavailableAlert from "./RegistrationSubmissionUnavailableAlert";
import RegistrationUserDetailActions from "./RegistrationUserDetailActions";
import RegistrationUserLandingPage from "./RegistrationUserLandingPage";

const GET_REG = gql`
  query RegistrationUserDetailPage_GetReg($RegId: String!) {
    Reg_by_pk(id: $RegId) {
      id
      name
      openAt
      closeAt
      travelOpenAt
      travelCloseAt
      bannerImageUrl
      descriptionContent
      archived
      GroupId
      EventId
    }
  }
`;

function RegistrationUserDetailPage({ match }) {
  const { id: regId } = match.params;

  const { loading: regLoading, error: regError, data: regData } = useQuery(GET_REG, {
    variables: { RegId: regId },
  });

  const reg = get(regData, "Reg_by_pk", null);
  const name = get(reg, "name", "");
  const archived = get(reg, "archived", false);

  function renderContent() {
    if (regLoading && !regData) {
      return <SpinPageContent style={{ height: "100%" }} />;
    }

    if (regError || !reg) {
      return <RegistrationLoadingError />;
    }

    if (isRegistrationClosed(reg.openAt, reg.closeAt)) {
      return <RegistrationClosedAlert />;
    }

    return (
      <>
        {archived && <RegistrationArchivedAlert style={{ margin: "24px 24px 12px 24px" }} />}

        {!archived && (
          <RegistrationSubmissionUnavailableAlert RegId={regId} style={{ margin: "24px 24px 12px 24px" }} />
        )}

        <RegistrationUserLandingPage
          bannerImageUrl={reg.bannerImageUrl}
          descriptionContent={reg.descriptionContent}
          title={reg.name}
          regId={reg.id}
        />
      </>
    );
  }

  let subTitle;

  if (regLoading && !regData) {
    subTitle = <span>Loading...</span>;
  } else if (regError || !reg) {
    subTitle = null;
  } else if (name) {
    subTitle = name;
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fafafa",
          borderBottom: "1px solid #e8e8e8",
        }}
      >
        <div style={{ padding: "15px 24px" }}>
          <LayoutPageHeader
            title="Registration"
            subTitle={subTitle}
            extra={<RegistrationUserDetailActions regId={regId} />}
          />
        </div>
      </div>

      {renderContent()}
    </>
  );
}

RegistrationUserDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default RegistrationUserDetailPage;
