import React from "react";
import PropTypes from "prop-types";
import { Input } from "formik-antd";
import get from "lodash/get";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldReadOnlyContent from "./FormFieldReadOnlyFields/FormFieldReadOnlyContent";

function FormFieldTextArea(props) {
  const { name, meta, disabled, readOnly } = props;

  const placeholder = get(meta, "placeholder", "");

  function getReadOnlyContent() {
    return (
      <Input.TextArea
        name={name}
        readOnly
        style={{ border: "none", padding: 0, resize: "none" }}
        autosize={{ minRows: 2, maxRows: 6 }}
      />
    );
  }

  if (readOnly) {
    return <FormFieldReadOnlyContent name={name} meta={meta} content={getReadOnlyContent()} />;
  }

  /* FIXME: The autosize below can cause the following warning in Chrome. Update ant-design to prevent this warning:
 Warning: Removing a style property during rerender (overflow) when a conflicting property is set (overflowY) can lead to styling bugs. To avoid this, don't mix shorthand and non-shorthand properties for the same value; instead, replace the shorthand with separate values.
    in textarea (created by ResizeObserver)
    in ResizeObserver (created by Context.Consumer)
    in TextArea (created by FieldInner)
    in FieldInner (created by Context.Consumer)
    in FormikConnect(FieldInner) (created by Field)
    in Field (created by ForwardRef)
    in ForwardRef (at FormFieldTextArea.js:14)

  This has been fixed in ant-design 3.26.14. See https://github.com/ant-design/ant-design/issues/21697
   */
  return (
    <FormFieldFormItem {...props}>
      <Input.TextArea name={name} placeholder={placeholder} disabled={disabled} autosize={{ minRows: 2, maxRows: 6 }} />
    </FormFieldFormItem>
  );
}

FormFieldTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
};

FormFieldTextArea.defaultProps = {
  readOnly: false,
};

export default FormFieldTextArea;
