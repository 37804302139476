import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Input } from "formik-antd";
import * as yup from "yup";
import get from "lodash/get";
import includes from "lodash/includes";
import { COMPETITION_SCORE_TYPE } from "../../../../../constants/competitionConstants";
import {
  schemaQualificationScoreValue,
  schemaCompetitionScoreValue,
  formatCompetitionScoreForEdit,
} from "../../../../../utils/scoreUtils";
import { schemaNullable } from "../../../../../utils/yupUtils";
import ActionForm from "../../../../ActionForm";
import FormFieldCompetitionScore from "../../../../FormFields/FormFieldCompetitionScore";
import "./TeamQualificationScoreProvided.scss";

const FIELDS = {
  qualificationScoreValue: {
    type: "competitionScore",
    required: true,
  },
  qualificationScoreLabel: {
    type: "text",
    required: false,
  },
  qualificationScoreType: {
    type: "text",
    required: false,
  },
  qualificationScoreRequired: {
    type: "bool",
    required: false,
  },
  qualificationScoreLimitMin: {
    type: "text",
    required: false,
  },
  qualificationScoreLimitMax: {
    type: "text",
    required: false,
  },
};

function TeamQualificationScoreProvided({
  teamQualificationScore,
  competition,
  showFormBottomComponent,
  readOnly,
  onTeamQualificationScoreSubmit,
}) {
  const competitionQualificationScoreLabel = get(competition, "qualificationScoreLabel", "") || "Qualification score";
  const competitionQualificationScoreType = get(competition, "qualificationScoreType", null);
  const competitionQualificationScoreRequired = get(competition, "qualificationScoreRequired", false);

  const initialValues = useMemo(() => {
    const competitionQualificationScoreLimitMinValue = get(competition, "qualificationScoreLimitMin.value", null);
    const competitionQualificationScoreLimitMaxValue = get(competition, "qualificationScoreLimitMax.value", null);

    return {
      qualificationScoreValue: formatCompetitionScoreForEdit(teamQualificationScore, competitionQualificationScoreType),

      // Hidden fields
      qualificationScoreType: competitionQualificationScoreType,

      // Data fields used only for validation logic
      selected: true,
      qualificationScoreInputDisplayed: true,
      qualificationScoreRequired: competitionQualificationScoreRequired,
      qualificationScoreLabel: competitionQualificationScoreLabel,
      qualificationScoreLimitMin: competitionQualificationScoreLimitMinValue,
      qualificationScoreLimitMax: competitionQualificationScoreLimitMaxValue,
    };
  }, [
    competition,
    competitionQualificationScoreRequired,
    competitionQualificationScoreLabel,
    competitionQualificationScoreType,
    teamQualificationScore,
  ]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      qualificationScoreValue: schemaQualificationScoreValue(schemaCompetitionScoreValue("qualificationScoreType")),

      // Hidden fields
      qualificationScoreType: schemaNullable(yup.string()),

      // Data fields used only for validation logic
      selected: yup.bool().required(),
      qualificationScoreInputDisplayed: yup.bool().required(),
      qualificationScoreRequired: yup.bool().required(),
      qualificationScoreLabel: yup
        .string()
        .trim()
        .required(),
      qualificationScoreLimitMin: schemaNullable(yup.number()),
      qualificationScoreLimitMax: schemaNullable(yup.number()),
    });
  }, []);

  let qualificationScoreValueHelpText = "";

  if (competitionQualificationScoreType === COMPETITION_SCORE_TYPE.ELAPSED_TIME) {
    qualificationScoreValueHelpText = "Enter a value in the format hh:mm:ss.SS (e.g. 11:55:24.81)";
  } else if (competitionQualificationScoreType === COMPETITION_SCORE_TYPE.NUMBER_INTEGER) {
    qualificationScoreValueHelpText = "Enter a number without decimal places (e.g. 100)";
  } else if (
    includes(
      [COMPETITION_SCORE_TYPE.LENGTH_METRES, COMPETITION_SCORE_TYPE.MASS_KILOGRAMS, COMPETITION_SCORE_TYPE.NUMBER],
      competitionQualificationScoreType,
    )
  ) {
    qualificationScoreValueHelpText = "Enter a number, optionally with up to 2 decimal places (e.g. 100 or 100.99)";
  }

  return (
    <ActionForm
      fields={FIELDS}
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={onTeamQualificationScoreSubmit}
      submitText="Save"
      formLayout="vertical"
      showFormBottomComponent={showFormBottomComponent}
      readOnly={readOnly}
      className="team-qualification-score-provided__form"
      renderFields={({ submitCount }) => (
        <>
          <FormFieldCompetitionScore
            name="qualificationScoreValue"
            meta={{
              label: competitionQualificationScoreLabel,
              helpText: qualificationScoreValueHelpText,
              required: competitionQualificationScoreRequired,
              competitionScoreTypeFieldId: "qualificationScoreType",
            }}
            submitCount={submitCount}
            readOnly={readOnly}
          />

          <Input name="qualificationScoreType" type="hidden" />
        </>
      )}
    />
  );
}

TeamQualificationScoreProvided.propTypes = {
  competition: PropTypes.shape({
    qualificationScoreRequired: PropTypes.bool,
    qualificationScoreLabel: PropTypes.string,
    qualificationScoreType: PropTypes.string,
    qualificationScoreLimitMin: PropTypes.any,
    qualificationScoreLimitMax: PropTypes.any,
  }).isRequired,
  teamQualificationScore: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.number,
    rawValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  showFormBottomComponent: PropTypes.bool,
  readOnly: PropTypes.bool,
  onTeamQualificationScoreSubmit: PropTypes.func.isRequired,
};

TeamQualificationScoreProvided.defaultProps = {
  teamQualificationScore: null,
  showFormBottomComponent: true,
  readOnly: false,
};

export default TeamQualificationScoreProvided;
