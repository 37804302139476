import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import get from "lodash/get";
import includes from "lodash/includes";

const validStatusTypes = ["success", "info", "warning", "error"];

function FormStatusMessage({ status, isSingleField, containerStyle }) {
  const statusType = get(status, "type", null);

  const hasValidStatusType = includes(validStatusTypes, statusType);
  const hasStatusError = statusType === "error";

  if (!hasValidStatusType) {
    return null;
  }

  const statusMessage = get(status, "message", null);
  const hasStatusMessage = !!statusMessage;

  const statusDescription = get(status, "description", null);
  const hasStatusDescription = !!statusDescription;

  let message;
  let description;

  if (hasStatusMessage) {
    message = statusMessage;
  } else if (hasStatusError) {
    if (isSingleField) {
      message = "Sorry, something went wrong saving this field. Please check the value you've provided and try again.";
    } else {
      message = "Something went wrong";
    }
  } else {
    return null;
  }

  if (hasStatusDescription) {
    description = statusDescription;
  } else if (hasStatusError) {
    description =
      "Sorry, something went wrong submitting this form. Please check the values you've provided and try again.";
  } else {
    return null;
  }

  return (
    <div style={containerStyle}>
      {isSingleField && <Alert message={message} type={statusType} showIcon />}

      {!isSingleField && <Alert message={message} description={description} type={statusType} showIcon />}
    </div>
  );
}

FormStatusMessage.propTypes = {
  status: PropTypes.object,
  isSingleField: PropTypes.bool,
  containerStyle: PropTypes.any,
};

FormStatusMessage.defaultProps = {
  status: null,
  isSingleField: false,
  containerStyle: { marginBottom: 16 },
};

export default FormStatusMessage;
