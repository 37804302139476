import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import { REG_SUBMISSION_STATUSES } from "../../../constants/regStatusConstants";
import PersonRegistrationSubmissionStatusAlert from "../../MenuPeople/Registrations/PersonRegistrationSubmissionStatusAlert";
import RegSubmissionProgress from "../../RegSubmissionProgress";
import { REGISTRATION_LIST_MODE } from "./MyRegistrationsConstants";
import "./MyRegistrationItemStatus.scss";

function MyRegistrationItemStatus({ regSubmission, regIsClosed, regIsNotOpenYet, listMode }) {
  if (regIsClosed) {
    const message = regIsNotOpenYet ? "Registration not open" : "Registration closed";
    return <Alert type="warning" message={message} className="ant-alert-with-description alert-registration-status" />;
  }

  if (listMode === REGISTRATION_LIST_MODE.CURRENT) {
    return (
      <div className="ant-alert-warning reg-submission-progress-container">
        <RegSubmissionProgress percent={regSubmission.percentComplete} />
      </div>
    );
  }

  return (
    <PersonRegistrationSubmissionStatusAlert
      status={regSubmission.status}
      lastSubmittedAt={regSubmission.lastSubmittedAt}
      regTimezoneName={regSubmission.Reg.timezoneName}
    />
  );
}

MyRegistrationItemStatus.propTypes = {
  regIsClosed: PropTypes.bool.isRequired,
  regIsNotOpenYet: PropTypes.bool.isRequired,
  regSubmission: PropTypes.shape({
    status: PropTypes.oneOf(Object.values(REG_SUBMISSION_STATUSES)).isRequired,
    lastSubmittedAt: PropTypes.string,
    percentComplete: PropTypes.number,
    Reg: PropTypes.shape({
      timezoneName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  listMode: PropTypes.oneOf(Object.values(REGISTRATION_LIST_MODE)).isRequired,
};

export default MyRegistrationItemStatus;
