import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import { Button, message, Modal, Typography } from "antd";
import each from "lodash/each";
import filter from "lodash/filter";
import get from "lodash/get";
import has from "lodash/has";
// eslint-disable-next-line import/order
import "ant-design-pro/dist/ant-design-pro.css";
import { REG_SUBMISSION_STATUSES, REG_STEP_STATUSES } from "../../../constants/regStatusConstants";
import { getContentFromServerErrorDescription, serverErrorStatus } from "../../../utils/graphqlErrors";
import isBlank from "../../../utils/isBlank";
import { useGetUserHasPermission, PERMISSION } from "../../../utils/useGetUserHasPermission";
import { useRegContext } from "../../../utils/useRegContext";
import RegSubmissionProgress from "../../RegSubmissionProgress";
import PersonRegistrationTravelStatusAlert from "../TravelGroups/PersonRegistrationTravelStatusAlert";
import { preparedStepsWithoutSubmissionsStatusStep } from "./PersonDetailRegistrationsConstants";
import PersonRegistrationSubmissionStatusAlert from "./PersonRegistrationSubmissionStatusAlert";
import RegistrationAgeRequirementStatusAlert from "./RegistrationAgeRequirementStatusAlert";
import RegistrationArchivedStatusAlert from "./RegistrationArchivedStatusAlert";
import RegistrationClosedStatusAlert from "./RegistrationClosedStatusAlert";

// Please remove once shopping cart has been implemented.
// eslint-disable-next-line no-unused-vars
function RegistrationsSidebar({ steps, shoppingCartCharges }) {
  const regContext = useRegContext();
  const regContextData = get(regContext, "regContextData", {});

  const canSeeTravelStatus = useGetUserHasPermission(PERMISSION.SHOW_REGISTRATION_TRAVEL_STATUS);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const hasSubmitRegistrationPermission = useGetUserHasPermission(PERMISSION.SUBMIT_REGISTRATION);

  const {
    RegId,
    PersonId,
    RegSubmission,
    reg,
    regIsReadOnly,
    regIsClosed,
    regAgeRequirementIsMet,
    updateRegSubmissionStatus,
  } = regContextData;

  const regAgeRequirementCheckedAndIsMet = regAgeRequirementIsMet === true;
  const canSubmitRegistration = hasSubmitRegistrationPermission && regAgeRequirementCheckedAndIsMet;

  const isCompleted = useMemo(() => {
    const completionCalculationSteps = filter(steps, step => {
      const stepStatus = get(step, "RegStatus.status", "");

      return stepStatus !== REG_STEP_STATUSES.statusNotApplicable;
    });

    let completedStepsCount = 0;

    each(completionCalculationSteps, step => {
      const stepStatus = get(step, "RegStatus.status", "");

      const isCompletedStep = stepStatus === REG_STEP_STATUSES.completed;

      if (isCompletedStep) {
        completedStepsCount += 1;
      }
    });

    const totalStepsCount = completionCalculationSteps.length;

    return completedStepsCount === totalStepsCount;
  }, [steps]);

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);

      const stepIds = preparedStepsWithoutSubmissionsStatusStep(steps).map(step => get(step, "id"));
      // Always submit for payment, even if the Registration has no Fees. Backend will determine the correct response.
      await updateRegSubmissionStatus({
        variables: {
          RegSubmissionId: get(RegSubmission, "id", null),
          status: REG_SUBMISSION_STATUSES.payment,
          stepIds,
        },
      });

      // TODO: Implement CrowdCatcher payment system here once Fango has successfully created the charge RegStepValues.
      // shoppingCartCharges is passing through Shopping Cart details.

      message.success("Registration submitted.");

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);

      const errorStatus = serverErrorStatus(error);

      const description = get(
        errorStatus,
        "description",
        "There was an error processing your request. Please try again later.",
      );

      const content = getContentFromServerErrorDescription(description);

      Modal.error({
        title: "Registration not submitted",
        content,
        style: { minWidth: 500 },
      });

      if (!has(errorStatus, "hasValidationError") && !has(errorStatus, "hasPermissionsError")) {
        Sentry.captureException(error);
      }
    }
  }, [updateRegSubmissionStatus, RegSubmission, setIsSubmitting, steps]);

  const submissionStatus = get(RegSubmission, "status", null);
  const submissionLastSubmittedAt = get(RegSubmission, "lastSubmittedAt");
  const submissionPercentComplete = get(RegSubmission, "percentComplete");

  const regArchived = get(reg, "archived", false);
  const regAgeRequirement = get(reg, "ageRequirement", false);

  if (isBlank(reg)) {
    // Render empty column when `reg` value is not available (e.g. not yet loaded). This allows us to simplify other
    // components that are rendered as children, as they can then rely on the `reg` value being available. Note that in
    // normal scenarios we would only ever be in this state very briefly.
    return <div className="person-registrations-sidebar" />;
  }

  return (
    <div className="person-registrations-sidebar">
      {!!regArchived && <RegistrationArchivedStatusAlert style={{ marginBottom: 24 }} />}

      {!regArchived && regIsClosed && <RegistrationClosedStatusAlert reg={reg} style={{ marginBottom: 24 }} />}

      {!!regAgeRequirement && (
        <RegistrationAgeRequirementStatusAlert
          reg={reg}
          regAgeRequirementIsMet={regAgeRequirementIsMet}
          style={{ marginBottom: 24 }}
        />
      )}

      {regIsReadOnly && (
        <PersonRegistrationSubmissionStatusAlert
          status={submissionStatus}
          lastSubmittedAt={submissionLastSubmittedAt}
          regTimezoneName={reg.timezoneName}
        />
      )}

      {!regIsReadOnly && (
        <div className="person-registrations-not-submitted ant-alert-warning">
          <RegSubmissionProgress percent={submissionPercentComplete} />

          {canSubmitRegistration && (
            <Button
              type="default"
              size="large"
              onClick={handleSubmit}
              disabled={!isCompleted || isSubmitting}
              loading={isSubmitting}
              style={{
                lineHeight: "normal",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              block
            >
              <Typography.Paragraph
                ellipsis
                style={{ maxWidth: "100%", display: "inline-block", margin: "0 6px 0 6px", color: "inherit" }}
              >
                Finalise Registration
              </Typography.Paragraph>
            </Button>
          )}
        </div>
      )}

      {canSeeTravelStatus && (
        <PersonRegistrationTravelStatusAlert
          PersonId={PersonId}
          RegId={RegId}
          showWhenTravelGroupsDisabled={false}
          style={{ marginTop: 24 }}
        />
      )}
    </div>
  );
}

RegistrationsSidebar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  shoppingCartCharges: PropTypes.arrayOf(PropTypes.object),
};

RegistrationsSidebar.defaultProps = {
  shoppingCartCharges: null,
};

export default RegistrationsSidebar;
