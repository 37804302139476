import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import { connect } from "formik";
import get from "lodash/get";

function FormFieldHidden(props) {
  const { name, formik } = props;
  const value = get(formik.values, name, "");

  return <Input type="hidden" name={name} value={value} />;
}

FormFieldHidden.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
};

export default connect(FormFieldHidden);
