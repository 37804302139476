import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Icon, Spin, Typography } from "antd";
import gql from "fraql";
import { isNil, get } from "lodash";
import ENV from "../constants/envConstants";
import SpinPageContent from "../components/SpinPageContent";
import { useTheme } from "../components/Theme/ThemeContext";
import "./Login.scss";

export const CONFIRM_EMAIL = gql`
  mutation confirm($username: String!, $confirm_key: String!, $recaptchaToken: String!) {
    confirm(username: $username, confirm_key: $confirm_key, recaptchaToken: $recaptchaToken) {
      result
    }
  }
`;

const ConfirmNewUser = () => {
  const themeContext = useTheme();
  const [mode, setMode] = useState("LOADING");
  const [failMessage, setFailMessage] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  // Store User and Key from the parameters of the customers link.
  const { key, user } = useMemo(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    return { key: get(params, "key", null), user: get(params, "user", null) };
  }, []);

  const [confirmEmail, { data, loading, error }] = useMutation(CONFIRM_EMAIL);

  // Fetch the reCAPTCHA token. Called by useEffect below.
  const fetchRecaptchaToken = useCallback(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(ENV.RECAPTCHA_PUBLIC_SITE_KEY, { action: mode }).then(token => {
        setRecaptchaToken(token);
      });
    });
  }, [mode]);

  // Download and attach reCAPTCHA script. Set up event handler once script has downloaded.
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${ENV.RECAPTCHA_PUBLIC_SITE_KEY}`;
    script.addEventListener("load", fetchRecaptchaToken);
    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Send email confirmation request once Google Recaptcha token has been fetched.
  useEffect(() => {
    try {
      if (!recaptchaToken) {
        setMode("LOADING");
        return;
      }
      const mutationOptions = { variables: { username: user, confirm_key: key, recaptchaToken } };

      confirmEmail(mutationOptions);
    } catch (mutationError) {
      console.log("Error occured during mutation update.");
      console.error(mutationError);
      setMode("FAILED");
    }
  }, [confirmEmail, recaptchaToken, user, key]);

  // Update page depending on email confirmation results.
  useEffect(() => {
    if (loading) {
      setFailMessage(null);
      setMode("LOADING");
    }
    // Mutation was successful, and confirmation succeeded.
    if (!loading && data && !error) {
      const success = get(data, "confirm.result.success", false);
      const result = get(data, "confirm.result", null);
      if (success) {
        setMode("SUCCESS");
        return;
      }
      // Mutation attempt worked, but the confirmation failed on backend.
      const errMessage = get(result, "error", null);
      setFailMessage(errMessage);
      setMode("FAILED");
    }
    // Something went wrong with the mutation attempt.
    if (!loading && error) {
      console.error("Confirm email failed. Error below:");
      console.error(error);
      setFailMessage("Server error");
      setMode("FAILED");
    }
  }, [data, loading, error]);

  if (isNil(key) || isNil(user)) {
    return <p>Invalid reset details.</p>;
  }
  return (
    <>
      <div className="login-form__main">
        <div>
          {isNil(themeContext) && <SpinPageContent />}
          {!isNil(themeContext) && <img alt="logo" className="login-form__img" src={themeContext.login_logo_path} />}
        </div>
        <div style={{ textAlign: "center", padding: "1em 0 1em 0" }}>
          {isNil(themeContext) && <Spin size="small" />}
          {!isNil(themeContext) && <Typography.Title level={4}>{themeContext.siteName}</Typography.Title>}
        </div>
        <div className="login-form__input-div">
          {mode === "LOADING" && (
            <div>
              <Typography.Title level={3}>Confirming email, please hold...</Typography.Title>
              <Spin size="large" />
            </div>
          )}
          {mode === "SUCCESS" && (
            <>
              <div style={{ paddingBottom: "1em" }}>
                <Icon type="check-circle" theme="twoTone" twoToneColor="#53c41a" style={{ fontSize: "5em" }} />
              </div>
              <p>Success!</p>
              <p>You can now return to the login screen and log in with your new credentials.</p>
            </>
          )}
          {mode === "FAILED" && (
            <>
              <div style={{ paddingBottom: "1em" }}>
                <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#FF0000" style={{ fontSize: "5em" }} />
              </div>
              <p>Error!</p>
              <p>The following errors were encountered whilst confirming your email address:</p>
              <p>{failMessage || "Server error."}</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

ConfirmNewUser.propTypes = {};

export default ConfirmNewUser;
