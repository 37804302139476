import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Spin } from "antd";
import gql from "fraql";
import get from "lodash/get";
import omit from "lodash/omit";
import sortBy from "lodash/sortBy";
import { useTenantPicker } from "../TenantPickerContext";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";

const GET_COUNTRY_OPTIONS = gql`
  query Get_Countries_From_Tenant($tenantId: String = "") {
    Country(where: { Tenant: { id: { _eq: $tenantId } } }) {
      id
      code
      name
    }
  }
`;

// TODO: This might not work in the context of Users filling in forms, due to how the Tenent Picker pulls Tenant ID from Auth0 token.
// Consider finding a way to pull TenantID from the Form and passing it here instead.

function FormFieldCountry(props) {
  const { meta } = props;
  const { tenantFilter } = useTenantPicker();

  const { loading, data: countryData } = useQuery(GET_COUNTRY_OPTIONS, {
    variables: { tenantId: tenantFilter },
  });

  let countryList = useMemo(() => {
    if (loading) {
      return [];
    }

    return get(countryData, "Country");
  }, [countryData, loading]);

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Select a country";
  }

  if (loading && !countryData) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (!loading && countryData) {
    countryList = sortBy(
      countryList.map(Country => ({ value: Country.id, label: Country.name })),
      option => option.label,
    );
  }
  return <FormFieldSelect {...props} meta={{ ...meta, options: countryList, placeholder }} />;
}

FormFieldCountry.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default FormFieldCountry;
