import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import { connect } from "formik";
import gql from "fraql";
import find from "lodash/find";
import get from "lodash/get";
import omit from "lodash/omit";
import isBlank from "../../utils/isBlank";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../StatusText";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";

const GET_TEAM_POSITIONS_FOR_COMPETITION = gql`
  query FormFieldTeamPosition_GetCompetitionTeamPositions($CompetitionId: String!) {
    Competition_by_pk(id: $CompetitionId) {
      id
      teamPositions
    }
  }
`;

function FormFieldTeamPosition(props) {
  const {
    meta,
    name,
    hideOnEmpty,
    hideOnLoading,
    formik: { values, setFieldTouched, touched },
    query,
    queryOptions,
  } = props;

  const { loading: teamPositionsLoading, error: teamPositionsError, data: teamPositionsData } = useQuery(
    query,
    queryOptions,
  );

  const fieldValue = get(values, name, undefined);

  const teamPositions = useMemo(() => {
    return get(teamPositionsData, "Competition_by_pk.teamPositions", []).map(teamPosition => ({
      value: teamPosition,
      label: teamPosition,
    }));
  }, [teamPositionsData]);

  const teamPositionOptions = useMemo(() => {
    const teamPositionsResult = [...teamPositions];

    if (!isBlank(fieldValue) && !find(teamPositionsResult, { value: fieldValue })) {
      teamPositionsResult.unshift({
        value: fieldValue,
        label: <StatusText status={STATUS_TEXT_STATUS_OPTIONS.ERROR}>{fieldValue} [Invalid]</StatusText>,
        title: `${fieldValue} [Invalid]`,
      });
    }

    return teamPositionsResult;
  }, [fieldValue, teamPositions]);

  useEffect(() => {
    if (!isBlank(fieldValue) && !find(teamPositions, { value: fieldValue })) {
      if (!get(touched, name, false)) {
        setFieldTouched(name, true);
      }
    }
  }, [teamPositions, fieldValue, name, setFieldTouched, touched]);

  if (teamPositionsLoading && !teamPositionsData) {
    if (hideOnLoading) {
      return null;
    }

    return (
      <FormFieldFormItem {...props} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (teamPositionsError) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayForInput={false}>
        <Alert message="Team positions failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  if (hideOnEmpty && teamPositionOptions.length === 0) {
    return null;
  }

  return <FormFieldSelect {...omit(props, ["formik"])} meta={{ ...meta, options: teamPositionOptions }} />;
}

FormFieldTeamPosition.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
    allowClear: PropTypes.bool,
    required: PropTypes.bool,
    helpText: PropTypes.node,
    displayDefaultLabel: PropTypes.bool,
    displayMode: PropTypes.string,
  }).isRequired,
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldTouched: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  hideOnEmpty: PropTypes.bool,
  hideOnLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  query: PropTypes.any,
  queryOptions: PropTypes.object,
};

FormFieldTeamPosition.defaultProps = {
  query: GET_TEAM_POSITIONS_FOR_COMPETITION,
  queryOptions: {},
  readOnly: false,
  hideOnEmpty: false,
  hideOnLoading: false,
};

export default connect(FormFieldTeamPosition);
