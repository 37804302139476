import isEmpty from "lodash/isEmpty";
import isFinite from "lodash/isFinite";
import isNaN from "lodash/isNaN";

/**
 * isBlank(undefined)
 // => true

 isBlank(null)
 // => true

 isBlank('')
 // => true

 isBlank([])
 // => true

 isBlank({})
 // => true

 isBlank(NaN)
 // => true

 isBlank(0)
 // => false

 See https://stackoverflow.com/a/36693986/1374827.
 */
function isBlank(value) {
  return (isEmpty(value) && !isFinite(value)) || isNaN(value);
}

export default isBlank;
