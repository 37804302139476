import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { REG_STEP_STATUSES } from "../../../../constants/regStatusConstants";
import FormSubmission from "../../../Form/FormSubmission";
import { ConnectedFormBottomComponent } from "../FormBottomComponent";

function RegistrationStepForm({
  step,
  stepIndex,
  updateRegStepStatus,
  nextStep,
  prevStep,
  regIsReadOnly,
  PersonId,
  showFormBottomComponent,
  readOnly,
}) {
  const FormId = step.meta.form;

  const handleSubmitSuccess = useCallback(async () => {
    await updateRegStepStatus(step, REG_STEP_STATUSES.completed);
    nextStep(true);
  }, [step, updateRegStepStatus, nextStep]);

  const handleSubmitDraftSuccess = useCallback(async () => {
    await updateRegStepStatus(step, REG_STEP_STATUSES.notCompleted);
  }, [step, updateRegStepStatus]);

  return (
    <FormSubmission
      FormId={FormId}
      FormBottomComponent={props => (
        <ConnectedFormBottomComponent
          step={step}
          stepIndex={stepIndex}
          prevStep={prevStep}
          nextStep={nextStep}
          {...props}
        />
      )}
      disabled={regIsReadOnly}
      persistId={step.id}
      relatedRecordsData={{ PersonId, GroupId: step.RegGroupId }}
      formLayout="vertical"
      saveContext
      showFormBottomComponent={showFormBottomComponent}
      readOnly={readOnly}
      upsertConstraint="unique_form_field_with_group_person"
      handleSubmitSuccess={handleSubmitSuccess}
      handleSubmitDraftSuccess={handleSubmitDraftSuccess}
    />
  );
}

RegistrationStepForm.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.string.isRequired,
    RegGroupId: PropTypes.string,
    name: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      form: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      order: PropTypes.number,
      conditions: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  stepIndex: PropTypes.number.isRequired,
  updateRegStepStatus: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  regIsReadOnly: PropTypes.bool.isRequired,
  PersonId: PropTypes.string.isRequired,
  showFormBottomComponent: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default RegistrationStepForm;
