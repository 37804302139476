import gql from "fraql";

export const GET_PERSON_REG_STEP_VALUES = gql`
  query GetPersonRegStepValues($PersonId: String!, $RegId: String!) {
    RegStepValue(where: { PersonId: { _eq: $PersonId }, RegId: { _eq: $RegId }, Step: { deleted: { _eq: false } } }) {
      id
      StepId
      value
    }
  }
`;

export const GET_STEP_REG_STEP_VALUE = gql`
  query GetStepRegStepValue($PersonId: String!, $RegId: String!, $StepId: String!) {
    RegStepValue(where: { PersonId: { _eq: $PersonId }, RegId: { _eq: $RegId }, StepId: { _eq: $StepId } }) {
      id
      value
    }
  }
`;

export const SAVE_STEP_REG_STEP_VALUE = gql`
  mutation SaveRegStepValue($objects: [RegStepValue_insert_input!]!) {
    insert_RegStepValue(
      objects: $objects
      on_conflict: { constraint: unique_value_step_per_person, update_columns: [value] }
    ) {
      affected_rows
    }
  }
`;

export const GET_REG_CHARGES = gql`
  query GetRegCharges($PersonId: String!, $RegId: String!) {
    RegStepValue(where: { PersonId: { _eq: $PersonId }, RegId: { _eq: $RegId }, value: { _has_key: "charge" } }) {
      id
      value
    }
  }
`;
