import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Icon, Tooltip } from "antd";
import isUrl from "is-url";
import { Editor, getEventTransfer } from "slate-react";
import isEmpty from "lodash/isEmpty";
import { renderMark, renderBlock, renderInline } from "./TextEditorRenders";
import {
  hasMark,
  hasLinks,
  hasBlock,
  onKeyDown,
  handleParseValues,
  wrapLink,
  unwrapLink,
  insertImage,
  isImage,
  schema,
} from "./TextEditorUtils";

const DEFAULT_NODE = "paragraph";
const ButtonGroup = Button.Group;

const TextEditor = ({ name, placeholder, defaultValue, setFieldValue, disabled }) => {
  const editorRef = useRef(null);
  const initialValue = handleParseValues(defaultValue);
  const [stateValue, onChange] = useState(initialValue);

  const onClickMark = (event, type) => {
    event.preventDefault();
    editorRef.current.toggleMark(type);
  };

  const onClickBlock = (event, type) => {
    event.preventDefault();

    const editor = editorRef.current;
    const { document } = editor.value;

    if (type !== "bulleted-list" && type !== "numbered-list") {
      const isActive = hasBlock(type, stateValue);
      const isList = hasBlock("list-item", stateValue);

      if (isList) {
        editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock("bulleted-list")
          .unwrapBlock("numbered-list");
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type);
      }
    } else {
      const isList = hasBlock("list-item", stateValue);
      const isType = editor.value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type);
      });

      if (isList && isType) {
        editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock("bulleted-list")
          .unwrapBlock("numbered-list");
      } else if (isList) {
        editor.unwrapBlock(type === "bulleted-list" ? "numbered-list" : "bulleted-list").wrapBlock(type);
      } else {
        editor.setBlocks("list-item").wrapBlock(type);
      }
    }
  };

  const onClickLink = event => {
    const editor = editorRef.current;
    event.preventDefault();
    const { value } = editor;

    if (hasLinks(value)) {
      editor.command(unwrapLink);
    } else {
      const href = window.prompt("Enter the URL of the link:", "https://");

      if (href == null || href === "https://") {
        return;
      }

      if (value.selection.isExpanded) {
        editor.command(wrapLink, href);
      } else {
        editor
          .insertText(href)
          .moveFocusBackward(href.length)
          .command(wrapLink, href);
      }
    }
  };

  const onClickImage = event => {
    const editor = editorRef.current;
    event.preventDefault();
    const src = window.prompt("Enter the URL of the image:");
    if (!src) {
      return;
    }
    editor.command(insertImage, src);
  };

  const onChangeContent = useCallback(
    ({ value }) => {
      setFieldValue(name, JSON.stringify(value.toJSON()));
      onChange(value);
    },
    [name, setFieldValue, onChange],
  );

  // eslint-disable-next-line consistent-return
  const onPaste = (event, editor, next) => {
    const { value } = editor;
    if (editor.value.selection.isCollapsed) {
      return next();
    }

    const transfer = getEventTransfer(event);
    const { type, text } = transfer;
    if (type !== "text" && type !== "html") {
      return next();
    }
    if (!isUrl(text)) {
      return next();
    }

    if (hasLinks(value)) {
      editor.command(unwrapLink);
    }

    editor.command(wrapLink, text);
  };

  // eslint-disable-next-line consistent-return
  const onDropOrPaste = (event, editor, next) => {
    const target = editor.findEventRange(event);
    if (!target && event.type === "drop") {
      return next();
    }

    const transfer = getEventTransfer(event);
    const { type, text, files } = transfer;

    if (type === "files") {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");
        if (mime !== "image") {
          // eslint-disable-next-line no-continue
          continue;
        }

        reader.addEventListener("load", () => {
          editor.command(insertImage, reader.result, target);
        });

        reader.readAsDataURL(file);
      }
      // eslint-disable-next-line consistent-return
      return;
    }

    if (type === "text") {
      if (!isUrl(text)) {
        return next();
      }
      if (!isImage(text)) {
        return next();
      }
      editor.command(insertImage, text, target);
      // eslint-disable-next-line consistent-return
      return;
    }

    next();
  };

  const renderBlockButton = (type, icon = null, label = null) => {
    let isActive = hasBlock(type, stateValue);
    if (["numbered-list", "bulleted-list"].includes(type)) {
      const { document, blocks } = stateValue;

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key);
        isActive = hasBlock("list-item", stateValue) && parent && parent.type === type;
      }
    }

    return (
      <Button
        type={isActive ? "primary" : "default"}
        onMouseDown={event => onClickBlock(event, type)}
        disabled={disabled}
      >
        {isEmpty(label) ? icon && <Icon type={icon} /> : label}
      </Button>
    );
  };

  const renderMarkButton = (type, icon) => {
    const isActive = hasMark(type, stateValue) ? "primary" : "default";
    return <Button type={isActive} icon={icon} onMouseDown={event => onClickMark(event, type)} disabled={disabled} />;
  };

  const renderLink = () => {
    const isActive = hasLinks(stateValue) ? "primary" : "default";
    return (
      <Tooltip placement="top" title="Add Link">
        <Button type={isActive} icon="link" onMouseDown={event => onClickLink(event)} disabled={disabled} />
      </Tooltip>
    );
  };

  const renderImage = () => {
    return (
      <Tooltip placement="top" title="Add Image">
        <Button icon="paper-clip" onMouseDown={event => onClickImage(event)} disabled={disabled} />
      </Tooltip>
    );
  };

  const memoizedEditor = useMemo(
    () => (
      <Editor
        name={name}
        ref={editorRef}
        className="ant-input text-editor-field"
        placeholder={placeholder}
        onChange={onChangeContent}
        renderInline={renderInline}
        renderBlock={renderBlock}
        renderMark={renderMark}
        onDrop={onDropOrPaste}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        value={stateValue}
        schema={schema}
        disabled={disabled}
      />
    ),
    [name, stateValue, placeholder, disabled, onChangeContent],
  );

  useEffect(() => {
    onChangeContent({ value: handleParseValues(defaultValue) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <div className="text-editor">
      <div className="text-editor-toolbar">
        <ButtonGroup>
          {renderMarkButton("bold", "bold")}
          {renderMarkButton("italic", "italic")}
          {renderMarkButton("underlined", "underline")}
          {renderLink()}
          {renderImage()}
        </ButtonGroup>
        <ButtonGroup>
          {renderBlockButton("heading-one", "", "H1")}
          {renderBlockButton("heading-two", "", "H2")}
          {renderBlockButton("heading-three", "", "H3")}
          {renderBlockButton("heading-four", "", "H4")}
          {renderBlockButton("block-quote", "message", "Quote")}
          {renderBlockButton("numbered-list", "ordered-list")}
          {renderBlockButton("bulleted-list", "unordered-list")}
        </ButtonGroup>
      </div>
      {memoizedEditor}
    </div>
  );
};

TextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TextEditor.defaultProps = {
  disabled: false,
  placeholder: "",
};

export default TextEditor;
