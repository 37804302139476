import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import { connect } from "formik";
import gql from "fraql";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { RESULT_STATUS_CATEGORY_LABEL } from "../../constants/competitionConstants";
import isBlank from "../../utils/isBlank";
import useClearFieldValueWhenDependentOnFieldValueChanges from "../../utils/useClearFieldValueWhenDependentOnFieldValueChanges";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../StatusText";
import { useTenantPicker } from "../TenantPickerContext";
import FormFieldCheckboxGroup from "./FormFieldCheckboxGroup";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldRadioGroup from "./FormFieldRadioGroup";

const GET_RESULT_STATUSES = gql`
  query FormFieldResultStatus_GetResultStatuses($tenantId: String) {
    ResultStatus(order_by: [{name: asc}, {category: asc}], where: {tenantId: {_eq: $tenantId}}) {
      id
      name
      category
      tenantId
    }
  }
`;

function FormFieldResultStatus(props) {
  const { name, meta, formik, data, skipQuery } = props;

  const { tenantFilter } = useTenantPicker();

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const multiple = get(meta, "multiple", false);
  const emptyMessageText = get(meta, "emptyMessageText") || "There are no statuses.";

  const { data: resultStatusesData, loading: resultStatusesLoading, error: resultStatusesError } = useQuery(
    GET_RESULT_STATUSES,
    {
      variables: {"tenantId": tenantFilter},
      skip: skipQuery,
    },
  );

  useClearFieldValueWhenDependentOnFieldValueChanges({
    fieldName: name,
    dependentOnFieldName: meta.dependentOnFieldName,
    formik,
  });

  const options = useMemo(() => {
    let resultStatusesDataForOptions;

    if (!skipQuery) {
      resultStatusesDataForOptions = get(resultStatusesData, "ResultStatus");
    } else {
      resultStatusesDataForOptions = data;
    }

    resultStatusesDataForOptions = resultStatusesDataForOptions || [];

    return resultStatusesDataForOptions.map(resultStatus => {
      const categoryLabel = get(RESULT_STATUS_CATEGORY_LABEL, resultStatus.category, "");
      if (isBlank(categoryLabel)) {
        return {
          value: resultStatus.id,
          label: resultStatus.name,
        };
      }
      return {
        value: resultStatus.id,
        label: (
          <span>
            {resultStatus.name} <StatusText status={STATUS_TEXT_STATUS_OPTIONS.DEFAULT}>[{categoryLabel}]</StatusText>
          </span>
        ),
      };
    });
  }, [data, resultStatusesData, skipQuery]);

  if (!skipQuery && resultStatusesLoading && !resultStatusesData) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (!skipQuery && resultStatusesError) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Statuses failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  if (isEmpty(options)) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message={emptyMessageText} type="info" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  if (multiple) {
    return <FormFieldCheckboxGroup {...omit(props, ["formik"])} meta={{ ...meta, options }} />;
  }

  return <FormFieldRadioGroup {...omit(props, ["formik"])} meta={{ ...meta, options }} />;
}

FormFieldResultStatus.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
    helpText: PropTypes.node,
    displayDefaultLabel: PropTypes.bool,
    displayMode: PropTypes.string,
    dependentOnFieldName: PropTypes.string,
    emptyMessageText: PropTypes.string,
  }).isRequired,
  readOnly: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  skipQuery: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

FormFieldResultStatus.defaultProps = {
  readOnly: false,
  skipQuery: false,
  data: null,
};

export default connect(FormFieldResultStatus);
