import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { DeleteOutlined, EditOutlined, TeamOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Alert, Button, Dropdown, Form, Menu, message, Modal } from "antd";
import { connect } from "formik";
import gql from "fraql";
import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import omit from "lodash/omit";
import { serverErrorStatus } from "../../../../../utils/graphqlErrors";
import useCurrentUser from "../../../../../utils/useCurrentUser";
import { useGetUserHasPermission, PERMISSION } from "../../../../../utils/useGetUserHasPermission";
import { useRegContext } from "../../../../../utils/useRegContext";
import ButtonMore from "../../../../ButtonMore";
import DrawerCreateTeam from "../../../../DrawerCreateTeam";
import DrawerUpdateTeam from "../../../../DrawerUpdateTeam";
import FormFieldFormItem from "../../../../FormFields/FormFieldFormItem";
import FormFieldTeam from "../../../../FormFields/FormFieldTeam";
import FormFieldTeamPosition from "../../../../FormFields/FormFieldTeamPosition";
import TeamQualificationScoreNotProvidedAlert from "../../../Competitions/TeamQualificationScoreNotProvidedAlert";
import ViewTeamDrawer from "../../../Competitions/ViewTeamDrawer/ViewTeamDrawer";
import "./RegistrationStepCompetitionsFormFieldTeam.scss";

const GET_TEAMS_FOR_COMPETITION = gql`
  query RegistrationStepCompetitionsFormFieldTeam_GetTeams($CompetitionId: String!) {
    Team(where: { CompetitionId: { _eq: $CompetitionId } }, order_by: { name: asc }) {
      id
      name
      qualificationScore
      Competition {
        id
        qualificationScoreType
        qualificationScoreRequired
        teamQualificationScoreDetermination
      }
      CreatedBy {
        id
      }
    }
  }
`;

const DELETE_TEAM = gql`
  mutation RegistrationStepCompetitionsFormFieldTeam_DeleteTeam($TeamId: ID!) {
    deleteTeam(TeamId: $TeamId) {
      affected_rows
    }
  }
`;

function RegistrationStepCompetitionsFormFieldTeam(props) {
  const {
    name,
    disabled,
    competitionId,
    registrantsCanCreateTeams,
    registrantsCanCreateTeamsRoles,
    managersCanCreateTeams,
    teamSelectionRequired,
    teamPositionRequired,
    formik: { setFieldValue, setFieldTouched, values },
  } = props;

  const teamFieldName = `${name}.team`;
  const teamFieldValue = get(values, teamFieldName, undefined);

  const teamPositionFieldName = `${name}.teamPosition`;

  const currentUser = useCurrentUser();
  const regContext = useRegContext();
  const regId = get(regContext, "regContextData.RegId");
  const userRegSubmissionParticipationRoleId = get(regContext, "regContextData.RegSubmission.RoleId", null);
  const hasFullAccessToManageTeam = useGetUserHasPermission(PERMISSION.MANAGE_TEAM);
  const hasConditionalAccessToManageTeam = useGetUserHasPermission(PERMISSION.MANAGE_TEAM_CONDITIONALLY);
  const hasRegistrantAccessToManageTeam = useGetUserHasPermission(PERMISSION.MANAGE_TEAM_REGISTRANT);
  const hasPermissionToShowViewTeamButton = useGetUserHasPermission(PERMISSION.VIEW_TEAM);

  const [isDrawerCreateTeamVisible, setIsDrawerCreateTeamVisible] = useState(false);
  const [isDrawerUpdateTeamVisible, setIsDrawerUpdateTeamVisible] = useState(false);
  const [isViewTeamDrawerVisible, setIsViewTeamDrawerVisible] = useState(false);

  const { error: teamsError, data: teamsData, refetch: teamsRefetch } = useQuery(GET_TEAMS_FOR_COMPETITION, {
    variables: { CompetitionId: competitionId },
  });

  const teamSelected = useMemo(() => {
    if (!teamFieldValue) {
      // Match return value of `find` function when team is not found in `teams`.
      return undefined;
    }

    const teams = get(teamsData, "Team", []);

    return find(teams, { id: teamFieldValue });
  }, [teamsData, teamFieldValue]);

  const [deleteTeam] = useMutation(DELETE_TEAM);

  const handleCloseDrawerCreateTeam = useCallback(() => {
    setIsDrawerCreateTeamVisible(false);
  }, [setIsDrawerCreateTeamVisible]);

  const handleCloseDrawerUpdateTeam = useCallback(() => {
    setIsDrawerUpdateTeamVisible(false);
  }, [setIsDrawerUpdateTeamVisible]);

  const handleMutationsTeamSuccess = useCallback(
    async newTeamId => {
      setIsDrawerCreateTeamVisible(false);
      setIsDrawerUpdateTeamVisible(false);

      await teamsRefetch();

      setFieldValue(teamFieldName, newTeamId);
      setFieldTouched(teamFieldName);
    },
    [
      teamFieldName,
      setIsDrawerCreateTeamVisible,
      setIsDrawerUpdateTeamVisible,
      teamsRefetch,
      setFieldValue,
      setFieldTouched,
    ],
  );

  const handleViewTeamClick = useCallback(() => {
    setIsViewTeamDrawerVisible(true);
  }, [setIsViewTeamDrawerVisible]);

  const handleCloseViewTeamDrawer = useCallback(() => {
    setIsViewTeamDrawerVisible(false);
  }, [setIsViewTeamDrawerVisible]);

  const handleDeleteTeam = useCallback(() => {
    const teamName = get(teamSelected, "name", "");

    if (!teamName) {
      return;
    }

    const content = `Are you sure you want to delete the team "${teamName}"? If any person has chosen it in their registration submission, it will not be deleted.`;

    const modal = Modal.confirm({
      title: "Delete team from competition",
      content,
      okText: "Ok",
      async onOk() {
        modal.update({
          okButtonProps: {
            loading: true,
          },
          cancelButtonProps: {
            disabled: true,
          },
        });

        try {
          await deleteTeam({
            variables: { TeamId: teamSelected.id },
          });

          await handleMutationsTeamSuccess("");

          message.success("Team deleted.");
        } catch (error) {
          console.error(error);

          const errorStatus = serverErrorStatus(error);

          const description = get(
            errorStatus,
            "description",
            "There was an error processing your request. Please try again later.",
          );

          Modal.error({
            title: "Team not deleted",
            content: description,
          });

          Sentry.captureException(error);
        }
      },
      onCancel() {},
    });
  }, [deleteTeam, teamSelected, handleMutationsTeamSuccess]);

  const userCanManageTeam = useMemo(() => {
    if (hasFullAccessToManageTeam) {
      return true;
    }

    const teamCreatorId = get(teamSelected, "CreatedBy.id", null);

    if (isNull(teamCreatorId)) {
      return false;
    }

    return (
      (hasConditionalAccessToManageTeam || hasRegistrantAccessToManageTeam) && get(currentUser, "id") === teamCreatorId
    );
  }, [
    hasFullAccessToManageTeam,
    hasConditionalAccessToManageTeam,
    hasRegistrantAccessToManageTeam,
    currentUser,
    teamSelected,
  ]);

  const dropdownMarkup = useMemo(() => {
    if (isUndefined(teamSelected)) {
      return null;
    }

    const menuItems = [];

    if (hasPermissionToShowViewTeamButton) {
      menuItems.push(
        <Menu.Item key="0" onClick={handleViewTeamClick}>
          <TeamOutlined />
          View Team
        </Menu.Item>,
      );
    }

    if (userCanManageTeam) {
      menuItems.push(
        <Menu.Item key="1" onClick={() => setIsDrawerUpdateTeamVisible(true)}>
          <EditOutlined />
          Edit
        </Menu.Item>,
        <Menu.Item key="2" onClick={handleDeleteTeam}>
          <DeleteOutlined />
          Delete
        </Menu.Item>,
      );
    }

    if (isEmpty(menuItems)) {
      return null;
    }

    const menu = <Menu theme="dark">{menuItems}</Menu>;

    return (
      <>
        <Dropdown overlay={menu} trigger={["click"]}>
          <ButtonMore className="form-field-team__button" />
        </Dropdown>

        <DrawerUpdateTeam
          isVisible={isDrawerUpdateTeamVisible && !disabled}
          handleClose={handleCloseDrawerUpdateTeam}
          handleSuccess={handleMutationsTeamSuccess}
          teamId={teamSelected.id}
        />

        <ViewTeamDrawer
          visible={isViewTeamDrawerVisible}
          teamId={teamFieldValue}
          regId={regId}
          handleClose={handleCloseViewTeamDrawer}
        />
      </>
    );
  }, [
    handleDeleteTeam,
    teamSelected,
    setIsDrawerUpdateTeamVisible,
    disabled,
    isDrawerUpdateTeamVisible,
    handleCloseDrawerUpdateTeam,
    handleMutationsTeamSuccess,
    isViewTeamDrawerVisible,
    teamFieldValue,
    regId,
    handleCloseViewTeamDrawer,
    hasPermissionToShowViewTeamButton,
    handleViewTeamClick,
    userCanManageTeam,
  ]);

  const canCreateTeam = useMemo(() => {
    if (hasFullAccessToManageTeam) {
      return true;
    }

    if (hasConditionalAccessToManageTeam && managersCanCreateTeams) {
      return true;
    }

    if (
      registrantsCanCreateTeams &&
      hasRegistrantAccessToManageTeam &&
      find(registrantsCanCreateTeamsRoles, { Role: { id: userRegSubmissionParticipationRoleId } })
    ) {
      return true;
    }

    return false;
  }, [
    hasFullAccessToManageTeam,
    hasConditionalAccessToManageTeam,
    hasRegistrantAccessToManageTeam,
    managersCanCreateTeams,
    registrantsCanCreateTeams,
    registrantsCanCreateTeamsRoles,
    userRegSubmissionParticipationRoleId,
  ]);

  if (teamsError) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayForInput={false}>
        <Alert message="Teams failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  return (
    <>
      <>
        <div className="form-field-team">
          <div className="form-field-team__container">
            <FormFieldTeam
              {...omit(props, ["formik"])}
              meta={{
                required: teamSelectionRequired,
                label: "Team",
                placeholder: "Select a team",
                allowClear: true,
              }}
              competitionId={competitionId}
              name={teamFieldName}
              query={GET_TEAMS_FOR_COMPETITION}
              queryOptions={{ variables: { CompetitionId: competitionId } }}
              hideOnEmpty
              hideOnLoading
            />
            {dropdownMarkup}
          </div>
          {teamSelected !== undefined && (
            <div className="form-field-team__position">
              <FormFieldTeamPosition
                {...omit(props, ["formik"])}
                meta={{
                  required: teamPositionRequired,
                  label: "Position",
                  placeholder: "Select a position",
                  allowClear: true,
                }}
                queryOptions={{ variables: { CompetitionId: competitionId } }}
                name={teamPositionFieldName}
                hideOnEmpty
                hideOnLoading
              />
            </div>
          )}
        </div>

        <TeamQualificationScoreNotProvidedAlert style={{ marginTop: 15 }} team={teamSelected} />
      </>

      {canCreateTeam && (
        <div className="registration-step-competition-form-field-team__create-new">
          <Form.Item label="Create your team">
            <Button onClick={() => setIsDrawerCreateTeamVisible(true)} disabled={disabled}>
              Create New
            </Button>
          </Form.Item>
        </div>
      )}

      <DrawerCreateTeam
        isVisible={isDrawerCreateTeamVisible && !disabled}
        handleClose={handleCloseDrawerCreateTeam}
        handleSuccess={handleMutationsTeamSuccess}
        competitionId={competitionId}
        regId={regId}
      />
    </>
  );
}

RegistrationStepCompetitionsFormFieldTeam.propTypes = {
  name: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  competitionId: PropTypes.string.isRequired,
  registrantsCanCreateTeams: PropTypes.bool.isRequired,
  registrantsCanCreateTeamsRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      Role: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  ).isRequired,
  managersCanCreateTeams: PropTypes.bool.isRequired,
  teamSelectionRequired: PropTypes.bool.isRequired,
  teamPositionRequired: PropTypes.bool.isRequired,
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    values: PropTypes.object,
  }).isRequired,
  readOnly: PropTypes.bool,
};

RegistrationStepCompetitionsFormFieldTeam.defaultProps = {
  readOnly: false,
};

export default connect(RegistrationStepCompetitionsFormFieldTeam);
