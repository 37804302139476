import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import isBlank from "../utils/isBlank";
import "./StatusText.scss";

export const STATUS_TEXT_STATUS_OPTIONS = {
  DEFAULT: "DEFAULT",
  WARNING: "WARNING",
  ERROR: "ERROR",
};

function StatusText({ status, children }) {
  if (isBlank(children)) {
    return null;
  }

  const className = classnames("status-text", {
    "status-text--default": status === STATUS_TEXT_STATUS_OPTIONS.DEFAULT,
    "status-text--warning": status === STATUS_TEXT_STATUS_OPTIONS.WARNING,
    "status-text--error": status === STATUS_TEXT_STATUS_OPTIONS.ERROR,
  });

  return <span className={className}>{children}</span>;
}

StatusText.propTypes = {
  status: PropTypes.string.isRequired,
  children: PropTypes.any,
};

StatusText.defaultProps = {
  children: null,
};

export default StatusText;
