import get from "lodash/get";
import isNil from "lodash/isNil";

function mapFormValuesToDbValues(formFields, values, updatedBy, relatedRecordsData) {
  return Object.keys(values).map(FormFieldId => {
    const formField = formFields.find(formFieldItem => formFieldItem.id === FormFieldId);

    const type = get(formField, "type", null);

    if (!type) {
      throw new Error("Unable to determine type of form field.");
    }

    const fieldValue = values[FormFieldId];

    return {
      // Values are stored as `jsonb` and `null` values are not valid. `null` values can end up here if the field
      // itself does not not accept an empty string as a valid value, e.g. the Number input field. Handle `null` values
      // here as we prepare them for updating the DB.
      value: isNil(fieldValue) ? "" : fieldValue,
      type,
      FormFieldId,
      updated_by: updatedBy,
      ...relatedRecordsData,
    };
  });
}

export default mapFormValuesToDbValues;
