// This is a slightly customised version of Select from `formik-antd`.
//
// It includes the following changes:
// - Update the `onChange` method so that we call `setFieldTouched` when the value changes, instead of waiting until
//   the Select input is blurred before we call `setFieldTouched`.
// - Convert code from TypeScript to JavaScript, define propTypes for the component.
// - Update code formatting, naming, etc. to match the rules used in our project.
//
// See:
// - https://fuse.atlassian.net/browse/FUS-818
// - https://github.com/formium/formik/issues/114

import React from "react";
import PropTypes from "prop-types";
import { Select as SelectAntD } from "antd";
import { Field } from "formik-antd";

function Select({ name, validate, fast, children, onChange, onBlur, ...restProps }) {
  return (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (
        <SelectAntD
          onChange={(newValue, option) => {
            setFieldValue(name, newValue);
            setFieldTouched(name);

            if (onChange) {
              onChange(newValue, option);
            }
          }}
          onBlur={newValue => {
            setFieldTouched(name);

            if (onBlur) {
              onBlur(newValue);
            }
          }}
          // Setting undefined will show the placeholder.
          value={value === "" || value === null ? undefined : value}
          {...restProps}
        >
          {children}
        </SelectAntD>
      )}
    </Field>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  fast: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Select.defaultProps = {
  validate: null,
  fast: false,
  children: null,
  onChange: null,
  onBlur: null,
};

Select.renderOptions = options =>
  options.map(({ label, ...restProps }, index) => (
    // eslint-disable-next-line react/jsx-pascal-case,react/no-array-index-key
    <SelectAntD.Option key={`select-option-${index}`} {...restProps}>
      {label}
    </SelectAntD.Option>
  ));

Select.Option = SelectAntD.Option;
Select.OptGroup = SelectAntD.OptGroup;

export default Select;
