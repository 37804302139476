import React from "react";
import PropTypes from "prop-types";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../StatusText";

function FormFieldDivider({ builder }) {
  if (builder) {
    return (
      <div style={{ lineHeight: "40px" }}>
        <StatusText status={STATUS_TEXT_STATUS_OPTIONS.DEFAULT}>[Divider]</StatusText>
      </div>
    );
  }

  return <div style={{ backgroundColor: "#e8e8e8", height: 1, margin: "24px 0" }} />;
}

FormFieldDivider.propTypes = {
  builder: PropTypes.bool,
};

FormFieldDivider.defaultProps = {
  builder: false,
};

export default FormFieldDivider;
