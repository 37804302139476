import difference from "lodash/difference";
import values from "lodash/values";
import { FIELD_TYPES } from "./fieldConstants";

export const FIELD_TYPES_VALID_FOR_CONDITION = difference(values(FIELD_TYPES), [FIELD_TYPES.file, FIELD_TYPES.image]);

export const CONDITION_FIELD_VALUE_COMPONENT_TYPES = [
  FIELD_TYPES.text,
  FIELD_TYPES.select,
  FIELD_TYPES.bool,
  FIELD_TYPES.date,
  FIELD_TYPES.time,
  FIELD_TYPES.datetime,
  FIELD_TYPES.number,
  FIELD_TYPES.currency,
  FIELD_TYPES.phone,
  FIELD_TYPES.email,
  FIELD_TYPES.country,
  FIELD_TYPES.language,
  FIELD_TYPES.gender,
  FIELD_TYPES.group,
];
