import React from "react";
import PropTypes from "prop-types";
import ISO6391 from "iso-639-1";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import FormFieldSelect from "./FormFieldSelect";

export const LANGUAGE_OPTIONS = sortBy(
  ISO6391.getLanguages(ISO6391.getAllCodes()).map(language => ({
    value: language.code,
    label: language.name,
  })),
  option => option.label,
);

function FormFieldLanguage(props) {
  const { meta } = props;

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Select a language";
  }

  return <FormFieldSelect {...props} meta={{ ...meta, options: LANGUAGE_OPTIONS, placeholder }} />;
}

FormFieldLanguage.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default FormFieldLanguage;
