import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { VIEW_DATE_TIME_FORMAT, DATE_TIME_INPUT_FORMAT, VIEW_TIME_FORMAT } from "../../constants/dateTimeConstants";
import FormFieldDate from "./FormFieldDate";

const SHOW_TIME = { format: VIEW_TIME_FORMAT, minuteStep: 1, use12Hours: false };

function FormFieldDatetime(props) {
  const { meta } = props;

  const showTime = useMemo(() => {
    return meta.showTime ?? SHOW_TIME;
  }, [meta.showTime]);

  const parsingFormat = useMemo(() => {
    return meta.parsingFormat ?? VIEW_DATE_TIME_FORMAT;
  }, [meta.parsingFormat]);

  // all datetime fields should include the timezone.
  return <FormFieldDate {...props} format={DATE_TIME_INPUT_FORMAT} showTime={showTime} parsingFormat={parsingFormat} />;
}

FormFieldDatetime.propTypes = {
  meta: PropTypes.shape({ showTime: PropTypes.object, parsingFormat: PropTypes.string }).isRequired,
};

export default FormFieldDatetime;
