import React from "react";
import filter from "lodash/filter";
import find from "lodash/find";
import first from "lodash/first";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import join from "lodash/join";
import split from "lodash/split";
import isBlank from "./isBlank";
import isJSON from "./isJson";

/*
Example error object returned from 'useMutation' or 'useQuery'

{
  "graphQLErrors": [
    {
      "extensions": { "path": "$", "code": "ValidationError" },
      "message": "{\"errors\": [{\"message\": \"Error message\", \"code\": \"invalid\", \"field\": \"field\"}]}"
    }
  ],
  "networkError": null,
  "message": "GraphQL error: {\"errors\": [{\"message\": \"Error message\", \"code\": \"invalid\", \"field\": \"field\"}]}"
}

 */

const GRAPHQL_ERRORS_KEY = "graphQLErrors";
const GRAPHQL_ERRORS_FIRST = "graphQLErrors.0";
const ERROR_CODE_PATH = "extensions.code";
const ERROR_MESSAGE_PATH = "message";
const VALIDATION_ERROR_CODE = "ValidationError";

// parse messages and filter on field or message
function filterErrorMessages(graphQlError, field, message) {
  const messageFromError = get(graphQlError, ERROR_MESSAGE_PATH);

  if (!isJSON(messageFromError)) {
    return [];
  }

  let errors = get(JSON.parse(messageFromError), "errors", []);

  if (!isNil(field)) {
    errors = filter(errors, err => get(err, "field") === field);
  }

  if (!isNil(message)) {
    errors = filter(errors, err => get(err, "message", "").includes(message));
  }

  return errors;
}

/*
  @returns true if there is only 1 graphQL error and it's code matches the given code.
 */
export function isErrorCode(graphQlError, code) {
  const errors = get(graphQlError, GRAPHQL_ERRORS_KEY);

  if (!errors) {
    return false;
  }

  if (errors.length !== 1) {
    return false;
  }

  const firstError = get(graphQlError, GRAPHQL_ERRORS_FIRST);
  return get(firstError, ERROR_CODE_PATH) === code;
}

/*
  Returns true if there is only 1 error and the error is a validation error which matches the given parameters.

  @param graphQlError - the error object from `useQuery` or `userMutation`
  @param field - the field to match on. If null or undefined then will not attempt to match on the field.
  @param message - the message to match on. If null or undefined then will not attempt to match on the message.
  @returns False if the error is not a validation error.
    Otherwise: true depending on the arguments passed.
    If field is passed, then it will return true if there is any error for the given field.
    If message is passed without a field, then it will return true if there is any error that has the substring that matches the given message.
    If message is passed with a field, then it will return true if there is any error for the field with a substring that matches the given error.
 */
export function isValidationError(graphQlError, field = null, message = null) {
  if (!isErrorCode(graphQlError, VALIDATION_ERROR_CODE)) {
    return false;
  }

  if (!isNil(field) || !isNil(message)) {
    const matchedMessages = filterErrorMessages(get(graphQlError, GRAPHQL_ERRORS_FIRST), field, message);
    return !isEmpty(matchedMessages);
  }

  return true;
}

export function getFieldErrorMessage(graphQlErrors, field) {
  const graphQlErrorFirst = get(graphQlErrors, GRAPHQL_ERRORS_FIRST);
  const messageFromError = get(graphQlErrorFirst, ERROR_MESSAGE_PATH);

  if (!isJSON(messageFromError)) {
    return null;
  }

  const errors = get(JSON.parse(messageFromError), "errors", []);
  const errorMessage = find(errors, { field });

  if (isNil(errorMessage)) {
    return null;
  }

  return errorMessage.message;
}

const ERROR_TYPE_VALIDATION = "ValidationError";
const ERROR_TYPE_PERMISSIONS = "PermissionDenied";

function jsonErrorMessages(message) {
  try {
    const jsonMessage = JSON.parse(message);
    const errors = get(jsonMessage, "errors", []);

    return errors.map(messageError => get(messageError, "message", ""));
  } catch (e) {
    // We couldn't parse the error message so just return an empty result.
  }
  return [];
}

function joinMessages(stringMessages) {
  const errorMessages = stringMessages.map(message => join(get(message, "messagesArray", []), " "));
  const joinedMessage = join(errorMessages, " ");

  if (isBlank(joinedMessage)) {
    return undefined;
  }

  return joinedMessage;
}

function hasValidationError(messages) {
  return !!find(messages, message => get(message, "type") === ERROR_TYPE_VALIDATION);
}

function hasPermissionsError(messages) {
  return !!find(messages, message => get(message, "type") === ERROR_TYPE_PERMISSIONS);
}

/*
  @returns Status object to pass to Formik's actions.setStatus() to render a status error message.
  Includes the key 'validationError' if it was a backend validation error.
 */
export function serverErrorStatus(serverError) {
  try {
    const graphQlErrors = get(serverError, "graphQLErrors", []);

    const messages = graphQlErrors.map(error => {
      const type = get(error, "extensions.code");
      const messagesArray = jsonErrorMessages(get(error, "message", ""));
      return { type, messagesArray };
    });

    const joinedMessages = joinMessages(messages);

    return {
      type: "error",
      message: "Error processing your request",
      description: joinedMessages,
      details: messages,
      hasValidationError: hasValidationError(messages),
      hasPermissionsError: hasPermissionsError(messages),
    };
  } catch (e) {
    // We can't parse the errors so just return a generic error.
  }
  return { type: "error" };
}

export function getContentFromServerErrorDescription(serverErrorDescription) {
  const errorList = filter(split(serverErrorDescription, "\n\n"), item => !!item);

  let content;

  if (errorList.length <= 1) {
    content = first(errorList);
  } else {
    content = (
      <ul>
        {errorList.map((errorItem, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <li key={index}>{errorItem}</li>;
        })}
      </ul>
    );
  }

  return content;
}
