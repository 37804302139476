import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../StatusText";

function FormFieldHeading({ meta, builder }) {
  let label = get(meta, "label", "");

  if (!label) {
    label = "[Unnamed field]";
  }

  if (builder) {
    return (
      <div
        style={{
          display: "block",
          lineHeight: "40px",
        }}
      >
        <strong style={{ color: "rgba(0, 0, 0, 0.85)" }}>{label}</strong>{" "}
        <StatusText status={STATUS_TEXT_STATUS_OPTIONS.DEFAULT}>[Heading]</StatusText>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "block",
        marginBottom: 10,
      }}
    >
      <strong style={{ color: "rgba(0, 0, 0, 0.85)" }}>{label}</strong>
    </div>
  );
}

FormFieldHeading.propTypes = {
  meta: PropTypes.object.isRequired,
  builder: PropTypes.bool,
};

FormFieldHeading.defaultProps = {
  builder: false,
};

export default FormFieldHeading;
