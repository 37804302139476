import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import FormFieldText from "./FormFieldText";

function FormFieldEmail(props) {
  const { name, meta } = props;

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Enter an email address";
  }

  return <FormFieldText {...props} name={name} meta={{ ...meta, placeholder }} />;
}

FormFieldEmail.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

export default FormFieldEmail;
