// This is a wrapper for DatePicker from `antd`, intended to be used as a drop-in replacement in cases where we have a
// need for the features/changes which this component includes.
//
// It includes the following changes:
// - Add support for the `addonAfter` prop. This is supported out of the box by Ant Design for the Input component, but
//   not for the DatePicker component.

import React from "react";
import PropTypes from "prop-types";
import { DatePicker as DatePickerAntD } from "antd";
import "./DatePicker.scss";

function DatePicker(props) {
  const { addonAfter, style, ...restProps } = props;

  if (addonAfter) {
    return (
      <div className="ant-input-group-wrapper ant-input-group-wrapper--with-addon-after" style={style}>
        <span className="ant-input-wrapper ant-input-group ant-calendar-picker calendar-picker--with-addon-after">
          <DatePickerAntD {...restProps} />
          {!!addonAfter && <span className="ant-input-group-addon">{addonAfter}</span>}
        </span>
      </div>
    );
  }

  return <DatePickerAntD {...restProps} style={style} />;
}

DatePicker.propTypes = {
  addonAfter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.any,
};

DatePicker.defaultProps = {
  addonAfter: undefined,
  style: undefined,
};

export default DatePicker;
