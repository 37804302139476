import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { DeleteOutlined, EditOutlined, PlusOutlined, EyeOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Alert, Dropdown, Menu, Modal, Spin, message } from "antd";
import { connect } from "formik";
import gql from "fraql";
import filter from "lodash/filter";
import find from "lodash/find";
import get from "lodash/get";
import isNil from "lodash/isNil";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import omit from "lodash/omit";
import { serverErrorStatus } from "../../utils/graphqlErrors";
import { getFlattenedOptions } from "../../utils/optionsUtils";
import { getTravelGroupName } from "../../utils/travelGroupUtils";
import useCurrentUser from "../../utils/useCurrentUser";
import { useGetUserHasPermission, PERMISSION } from "../../utils/useGetUserHasPermission";
import ButtonMore from "../ButtonMore";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../StatusText";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";
import "./FormFieldTravelGroup.scss";

const GET_TRAVEL_GROUP_LIST = gql`
  query FormFieldTravelGroup_TravelGroupList {
    TravelGroup(order_by: [{ Group: { name: asc } }, { Group: { number: asc } }]) {
      id
      type
      number
      formCompletedAt
      createdBy
      Group {
        id
        name
      }
    }
  }
`;

const DELETE_TRAVEL_GROUP = gql`
  mutation FormFieldTravelGroup_DeleteTravelGroup($TravelGroupId: ID!) {
    deleteTravelGroup(TravelGroupId: $TravelGroupId) {
      affected_rows
    }
  }
`;

function FormFieldTravelGroup(props) {
  const {
    name,
    meta,
    disabled,
    query,
    queryOptions,
    mutationOptions,
    managersCanCreateTravelGroups,
    setTravelGroupFormDrawerState,
    setIsCreateTravelGroupsDrawerVisible,
    hideDropdown,
    formik: { values, setFieldValue },
  } = props;

  const fieldValue = get(values, name, undefined);

  const hasFullAccess = useGetUserHasPermission(PERMISSION.MANAGE_TRAVEL_GROUP);
  const hasConditionalAccess = useGetUserHasPermission(PERMISSION.MANAGE_TRAVEL_GROUP_CONDITIONALLY);
  const hasWriteAccess = useGetUserHasPermission(PERMISSION.WRITE_ACCESS);

  const currentUser = useCurrentUser();

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);

  const { loading: travelGroupsLoading, error: travelGroupsError, data: travelGroupsData } = useQuery(
    query,
    queryOptions,
  );

  const travelGroupSelected = useMemo(() => {
    if (!fieldValue) {
      // Match return value of `find` function when travel group is not found in `travelGroups`.
      return undefined;
    }

    const travelGroups = get(travelGroupsData, "TravelGroup", []);

    return find(travelGroups, { id: fieldValue });
  }, [travelGroupsData, fieldValue]);

  const [deleteTravelGroup] = useMutation(DELETE_TRAVEL_GROUP, mutationOptions);

  const handleOnDeleteTravelGroup = useCallback(
    TravelGroupId => {
      const travelGroupName = getTravelGroupName(travelGroupSelected);

      if (!travelGroupName) {
        return;
      }

      const content = `Are you sure you want to delete the travel group "${travelGroupName}"? If any person currently has it assigned as their arrival or departure travel group, it will not be deleted.`;

      const modal = Modal.confirm({
        title: "Delete travel group",
        content,
        okText: "Ok",
        async onOk() {
          modal.update({
            okButtonProps: {
              loading: true,
            },
            cancelButtonProps: {
              disabled: true,
            },
          });

          try {
            await deleteTravelGroup({
              variables: { TravelGroupId },
            });

            message.success("Travel group deleted.");
          } catch (errorDeleteTravelGroup) {
            console.error(errorDeleteTravelGroup);

            const errorStatus = serverErrorStatus(errorDeleteTravelGroup);

            const description = get(
              errorStatus,
              "description",
              "There was an error processing your request. Please try again later.",
            );

            Modal.error({
              title: "Travel group not deleted",
              content: description,
            });

            Sentry.captureException(errorDeleteTravelGroup);
          }
        },
        onCancel() {},
      });
    },
    [deleteTravelGroup, travelGroupSelected],
  );

  const optionGroups = useMemo(() => {
    const travelGroups = get(travelGroupsData, "TravelGroup", []);

    const completedTravelGroups = filter(travelGroups, travelGroup => !isNil(travelGroup.formCompletedAt));
    const notCompletedTravelGroups = filter(travelGroups, travelGroup => isNull(travelGroup.formCompletedAt));
    const unknownStatusTravelGroups = filter(travelGroups, travelGroup => isUndefined(travelGroup.formCompletedAt));

    return [
      {
        key: "COMPLETED",
        label: "Completed",
        children: completedTravelGroups.map(travelGroup => ({
          value: travelGroup.id,
          label: (
            <span>
              {getTravelGroupName(travelGroup)}{" "}
              <StatusText status={STATUS_TEXT_STATUS_OPTIONS.DEFAULT}>[Completed]</StatusText>
            </span>
          ),
          title: getTravelGroupName(travelGroup),
        })),
      },
      {
        key: "NOT_COMPLETED",
        label: "Not completed",
        children: notCompletedTravelGroups.map(travelGroup => ({
          value: travelGroup.id,
          label: (
            <span>
              {getTravelGroupName(travelGroup)}{" "}
              <StatusText status={STATUS_TEXT_STATUS_OPTIONS.WARNING}>[Not completed]</StatusText>
            </span>
          ),
          title: getTravelGroupName(travelGroup),
        })),
      },
      {
        key: "UNKNOWN_STATUS",
        label: "Unknown status",
        children: unknownStatusTravelGroups.map(travelGroup => ({
          value: travelGroup.id,
          label: (
            <span>
              {getTravelGroupName(travelGroup)}{" "}
              <StatusText status={STATUS_TEXT_STATUS_OPTIONS.ERROR}>[Unknown status]</StatusText>
            </span>
          ),
          title: getTravelGroupName(travelGroup),
          disabled: true,
        })),
      },
    ];
  }, [travelGroupsData]);

  // The available Travel Group options may change due to deletion of a Travel Group in this component or
  // another. This ensures the selected value is always a valid one.
  useEffect(() => {
    if (!travelGroupsData || isNull(fieldValue)) {
      return;
    }

    // Generate array containing all available options based on the option groups.
    const options = getFlattenedOptions(optionGroups);

    if (!isUndefined(find(options, { value: fieldValue }))) {
      return;
    }

    // The current field value is not currently a valid option, so we reset the field value.
    setFieldValue(name, null);
  }, [name, travelGroupsData, optionGroups, fieldValue, setFieldValue]);

  const handleOnEditTravelGroupInfo = useCallback(() => {
    setTravelGroupFormDrawerState({ visible: true, TravelGroupId: fieldValue });
  }, [fieldValue, setTravelGroupFormDrawerState]);

  const handleOnViewTravelGroupInfo = useCallback(() => {
    setTravelGroupFormDrawerState({ visible: true, TravelGroupId: fieldValue, disabled: true });
  }, [fieldValue, setTravelGroupFormDrawerState]);

  const handleOnCreateTravelGroups = useCallback(() => {
    setIsCreateTravelGroupsDrawerVisible(true);
  }, [setIsCreateTravelGroupsDrawerVisible]);

  const dropdownMarkup = useMemo(() => {
    if (hideDropdown || !(hasFullAccess || hasConditionalAccess)) {
      return null;
    }

    const canCreateNew =
      !!setIsCreateTravelGroupsDrawerVisible &&
      hasWriteAccess &&
      (hasFullAccess || (hasConditionalAccess && managersCanCreateTravelGroups));

    let canEdit = false;
    let canDelete = false;
    let canView = false;

    if (!isUndefined(travelGroupSelected)) {
      if (!hasWriteAccess) {
        canView = !!setTravelGroupFormDrawerState;
      } else {
        canEdit = !!setTravelGroupFormDrawerState;
      }
      canDelete =
        hasWriteAccess &&
        (hasFullAccess || (hasConditionalAccess && !!currentUser && currentUser.id === travelGroupSelected.createdBy));
    }

    if (!canCreateNew && !canEdit && !canDelete && !canView) {
      return null;
    }

    const menu = (
      <Menu theme="dark">
        {canView && (
          <Menu.Item key="1" onClick={handleOnViewTravelGroupInfo}>
            <EyeOutlined />
            View Travel Group Details
          </Menu.Item>
        )}

        {canEdit && (
          <Menu.Item key="1" onClick={handleOnEditTravelGroupInfo}>
            <EditOutlined />
            Edit Travel Group Details
          </Menu.Item>
        )}

        {canDelete && (
          <Menu.Item key="2" onClick={() => handleOnDeleteTravelGroup(travelGroupSelected.id)} disabled={disabled}>
            <DeleteOutlined />
            Delete Travel Group
          </Menu.Item>
        )}

        {canCreateNew && (
          <Menu.Item key="0" onClick={handleOnCreateTravelGroups} disabled={disabled}>
            <PlusOutlined />
            Create New Travel Groups
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={["click"]}>
        <ButtonMore className="form-field-travel-group__button" />
      </Dropdown>
    );
  }, [
    disabled,
    managersCanCreateTravelGroups,
    hideDropdown,
    hasFullAccess,
    hasConditionalAccess,
    hasWriteAccess,
    currentUser,
    travelGroupSelected,
    handleOnCreateTravelGroups,
    handleOnEditTravelGroupInfo,
    handleOnViewTravelGroupInfo,
    handleOnDeleteTravelGroup,
    setIsCreateTravelGroupsDrawerVisible,
    setTravelGroupFormDrawerState,
  ]);

  if (travelGroupsLoading && !travelGroupsData) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (travelGroupsError) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Travel groups failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  return (
    <div className="form-field-travel-group">
      <div className="form-field-travel-group__container">
        <FormFieldSelect
          {...omit(props, ["formik"])}
          disabled={disabled || travelGroupsLoading}
          loading={travelGroupsLoading}
          meta={{ ...meta, options: optionGroups }}
        />

        {dropdownMarkup}
      </div>
    </div>
  );
}

FormFieldTravelGroup.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
    helpText: PropTypes.node,
    displayDefaultLabel: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  query: PropTypes.any,
  queryOptions: PropTypes.object,
  managersCanCreateTravelGroups: PropTypes.bool,
  setTravelGroupFormDrawerState: PropTypes.func,
  setIsCreateTravelGroupsDrawerVisible: PropTypes.func,
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func,
    values: PropTypes.object,
  }).isRequired,
  mutationOptions: PropTypes.object,
  hideDropdown: PropTypes.bool,
};

FormFieldTravelGroup.defaultProps = {
  disabled: false,
  query: GET_TRAVEL_GROUP_LIST,
  queryOptions: {},
  managersCanCreateTravelGroups: false,
  setTravelGroupFormDrawerState: null,
  setIsCreateTravelGroupsDrawerVisible: null,
  mutationOptions: {},
  hideDropdown: false,
};

export default connect(FormFieldTravelGroup);
