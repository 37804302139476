import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import { connect } from "formik";
import gql from "fraql";
import each from "lodash/each";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import omit from "lodash/omit";
import uniq from "lodash/uniq";
import { STEP_TYPE } from "../../constants/stepsConstants";
import useUpdateEffect from "../../utils/useUpdateEffect";
import FormFieldCheckboxGroup from "./FormFieldCheckboxGroup";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldRadio from "./FormFieldRadio";
import FormFieldSelect from "./FormFieldSelect";

const GET_REG_WITH_COMPETITIONS_STEPS = gql`
  query FormFieldRegistrationCompetition_GetRegWithCompetitionsSteps($RegId: String!) {
    Reg_by_pk(id: $RegId) {
      id
      Steps(where: {meta: {_contains: {type: ${STEP_TYPE.COMPETITIONS}}}}) {
        id
        meta
      }
    }
  }
`;

const GET_COMPETITIONS = gql`
  query FormFieldRegistrationCompetition_GetCompetitions($CompetitionsIds: [String!]!) {
    Competition(where: { id: { _in: $CompetitionsIds } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;

export const DISPLAY_MODES = {
  checkboxList: "checkboxList",
  select: "select",
  radio: "radio",
};

function FormFieldRegistrationCompetition(props) {
  const {
    name,
    meta,
    disabled,
    formik: { values, setFieldValue },
  } = props;

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const displayMode = get(meta, "displayMode", DISPLAY_MODES.select);

  const formRegistrationIdValue = get(values, meta.registrationFieldId, null);

  useUpdateEffect(() => {
    // Reset this field's value whenever the Registration field's value changes, since the list of Competitions (and the
    // set of valid Competitions) is dependent on the Registration selected, and therefore the current value for this
    // field may no longer be valid for the new value of the Registration field.
    setFieldValue(name, null);
  }, [formRegistrationIdValue]);

  const { error: regError, data: regData } = useQuery(GET_REG_WITH_COMPETITIONS_STEPS, {
    variables: { RegId: formRegistrationIdValue },
    skip: !formRegistrationIdValue,
  });

  const regCompetitionsIds = useMemo(() => {
    if (isNil(regData)) {
      return [];
    }

    const reg = get(regData, "Reg_by_pk", null);
    const steps = get(reg, "Steps", []);

    let allCompetitionsIds = [];

    each(steps, step => {
      const stepCompetitionsIds = get(step, "meta.competitions", []);

      allCompetitionsIds = allCompetitionsIds.concat(stepCompetitionsIds);
    });

    return uniq(allCompetitionsIds);
  }, [regData]);

  const { loading: competitionsLoading, error: competitionsError, data: competitionsData } = useQuery(
    GET_COMPETITIONS,
    { variables: { CompetitionsIds: regCompetitionsIds } },
  );

  const options = useMemo(() => {
    const competitions = get(competitionsData, "Competition", []);

    return competitions.map(competition => ({
      value: competition.id,
      label: competition.name,
    }));
  }, [competitionsData]);

  if (!formRegistrationIdValue) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert
          message="A Competition can be selected after selecting a Registration"
          type="info"
          className="form-field-alert"
        />
      </FormFieldFormItem>
    );
  }

  if (isEmpty(regCompetitionsIds)) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert
          message="There are no Competitions configured as options in Competitions Steps of the selected Registration"
          type="error"
          className="form-field-alert"
        />
      </FormFieldFormItem>
    );
  }

  if (competitionsLoading && !competitionsData) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (regError || competitionsError) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Competitions failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  switch (displayMode) {
    case DISPLAY_MODES.checkboxList: {
      return (
        <FormFieldCheckboxGroup
          {...omit(props, ["formik"])}
          disabled={disabled || competitionsLoading}
          meta={{ ...meta, options }}
        />
      );
    }
    case DISPLAY_MODES.select: {
      return (
        <FormFieldSelect
          {...omit(props, ["formik"])}
          disabled={disabled || competitionsLoading}
          meta={{ ...meta, options }}
        />
      );
    }
    case DISPLAY_MODES.radio: {
      return (
        <FormFieldRadio
          {...omit(props, ["formik"])}
          disabled={disabled || competitionsLoading}
          meta={{ ...meta, options }}
        />
      );
    }
    default: {
      console.error("Unsupported display mode", displayMode);
      return null;
    }
  }
}

FormFieldRegistrationCompetition.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

FormFieldRegistrationCompetition.defaultProps = {
  disabled: false,
};

export default connect(FormFieldRegistrationCompetition);
