import React from "react";
import { Alert } from "antd";

function NoMatchPath() {
  return (
    <div style={{ padding: 24 }}>
      <Alert
        type="error"
        message="Page not found"
        description="Sorry, the page you requested could not be found. Please check the URL and try again."
        showIcon
      />
    </div>
  );
}

export default NoMatchPath;
