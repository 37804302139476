import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { Button, Icon, Spin, Typography } from "antd";
import { Formik } from "formik";
import { Form, SubmitButton, Input, Checkbox } from "formik-antd";
import gql from "fraql";
import { isEmpty, isNil, get } from "lodash";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import ENV from "../constants/envConstants";
import { useAuth0 } from "../components/Auth0";
import SpinPageContent from "../components/SpinPageContent";
import TextEditorPreview from "../components/TextEditor/TextEditorPreview";
import { useTheme } from "../components/Theme/ThemeContext";
import "./Login.scss";

// GraphQL Queries
export const LOGIN = gql`
  mutation Login($username: String!, $password: String!, $baseurl: String!, $recaptchaToken: String!) {
    login(username: $username, password: $password, baseurl: $baseurl, recaptchaToken: $recaptchaToken) {
      result
    }
  }
`;

export const REGISTER = gql`
  mutation Register(
    $username: String!
    $password: String!
    $firstname: String!
    $lastname: String!
    $baseurl: String!
    $recaptchaToken: String!
  ) {
    register(
      password: $password
      username: $username
      firstname: $firstname
      lastname: $lastname
      baseurl: $baseurl
      recaptchaToken: $recaptchaToken
    ) {
      result
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation requestResetPassword($username: String!, $baseurl: String!, $recaptchaToken: String!) {
    requestResetPassword(username: $username, baseurl: $baseurl, recaptchaToken: $recaptchaToken) {
      result
    }
  }
`;

// Validation shemas for forms
const loginInitialValues = {
  username: undefined,
  password: undefined,
};

const loginValidationSchema = yup.object({
  username: yup
    .string()
    .email("Invalid email address. Please enter a valid email address.")
    .required("Please provide your username/email address."),
  password: yup.string().required("Please enter your password."),
});

const passwordResetInitialValues = {
  username: undefined,
};

const passwordResetValidationSchema = yup.object({
  username: yup
    .string()
    .email("Invalid email address. Please enter a valid email address.")
    .required("Please provide your username/email address."),
});

// Email is "username" for the backend. We use "email" on this form so that Password Managers recognise and save the details more reliably.
const registerInitialValues = {
  firstname: undefined,
  lastname: undefined,
  email: undefined,
  password: undefined,
  confirmPassword: undefined,
  termsAndConditions: false,
};

const registerValidationSchema = yup.object({
  firstname: yup.string().required("Please provide your username/email address."),
  lastname: yup.string().required("Please enter your password."),
  email: yup
    .string()
    .email("Invalid email address. Please enter a valid email address.")
    .required("Please provide your username/email address."),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(6, "Password must be at least 6 characters long."),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords do not match. Please check and try again.")
    .required("Please confirm your password."),
  termsAndConditions: yup.boolean().oneOf([true], "You must agree to the Terms and Conditions to sign up."),
});

// Login Component
const Login = ({ pagemode }) => {
  const { loginCompleted } = useAuth0();
  const { search } = useLocation();
  const themeContext = useTheme();
  const [mode, setMode] = useState(pagemode);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const [loginUser] = useMutation(LOGIN);
  const [registerUser] = useMutation(REGISTER);
  const [requestPasswordReset] = useMutation(REQUEST_PASSWORD_RESET);

  useEffect(() => {
    const temp = queryString.parse(search);

    if (temp.action && temp.action === "signup") {
      setMode("REGISTER");
    }
  }, [search]);

  // Fetch the reCAPTCHA token. Called by useEffect below and upon any failed attempts (login, register, etc).
  const fetchRecaptchaToken = useCallback(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(ENV.RECAPTCHA_PUBLIC_SITE_KEY, { action: mode }).then(token => {
        setRecaptchaToken(token);
      });
    });
  }, [mode]);

  // Download and attach reCAPTCHA script. Set up event handler once script has downloaded.
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${ENV.RECAPTCHA_PUBLIC_SITE_KEY}`;
    script.addEventListener("load", fetchRecaptchaToken);
    // Tokens expire every 120 seconds. Therefore, set up timer to refresh token every 100 seconds, in case user idles on screen.
    setTimeout(fetchRecaptchaToken, 100000);
    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleLogin(values, actions) {
    const { username, password } = values;
    const { setSubmitting, setFieldError } = actions;

    // the reCAPTCHA token may not be ready upon form submission. Simply wait until it is available...
    while (recaptchaToken === "") {
      console.log("Awaiting recaptcha token...");
      // eslint-disable-next-line no-await-in-loop
      await new Promise(r => setTimeout(r, 1000));
    }

    loginUser({
      variables: {
        username,
        password,
        baseurl: window.location.origin,
        recaptchaToken,
      },
    })
      .then(({ data }) => {
        if (data) {
          if (!isEmpty(data)) {
            const authToken = get(data, "login.result.authToken", null);
            const success = get(data, "login.result.success", null);
            if (success) {
              if (!isNil(authToken)) {
                if (authToken !== "") {
                  loginCompleted(authToken);
                  return;
                }
              }
            }
            setFieldError("username", "Login Credentials not recognised. Please check your details and try again.");
            setSubmitting(false);
            fetchRecaptchaToken();
          }
        }
      })
      .catch(error => {
        console.log(error);
        alert("LOGIN FAILED!\n[error code: 864001]");
        setSubmitting(false);
        fetchRecaptchaToken();
      });
  }

  async function handleRegister(values, actions) {
    const { email, password, firstname, lastname } = values;
    const { setSubmitting, setFieldError } = actions;

    // the reCAPTCHA token may not be ready upon form submission. Simply wait until it is available...
    while (recaptchaToken === "") {
      console.log("Awaiting recaptcha token...");
      // eslint-disable-next-line no-await-in-loop
      await new Promise(r => setTimeout(r, 1000));
    }

    registerUser({
      variables: {
        password,
        firstname,
        username: email,
        lastname,
        baseurl: window.location.origin,
        recaptchaToken,
      },
    })
      .then(({ data }) => {
        if (data) {
          if (!isEmpty(data)) {
            const success = get(data, "register.result.success", null);
            if (!isNil(success)) {
              if (success) {
                setMode("REGISTERSUCCESS");
                return;
              }
              // There is only 1 reported failure state with bad registrations; the username already exists. Other states are not reported.
              setFieldError(
                "email",
                "Error: This username already exists. You can reset your password on the Login tab.",
              );
              setSubmitting(false);
              fetchRecaptchaToken();
              return;
            }
          }
        }
        setFieldError("confirmPassword", "An error occured when lodging your registration. Please try again later.");
        setSubmitting(false);
        fetchRecaptchaToken();
      })
      .catch(error => {
        console.log(error);
        alert("REGISTER FAILED!\n[error code: 864002]");
        setSubmitting(false);
        fetchRecaptchaToken();
      });
  }

  async function handlePasswordResetRequest(values, actions) {
    const { username } = values;
    const { setSubmitting, setFieldError } = actions;

    // the reCAPTCHA token may not be ready upon form submission. Simply wait until it is available...
    while (recaptchaToken === "") {
      console.log("Awaiting recaptcha token...");
      // eslint-disable-next-line no-await-in-loop
      await new Promise(r => setTimeout(r, 1000));
    }

    requestPasswordReset({
      variables: {
        username,
        baseurl: window.location.origin,
        recaptchaToken,
      },
    })
      .then(({ data }) => {
        if (data) {
          if (!isEmpty(data)) {
            const success = get(data, "requestResetPassword.result.success", null);
            if (!isNil(success)) {
              if (success) {
                setMode("PASSWORD_RESET_REQUEST_SUCCESS");
                return;
              }
              const error = get(data, "requestResetPassword.result.error", null);
              if (!isNil(error)) {
                setFieldError("username", error);
                setSubmitting(false);
                fetchRecaptchaToken();
                return;
              }
            }
          }
          setFieldError("username", "An issue occured when lodging your request. Please try again later.");
          setSubmitting(false);
          fetchRecaptchaToken();
        }
      })
      .catch(error => {
        console.log(error);
        alert("LOGIN FAILED!\n[error code: 864001]");
        setSubmitting(false);
        fetchRecaptchaToken();
      });
  }

  return (
    <>
      <div className="login-form__main">
        <div style={{ padding: "1em 1em 0 1em" }}>
          {isNil(themeContext) && <SpinPageContent />}
          {!isNil(themeContext) && <img alt="logo" className="login-form__img" src={themeContext.login_logo_path} />}
        </div>
        <div style={{ textAlign: "center", padding: "1em 0 1em 0" }}>
          {isNil(themeContext) && <Spin size="small" />}
          {!isNil(themeContext) && <Typography.Title level={4}>{themeContext.siteName}</Typography.Title>}
        </div>
        <div className="login-form__mode-toggle-btn">
          <Button
            block
            type={mode === "LOGIN" ? "primary" : "default"}
            value="LOGIN"
            onClick={e => setMode(e.target.value)}
          >
            Login
          </Button>
        </div>
        <div className="login-form__mode-toggle-btn">
          <Button
            block
            type={mode === "REGISTER" ? "primary" : "default"}
            value="REGISTER"
            onClick={e => setMode(e.target.value)}
          >
            Sign Up
          </Button>
        </div>
        {mode === "LOGIN" && (
          <Formik
            name="login-form"
            initialValues={loginInitialValues}
            validationSchema={loginValidationSchema}
            onSubmit={(values, actions) => handleLogin(values, actions)}
          >
            <Form name="login">
              <div className="login-form__input-div">
                <Form.Item name="username">
                  <Input
                    name="username"
                    type="email"
                    addonBefore={<Icon type="profile" />}
                    placeholder="yours@example.com"
                  />
                </Form.Item>
                <Form.Item name="password">
                  <Input
                    name="password"
                    type="password"
                    addonBefore={<Icon type="lock" />}
                    placeholder="your password"
                  />
                </Form.Item>
                <p style={{ cursor: "pointer" }} onClick={() => setMode("PASSWORDRESET")}>
                  Forgot your password?
                </p>
              </div>
              <SubmitButton block type="primary" className="login-form__submit-btn">
                Log In &gt;
              </SubmitButton>
            </Form>
          </Formik>
        )}
        {mode === "PASSWORDRESET" && (
          <Formik
            name="reset-password-form"
            initialValues={passwordResetInitialValues}
            validationSchema={passwordResetValidationSchema}
            onSubmit={(values, actions) => handlePasswordResetRequest(values, actions)}
          >
            <Form name="password-reset">
              <div className="login-form__input-div">
                <p>
                  To reset your password, please provide the email address you use to login with, then click on the
                  Reset button.
                </p>
                <Form.Item name="username">
                  <Input
                    name="username"
                    type="email"
                    addonBefore={<Icon type="profile" />}
                    placeholder="yours@example.com"
                  />
                </Form.Item>
              </div>
              <SubmitButton block type="primary" className="login-form__submit-btn">
                Reset Password &gt;
              </SubmitButton>
            </Form>
          </Formik>
        )}
        {mode === "REGISTER" && (
          <Formik
            initialValues={registerInitialValues}
            validationSchema={registerValidationSchema}
            onSubmit={handleRegister}
          >
            <Form name="registration">
              <div className="login-form__input-div">
                <Form.Item name="firstname">
                  <Input name="firstname" type="text" placeholder="First Name" addonBefore={<Icon type="user" />} />
                </Form.Item>
                <Form.Item name="lastname">
                  <Input name="lastname" type="text" placeholder="Last Name" addonBefore={<Icon type="user" />} />
                </Form.Item>
                <Form.Item name="email">
                  <Input name="email" type="email" placeholder="yours@example.com" addonBefore="@" />
                </Form.Item>
                <Form.Item name="password">
                  <Input
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    addonBefore={<Icon type="lock" />}
                  />
                </Form.Item>
                <Form.Item name="confirmPassword">
                  <Input
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm your password"
                    addonBefore={<Icon type="lock" />}
                  />
                </Form.Item>
              </div>
              <div className="login-form__terms">
                {isNil(themeContext) && <Spin size="small" />}
                {!isNil(themeContext) && (
                  <TextEditorPreview
                    className="termsAndConditionsStatement"
                    defaultValue={themeContext.termsConditionsAcceptanceStatement}
                  />
                )}
                <Form.Item name="termsAndConditions">
                  <Checkbox name="termsAndConditions">I agree to the Terms and Conditions above.</Checkbox>
                </Form.Item>
              </div>
              <SubmitButton type="primary" block className="login-form__submit-btn">
                Sign Up &gt;
              </SubmitButton>
            </Form>
          </Formik>
        )}
        {mode === "REGISTERSUCCESS" && (
          <div className="login-form__input-div">
            <div style={{ paddingBottom: "1em" }}>
              <Icon type="check-circle" theme="twoTone" twoToneColor="#53c41a" style={{ fontSize: "5em" }} />
            </div>
            <p>Success! To continue, please check your emails and click on the &quot;Verify Email&quot; link.</p>
            <p>Once verified, you can log in.</p>
          </div>
        )}
        {mode === "PASSWORD_RESET_REQUEST_SUCCESS" && (
          <div className="login-form__input-div">
            <div style={{ paddingBottom: "1em" }}>
              <Icon type="check-circle" theme="twoTone" twoToneColor="#53c41a" style={{ fontSize: "5em" }} />
            </div>
            <p>Reset request complete! To continue, please check your email for further instructions.</p>
          </div>
        )}
      </div>
      <div className="g-recaptcha" data-sitekey={ENV.RECAPTCHA_PUBLIC_SITE_KEY} data-size="invisible" />
    </>
  );
};

Login.propTypes = {
  pagemode: PropTypes.string,
};

Login.defaultProps = {
  pagemode: "LOGIN",
};

export default Login;
