import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Table, Tooltip } from "antd";
import classnames from "classnames";
import ColumnValueText from "../ColumnValueText";
import TableWithTruncationBodyCell from "./TableWithTruncationBodyCell";
import TableWithTruncationHeaderCell from "./TableWithTruncationHeaderCell";
import "./TableWithTruncation.scss";

const TABLE_COMPONENTS = {
  header: {
    cell: TableWithTruncationHeaderCell,
  },
  body: {
    cell: TableWithTruncationBodyCell,
  },
};

/**
 * Wrapper for the Ant Design Table component which can be used as a drop-in replacement and offers some often useful
 * features which can be opted into.
 *
 * The primary feature is that columns which specify a width in their configuration will have truncation enabled for
 * header and body cells. So if the width of the header or cell content exceeds the width of the column, the header/cell
 * content is automatically truncated with an ellipsis (via CSS), instead of wrapping to the next line.
 *
 * @param columns The table columns (see `propTypes` for additional per-column opt-in features via column attributes).
 * @param className The `className` to pass through to the Ant Design Table component.
 * @param includeIndexColumn Whether to add an index column as the first column.
 * @param wideIndexColumn Whether to make the index column wide, i.e. if high index numbers are possible/expected.
 * @param stretchLastColumn Whether to stretch the last column to take up any remaining horizontal space.
 * @param restProps Other props to pass through to the Ant Design Table component.
 * @returns {JSX.Element}
 */
function TableWithTruncation({
  columns,
  className,
  includeIndexColumn,
  wideIndexColumn,
  stretchLastColumn,
  ...restProps
}) {
  const preparedColumns = useMemo(() => {
    const tableColumns = [...columns];

    const hasColumns = tableColumns.length > 0;

    // Optionally add an index column as the first column. We need to ensure we only do this when we actually have
    // columns defined.
    if (hasColumns && includeIndexColumn) {
      tableColumns.unshift({
        title: "#",
        dataIndex: "index",
        key: "index",
        render: (value, record, index) => {
          const rowNumber = index + 1;

          if (wideIndexColumn) {
            return (
              <Tooltip placement="topLeft" trigger="hover" title={rowNumber}>
                <ColumnValueText>{rowNumber}</ColumnValueText>
              </Tooltip>
            );
          }

          return <ColumnValueText>{rowNumber}</ColumnValueText>;
        },
        width: wideIndexColumn ? 80 : 40,
        className: "table-with-truncation__index-column",
      });
    }

    return tableColumns.map((columnData, columnIndex) => {
      const column = { ...columnData };

      const isLastColumn = columnIndex === tableColumns.length - 1;

      // For the column that appears last, we can optionally have it 'stretch' by enforcing no set `width`, in order to
      // allow Ant Design's Table functionality to automatically take up all remaining horizontal space with this last
      // column. If stretching the last column, we must set a `minWidth` for it so that we can render it correctly when
      // it's *not* being stretched to fill empty space (i.e. when there is no empty space to fill).
      if (isLastColumn && stretchLastColumn) {
        column.minWidth = column.width;
        column.width = undefined;
      }

      // Pass the column configuration itself as a prop to our header cell and body cell components.
      column.onHeaderCell = () => ({ column });
      column.onCell = () => ({ column });

      return column;
    });
  }, [columns, includeIndexColumn, wideIndexColumn, stretchLastColumn]);

  const combinedClassName = classnames(className, {
    "table-with-truncation": true,
  });

  return <Table components={TABLE_COMPONENTS} columns={preparedColumns} className={combinedClassName} {...restProps} />;
}

TableWithTruncation.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      // Fixed width for the column (optional). This enables truncation for this column if the width of the header or
      // cell content exceeds the width of the column.
      width: PropTypes.number,

      // Whether to show the header's title in a tooltip on hover (optional). This is useful if header title content is
      // not hardcoded (e.g. the user specifies it), in which case the header title content may be truncated.
      showHeaderTooltip: PropTypes.bool,
    }),
  ).isRequired,
  className: PropTypes.string,
  includeIndexColumn: PropTypes.bool,
  wideIndexColumn: PropTypes.bool,
  stretchLastColumn: PropTypes.bool,
};

TableWithTruncation.defaultProps = {
  className: undefined,
  includeIndexColumn: false,
  wideIndexColumn: false,
  stretchLastColumn: false,
};

export default TableWithTruncation;
