import includes from "lodash/includes";
import isArray from "lodash/isArray";
import { FILE_TYPE_EXTENSION_MAP, FILE_TYPE_MIME_TYPE_MAP, FILE_TYPES } from "../constants/fileConstants";

// eslint-disable-next-line import/prefer-default-export
export function getAcceptOptions(fileTypeOptions) {
  if (!isArray(fileTypeOptions)) {
    return null;
  }

  const acceptOptions = [];

  FILE_TYPES.forEach(fileType => {
    if (includes(fileTypeOptions, fileType)) {
      acceptOptions.push(...FILE_TYPE_EXTENSION_MAP[fileType], ...FILE_TYPE_MIME_TYPE_MAP[fileType]);
    }
  });

  return acceptOptions;
}
