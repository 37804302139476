import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Button, Icon, Upload, Modal } from "antd";
import each from "lodash/each";
import find from "lodash/find";
import get from "lodash/get";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isInteger from "lodash/isInteger";
import isString from "lodash/isString";
import isUndefined from "lodash/isUndefined";
import omit from "lodash/omit";
import toInteger from "lodash/toInteger";
import { FormContext } from "../../../utils/contextUtils";
import { getAcceptOptions } from "../../../utils/fileUtils";
import isBlank from "../../../utils/isBlank";
import useFileUploader from "../../../utils/useFileUploader";
import useUpdateEffect from "../../../utils/useUpdateEffect";
import FormFieldFormItem from "../FormFieldFormItem";
import FormFieldReadOnlyContent from "../FormFieldReadOnlyFields/FormFieldReadOnlyContent";

function allSettled(promises) {
  const mappedPromises = promises.map(promise => {
    return promise
      .then(value => {
        return {
          status: "fulfilled",
          value,
        };
      })
      .catch(reason => {
        return {
          status: "rejected",
          reason,
        };
      });
  });
  return Promise.all(mappedPromises);
}

function validateRequired({ fileList, required }) {
  if (!required || !!fileList.length) {
    return { isValid: true };
  }

  return { isValid: false, message: "Please upload a file." };
}

function validateMaxFiles({ fileList, maxFiles }) {
  const filesCount = fileList.length;

  if (isInteger(maxFiles) && filesCount > maxFiles) {
    const filesText = maxFiles === 1 ? "file" : "files";

    return { isValid: false, message: `You can only upload ${maxFiles} ${filesText}.` };
  }

  return { isValid: true };
}

function validateMaxTotalFileSize({ fileList, maxTotalFileSize }) {
  let totalFileSize = 0;

  each(fileList, file => {
    totalFileSize += file.size / 1024 / 1024;
  });

  if (isInteger(maxTotalFileSize) && totalFileSize > maxTotalFileSize) {
    return { isValid: false, message: `The total combined size of files must be ${maxTotalFileSize} MB or less.` };
  }

  return { isValid: true };
}

function validateFileTypes({ fileList, acceptOptions }) {
  const result = { isValid: true };

  // eslint-disable-next-line consistent-return
  each(fileList, file => {
    const isFileTypeAllowed = acceptOptions.length === 0 || includes(acceptOptions, file.type);

    if (!isFileTypeAllowed) {
      result.isValid = false;
      result.message = `The file type for "${file.name}" is not allowed.`;

      return false;
    }
  });

  return result;
}

function validateFileStatus({ fileList }) {
  const result = { isValid: true };

  // eslint-disable-next-line consistent-return
  each(fileList, file => {
    if (file.status === "error" && file.uploadAttempted) {
      result.isValid = false;
      result.message = `There was a problem uploading your file(s).`;
      return false;
    }
  });

  return result;
}

function validateConditions({ fileList, required, maxFiles, maxTotalFileSize, acceptOptions }) {
  const validationRequired = validateRequired({ fileList, required });

  if (!validationRequired.isValid) {
    return validationRequired.message;
  }

  const validationMaxFiles = validateMaxFiles({ fileList, maxFiles });

  if (!validationMaxFiles.isValid) {
    return validationMaxFiles.message;
  }

  const validationMaxTotalFileSize = validateMaxTotalFileSize({ fileList, maxTotalFileSize });

  if (!validationMaxTotalFileSize.isValid) {
    return validationMaxTotalFileSize.message;
  }

  const validationFileTypes = validateFileTypes({ fileList, acceptOptions });

  if (!validationFileTypes.isValid) {
    return validationFileTypes.message;
  }

  return undefined;
}

function FormFieldFileUpload(props) {
  const {
    name,
    meta,
    disabled,
    initialFileList,
    relatedRecordsData,
    formik: { validateField, setFieldValue, setFieldTouched, touched },
    readOnly,
  } = props;

  const ownerInfo = useMemo(() => {
    return {
      PersonId: get(relatedRecordsData, "PersonId", get(meta, "PersonId", null)),
      TravelGroupId: get(relatedRecordsData, "TravelGroupId", null),
    };
  }, [relatedRecordsData, meta]);

  const isPublic = useMemo(() => {
    return get(meta, "isPublic", false);
  }, [meta]);
  const { uploader } = useFileUploader({ ...ownerInfo });
  const formContext = useContext(FormContext);
  const [fileStateList, setFileStateList] = useState([...initialFileList]);
  const fileRefs = useRef({});
  const [checkNewFiles, setCheckNewFiles] = useState(false);

  const isTouched = get(touched, name, false);

  const updatingFieldNames = formContext?.updatingFieldNames;

  const isCurrentFieldUpdating = useMemo(() => {
    if (isEmpty(updatingFieldNames)) {
      return false;
    }

    return includes(updatingFieldNames, name);
  }, [updatingFieldNames, name]);

  const required = get(meta, "required", false);
  const fileTypeOptions = get(meta, "fileTypes", []);
  const listType = get(meta, "listType", "text");

  let maxFiles = get(meta, "maxFiles", null);

  if (!isBlank(maxFiles) && isString(maxFiles)) {
    maxFiles = toInteger(maxFiles);
  }

  let maxTotalFileSize = get(meta, "maxTotalFileSize", null);

  if (!isBlank(maxTotalFileSize) && isString(maxTotalFileSize)) {
    maxTotalFileSize = toInteger(maxTotalFileSize);
  }

  let multiple;

  if (isInteger(maxFiles)) {
    multiple = maxFiles !== 1;
  } else {
    multiple = true;
  }

  const acceptOptions = getAcceptOptions(fileTypeOptions);

  function updateFileState(uid, changes) {
    setFileStateList(prevFileStateList => {
      const index = prevFileStateList.findIndex(fileState => fileState.uid === uid);
      if (index > -1) {
        const newFileStateList = [...prevFileStateList];
        newFileStateList[index] = { ...prevFileStateList[index], ...changes };

        return newFileStateList;
      }
      return prevFileStateList;
    });
  }

  function validate() {
    const validationConditions = validateConditions({
      fileList: fileStateList,
      required,
      maxFiles,
      maxTotalFileSize,
      acceptOptions,
    });

    if (!isBlank(validationConditions)) {
      return validationConditions;
    }

    const validationFileStatus = validateFileStatus({ fileList: fileStateList });

    if (!validationFileStatus.isValid) {
      return validationFileStatus.message;
    }

    return undefined;
  }

  useEffect(
    () => {
      // On mount, immediately validate this field. This is done to ensure that when a form is first loaded, the form's
      // state will indicate when an upload field has a validation error. Without doing this, this field's validation is
      // not run when a form is first loaded, which means that even if the field is in an invalid state (e.g. the field is
      // marked as required, but no file has been uploaded yet), the form would be considered valid anyway. Note that it
      // appears as though Formik *does not* correctly mark `isValid` as `false` after this field's validation is run here
      // and results in a validation error, but it *does* show an error for this field in the form's `errors` state, so we
      // need to ensure we don't rely on the `isValid` state in any place where we check if the form is currently valid,
      // and instead also check the `errors` state itself. See https://fuse.atlassian.net/browse/FUS-491.
      validateField(name);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useUpdateEffect(() => {
    setFileStateList(prevFileStateList => {
      // Exclude items that have previously come from initialFileList (Only files from initialFileList would have the same id and uid) and
      // Exclude items that exist in new initialFileList
      const transientFileStateList = prevFileStateList.filter(
        fileState => fileState.id !== fileState.uid && isUndefined(find(initialFileList, { id: fileState.id })),
      );

      return initialFileList.concat(transientFileStateList);
    });
  }, [initialFileList, setFileStateList]);

  useEffect(() => {
    setFieldValue(
      name,
      fileStateList.filter(file => !isBlank(file.id)).map(file => file.id),
    );
  }, [fileStateList, name, setFieldValue]);

  useEffect(
    () => {
      async function attemptFileUpload() {
        const validationErrorMessage = validateConditions({
          fileList: fileStateList,
          required,
          maxFiles,
          maxTotalFileSize,
          acceptOptions,
        });

        if (validationErrorMessage) {
          setFileStateList(prevFileStateList =>
            prevFileStateList.map(state => {
              if (isBlank(state.status)) {
                return { ...state, status: "error" };
              }

              return state;
            }),
          );

          return;
        }

        const fileUploaders = fileStateList
          .filter(
            fileState =>
              isBlank(fileState.id) && !fileState.uploadAttempted && !isEmpty(fileRefs.current[fileState.uid]),
          )
          .map(fileState => {
            return async () => {
              try {
                updateFileState(fileState.uid, { status: "uploading" });

                const { id, url } = await uploader(fileRefs.current[fileState.uid], isPublic);

                updateFileState(fileState.uid, { status: "done", id, url, thumbUrl: url });
              } catch (error) {
                console.error(error);

                updateFileState(fileState.uid, { status: "error", uploadAttempted: true });

                throw Error("error uploading file");
              } finally {
                fileRefs.current[fileState.uid] = null; // to release the reference for GC
              }
            };
          });

        // eslint-disable-next-line no-unused-expressions
        formContext?.setFieldAsUpdating(name);

        const results = await allSettled(fileUploaders.map(f => f()));

        // eslint-disable-next-line no-unused-expressions
        formContext?.setFieldAsNotUpdating(name);

        if (results.some(result => result.status === "rejected")) {
          Modal.error({
            title: "Something went wrong",
            content: "File upload(s) failed.",
          });
        }
      }

      if (checkNewFiles) {
        setCheckNewFiles(false);
        attemptFileUpload();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkNewFiles],
  );

  const handleRemove = file => {
    setFileStateList(prevStateFileList => prevStateFileList.filter(prevStateFile => prevStateFile.uid !== file.uid));
    setCheckNewFiles(true);

    if (!isTouched) {
      setFieldTouched(name, true);
    }
  };

  const handleBeforeUpload = (file, allFiles) => {
    setFileStateList(prevFileStateList => [
      ...prevFileStateList,
      {
        uid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
        status: "",
        url: "",
        thumbUrl: "",
        uploadAttempted: false,
      },
    ]);

    fileRefs.current[file.uid] = file;

    if (file.uid === allFiles[allFiles.length - 1].uid) {
      setCheckNewFiles(true);

      if (!isTouched) {
        setFieldTouched(name, true);
      }
    }

    return false;
  };

  function getReadOnlyContent() {
    if (isEmpty(initialFileList)) {
      return null;
    }

    return (
      <Upload
        className="form-field-file-upload"
        name={name}
        multiple={multiple}
        disabled
        defaultFileList={initialFileList}
      />
    );
  }

  if (readOnly) {
    return <FormFieldReadOnlyContent name={name} meta={meta} content={getReadOnlyContent()} />;
  }

  return (
    <FormFieldFormItem {...omit(props, ["formik"])} meta={{ ...meta, validate }} displayForInput={false}>
      <Upload
        className="form-field-file-upload"
        name={name}
        accept={acceptOptions.join(",")}
        multiple={multiple}
        disabled={disabled || isCurrentFieldUpdating}
        fileList={fileStateList}
        onRemove={handleRemove}
        beforeUpload={handleBeforeUpload}
        listType={listType}
      >
        <Button>
          <Icon type="upload" /> Click to Upload
        </Button>
      </Upload>
    </FormFieldFormItem>
  );
}

FormFieldFileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  initialFileList: PropTypes.arrayOf(PropTypes.object).isRequired,
  formik: PropTypes.object.isRequired,
  relatedRecordsData: PropTypes.object,
  readOnly: PropTypes.bool,
};

FormFieldFileUpload.defaultProps = {
  relatedRecordsData: {},
  readOnly: false,
};

export default FormFieldFileUpload;
