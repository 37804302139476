import React from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import get from "lodash/get";
import includes from "lodash/includes";
import { COMPETITION_SCORE_TYPE, COMPETITION_SCORE_TYPES_NUMERIC } from "../../constants/competitionConstants";
import useUpdateEffect from "../../utils/useUpdateEffect";
import FormFieldElapsedTime from "./FormFieldElapsedTime";
import FormFieldText from "./FormFieldText";

function FormFieldCompetitionScore(props) {
  const {
    name,
    meta,
    formik: { values, setFieldValue },
  } = props;

  const formCompetitionScoreTypeValue = get(values, meta.competitionScoreTypeFieldId, null);

  useUpdateEffect(() => {
    // Reset this field's value whenever the competition score type field's value changes, since the value may not be
    // compatible with the new value of the competition score type field.
    setFieldValue(name, null);
  }, [formCompetitionScoreTypeValue]);

  if (formCompetitionScoreTypeValue === COMPETITION_SCORE_TYPE.ELAPSED_TIME) {
    return <FormFieldElapsedTime {...props} />;
  }

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Enter a number";
  }

  let addonAfter;

  if (formCompetitionScoreTypeValue === COMPETITION_SCORE_TYPE.LENGTH_METRES) {
    addonAfter = "metres";
  } else if (formCompetitionScoreTypeValue === COMPETITION_SCORE_TYPE.MASS_KILOGRAMS) {
    addonAfter = "kilograms";
  }

  const style = {
    // Avoid the number input being too wide.
    width: 230,
  };

  if (includes(COMPETITION_SCORE_TYPES_NUMERIC, formCompetitionScoreTypeValue)) {
    return <FormFieldText {...props} meta={{ ...meta, placeholder, addonAfter, style }} />;
  }

  return null;
}

FormFieldCompetitionScore.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    competitionScoreTypeFieldId: PropTypes.string.isRequired,
  }).isRequired,
  formik: PropTypes.object.isRequired,
};

export default connect(FormFieldCompetitionScore);
