import React from "react";
import { Alert } from "antd";
import { isIE, CustomView } from "react-device-detect";

function UnsupportedBrowserAlert() {
  return (
    <CustomView condition={isIE}>
      <div style={{ padding: 12 }}>
        <Alert
          type="warning"
          message="You are using an unsupported browser. We recommend that you use a recent version of Chrome, Edge or Safari."
          showIcon
        />
      </div>
    </CustomView>
  );
}

export default UnsupportedBrowserAlert;
