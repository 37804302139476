import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import gql from "fraql";
import get from "lodash/get";
import FormFieldCheckboxGroup from "./FormFieldCheckboxGroup";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";
import getActiveArchivedOptions from "./getActiveArchivedOptions";

const GET_ROLES = gql`
  query FormFieldRole_GetRoles {
    Role(order_by: { name: asc }) {
      id
      name
      archived
    }
  }
`;

export const DISPLAY_MODES = {
  checkboxList: "checkboxList",
  select: "select",
};

function FormFieldRole(props) {
  const { meta, query, queryOptions } = props;

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const displayMode = get(meta, "displayMode", DISPLAY_MODES.select);

  const { loading, error, data } = useQuery(query, queryOptions);

  const options = useMemo(() => getActiveArchivedOptions({ allRecords: get(data, "Role", []) }), [data]);

  if (loading && !data) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (error) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Roles failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  switch (displayMode) {
    case DISPLAY_MODES.checkboxList: {
      return <FormFieldCheckboxGroup {...props} meta={{ ...meta, options }} />;
    }
    case DISPLAY_MODES.select: {
      return <FormFieldSelect {...props} loading={loading} meta={{ ...meta, options }} />;
    }
    default: {
      console.error("Unsupported display mode", displayMode);
      return null;
    }
  }
}

FormFieldRole.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
    helpText: PropTypes.node,
    displayDefaultLabel: PropTypes.bool,
    displayMode: PropTypes.string,
  }).isRequired,
  query: PropTypes.any,
  queryOptions: PropTypes.object,
};

FormFieldRole.defaultProps = {
  query: GET_ROLES,
  queryOptions: {},
};

export default FormFieldRole;
