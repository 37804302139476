import React from "react";
import PropTypes from "prop-types";
import { Pie } from "ant-design-pro/lib/Charts";
import "./RegSubmissionProgress.scss";

function RegSubmissionProgress({ percent }) {
  return (
    <div className="reg-submission-progress">
      <div className="reg-submission-progress-chart">
        <Pie percent={percent} total={`${percent}%`} height={90} color="#f39336" />
      </div>
      <div className="reg-submission-progress-status">Complete</div>
    </div>
  );
}

RegSubmissionProgress.propTypes = {
  percent: PropTypes.number.isRequired,
};

export default RegSubmissionProgress;
