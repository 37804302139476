import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import get from "lodash/get";

function FormFieldAlert({ meta }) {
  const message = get(meta, "message", "");
  const style = get(meta, "style", "");
  const showIcon = get(meta, "showIcon", true);

  if (!message) {
    return null;
  }

  return (
    <div style={{ marginBottom: 10 }}>
      <Alert message={message} type={style} showIcon={showIcon} />
    </div>
  );
}

FormFieldAlert.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default FormFieldAlert;
