import get from "lodash/get";
import isNil from "lodash/isNil";
import padStart from "lodash/padStart";

// eslint-disable-next-line import/prefer-default-export
export function getTravelGroupName(travelGroup) {
  if (isNil(travelGroup)) {
    return null;
  }

  const groupName = get(travelGroup, "Group.name", null);
  const number = get(travelGroup, "number", null);

  if (!groupName || isNil(number)) {
    return "[Unknown travel group]";
  }

  return `${groupName} ${padStart(number, 2, "0")}`;
}
