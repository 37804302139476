import React from "react";
import * as yup from "yup";
import get from "lodash/get";
import includes from "lodash/includes";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import isUndefined from "lodash/isUndefined";
import { CONDITION_FIELD_VALUE_COMPONENT_TYPES } from "../constants/conditionConstants";
import { FIELD_TYPES } from "../constants/fieldConstants";
import { GENDER_TYPE_OPTIONS } from "../constants/personConstants";
import { DEFAULT_REQUIRED_MSG, REQUIRED_MESSAGES, TYPE_ERROR_MESSAGES } from "../constants/schemaConstants";
import { FORM_FIELD_COMPONENT_MAP } from "../components/FormFields/constants";
import FormFieldSelect from "../components/FormFields/FormFieldSelect";
import FormFieldText from "../components/FormFields/FormFieldText";

const schemaEmail = yup.string().email();

function getFieldValueSchemaParams(conditionType, requiredConditionTypes, fieldType) {
  let isRequired;
  let requiredText;

  if (includes(requiredConditionTypes, conditionType)) {
    isRequired = true;
    requiredText = includes(CONDITION_FIELD_VALUE_COMPONENT_TYPES, fieldType)
      ? get(REQUIRED_MESSAGES, fieldType, DEFAULT_REQUIRED_MSG)
      : DEFAULT_REQUIRED_MSG;
  } else {
    isRequired = false;
  }

  return { isRequired, requiredText };
}

export function getFieldValueSchema({ schema, conditionType, requiredConditionTypes, fieldType }) {
  const { isRequired, requiredText } = getFieldValueSchemaParams(conditionType, requiredConditionTypes, fieldType);

  /**
   * "Required" like a test function
   * because when the "test" method is called, the "required()" parameter is ignored.
   * */
  switch (fieldType) {
    case FIELD_TYPES.select: {
      return schema
        .test(
          "isNotEmptyValue",
          requiredText,
          value => !isRequired || !isEmpty(value) || isNumber(value) || isString(value),
        )
        .test(
          "isNumberOrString",
          TYPE_ERROR_MESSAGES[FIELD_TYPES.number],
          value => isUndefined(value) || isEmpty(value) || isNumber(value) || isString(value),
        );
    }
    case FIELD_TYPES.number: {
      return schema
        .test("isNotEmptyValue", requiredText, value => !isRequired || !isEmpty(value) || isNumber(value))
        .test("isNumber", TYPE_ERROR_MESSAGES[FIELD_TYPES.number], value => {
          return isUndefined(value) || isEmpty(value) || isNumber(value);
        });
    }
    case FIELD_TYPES.bool: {
      return schema.test("isBoolean", null, value => isBoolean(value));
    }
    case FIELD_TYPES.email: {
      return schema
        .test("isNotEmptyValue", requiredText, value => !isRequired || !isEmpty(value))
        .test("isEmail", TYPE_ERROR_MESSAGES[FIELD_TYPES.email], value => schemaEmail.isValidSync(value));
    }
    default: {
      return schema
        .test("isNotEmptyValue", requiredText, value => !isRequired || !isEmpty(value))
        .test("isString", null, value => isUndefined(value) || isString(value));
    }
  }
}

export function getConditionFieldValueComponent(condition, nameFieldValue, submitCount, getChoices, disabled) {
  const fieldType = get(condition, "fieldType", undefined);

  if (!fieldType) {
    return null;
  }

  const meta = { displayDefaultLabel: false };
  let Component;

  if (includes(CONDITION_FIELD_VALUE_COMPONENT_TYPES, fieldType)) {
    if (fieldType === FIELD_TYPES.gender) {
      Component = FormFieldSelect;
      meta.options = GENDER_TYPE_OPTIONS;
      meta.placeholder = "Select a gender";
    } else if (fieldType === FIELD_TYPES.select) {
      const fieldId = get(condition, "field", undefined);

      Component = FormFieldSelect;
      meta.choices = getChoices(fieldId);
    } else {
      Component = get(FORM_FIELD_COMPONENT_MAP, fieldType, undefined);
    }
  } else {
    Component = FormFieldText;
  }

  return <Component name={nameFieldValue} meta={meta} submitCount={submitCount} disabled={disabled} />;
}
