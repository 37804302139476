import React from "react";
import { Typography, Form } from "antd";
import get from "lodash/get";
import { FIELD_TYPES } from "../../constants/fieldConstants";
import FormFieldSelect from "./FormFieldSelect";

const FormFieldChoices = props => {
  const bindType = get(props, "meta.bindType", "");

  switch (bindType) {
    case FIELD_TYPES.select:
      return <FormFieldSelect {...props} />;

    default:
      return (
        <Form.Item label=" ">
          <Typography.Text type="danger">Sorry: This field type not supported in select.</Typography.Text>
        </Form.Item>
      );
  }
};

export default FormFieldChoices;
