import React, { useEffect, useCallback, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Form } from "antd";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import debounce from "lodash/debounce";
import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import startsWith from "lodash/startsWith";
import trim from "lodash/trim";
import values from "lodash/values";
import { getFullName } from "../MenuPeople/PersonName";
import { getGQLSearch, getWhereGQLVariables, getOrderGQLVariables, GLOBAL_SEARCH_OPTIONS } from "./globalSearchGql";
import HeaderAutocomplete from "./HeaderAutocomplete";
import "./GlobalSearch.scss";

export const GLOBAL_SEARCH_QUERY_PARAM = "search";

const DEFAULT_SEARCH_LIMIT = 5;
const MINIMUM_SEARCH_VALUE_LENGTH = 3;

function prepareResponse(response, value) {
  const result = [];

  values(GLOBAL_SEARCH_OPTIONS).forEach(({ type, groupname, pathname }) => {
    const data = get(response, type, []);

    if (!isEmpty(data)) {
      const children = data.map(({ id, name, firstName, lastName, phone, email }) => {
        let title = "";
        let subtitle = "";

        switch (type) {
          case "Person":
            title = `${getFullName(firstName, lastName)} (${id.toUpperCase()})`;

            if (phone && email) {
              subtitle = `${phone} ${email}`;
            } else if (phone) {
              subtitle = phone;
            } else if (email) {
              subtitle = email;
            }

            break;
          default:
            title = name;

            break;
        }

        return {
          title,
          subtitle,
          type: type.toLocaleLowerCase(),
          uuid: id,
          pathname,
        };
      });

      const groupObj = {
        title: groupname,
        moreLink: `${pathname}?${queryString.stringify({ [GLOBAL_SEARCH_QUERY_PARAM]: value })}`,
        children,
      };

      result.push(groupObj);
    }
  });

  return result;
}

function Search({ form }) {
  const refInput = useRef();
  const history = useHistory();
  const location = useLocation();
  const { getFieldDecorator } = form;
  const formValue = form.getFieldValue("value");
  const [state, setState] = useState({ skip: true, value: formValue, dataSource: [] });
  const [query, setQuery] = useState(null);

  const delayedQuery = useCallback(
    debounce(
      val =>
        setState(prev => {
          const normalizedValue = trim(val);
          return {
            ...prev,
            value: normalizedValue,
            skip: normalizedValue.length < MINIMUM_SEARCH_VALUE_LENGTH,
            dataSource: normalizedValue.length < MINIMUM_SEARCH_VALUE_LENGTH ? [] : prev.dataSource,
          };
        }),
      800,
    ),
    [],
  );

  const onResetField = useCallback(() => {
    refInput.current.blur();
    form.resetFields();
  }, [form, refInput]);

  const onSelectMore = url => {
    if (url) {
      setTimeout(() => {
        history.push(url);
        onResetField();
      }, 400);
    }
  };

  const onSelect = (selected, element) => {
    const { pathname, uuid } = element.props.obj;
    if (pathname && uuid) {
      history.push(`${pathname}/${uuid}`);
      onResetField();
    }
  };

  const { loading: globalSearchLoading, error: globalSearchError } = useQuery(getGQLSearch(query), {
    skip: state.skip,
    variables: {
      ...getWhereGQLVariables(query, state.value),
      ...getOrderGQLVariables(query),
      limit: DEFAULT_SEARCH_LIMIT,
    },
    onCompleted(response) {
      const dataSource = prepareResponse(response, state.value);
      setState(prev => ({ ...prev, skip: true, dataSource }));
    },
  });

  useEffect(() => {
    const { pathname } = location;
    const currentSection = find(GLOBAL_SEARCH_OPTIONS, section => {
      return startsWith(pathname, section.pathname);
    });

    let queryValue = null;
    if (!isEmpty(currentSection)) {
      queryValue = currentSection;
    }
    setQuery(queryValue);
    setState(prev => ({ ...prev, dataSource: [] }));
  }, [location]);

  const queryGroupName = get(query, "groupname", "");

  useEffect(() => {
    if (formValue) {
      delayedQuery(formValue);
    }
  }, [formValue, delayedQuery, queryGroupName]);

  return (
    <Form className="header-search">
      {getFieldDecorator("value", {
        initialValue: "",
      })(
        <HeaderAutocomplete
          error={globalSearchError}
          query={query}
          ref={refInput}
          loading={globalSearchLoading}
          setQuery={setQuery}
          onSelect={onSelect}
          onSelectMore={onSelectMore}
          dataSource={state.dataSource}
        />,
      )}
    </Form>
  );
}

Search.propTypes = {
  form: PropTypes.object.isRequired,
};

const GlobalSearch = Form.create()(Search);

export default GlobalSearch;
