import React from "react";
import PropTypes from "prop-types";
import { InputNumber } from "formik-antd";
import toInteger from "lodash/toInteger";
import { FORM_FIELD_DATE_WIDTH } from "../../constants/styleConstants";
import FormFieldFormItem from "./FormFieldFormItem";

function FormFieldTeamMembersMinMax({ submitCount, meta }) {
  return (
    <>
      <FormFieldFormItem
        name="minTeamMembers"
        submitCount={submitCount}
        meta={{ ...meta, label: "Minimum number of members per team" }}
      >
        <InputNumber
          style={{ width: FORM_FIELD_DATE_WIDTH }}
          min={0}
          step={1}
          formatter={value => value && toInteger(value)}
          parser={value => value && toInteger(value)}
          name="minTeamMembers"
          placeholder="Enter a number"
        />
      </FormFieldFormItem>

      <FormFieldFormItem
        name="maxTeamMembers"
        submitCount={submitCount}
        meta={{ ...meta, label: "Maximum number of members per team" }}
      >
        <InputNumber
          style={{ width: FORM_FIELD_DATE_WIDTH }}
          min={1}
          step={1}
          formatter={value => value && toInteger(value)}
          parser={value => value && toInteger(value)}
          name="maxTeamMembers"
          placeholder="Enter a number"
        />
      </FormFieldFormItem>
    </>
  );
}

FormFieldTeamMembersMinMax.propTypes = {
  submitCount: PropTypes.number.isRequired,
  meta: PropTypes.object.isRequired,
};

export default FormFieldTeamMembersMinMax;
