import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import gql from "fraql";
import get from "lodash/get";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";

const GET_FIELD_LIST = gql`
  query FormFieldField_GetFieldList {
    Field(order_by: { name: asc }) {
      id
      name
    }
  }
`;

function FormFieldField(props) {
  const { meta, query, queryOptions } = props;

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);

  const { loading, error, data } = useQuery(query, queryOptions);

  const options = useMemo(() => {
    const fields = get(data, "Field", []);

    return fields.map(field => ({ value: field.id, label: field.name }));
  }, [data]);

  if (loading && !data) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (error) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Fields failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  return <FormFieldSelect {...props} loading={loading} meta={{ ...meta, options }} />;
}

FormFieldField.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
    helpText: PropTypes.node,
    displayDefaultLabel: PropTypes.bool,
  }).isRequired,
  query: PropTypes.any,
  queryOptions: PropTypes.object,
};

FormFieldField.defaultProps = {
  query: GET_FIELD_LIST,
  queryOptions: {},
};

export default FormFieldField;
