import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import classnames from "classnames";
import "./TableWithTruncationHeaderCell.scss";

function TableWithTruncationHeaderCell({ column, className, children, ...restProps }) {
  // Ant Design may render the selection column automatically, but we will not receive any column information for it.
  if (!column) {
    return <th {...restProps}>{children}</th>;
  }

  const tableHeaderStyle = {};

  if (column.width) {
    tableHeaderStyle.maxWidth = column.width;
  } else if (column.minWidth) {
    tableHeaderStyle.minWidth = column.minWidth;
    tableHeaderStyle.maxWidth = column.minWidth;
  }

  const combinedClassName = classnames(className, {
    "table-with-truncation-header": true,
    "table-with-truncation-header--truncate": !!tableHeaderStyle.maxWidth,
  });

  if (column.showHeaderTooltip) {
    return (
      <th {...restProps} className={combinedClassName} style={tableHeaderStyle}>
        <Tooltip placement="topLeft" trigger="hover" title={column.title}>
          <div className="report-table-header__content">{children}</div>
        </Tooltip>
      </th>
    );
  }

  return (
    <th {...restProps} className={combinedClassName} style={tableHeaderStyle}>
      <div className="report-table-header__content">{children}</div>
    </th>
  );
}

TableWithTruncationHeaderCell.propTypes = {
  column: PropTypes.shape({
    title: PropTypes.string.isRequired,
    width: PropTypes.number,
    minWidth: PropTypes.number,
    showHeaderTooltip: PropTypes.bool,
  }),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

TableWithTruncationHeaderCell.defaultProps = {
  column: null,
  className: "",
};

export default TableWithTruncationHeaderCell;
