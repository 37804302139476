import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import gql from "fraql";
import get from "lodash/get";
import isNil from "lodash/isNil";
import omit from "lodash/omit";
import FormFieldFormItem from "../FormFieldFormItem";
import FormFieldFileUpload from "./FormFieldFileUpload";

const GET_FILES = gql`
  query FormFieldFileReal_GetFiles($fileIds: [String!]) {
    File(where: { id: { _in: $fileIds } }) {
      id
      name
      size
      type
      url
    }
  }
`;

function FormFieldFileReal(props) {
  const {
    name,
    formik: { initialValues },
  } = props;

  // Expects an Array with strings. These strings should be Id's for File objects.
  const fieldInitialValue = get(initialValues, name);

  const { loading: getFilesLoading, error: getFilesError, data: getFilesData } = useQuery(GET_FILES, {
    variables: { fileIds: fieldInitialValue },
    skip: isNil(fieldInitialValue),
  });

  const initialFileList = useMemo(() => {
    const files = get(getFilesData, "File", []);
    return files.map(file => ({
      id: file.id,
      uid: file.id,
      name: file.name,
      size: file.size,
      type: file.type,
      url: file.url,
      status: "done",
    }));
  }, [getFilesData]);

  if (getFilesLoading && !getFilesData) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (getFilesError) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayForInput={false}>
        <Alert message="Files failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  return <FormFieldFileUpload {...props} initialFileList={initialFileList} />;
}

FormFieldFileReal.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  formik: PropTypes.object.isRequired,
};

export default FormFieldFileReal;
