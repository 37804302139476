import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { List } from "antd";
import filter from "lodash/filter";
import includes from "lodash/includes";
import { getFlattenedOptions, orderOptionsByLabel } from "../../../utils/optionsUtils";

function ReadOnlyList(props) {
  const { options, selectedValues } = props;
  const orderedOptions = useMemo(() => {
    const flattenedOptions = getFlattenedOptions(options);
    const selectedOptions = filter(flattenedOptions, option => includes(selectedValues, option.value));
    return orderOptionsByLabel(selectedOptions);
  }, [options, selectedValues]);

  return (
    <List
      size="small"
      bordered
      dataSource={orderedOptions}
      renderItem={option => <List.Item>{option.label}</List.Item>}
    />
  );
}

ReadOnlyList.propTypes = {
  options: PropTypes.array.isRequired,
  selectedValues: PropTypes.array.isRequired,
};

export default ReadOnlyList;
