import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Popover, Typography } from "antd";
import moment from "moment-timezone";
import { VIEW_DATE_TIME_FORMAT, DATE_FULL_TIME, UTC_TIME_ZONE } from "../constants/dateTimeConstants";
import { hasTimezoneAbbreviation } from "../utils/dateTimeUtils";
import isBlank from "../utils/isBlank";
import { useCurrentUserPersonTimezone, EMPTY_TIMEZONE_NAME } from "../utils/useCurrentUserPersonTimezone";
import "./DateTimeValueWithTimezone.scss";

// this is exported for tests , it should not be used outside of this component
export const DISPLAY_SOURCE_LABEL = {
  REFERENCE: "Reference",
  MY_TIMEZONE: "My time zone",
  MY_LOCAL_TIME: "My local time",
  UTC: "UTC (GMT)",
};

function formatDateTimeWithTimezoneShort({ dateTimeWithTZ, timezoneName, displayFormat }) {
  if (!moment.isMoment(dateTimeWithTZ) || !dateTimeWithTZ.isValid()) {
    return "";
  }

  if (!isBlank(timezoneName) && hasTimezoneAbbreviation(dateTimeWithTZ)) {
    return dateTimeWithTZ.format(`${displayFormat} z`);
  }

  return dateTimeWithTZ.format(`${displayFormat} Z`);
}

function formatDateTimeWithTimezoneLong({ dateTimeWithTZ, timezoneName, displayFormat }) {
  if (!moment.isMoment(dateTimeWithTZ) || !dateTimeWithTZ.isValid()) {
    return "";
  }

  if (isBlank(timezoneName)) {
    return `${dateTimeWithTZ.format(displayFormat)} (UTC${dateTimeWithTZ.format("Z")})`;
  }

  if (hasTimezoneAbbreviation(dateTimeWithTZ)) {
    if (timezoneName === UTC_TIME_ZONE) {
      return dateTimeWithTZ.format(`${displayFormat} z`);
    }

    return `${dateTimeWithTZ.format(displayFormat)} ${timezoneName} (${dateTimeWithTZ.format(
      "z",
    )}, UTC${dateTimeWithTZ.format("Z")})`;
  }

  return `${dateTimeWithTZ.format(displayFormat)} ${timezoneName} (UTC${dateTimeWithTZ.format("Z")})`;
}

// this function is exported for tests, it should not be used outside of this component.
export function getTimezoneDetails({
  dateTime,
  referenceTimezoneName,
  userTimezoneName,
  userLocalUtcOffset,
  displayFormat,
}) {
  let primarySource;
  let primaryTimezoneName;
  let primaryDateTimeWithTZ;

  let secondarySource;
  let secondaryTimezoneName;
  let secondaryDateTimeWithTZ;

  let tertiarySource;
  let tertiaryTimezoneName;
  let tertiaryDateTimeWithTZ;

  if (!isBlank(referenceTimezoneName)) {
    primarySource = DISPLAY_SOURCE_LABEL.REFERENCE;
    primaryDateTimeWithTZ = moment.tz(dateTime, referenceTimezoneName);
    primaryTimezoneName = referenceTimezoneName;

    if (!isBlank(userTimezoneName) && userTimezoneName !== referenceTimezoneName) {
      secondarySource = DISPLAY_SOURCE_LABEL.MY_TIMEZONE;
      secondaryDateTimeWithTZ = moment.tz(dateTime, userTimezoneName);
      secondaryTimezoneName = userTimezoneName;
    } else if (isBlank(userTimezoneName)) {
      secondarySource = DISPLAY_SOURCE_LABEL.MY_LOCAL_TIME;
      secondaryDateTimeWithTZ = moment(dateTime.utc()).utcOffset(userLocalUtcOffset);
      secondaryTimezoneName = "";
    }
  } else {
    if (!isBlank(userTimezoneName)) {
      primarySource = DISPLAY_SOURCE_LABEL.MY_TIMEZONE;
      primaryDateTimeWithTZ = moment.tz(dateTime, userTimezoneName);
      primaryTimezoneName = userTimezoneName;
    } else {
      primarySource = DISPLAY_SOURCE_LABEL.MY_LOCAL_TIME;
      primaryDateTimeWithTZ = moment(dateTime.utc()).utcOffset(userLocalUtcOffset);
      primaryTimezoneName = "";
    }

    if (userTimezoneName !== UTC_TIME_ZONE) {
      secondarySource = DISPLAY_SOURCE_LABEL.UTC;
      secondaryDateTimeWithTZ = dateTime.utc();
      secondaryTimezoneName = UTC_TIME_ZONE;
    }
  }

  if (primaryTimezoneName !== UTC_TIME_ZONE && secondaryTimezoneName !== UTC_TIME_ZONE) {
    tertiarySource = DISPLAY_SOURCE_LABEL.UTC;
    tertiaryDateTimeWithTZ = dateTime.utc();
    tertiaryTimezoneName = UTC_TIME_ZONE;
  }

  return {
    primary: {
      short: formatDateTimeWithTimezoneShort({
        dateTimeWithTZ: primaryDateTimeWithTZ,
        timezoneName: primaryTimezoneName,
        displayFormat,
      }),
      long: formatDateTimeWithTimezoneLong({
        dateTimeWithTZ: primaryDateTimeWithTZ,
        timezoneName: primaryTimezoneName,
        displayFormat,
      }),
      label: primarySource,
    },
    secondary: {
      long: formatDateTimeWithTimezoneLong({
        dateTimeWithTZ: secondaryDateTimeWithTZ,
        timezoneName: secondaryTimezoneName,
        displayFormat,
      }),
      label: secondarySource,
    },
    tertiary: {
      long: formatDateTimeWithTimezoneLong({
        dateTimeWithTZ: tertiaryDateTimeWithTZ,
        timezoneName: tertiaryTimezoneName,
        displayFormat,
      }),
      label: tertiarySource,
    },
  };
}

function DateTimeValueWithTimezone({ value, referenceTimezoneName, showSeconds }) {
  const currentUserPersonTimezone = useCurrentUserPersonTimezone();

  const displayFormat = useMemo(() => (showSeconds ? DATE_FULL_TIME : VIEW_DATE_TIME_FORMAT), [showSeconds]);

  const { primary, secondary, tertiary } = useMemo(
    () =>
      getTimezoneDetails({
        dateTime: value,
        referenceTimezoneName,
        userTimezoneName: currentUserPersonTimezone,
        userLocalUtcOffset: moment().utcOffset(),
        displayFormat,
      }),
    [value, referenceTimezoneName, currentUserPersonTimezone, displayFormat],
  );

  return (
    <Popover
      content={
        <div>
          <div className="date-time-value-with-timezone__section">
            <Typography.Text strong>{primary.label}</Typography.Text>
            <br />
            <Typography.Text type="secondary">{primary.long}</Typography.Text>
          </div>

          {secondary.label && (
            <div className="date-time-value-with-timezone__section">
              <Typography.Text strong>{secondary.label}</Typography.Text>
              <br />
              <Typography.Text type="secondary">{secondary.long}</Typography.Text>
            </div>
          )}

          {tertiary.label && (
            <div className="date-time-value-with-timezone__section">
              <Typography.Text strong>{tertiary.label}</Typography.Text>
              <br />
              <Typography.Text type="secondary">{tertiary.long}</Typography.Text>
            </div>
          )}
        </div>
      }
    >
      <span className="text-with-tooltip">{primary.short}</span>
    </Popover>
  );
}

DateTimeValueWithTimezone.propTypes = {
  value: PropTypes.instanceOf(moment).isRequired,
  referenceTimezoneName: PropTypes.string,
  showSeconds: PropTypes.bool,
};

DateTimeValueWithTimezone.defaultProps = {
  referenceTimezoneName: EMPTY_TIMEZONE_NAME,
  showSeconds: false,
};

export default DateTimeValueWithTimezone;
