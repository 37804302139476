import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Button, Dropdown, Menu, Modal } from "antd";
import gql from "fraql";
import { useHistory } from "react-router-dom";
import first from "lodash/first";
import get from "lodash/get";
import { PEOPLE } from "../../constants/routeConstants";
import { serverErrorStatus } from "../../utils/graphqlErrors";
import { PERMISSION, useGetUserHasPermission } from "../../utils/useGetUserHasPermission";
import ColumnValueText from "../ColumnValueText";

const PERSON_DELETE = gql`
  mutation PersonProfileActionsMenu_DeletePerson($PersonId: ID!) {
    deletePerson(PersonId: $PersonId) {
      affected_rows
    }
  }
`;

const GET_PERSON_USERS = gql`
  query PersonProfileActionsMenu_GetPersonUsers($PersonId: String!) {
    Person_by_pk(id: $PersonId) {
      id
      Users {
        id
        auth0Id
        email
      }
    }
  }
`;

function PersonProfileActionsMenu({ personId, style }) {
  const history = useHistory();
  const apolloClient = useApolloClient();

  const { loading: personUsersLoading, error: personUsersError, data: personUsersData } = useQuery(GET_PERSON_USERS, {
    variables: { PersonId: personId },
  });

  const [deletePerson] = useMutation(PERSON_DELETE);

  const hasDeletePersonPermission = useGetUserHasPermission(PERMISSION.DELETE_PERSON);
  const hasViewPersonUserPermission = useGetUserHasPermission(PERMISSION.VIEW_PERSON_USER);

  const personUser = useMemo(() => first(get(personUsersData, "Person_by_pk.Users", [])), [personUsersData]);

  async function handleClickDeletePerson() {
    const content = "Are you sure you want to delete this person? This action cannot be undone.";

    const modal = Modal.confirm({
      title: "Delete person",
      content,
      okText: "Delete",
      async onOk() {
        modal.update({
          okButtonProps: {
            loading: true,
          },
          cancelButtonProps: {
            disabled: true,
          },
        });

        try {
          await deletePerson({
            variables: { PersonId: personId },
          });

          history.replace(PEOPLE);

          Modal.confirm({
            title: "Person deleted successfully",
            content: "Your request to delete this person was successful.",
            cancelButtonProps: {
              hidden: true,
            },
            async onOk() {
              apolloClient.resetStore();
            },
          });
        } catch (error) {
          const errorStatus = serverErrorStatus(error);

          const description = get(errorStatus, "description", "There was an error deleting this person.");

          Modal.error({
            title: "Person not deleted",
            content: description,
          });

          Sentry.captureException(error);
        }
      },
      onCancel() {},
    });
  }

  function handleClickViewUser() {
    Modal.info({
      title: "User account details",
      content: (
        <ul>
          <li>
            <strong>Auth0 ID:</strong> <ColumnValueText>{personUser.auth0Id}</ColumnValueText>
          </li>
          <li>
            <strong>Email:</strong> <ColumnValueText>{personUser.email}</ColumnValueText>
          </li>
        </ul>
      ),
      style: { minWidth: 500 },
    });
  }

  if (!hasDeletePersonPermission && !hasViewPersonUserPermission) {
    return null;
  }

  function renderUserAccountDetailsMenuItem() {
    if (personUsersLoading && !personUsersData) {
      return (
        <Menu.Item disabled>
          <EyeOutlined /> Loading...
        </Menu.Item>
      );
    }

    if (personUsersError) {
      return (
        <Menu.Item disabled>
          <EyeOutlined /> Error loading user account details
        </Menu.Item>
      );
    }

    if (!personUser) {
      return null;
    }

    return (
      <Menu.Item onClick={handleClickViewUser}>
        <EyeOutlined /> View User Account Details
      </Menu.Item>
    );
  }

  const menu = (
    <Menu>
      {hasDeletePersonPermission && (
        <Menu.Item onClick={handleClickDeletePerson}>
          <DeleteOutlined /> Delete Person
        </Menu.Item>
      )}

      {hasViewPersonUserPermission && renderUserAccountDetailsMenuItem()}
    </Menu>
  );

  return (
    <div style={style}>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <Button icon="down" size="small">
          Actions
        </Button>
      </Dropdown>
    </div>
  );
}

PersonProfileActionsMenu.propTypes = {
  personId: PropTypes.string.isRequired,
  style: PropTypes.any,
};

PersonProfileActionsMenu.defaultProps = {
  style: undefined,
};

export default PersonProfileActionsMenu;
