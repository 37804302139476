export const COMPETITION_SCORE_TYPE = {
  ELAPSED_TIME: "ELAPSED_TIME",
  LENGTH_METRES: "LENGTH_METRES",
  MASS_KILOGRAMS: "MASS_KILOGRAMS",
  NUMBER: "NUMBER",
  NUMBER_INTEGER: "NUMBER_INTEGER",
};

export const COMPETITION_SCORE_TYPE_LABEL = {
  [COMPETITION_SCORE_TYPE.ELAPSED_TIME]: "Time",
  [COMPETITION_SCORE_TYPE.LENGTH_METRES]: "Length (metres)",
  [COMPETITION_SCORE_TYPE.MASS_KILOGRAMS]: "Mass (kilograms)",
  [COMPETITION_SCORE_TYPE.NUMBER]: "Number (decimal places allowed)",
  [COMPETITION_SCORE_TYPE.NUMBER_INTEGER]: "Number (decimal places not allowed)",
};

export const COMPETITION_SCORE_TYPES_NUMERIC = [
  COMPETITION_SCORE_TYPE.LENGTH_METRES,
  COMPETITION_SCORE_TYPE.MASS_KILOGRAMS,
  COMPETITION_SCORE_TYPE.NUMBER,
  COMPETITION_SCORE_TYPE.NUMBER_INTEGER,
];

export const COMPETITION_SCORE_TYPE_OPTIONS = Object.keys(COMPETITION_SCORE_TYPE).map(qualificationScoreTypeKey => ({
  value: COMPETITION_SCORE_TYPE[qualificationScoreTypeKey],
  label: COMPETITION_SCORE_TYPE_LABEL[qualificationScoreTypeKey],
}));

export const TEAM_QUALIFICATION_SCORE_DETERMINATION = {
  PROVIDED_EXPLICITLY: "PROVIDED_EXPLICITLY",
  SUM: "SUM",
  AVERAGE: "AVERAGE",
};

export const TEAM_QUALIFICATION_SCORE_DETERMINATION_CALCULATED = [
  TEAM_QUALIFICATION_SCORE_DETERMINATION.SUM,
  TEAM_QUALIFICATION_SCORE_DETERMINATION.AVERAGE,
];

export const TEAM_QUALIFICATION_SCORE_DETERMINATION_LABEL = {
  [TEAM_QUALIFICATION_SCORE_DETERMINATION.PROVIDED_EXPLICITLY]: "Provided explicitly",
  [TEAM_QUALIFICATION_SCORE_DETERMINATION.SUM]: "Sum of individual registrants' qualification scores",
  [TEAM_QUALIFICATION_SCORE_DETERMINATION.AVERAGE]: "Average of individual registrants' qualification scores",
};

export const TEAM_QUALIFICATION_SCORE_DETERMINATION_OPTIONS = Object.keys(TEAM_QUALIFICATION_SCORE_DETERMINATION).map(
  teamQualificationScoreDeterminationKey => ({
    value: TEAM_QUALIFICATION_SCORE_DETERMINATION[teamQualificationScoreDeterminationKey],
    label: TEAM_QUALIFICATION_SCORE_DETERMINATION_LABEL[teamQualificationScoreDeterminationKey],
  }),
);

export const RESULT_STATUS_CATEGORY = {
  DISQUALIFIED: "DISQUALIFIED",
  SCRATCHED: "SCRATCHED",
};

export const RESULT_STATUS_CATEGORY_LABEL = {
  [RESULT_STATUS_CATEGORY.DISQUALIFIED]: "Disqualified",
  [RESULT_STATUS_CATEGORY.SCRATCHED]: "Scratched",
};

export const COMPETITION_VIEWS = {
  COMPETITORS: "COMPETITORS",
  ROUNDS: "ROUNDS",
  DIVISIONING: "DIVISIONING",
};

export const COMPETITION_COLUMN_TYPE = {
  TEXT: "TEXT",
  SCORE: "SCORE",
  // TODO: Add back these types when we implement the backend for them.
  // NUMBER: "NUMBER",
  // INTEGER: "INTEGER",
};

export const COMPETITION_COLUMN_TYPE_LABEL = {
  [COMPETITION_COLUMN_TYPE.TEXT]: "Text",
  [COMPETITION_COLUMN_TYPE.SCORE]: "Score",
  // [COMPETITION_COLUMN_TYPE.NUMBER]: "Number",
  // [COMPETITION_COLUMN_TYPE.INTEGER]: "Integer",
};
