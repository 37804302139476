import React from "react";
import PropTypes from "prop-types";
import { Radio } from "formik-antd";
import get from "lodash/get";
import FormFieldFormItem from "./FormFieldFormItem";

function FormFieldRadio(props) {
  const { name, meta, disabled } = props;

  const options = get(meta, "options", []);
  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);

  return (
    <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel}>
      <Radio.Group name={name} options={options} disabled={disabled} />
    </FormFieldFormItem>
  );
}

FormFieldRadio.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

FormFieldRadio.defaultProps = {
  disabled: false,
};

export default FormFieldRadio;
