import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import gql from "fraql";
import get from "lodash/get";
import RegistrationStatus from "../RegistrationStatus";
import FormFieldCheckboxGroup from "./FormFieldCheckboxGroup";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";
import getActiveArchivedOptions from "./getActiveArchivedOptions";

const GET_REGISTRATIONS = gql`
  query FormFieldRegistration_GetRegistrations {
    Reg(order_by: { name: asc }) {
      id
      name
      archived
      openAt
      closeAt
    }
  }
`;

export const DISPLAY_MODES = {
  checkboxList: "checkboxList",
  select: "select",
};

function FormFieldRegistration(props) {
  const { meta, query, queryOptions } = props;

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const displayMode = get(meta, "displayMode", DISPLAY_MODES.select);
  const disableArchivedRecords = get(meta, "disableArchivedRecords", true);

  const { loading, error, data } = useQuery(query, queryOptions);

  const options = useMemo(
    () =>
      getActiveArchivedOptions({
        allRecords: get(data, "Reg", []),
        disableArchivedRecords,
        labelDecorator: record => (
          <div style={{ display: "flex" }}>
            <RegistrationStatus size="small" reg={record} />
            {record.name}
          </div>
        ),
      }),
    [data, disableArchivedRecords],
  );

  if (loading && !data) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (error) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Registrations failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  switch (displayMode) {
    case DISPLAY_MODES.checkboxList: {
      return <FormFieldCheckboxGroup {...props} meta={{ ...meta, options }} />;
    }
    case DISPLAY_MODES.select: {
      return <FormFieldSelect {...props} loading={loading} meta={{ ...meta, options }} />;
    }
    default: {
      console.error("Unsupported display mode", displayMode);
      return null;
    }
  }
}

FormFieldRegistration.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
    helpText: PropTypes.node,
    displayDefaultLabel: PropTypes.bool,
    displayMode: PropTypes.string,
  }).isRequired,
  query: PropTypes.any,
  queryOptions: PropTypes.object,
};

FormFieldRegistration.defaultProps = {
  query: GET_REGISTRATIONS,
  queryOptions: {},
};

export default FormFieldRegistration;
