import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";

function RegistrationArchivedAlert({ style }) {
  return (
    <Alert
      message="Registration unavailable"
      description="This registration is no longer available. If you believe this is an error please contact an administrator."
      type="warning"
      showIcon
      style={style}
    />
  );
}

RegistrationArchivedAlert.propTypes = {
  style: PropTypes.any,
};

RegistrationArchivedAlert.defaultProps = {
  style: {},
};

export default RegistrationArchivedAlert;
