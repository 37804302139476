import React from "react";
import PropTypes from "prop-types";
import isString from "lodash/isString";
import isBlank from "../../../utils/isBlank";
import EmptyValueText from "../../EmptyValueText";
import FormFieldFormItem from "../FormFieldFormItem";
import ReadOnlyText from "./ReadOnlyText";

/*
 * This component is used to render readonly content in forms.
 *
 * Props:
 * - content ( optional ) - Either react component or string which will be wrapped inside a FormFieldFormItem.
 * - name - name of the form field.
 * - meta - meta object for the form field component. Only `required` and `label` are extracted from meta and passed to `FormFieldFormItem`'s meta.
 */
function FormFieldReadOnlyContent({ name, meta, content }) {
  const { required, label } = meta;

  let contentComponent;

  if (isBlank(content)) {
    contentComponent = <EmptyValueText />;
  } else if (isString(content)) {
    contentComponent = <ReadOnlyText>{content}</ReadOnlyText>;
  } else {
    contentComponent = content;
  }

  return (
    <FormFieldFormItem
      name={name}
      meta={{ required, label }}
      displayDefaultLabel
      displayForInput={false}
      submitCount={0}
      className="form-field-form-item--read-only"
    >
      {contentComponent}
    </FormFieldFormItem>
  );
}

FormFieldReadOnlyContent.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    required: PropTypes.bool,
    label: PropTypes.string,
  }).isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

FormFieldReadOnlyContent.defaultProps = {
  content: null,
};

export default FormFieldReadOnlyContent;
