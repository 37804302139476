import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";

function RegistrationArchivedStatusAlert({ style }) {
  return (
    <Alert
      message="Registration archived"
      description="Information cannot be updated for this registration because it has been archived."
      type="warning"
      showIcon={false}
      className="ant-alert-with-description alert-registration-status"
      style={style}
    />
  );
}

RegistrationArchivedStatusAlert.propTypes = {
  style: PropTypes.any,
};

RegistrationArchivedStatusAlert.defaultProps = {
  style: undefined,
};

export default RegistrationArchivedStatusAlert;
