import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "antd";
import { connect } from "formik";
import get from "lodash/get";
import padStart from "lodash/padStart";
import split from "lodash/split";
import isBlank from "../../utils/isBlank";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldReadOnlyContent from "./FormFieldReadOnlyFields/FormFieldReadOnlyContent";
import "./FormFieldElapsedTime.scss";

const HOURS_INDEX = 0;
const MINUTES_INDEX = 1;
const SECONDS_INDEX = 2;
const CENTISECONDS_INDEX = 3;

const getTimePart = (fieldValue, partIndex) => {
  if (!fieldValue) {
    return "";
  }

  const timeParts = split(fieldValue, /[.:]/);

  return get(timeParts, partIndex, "");
};

const handleTimeChange = (updateLocalFieldState, value) => {
  let newLocalFieldValue = value;

  if (!isBlank(newLocalFieldValue)) {
    // Remove any non-digit characters.
    newLocalFieldValue = newLocalFieldValue.replace(/[^\d]/, "");
  }

  updateLocalFieldState(newLocalFieldValue);
};

function FormFieldElapsedTime(props) {
  const { name, meta, disabled, formik, readOnly } = props;
  const { values, setFieldValue, setFieldTouched } = formik;

  const fieldValue = get(values, name);

  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");
  const [centiseconds, setCentiseconds] = useState("");

  const { displayDefaultLabel, showCentiseconds = true, showClearButton = true } = meta;

  /**
   * Given the value of this Elapsed Time field in the Formik state, synchronise this component's local fields state
   * with the specified Formik field value.
   */
  const syncLocalFieldsStateWithFormikFieldValue = useCallback(
    formikFieldValue => {
      setHours(getTimePart(formikFieldValue, HOURS_INDEX));
      setMinutes(getTimePart(formikFieldValue, MINUTES_INDEX));
      setSeconds(getTimePart(formikFieldValue, SECONDS_INDEX));
      setCentiseconds(getTimePart(formikFieldValue, CENTISECONDS_INDEX));
    },
    [setHours, setMinutes, setSeconds, setCentiseconds],
  );

  useEffect(() => {
    // Any time the Formik field value changes, we need to synchronise the local fields state with the new value.
    syncLocalFieldsStateWithFormikFieldValue(fieldValue);
  }, [fieldValue, syncLocalFieldsStateWithFormikFieldValue]);

  const handleHoursChange = event => handleTimeChange(setHours, event.target.value);
  const handleMinutesChange = event => handleTimeChange(setMinutes, event.target.value);
  const handleSecondsChange = event => handleTimeChange(setSeconds, event.target.value);
  const handleCentisecondsChange = event => handleTimeChange(setCentiseconds, event.target.value);

  const handleTimeFieldBlur = useCallback(() => {
    let newFieldValue;

    if (!hours && !minutes && !seconds && !centiseconds) {
      // If all of the local fields are empty, this corresponds with a Formik state field value of "", which means an
      // empty value.
      newFieldValue = "";
    } else {
      const hoursValue = padStart(hours, 1, "0");
      const minutesValue = padStart(minutes, 2, "0");
      const secondsValue = padStart(seconds, 2, "0");
      const centisecondsValue = padStart(centiseconds, 2, "0");

      newFieldValue = `${hoursValue}:${minutesValue}:${secondsValue}.${centisecondsValue}`;
    }

    setFieldValue(name, newFieldValue, true);
    setFieldTouched(name, true);

    if (newFieldValue === fieldValue) {
      // If the field value has not changed, we must synchronise the local fields state with the current field value.
      // This is for the case where the user has a value for each of the local fields, and then they clear the value for
      // one of them which had a value of "0" or "00" (but now has an empty string value), at which point the field
      // value within the Formik state will still remain the same as before, but now the local field value would be
      // empty, and as a result the Formik state field value and the local field value would be out of sync and
      // different from each other. We must always ensure this local component's state is in sync with whatever the
      // Formik state field value currently is.
      syncLocalFieldsStateWithFormikFieldValue(fieldValue);
    }
  }, [
    hours,
    minutes,
    seconds,
    centiseconds,
    name,
    fieldValue,
    syncLocalFieldsStateWithFormikFieldValue,
    setFieldValue,
    setFieldTouched,
  ]);

  const handleClickClear = useCallback(() => {
    setFieldValue(name, "", true);
    setFieldTouched(name, true);
  }, [name, setFieldValue, setFieldTouched]);

  function getReadOnlyContent() {
    if (isBlank(fieldValue)) {
      return null;
    }

    return fieldValue;
  }

  if (readOnly) {
    return <FormFieldReadOnlyContent name={name} meta={meta} content={getReadOnlyContent()} />;
  }

  return (
    <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} className="form-field-elapsed-time">
      <Input
        name="hours"
        value={hours}
        onBlur={handleTimeFieldBlur}
        onChange={handleHoursChange}
        disabled={disabled}
        prefix="H"
        className="form-field-elapsed-time__input"
      />

      <span className="separator">:</span>

      <Input
        name="minutes"
        value={minutes}
        onBlur={handleTimeFieldBlur}
        onChange={handleMinutesChange}
        disabled={disabled}
        maxLength={2}
        prefix="M"
        className="form-field-elapsed-time__input"
      />

      <span className="separator">:</span>

      <Input
        name="seconds"
        value={seconds}
        onBlur={handleTimeFieldBlur}
        onChange={handleSecondsChange}
        disabled={disabled}
        maxLength={2}
        prefix="S"
        className="form-field-elapsed-time__input"
      />

      {showCentiseconds && (
        <>
          <span className="separator">:</span>

          <Input
            name="centiseconds"
            value={centiseconds}
            onBlur={handleTimeFieldBlur}
            onChange={handleCentisecondsChange}
            disabled={disabled}
            maxLength={2}
            prefix="CS"
            className="form-field-elapsed-time__input form-field-elapsed-time__centiseconds"
          />
        </>
      )}

      {showClearButton && (
        <Button
          onClick={handleClickClear}
          className="form-field-elapsed-time__clear-btn"
          icon="close"
          disabled={disabled || !fieldValue}
        >
          Clear
        </Button>
      )}
    </FormFieldFormItem>
  );
}

FormFieldElapsedTime.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

FormFieldElapsedTime.defaultProps = {
  disabled: false,
  readOnly: false,
};

export default connect(FormFieldElapsedTime);
