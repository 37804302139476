import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { Alert, message, Typography } from "antd";
import gql from "fraql";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import {
  REG_STEP_STATUSES,
  REG_SUBMISSION_STATUSES,
  SUBMISSION_STATUS_STEP_ID,
} from "../../../../constants/regStatusConstants";
import { STEP_TYPE } from "../../../../constants/stepsConstants";
import { parseDateTime } from "../../../../utils/dateTimeUtils";
import { PERMISSION, useGetUserHasPermission } from "../../../../utils/useGetUserHasPermission";
import { useRegContext } from "../../../../utils/useRegContext";
import useUpdateEffect from "../../../../utils/useUpdateEffect";
import DateTimeValueWithTimezone from "../../../DateTimeValueWithTimezone";
import RegSubmissionStatusSelect from "../../../RegSubmissionStatusSelect";
import SectionHeaderContainer from "../../../SectionHeaderContainer";
import RegistrationsSidebar from "../RegistrationsSidebar";
import RegistrationStepAlternate from "./RegistrationStepAlternate";
import RegistrationStepCompetitions from "./RegistrationStepCompetitions/RegistrationStepCompetitions";
import RegistrationStepForm from "./RegistrationStepForm";
import RegistrationStepGroup from "./RegistrationStepGroup";
import RegistrationStepInfo from "./RegistrationStepInfo";
import RegistrationStepParticipation from "./RegistrationStepParticipation";
import RegistrationStepProfile from "./RegistrationStepProfile";
import RegistrationStepSports from "./RegistrationStepSports";

const REG_STATUS_ALERT_CONFIG = {
  [REG_SUBMISSION_STATUSES.submitted]: {
    title: "Submitted",
    description: "Registration has been submitted and is ready for review.",
    type: "info",
  },
  [REG_SUBMISSION_STATUSES.approved]: {
    title: "Approved",
    description: "Registration has been approved.",
    type: "success",
  },
  [REG_SUBMISSION_STATUSES.rejected]: {
    title: "Rejected",
    description: "Registration has been rejected.",
    type: "warning",
  },
  [REG_SUBMISSION_STATUSES.notSubmitted]: {
    title: "Not submitted",
    description: "Registration has not yet been submitted.",
    type: "info",
  },
  [REG_SUBMISSION_STATUSES.reverted]: {
    title: "Not submitted",
    description: "Registration is not submitted.",
    type: "info",
  },
  [REG_SUBMISSION_STATUSES.deregistered]: {
    title: "Deregistered",
    description: "Registration has been deregistered.",
    type: "warning",
  },
};

const UPDATE_REG_STEP_STATUS = gql`
  mutation RegistrationsContent_UpdateRegStepStatus($RegId: ID!, $StepId: ID!, $PersonId: ID!, $status: String!) {
    upsertRegStatus(RegId: $RegId, StepId: $StepId, PersonId: $PersonId, status: $status) {
      affected_rows
    }
  }
`;

function getAlertDescription(existingDescription, status, lastSubmittedAt, timezoneName) {
  if (isNil(lastSubmittedAt)) {
    return existingDescription;
  }
  return (
    <>
      {existingDescription} It was last submitted at{" "}
      <DateTimeValueWithTimezone value={parseDateTime(lastSubmittedAt)} referenceTimezoneName={timezoneName} />.
    </>
  );
}

function RegistrationStatusAlert() {
  const regContext = useRegContext();
  const regContextData = get(regContext, "regContextData", {});
  const RegSubmission = get(regContextData, "RegSubmission");
  const regTimezoneName = get(regContextData, "reg.timezoneName", "");
  const showRegStatusSelect = useGetUserHasPermission(PERMISSION.SHOW_REG_STATUS_SELECT);

  const shouldShowRegStatusSelect = useMemo(() => {
    return showRegStatusSelect && !!RegSubmission;
  }, [showRegStatusSelect, RegSubmission]);

  const status = get(RegSubmission, "status", null);
  const lastSubmittedAt = get(RegSubmission, "lastSubmittedAt", null);
  const alertConfig = useMemo(() => {
    const config = { ...get(REG_STATUS_ALERT_CONFIG, status, {}) };

    if (isEmpty(config)) {
      return config;
    }

    if (isNil(lastSubmittedAt)) {
      return config;
    }

    if (
      ![REG_SUBMISSION_STATUSES.submitted, REG_SUBMISSION_STATUSES.approved, REG_SUBMISSION_STATUSES.rejected].includes(
        status,
      )
    ) {
      return config;
    }

    config.description = getAlertDescription(config.description, status, lastSubmittedAt, regTimezoneName);

    return config;
  }, [status, lastSubmittedAt, regTimezoneName]);

  if (isEmpty(alertConfig)) {
    return null;
  }

  return (
    <>
      <Alert message={alertConfig.title} description={alertConfig.description} type={alertConfig.type} showIcon />

      {shouldShowRegStatusSelect && (
        <div style={{ marginTop: 20 }}>
          <RegSubmissionStatusSelect />
        </div>
      )}
    </>
  );
}

function RegistrationsTypesSwitch({
  activeStep,
  activeStepId,
  activeStepIndex,
  updateRegStepStatus,
  nextStep,
  prevStep,
  PersonId,
}) {
  const regContext = useRegContext();
  const regContextData = get(regContext, "regContextData", {});
  const hasWriteAccess = useGetUserHasPermission(PERMISSION.WRITE_ACCESS);
  const isReadOnlyAccess = !hasWriteAccess;

  const { RegId: regId, regAgeRequirementIsMet } = regContextData;
  const regIsReadOnly = isReadOnlyAccess || !!regContextData.regIsReadOnly;
  const regAgeRequirementCheckedAndIsMet = regAgeRequirementIsMet === true;
  const activeStepIsSubmissionStatusStep = activeStepId === SUBMISSION_STATUS_STEP_ID;

  if (activeStepIsSubmissionStatusStep) {
    return <RegistrationStatusAlert />;
  }

  if (!regId) {
    // Safe-guard so child components can have `regId` as a required prop.
    return null;
  }

  const stepType = get(activeStep, "meta.type");

  // Note: we are deliberately not checking `regAgeRequirementCheckedAndIsMet` for some step types, because we want the
  // user to be able to continue through them regardless of whether they have met the age requirement (if there is one).
  // For example, we want users to still be able to fill out any Profile steps, as that's how they can provide or update
  // their date of birth.

  switch (stepType) {
    case STEP_TYPE.INFO:
      return (
        <RegistrationStepInfo
          step={activeStep}
          stepIndex={activeStepIndex}
          updateRegStepStatus={updateRegStepStatus}
          nextStep={nextStep}
          prevStep={prevStep}
          regIsReadOnly={regIsReadOnly}
          showFormBottomComponent={hasWriteAccess}
          readOnly={isReadOnlyAccess}
        />
      );

    case STEP_TYPE.LEGAL:
      return (
        <RegistrationStepInfo
          step={activeStep}
          stepIndex={activeStepIndex}
          updateRegStepStatus={updateRegStepStatus}
          nextStep={nextStep}
          prevStep={prevStep}
          regIsReadOnly={regIsReadOnly}
          showFormBottomComponent={hasWriteAccess}
          readOnly={isReadOnlyAccess}
        />
      );

    case STEP_TYPE.FORM:
      return (
        <RegistrationStepForm
          step={activeStep}
          stepIndex={activeStepIndex}
          updateRegStepStatus={updateRegStepStatus}
          nextStep={nextStep}
          prevStep={prevStep}
          regIsReadOnly={regIsReadOnly || !regAgeRequirementCheckedAndIsMet}
          PersonId={PersonId}
          showFormBottomComponent={hasWriteAccess}
          readOnly={isReadOnlyAccess}
        />
      );

    case STEP_TYPE.MEMBERSHIP:
      return (
        <RegistrationStepGroup
          step={activeStep}
          stepIndex={activeStepIndex}
          updateRegStepStatus={updateRegStepStatus}
          nextStep={nextStep}
          prevStep={prevStep}
          regIsReadOnly={regIsReadOnly || !regAgeRequirementCheckedAndIsMet}
          PersonId={PersonId}
          showFormBottomComponent={hasWriteAccess}
          readOnly={isReadOnlyAccess}
        />
      );

    case STEP_TYPE.PROFILE:
      return (
        <RegistrationStepProfile
          step={activeStep}
          stepIndex={activeStepIndex}
          updateRegStepStatus={updateRegStepStatus}
          nextStep={nextStep}
          prevStep={prevStep}
          regIsReadOnly={
            // Note: we are deliberately not checking `regAgeRequirementCheckedAndIsMet` here like we do for all other
            // step types, because we want the user to be able to fill out the profile step regardless of whether they
            // have met the age requirement (if there is one).
            regIsReadOnly
          }
          PersonId={PersonId}
          showFormBottomComponent={hasWriteAccess}
          readOnly={isReadOnlyAccess}
        />
      );

    case STEP_TYPE.SPORTS:
      return (
        <RegistrationStepSports
          step={activeStep}
          stepIndex={activeStepIndex}
          updateRegStepStatus={updateRegStepStatus}
          nextStep={nextStep}
          prevStep={prevStep}
          regIsReadOnly={regIsReadOnly || !regAgeRequirementCheckedAndIsMet}
          PersonId={PersonId}
          showFormBottomComponent={hasWriteAccess}
          readOnly={isReadOnlyAccess}
        />
      );

    case STEP_TYPE.COMPETITIONS:
      return (
        <RegistrationStepCompetitions
          step={activeStep}
          stepIndex={activeStepIndex}
          updateRegStepStatus={updateRegStepStatus}
          nextStep={nextStep}
          prevStep={prevStep}
          regIsReadOnly={regIsReadOnly || !regAgeRequirementCheckedAndIsMet}
          PersonId={PersonId}
          showFormBottomComponent={hasWriteAccess}
          readOnly={isReadOnlyAccess}
        />
      );

    case STEP_TYPE.PARTICIPATION:
      return (
        <RegistrationStepParticipation
          step={activeStep}
          stepIndex={activeStepIndex}
          updateRegStepStatus={updateRegStepStatus}
          nextStep={nextStep}
          prevStep={prevStep}
          regIsReadOnly={regIsReadOnly || !regAgeRequirementCheckedAndIsMet}
          personId={PersonId}
          regId={regId}
          showFormBottomComponent={hasWriteAccess}
          readOnly={isReadOnlyAccess}
        />
      );

    case STEP_TYPE.ALTERNATE:
      return (
        <RegistrationStepAlternate
          step={activeStep}
          stepIndex={activeStepIndex}
          updateRegStepStatus={updateRegStepStatus}
          nextStep={nextStep}
          prevStep={prevStep}
          regIsReadOnly={regIsReadOnly || !regAgeRequirementCheckedAndIsMet}
          personId={PersonId}
          regId={regId}
          showFormBottomComponent={hasWriteAccess}
          readOnly={isReadOnlyAccess}
        />
      );

    default:
      return (
        <Alert
          message="Sorry, this step is not currently supported. Please contact an administrator."
          type="warning"
          showIcon
        />
      );
  }
}

RegistrationsTypesSwitch.propTypes = {
  activeStep: PropTypes.object.isRequired,
  activeStepId: PropTypes.string.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  updateRegStepStatus: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  PersonId: PropTypes.string.isRequired,
};

function RegistrationsContent({
  PersonId,
  RegId,
  steps,
  activeStep,
  activeStepIndex,
  nextStep,
  prevStep,
  refetchDataAfterSubmit,
  refetchRegStatuses,
  shoppingCartCharges,
  refetchRegCharges,
}) {
  const activeStepId = get(activeStep, "id", null);
  const hasActiveStep = !!activeStep;
  const hasActiveStepId = !!activeStepId;
  const hasActiveStepIndex = activeStepIndex >= 0;
  const hasSteps = useMemo(() => !isEmpty(steps), [steps]);
  const containerRef = useRef();
  const {
    regContextData: { refetchRegSubmission },
  } = useRegContext();

  useUpdateEffect(() => {
    if (activeStepId && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [activeStepId]);

  const [updateRegistrationStepStatus] = useMutation(UPDATE_REG_STEP_STATUS);

  const updateRegStepStatus = useCallback(
    async (step, status = REG_STEP_STATUSES.completed) => {
      if (!step) {
        console.error("Invalid step.");
        message.error("Failed to update registration step status.");

        throw new Error("Invalid step.");
      }

      const { id: StepId } = step;

      // First call `refetchDataAfterSubmit` so that we can refetch any necessary data that could have changed as a
      // result of submitting the step. We call this function here simply because all step components (e.g.
      // RegistrationStepGroup) call `updateRegStepStatus` as part of their submission flow, so it's easier to add
      // this here rather than passing this function to all of those components and ensuring they explicitly call it
      // at an appropriate point in time during the submission flow. The only thing we need to be careful about is
      // ensuring that we `await` the call to the `updateRegStepStatus` function from those step components, in order
      // to ensure we refetch data *before* we try to go to the next step.
      await refetchDataAfterSubmit(step);

      await updateRegistrationStepStatus({
        variables: {
          RegId: step.RegId,
          StepId,
          PersonId,
          status,
        },
      });

      await refetchRegStatuses();
      await refetchRegSubmission();
      await refetchRegCharges();
    },
    [
      PersonId,
      refetchDataAfterSubmit,
      updateRegistrationStepStatus,
      refetchRegStatuses,
      refetchRegSubmission,
      refetchRegCharges,
    ],
  );

  const infoContent = useMemo(() => {
    if (!RegId) {
      return (
        <>
          <SectionHeaderContainer>
            <Typography.Title level={3}>Step</Typography.Title>
          </SectionHeaderContainer>

          <Alert message="Select a registration to view its steps." type="info" className="form-field-alert" />
        </>
      );
    }

    if (isEmpty(steps)) {
      return (
        <>
          <SectionHeaderContainer>
            <Typography.Title level={3}>Step</Typography.Title>
          </SectionHeaderContainer>

          <Alert
            message="Cannot fill out registration"
            description="There are no steps for this registration."
            type="warning"
            showIcon
          />
        </>
      );
    }

    if (!hasActiveStep || !hasActiveStepId || !hasActiveStepIndex) {
      return (
        <>
          <SectionHeaderContainer>
            <Typography.Title level={3}>Step</Typography.Title>
          </SectionHeaderContainer>

          <Alert message="Select a step" description="Select a step for this registration." type="info" showIcon />
        </>
      );
    }

    return null;
  }, [RegId, steps, hasActiveStep, hasActiveStepId, hasActiveStepIndex]);

  return (
    <>
      <div className="person-registrations-content" ref={containerRef}>
        {!infoContent && (
          <>
            <SectionHeaderContainer>
              <Typography.Title level={3}>{activeStep.name}</Typography.Title>
            </SectionHeaderContainer>

            <RegistrationsTypesSwitch
              key={activeStepId}
              activeStep={activeStep}
              activeStepId={activeStepId}
              activeStepIndex={activeStepIndex}
              updateRegStepStatus={updateRegStepStatus}
              prevStep={prevStep}
              nextStep={nextStep}
              PersonId={PersonId}
            />
          </>
        )}

        {infoContent}
      </div>

      {hasSteps && <RegistrationsSidebar steps={steps} shoppingCartCharges={shoppingCartCharges} />}
    </>
  );
}

RegistrationsContent.propTypes = {
  PersonId: PropTypes.string.isRequired,
  RegId: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeStep: PropTypes.object,
  activeStepIndex: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.instanceOf(null)]),
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  refetchDataAfterSubmit: PropTypes.func.isRequired,
  refetchRegStatuses: PropTypes.func.isRequired,
  refetchRegCharges: PropTypes.func.isRequired,
  shoppingCartCharges: PropTypes.arrayOf(PropTypes.array),
};

RegistrationsContent.defaultProps = {
  RegId: null,
  activeStep: null,
  activeStepIndex: null,
  shoppingCartCharges: null,
};

export default RegistrationsContent;
