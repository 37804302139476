import React from "react";
import { Alert } from "antd";
import LayoutPageHeader from "./LayoutPageHeader";

function AuthenticationRequiredPage() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#fafafa",
          borderBottom: "2px solid #e8e8e8",
          padding: "15px 24px",
        }}
      >
        <LayoutPageHeader title="Authentication Required" />
      </div>

      <div style={{ padding: 24 }}>
        <Alert
          type="info"
          message="Please log in"
          description="Sorry, you need to be logged in to access this page. You will be automatically redirected to the login page."
          showIcon
        />
      </div>
    </>
  );
}

export default AuthenticationRequiredPage;
