import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Tooltip, Avatar } from "antd";
import moment from "moment";
import get from "lodash/get";
import { REGISTRATION_STATUS, REGISTRATION_STATUS_LABEL } from "../constants/regStatusConstants";
import { parseDateTime } from "../utils/dateTimeUtils";
import { useGetUserHasPermission, PERMISSION } from "../utils/useGetUserHasPermission";
import "./RegistrationStatus.scss";

export const REGISTRATION_STATUS_OPTIONS = {
  [REGISTRATION_STATUS.UPCOMING]: {
    color: "#A94898",
    tooltip: REGISTRATION_STATUS_LABEL[REGISTRATION_STATUS.UPCOMING],
    initial: "U",
  },
  [REGISTRATION_STATUS.FINISHED]: {
    color: "#000000",
    tooltip: REGISTRATION_STATUS_LABEL[REGISTRATION_STATUS.FINISHED],
    initial: "F",
  },
  [REGISTRATION_STATUS.LIVE]: {
    color: "#197636",
    tooltip: REGISTRATION_STATUS_LABEL[REGISTRATION_STATUS.LIVE],
    initial: "L",
  },
  [REGISTRATION_STATUS.ARCHIVED]: {
    color: "#C4C4C4",
    tooltip: REGISTRATION_STATUS_LABEL[REGISTRATION_STATUS.ARCHIVED],
    initial: "A",
  },
};

function getRegistrationStatus(reg, now = moment()) {
  const archived = get(reg, "archived", false);

  if (archived) {
    return REGISTRATION_STATUS_OPTIONS[REGISTRATION_STATUS.ARCHIVED];
  }

  const openAt = get(reg, "openAt", null);
  const closeAt = get(reg, "closeAt", null);

  if (!openAt || !closeAt) {
    return REGISTRATION_STATUS_OPTIONS[REGISTRATION_STATUS.LIVE];
  }

  const currentDateTime = now.utc();
  const openAtUtc = parseDateTime(openAt).utc();
  const closeAtUtc = parseDateTime(closeAt).utc();

  if (currentDateTime.isBefore(openAtUtc)) {
    return REGISTRATION_STATUS_OPTIONS[REGISTRATION_STATUS.UPCOMING];
  }

  if (currentDateTime.isAfter(closeAtUtc)) {
    return REGISTRATION_STATUS_OPTIONS[REGISTRATION_STATUS.FINISHED];
  }

  return REGISTRATION_STATUS_OPTIONS[REGISTRATION_STATUS.LIVE];
}

function RegistrationStatus({ reg, size }) {
  const canShowRegistrationStatus = useGetUserHasPermission(PERMISSION.SHOW_REGISTRATION_STATUS);

  const registrationStatus = useMemo(() => getRegistrationStatus(reg), [reg]);

  if (!canShowRegistrationStatus) {
    return null;
  }

  return (
    <div className="registration-status">
      <Tooltip title={registrationStatus.tooltip}>
        <Avatar size={size} style={{ backgroundColor: registrationStatus.color }}>
          {registrationStatus.initial}
        </Avatar>
      </Tooltip>
    </div>
  );
}

RegistrationStatus.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(["large", "small", "default"])]),
  reg: PropTypes.shape({
    id: PropTypes.string.isRequired,
    archived: PropTypes.bool.isRequired,
    openAt: PropTypes.string.isRequired,
    closeAt: PropTypes.string.isRequired,
  }).isRequired,
};

RegistrationStatus.defaultProps = {
  size: "default",
};
export default RegistrationStatus;
