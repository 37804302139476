import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import gql from "fraql";
import each from "lodash/each";
import get from "lodash/get";
import { AUTH_ROLES } from "../../constants/authConstants";
import { useAuth0 } from "../Auth0";
import FormFieldCheckboxGroup from "./FormFieldCheckboxGroup";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";
import getActiveArchivedOptions from "./getActiveArchivedOptions";

const GET_SPORTS = gql`
  query FormFieldSport_GetSports {
    Sport(order_by: { name: asc }) {
      id
      name
      archived
      Tenant{
        key
      }
    }
  }
`;

export const DISPLAY_MODES = {
  checkboxList: "checkboxList",
  select: "select",
};

function FormFieldSport(props) {
  const { meta, query, queryOptions } = props;

  const { authRole } = useAuth0();

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const displayMode = get(meta, "displayMode", DISPLAY_MODES.select);

  const [options, setOptions] = useState([]);

  const { loading, error, data } = useQuery(query, queryOptions);

  

  useEffect(()=>{
    const appendTenant = (opts) => {
      const temp = [];
  
      each (opts, function(option){
        
        // take a clone
        const tempOption = JSON.parse(JSON.stringify(option));

        tempOption.name = (authRole === AUTH_ROLES.GLOBAL_ADMIN) ? `${option.name}${(option.Tenant ? ` (${option.Tenant.key})` : "")}` : tempOption.name;
  
        temp.push(tempOption);
      });
  
      return temp;
    }
    
    const result = getActiveArchivedOptions({ allRecords: appendTenant(get(data, "Sport", [])) });
    setOptions(result);
  }, [data, authRole])

  if (loading && !data) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (error) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Sports failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  switch (displayMode) {
    case DISPLAY_MODES.checkboxList: {
      return <FormFieldCheckboxGroup {...props} meta={{ ...meta, options }} />;
    }
    case DISPLAY_MODES.select: {
      return <FormFieldSelect {...props} loading={loading} meta={{ ...meta, options }} />;
    }
    default: {
      console.error("Unsupported display mode", displayMode);
      return null;
    }
  }
}

FormFieldSport.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
    helpText: PropTypes.node,
    displayDefaultLabel: PropTypes.bool,
    displayMode: PropTypes.string,
  }).isRequired,
  query: PropTypes.any,
  queryOptions: PropTypes.object,
  readOnly: PropTypes.bool,
};

FormFieldSport.defaultProps = {
  query: GET_SPORTS,
  queryOptions: {},
  readOnly: false,
};

export default FormFieldSport;
