import React, { useEffect, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import localforage from "localforage";
import isNil from "lodash/isNil";
import toInteger from "lodash/toInteger";
import { GROUP_PICKER_ACTIONS } from "../constants/groupPickerConstants";

export const GroupPickerContext = React.createContext();

export const useGroupPicker = () => useContext(GroupPickerContext);

const reducer = (state, action) => {
  switch (action.type) {
    case GROUP_PICKER_ACTIONS.REHYDRATE:
      return { ...state, ...action.payload, loading: false };
    case GROUP_PICKER_ACTIONS.RESIZE:
      return { ...state, width: toInteger(state.width + action.payload.width) };
    case GROUP_PICKER_ACTIONS.SET_GROUP:
      if (isNil(action.payload)) {
        return state;
      }

      return { ...state, group: action.payload };
    case GROUP_PICKER_ACTIONS.RESET_GROUP:
      return { ...state, group: null };
    default:
      return state;
  }
};

const initialState = { loading: true, width: 200, group: null };

export function GroupPickerProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function rehydrate() {
      let persistedState;

      try {
        persistedState = await localforage.getItem("groupPicker");
      } catch (error) {
        console.error(error);
      }

      dispatch({ type: GROUP_PICKER_ACTIONS.REHYDRATE, payload: persistedState });
    }

    rehydrate();
  }, []);

  useEffect(() => {
    async function updateLocalStorage() {
      await localforage.setItem("groupPicker", state);
    }

    if (state.loading) {
      return;
    }

    updateLocalStorage();
  }, [state]);

  return <GroupPickerContext.Provider value={[state, dispatch]}>{children}</GroupPickerContext.Provider>;
}

GroupPickerProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
