import React from "react";
import filter from "lodash/filter";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../StatusText";

function getActiveArchivedOptions({
  allRecords,
  disableArchivedRecords = true,
  labelDecorator = (record, labelComponent) => labelComponent,
}) {
  const archivedRecords = filter(allRecords, { archived: true });

  if (!archivedRecords.length) {
    return allRecords.map(record => ({
      value: record.id,
      title: record.name,
      label: labelDecorator(record, record.name),
    }));
  }

  const activeRecords = filter(allRecords, { archived: false });

  return [
    {
      key: "active",
      label: "Active",
      children: activeRecords.map(record => ({
        value: record.id,
        title: record.name,
        label: labelDecorator(record, record.name),
      })),
    },
    {
      key: "archived",
      label: "Archived",
      children: archivedRecords.map(record => ({
        value: record.id,
        label: labelDecorator(
          record,
          <span>
            {record.name} <StatusText status={STATUS_TEXT_STATUS_OPTIONS.WARNING}>[Archived]</StatusText>
          </span>,
        ),
        title: record.name,
        disabled: disableArchivedRecords,
      })),
    },
  ];
}

export default getActiveArchivedOptions;
