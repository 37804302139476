import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import { connect } from "formik";
import gql from "fraql";
import get from "lodash/get";
import omit from "lodash/omit";
import useUpdateEffect from "../../utils/useUpdateEffect";
import FormFieldCheckboxGroup from "./FormFieldCheckboxGroup";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldRadio from "./FormFieldRadio";
import FormFieldSelect from "./FormFieldSelect";

const GET_COMPETITIONS_FOR_SPORT_MEET = gql`
  query CompetitionsForSportMeet($SportMeetId: String!) {
    Competition(where: { SportMeet: { id: { _eq: $SportMeetId } } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;

export const DISPLAY_MODES = {
  checkboxList: "checkboxList",
  select: "select",
  radio: "radio",
};

function FormFieldSportMeetCompetition(props) {
  const {
    name,
    meta,
    disabled,
    formik: { values, setFieldValue },
  } = props;

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const displayMode = get(meta, "displayMode", DISPLAY_MODES.select);

  const formSportMeetIdValue = get(values, meta.sportMeetFieldId, null);

  useUpdateEffect(() => {
    // Reset this field's value whenever the Sport Meet field's value changes, since the list of Competitions (and the
    // set of valid Competitions) is dependent on the Sport Meet selected, and therefore the current value for this
    // field may no longer be valid for the new value of the Sport Meet field.
    setFieldValue(name, []);
  }, [formSportMeetIdValue]);

  const { loading: competitionsLoading, error: competitionsError, data: competitionsData } = useQuery(
    GET_COMPETITIONS_FOR_SPORT_MEET,
    {
      variables: { SportMeetId: formSportMeetIdValue },
      skip: !formSportMeetIdValue,
    },
  );

  const options = useMemo(() => {
    const competitions = get(competitionsData, "Competition", []);

    return competitions.map(competition => ({
      value: competition.id,
      label: competition.name,
    }));
  }, [competitionsData]);

  if (!formSportMeetIdValue) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert
          message="Competitions can be selected after selecting a sport."
          type="info"
          className="form-field-alert"
        />
      </FormFieldFormItem>
    );
  }

  if (competitionsLoading && !competitionsData) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (competitionsError) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Competitions for the sport failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  switch (displayMode) {
    case DISPLAY_MODES.checkboxList: {
      return (
        <FormFieldCheckboxGroup
          {...omit(props, ["formik"])}
          disabled={disabled || competitionsLoading}
          meta={{ ...meta, options }}
        />
      );
    }
    case DISPLAY_MODES.select: {
      return (
        <FormFieldSelect
          {...omit(props, ["formik"])}
          disabled={disabled || competitionsLoading}
          meta={{ ...meta, options }}
        />
      );
    }
    case DISPLAY_MODES.radio: {
      return (
        <FormFieldRadio
          {...omit(props, ["formik"])}
          disabled={disabled || competitionsLoading}
          meta={{ ...meta, options }}
        />
      );
    }
    default: {
      console.error("Unsupported display mode", displayMode);
      return null;
    }
  }
}

FormFieldSportMeetCompetition.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    sportMeetFieldId: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

FormFieldSportMeetCompetition.defaultProps = {
  disabled: false,
};

export default connect(FormFieldSportMeetCompetition);
