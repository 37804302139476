import get from "lodash/get";
import includes from "lodash/includes";
import { FIELD_TYPES, SELECT_FIELD_MODES } from "../constants/fieldConstants";
import isBlank from "./isBlank";

export const FIELD_TYPES_ARRAY = [FIELD_TYPES.select, FIELD_TYPES.country, FIELD_TYPES.language];
export const FIELD_TYPES_UPLOAD = [FIELD_TYPES.file, FIELD_TYPES.image];

/**
 * Given a field's type, return the default value that should be used for this field (for when the field does not
 * already have a valid value).
 */
function getFieldDefaultValue(type, meta) {
  if (isBlank(type) || type === FIELD_TYPES.heading || type === FIELD_TYPES.divider) {
    return undefined;
  }

  if (type === FIELD_TYPES.bool) {
    return null;
  }

  if (type === FIELD_TYPES.number) {
    return null;
  }

  if (type === FIELD_TYPES.content) {
    return [];
  }

  if (includes(FIELD_TYPES_UPLOAD, type)) {
    return [];
  }

  if (includes(FIELD_TYPES_ARRAY, type)) {
    let mode = get(meta, "mode", null);

    if (!mode) {
      mode = SELECT_FIELD_MODES.default;
    }

    /**
     * Note: in the past we used "undefined" for the default mode, with the reason for this being that when the initial
     * value is a string then the placeholder is not displayed. However, this does not appear to be the case, so now
     * we use "" as the default value, to match with the value that would be saved in the database via
     * `mapFormValuesToDbValue`.
     */
    if (mode === SELECT_FIELD_MODES.default) {
      return "";
    }

    return [];
  }

  return "";
}

export default getFieldDefaultValue;
