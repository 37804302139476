import { useMemo } from "react";
import filter from "lodash/filter";
import find from "lodash/find";
import get from "lodash/get";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { NO_ACCESS_TITLE } from "../constants/labelConstants";

function mapChildren(allGroups, parentId) {
  const childGroups = filter(allGroups, group => group.parentId === parentId);

  return childGroups.map(childGroup => ({
    key: childGroup.id,
    value: childGroup.id,
    title: childGroup.name,
    children: mapChildren(allGroups, childGroup.id),
  }));
}

function mapGroupWithChildren(allGroups, groupItem) {
  return {
    key: groupItem.id,
    value: groupItem.id,
    title: groupItem.name,
    children: mapChildren(allGroups, groupItem.id),
  };
}

function useGroupTreeData(groups, options) {
  const selectedValues = get(options, "selectedValues");
  const noAccessParentGroupTitleRenderer = get(options, "noAccessParentGroupTitleRenderer");
  const noAccessChildGroupTitleRenderer = get(options, "noAccessChildGroupTitleRenderer");

  const selectedGroupIds = useMemo(() => {
    if (isNil(selectedValues)) {
      return [];
    }

    if (!isArray(selectedValues)) {
      return [selectedValues];
    }

    return selectedValues;
  }, [selectedValues]);

  const noAccessChildGroupTitle = useMemo(() => {
    if (!isNil(noAccessChildGroupTitleRenderer)) {
      return noAccessChildGroupTitleRenderer();
    }

    return NO_ACCESS_TITLE;
  }, [noAccessChildGroupTitleRenderer]);

  const noAccessParentGroupTitle = useMemo(() => {
    if (!isNil(noAccessParentGroupTitleRenderer)) {
      return noAccessParentGroupTitleRenderer();
    }

    return NO_ACCESS_TITLE;
  }, [noAccessParentGroupTitleRenderer]);

  const groupsWithNoAccess = useMemo(() => {
    if (isEmpty(selectedGroupIds)) {
      return [];
    }

    const noAccessGroupsArray = selectedGroupIds
      .filter(selectedGroupId => !find(groups, { id: selectedGroupId }))
      .map(selectedGroupId => ({
        key: selectedGroupId,
        value: selectedGroupId,
        title: noAccessChildGroupTitle,
      }));

    if (isEmpty(noAccessGroupsArray)) {
      return [];
    }

    return [
      {
        key: "NO_ACCESS_PARENT_GROUP",
        value: "NO_ACCESS_PARENT_GROUP",
        title: noAccessParentGroupTitle,
        selectable: false,
        children: noAccessGroupsArray,
      },
    ];
  }, [groups, noAccessChildGroupTitle, noAccessParentGroupTitle, selectedGroupIds]);

  return useMemo(() => {
    const rootGroups = filter(groups, groupItem => groupItem.parentId === null);
    const nonRootGroups = filter(groups, groupItem => groupItem.parentId !== null);

    const uppermostGroupsWithoutVisibleParent = filter(nonRootGroups, groupItem => {
      // Check if the group we're looking at belongs to a root group (a group at the top of the hierarchy with no
      // parent) which is also visible to the user.
      const rootParentGroupExists = find(rootGroups, rootGroupItem => includes(groupItem.path, rootGroupItem.id));

      // If so, we do not need to include this group, because the root parent will be included instead, and the root
      // parent will include all of its children (of which this group is one).
      if (rootParentGroupExists) {
        return false;
      }

      // Check if the group we're looking at has a parent (at any level the hierarchy, not just a direct parent) which
      // is also visible to the user, and which is not a root group.
      const nonRootParentGroupExists = find(nonRootGroups, nonRootGroupItem => {
        return (
          nonRootGroupItem.path !== groupItem.path &&
          includes(groupItem.path, `${nonRootGroupItem.path}.${nonRootGroupItem.id}`)
        );
      });

      // If so, we do not need to include this group, because the parent will be included instead, and the parent will
      // include all of its children (of which this group is one).
      return !nonRootParentGroupExists;
    });

    return [
      ...rootGroups.map(groupItem => mapGroupWithChildren(groups, groupItem)),
      ...uppermostGroupsWithoutVisibleParent.map(groupItem => mapGroupWithChildren(groups, groupItem)),
      ...groupsWithNoAccess,
    ];
  }, [groups, groupsWithNoAccess]);
}

export default useGroupTreeData;
