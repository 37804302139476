import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert } from "antd";
import gql from "fraql";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { REG_SUBMISSION_STATUSES } from "../../constants/regStatusConstants";
import useCurrentUser from "../../utils/useCurrentUser";

const GET_REG_SUBMISSION = gql`
  query RegistrationSubmissionUnavailableAlert_GetRegSubmission($regId: String!, $personId: String!) {
    RegSubmission(where: { RegId: { _eq: $regId }, PersonId: { _eq: $personId } }) {
      id
      status
    }
  }
`;

function RegistrationSubmissionUnavailableAlert({ RegId, style }) {
  const currentUser = useCurrentUser();
  const personId = get(currentUser, "personId", null);
  const skip = isNil(personId);

  const { data: regSubmissionData } = useQuery(GET_REG_SUBMISSION, {
    variables: { regId: RegId, personId },
    skip,
  });

  const status = get(regSubmissionData, "RegSubmission.0.status");

  if (status !== REG_SUBMISSION_STATUSES.deregistered) {
    return null;
  }

  return (
    <Alert
      message="Registration unavailable"
      description="This registration is no longer available. If you believe this is an error please contact an administrator."
      type="warning"
      showIcon
      style={style}
    />
  );
}

RegistrationSubmissionUnavailableAlert.propTypes = {
  RegId: PropTypes.string.isRequired,
  style: PropTypes.any,
};

RegistrationSubmissionUnavailableAlert.defaultProps = {
  style: {},
};

export default RegistrationSubmissionUnavailableAlert;
