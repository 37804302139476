import { useMemo } from "react";
import every from "lodash/every";
import has from "lodash/has";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import isString from "lodash/isString";
import some from "lodash/some";
import { AUTH_ROLES } from "../constants/authConstants";
import { useAuth0 } from "../components/Auth0";
import isBlank from "./isBlank";

/**
 * Given the authenticated user's role and a permission to an action item, return whether the authorised user has permission
 * to perform the action, view a page, etc.
 */

export const PERMISSION = {
  // A general update access, read only roles will not have this permission
  WRITE_ACCESS: "WRITE_ACCESS",

  // global and nav permissions
  ACCESS_DASHBOARD: "ACCESS_DASHBOARD",
  ACCESS_PEOPLE: "ACCESS_PEOPLE",
  ACCESS_PROFILE: "ACCESS_PROFILE",
  ACCESS_SUMMARY_REPORT: "ACCESS_SUMMARY_REPORT",
  ACCESS_ELEVATED_GLOBAL_NAVIGATION: "ACCESS_ELEVATED_GLOBAL_NAVIGATION",
  SHOW_GLOBAL_SEARCH_BAR: "SHOW_GLOBAL_SEARCH_BAR",
  ACCESS_USER_REGISTRATIONS: "ACCESS_USER_REGISTRATIONS",
  ACCESS_REGISTRATIONS: "ACCESS_REGISTRATIONS",
  ACCESS_FORMS: "ACCESS_FORMS",
  ACCESS_COMPETITION_MANAGEMENT: "ACCESS_COMPETITION_MANAGEMENT",

  // forms
  ARCHIVE_FROM: "ARCHIVE_FROM",
  EDIT_FORM: "EDIT_FORM",
  EDIT_FORM_CONDITIONALLY: "EDIT_FORM_CONDITIONALLY",

  // people related permissions
  SHOW_ADD_PEOPLE_DROPDOWN: "SHOW_ADD_PEOPLE_DROPDOWN",
  SHOW_BULK_ACTION_MUTATING_ITEMS: "SHOW_BULK_ACTION_MUTATING_ITEMS",
  DELETE_PERSON: "DELETE_PERSON",
  VIEW_PERSON_USER: "VIEW_PERSON_USER",
  ENABLE_PEOPLE_ACTIONS_WITHOUT_GROUP_SELECTION: "ENABLE_PEOPLE_ACTIONS_WITHOUT_GROUP_SELECTION",
  UPLOAD_PERSON_PROFILE_PHOTO: "UPLOAD_PERSON_PROFILE_PHOTO",

  // registration submission permissions
  ADD_REGISTRATION: "ADD_REGISTRATION",
  SUBMIT_REGISTRATION: "SUBMIT_REGISTRATION",
  SHOW_REG_STATUS_SELECT: "SHOW_REG_STATUS_SELECT",
  SHOW_SUBMISSION_STATUS_STEP: "SHOW_SUBMISSION_STATUS_STEP",
  EDIT_CLOSED_REGISTRATION: "EDIT_CLOSED_REGISTRATION",
  CHANGE_SUBMISSION_STATUS_OF_CLOSED_REGISTRATION: "CHANGE_SUBMISSION_STATUS_OF_CLOSED_REGISTRATION",
  SHOW_ALL_SUBMISSION_STATUS_TRANSITIONS_OPTIONS: "SHOW_ALL_SUBMISSION_STATUS_TRANSITIONS_OPTIONS",
  SHOW_LIMITED_SUBMISSION_STATUS_TRANSITIONS_OPTIONS: "SHOW_LIMITED_SUBMISSION_STATUS_TRANSITIONS_OPTIONS",
  SHOW_REGISTRATION_STATUS: "SHOW_REGISTRATION_STATUS",
  SHOW_INELIGIBLE_COMPETITIONS: "SHOW_INELIGIBLE_COMPETITIONS",

  // travel group permissions
  SHOW_REGISTRATION_TRAVEL_STATUS: "SHOW_REGISTRATION_TRAVEL_STATUS",
  SUBMIT_TRAVEL_INFO_WHILE_PERIOD_IS_CLOSED: "SUBMIT_TRAVEL_INFO_WHILE_PERIOD_IS_CLOSED",
  MANAGE_TRAVEL_GROUP: "MANAGE_TRAVEL_GROUP",
  MANAGE_TRAVEL_GROUP_CONDITIONALLY: "MANAGE_TRAVEL_GROUP_CONDITIONALLY",

  // team permissions
  VIEW_TEAM: "VIEW_TEAM",
  MANAGE_TEAM: "MANAGE_TEAM",
  MANAGE_TEAM_CONDITIONALLY: "MANAGE_TEAM_CONDITIONALLY",
  MANAGE_TEAM_REGISTRANT: "MANAGE_TEAM_REGISTRANT",

  // events
  SHOW_EVENT_PERMISSIONS_TAB: "UPDATE_EVENT_PERMISSIONS",

  // downloads
  DOWNLOAD_EXPORTED_FILES: "DOWNLOAD_EXPORTED_FILES",
  DOWNLOAD_PRIVATE_FILES: "DOWNLOAD_PRIVATE_FILES",

  // Tenant administration + Fusesport Staff administration
  TENANT_SYSTEM_ADMIN: "TENANT_SYSTEM_ADMIN",
  FUSESPORT_ADMIN: "FUSESPORT_ADMIN",
};

const MANAGER_ACCESS = [
  PERMISSION.WRITE_ACCESS,
  PERMISSION.ADD_REGISTRATION,
  PERMISSION.SHOW_ADD_PEOPLE_DROPDOWN,
  PERMISSION.SHOW_BULK_ACTION_MUTATING_ITEMS,
  PERMISSION.VIEW_TEAM,
  PERMISSION.SUBMIT_REGISTRATION,
  PERMISSION.UPLOAD_PERSON_PROFILE_PHOTO,
  PERMISSION.SHOW_REG_STATUS_SELECT,
  PERMISSION.ACCESS_PEOPLE,
  PERMISSION.ACCESS_PROFILE,
  PERMISSION.ACCESS_SUMMARY_REPORT,
  PERMISSION.ACCESS_ELEVATED_GLOBAL_NAVIGATION,
  PERMISSION.SHOW_SUBMISSION_STATUS_STEP,
  PERMISSION.SHOW_REGISTRATION_TRAVEL_STATUS,
  PERMISSION.SHOW_LIMITED_SUBMISSION_STATUS_TRANSITIONS_OPTIONS,
  PERMISSION.SHOW_REGISTRATION_STATUS,
  PERMISSION.MANAGE_TRAVEL_GROUP_CONDITIONALLY,
  PERMISSION.MANAGE_TEAM_CONDITIONALLY,
  PERMISSION.DOWNLOAD_EXPORTED_FILES,
  PERMISSION.DOWNLOAD_PRIVATE_FILES,
];

const ADMINISTRATOR_GROUP_ACCESS = [
  PERMISSION.WRITE_ACCESS,
  PERMISSION.ADD_REGISTRATION,
  PERMISSION.SHOW_ADD_PEOPLE_DROPDOWN,
  PERMISSION.SHOW_BULK_ACTION_MUTATING_ITEMS,
  PERMISSION.VIEW_TEAM,
  PERMISSION.SUBMIT_REGISTRATION,
  PERMISSION.UPLOAD_PERSON_PROFILE_PHOTO,
  PERMISSION.SHOW_REG_STATUS_SELECT,
  PERMISSION.ACCESS_PEOPLE,
  PERMISSION.ACCESS_PROFILE,
  PERMISSION.ACCESS_SUMMARY_REPORT,
  PERMISSION.ACCESS_ELEVATED_GLOBAL_NAVIGATION,
  PERMISSION.SHOW_SUBMISSION_STATUS_STEP,
  PERMISSION.SHOW_REGISTRATION_TRAVEL_STATUS,
  PERMISSION.SHOW_ALL_SUBMISSION_STATUS_TRANSITIONS_OPTIONS,
  PERMISSION.SHOW_REGISTRATION_STATUS,
  PERMISSION.MANAGE_TRAVEL_GROUP,
  PERMISSION.MANAGE_TEAM,
  PERMISSION.EDIT_CLOSED_REGISTRATION,
  PERMISSION.CHANGE_SUBMISSION_STATUS_OF_CLOSED_REGISTRATION,
  PERMISSION.SUBMIT_TRAVEL_INFO_WHILE_PERIOD_IS_CLOSED,
  PERMISSION.ACCESS_FORMS,
  PERMISSION.ACCESS_COMPETITION_MANAGEMENT,
  PERMISSION.EDIT_FORM_CONDITIONALLY,
  PERMISSION.ACCESS_REGISTRATIONS,
  PERMISSION.DELETE_PERSON,
  PERMISSION.SHOW_INELIGIBLE_COMPETITIONS,
  PERMISSION.DOWNLOAD_EXPORTED_FILES,
  PERMISSION.DOWNLOAD_PRIVATE_FILES,
];

const BASIC_USER_ACCESS = [
  PERMISSION.WRITE_ACCESS,
  PERMISSION.SUBMIT_REGISTRATION,
  PERMISSION.ACCESS_USER_REGISTRATIONS,
  PERMISSION.ACCESS_PROFILE,
  PERMISSION.MANAGE_TEAM_REGISTRANT,
  PERMISSION.DOWNLOAD_PRIVATE_FILES,
];

const MANAGER_READ_ONLY_ACCESS = [
  PERMISSION.VIEW_TEAM,
  PERMISSION.ACCESS_PEOPLE,
  PERMISSION.ACCESS_PROFILE,
  PERMISSION.ACCESS_SUMMARY_REPORT,
  PERMISSION.ACCESS_ELEVATED_GLOBAL_NAVIGATION,
  PERMISSION.SHOW_SUBMISSION_STATUS_STEP,
  PERMISSION.SHOW_REGISTRATION_TRAVEL_STATUS,
  PERMISSION.SHOW_REGISTRATION_STATUS,
  PERMISSION.MANAGE_TRAVEL_GROUP_CONDITIONALLY,
  PERMISSION.DOWNLOAD_PRIVATE_FILES,
];

// Tenant/System Adminisrators receive all permissions + Tenant System Admin duties.
// This bizarre looking one-line basically declares an array, adds all Access arrays to that newly declared Array that contains unique Admin_access permissions, and deduplicates all entries using the Set object, finally spreading the Set afterwards back into another new array.
const ADMIN_ACCESS = [
  ...new Set([PERMISSION.TENANT_SYSTEM_ADMIN].concat(ADMINISTRATOR_GROUP_ACCESS, MANAGER_ACCESS, BASIC_USER_ACCESS)),
];
// Fusesport Staff have no restrictions, and will always have access.

const PERMISSION_MATRIX = {
  [AUTH_ROLES.ADMIN]: ADMIN_ACCESS,
  [AUTH_ROLES.ADMINISTRATOR_GROUP]: ADMINISTRATOR_GROUP_ACCESS,
  [AUTH_ROLES.MANAGER]: MANAGER_ACCESS,
  [AUTH_ROLES.MANAGER_READ_ONLY]: MANAGER_READ_ONLY_ACCESS,
  [AUTH_ROLES.BASIC_USER]: BASIC_USER_ACCESS,
};

function hasPermissionItem({ permission, userRole, authPermissions }) {
  if (isBlank(permission) || isNil(userRole)) {
    return false;
  }

  if (AUTH_ROLES.GLOBAL_ADMIN === userRole) {
    return true;
  }

  if (!includes(Object.keys(PERMISSION_MATRIX), userRole)) {
    console.error(`${userRole} role is not found!`);

    return false;
  }

  if (!isEmpty(authPermissions) && includes(authPermissions, permission)) {
    return true;
  }

  return includes(PERMISSION_MATRIX[userRole], permission);
}

/**
 * Returns whether the given role has access to a permission or not.
 * @param {Object} params
 * @param {string | Object} params.permission - one of PERMISSION values or an object having either 'or' or 'and' property containing an array of PERMISSION values
 * @param {string} params.userRole - current user's role
 * @param {string[]} params.authPermissions - Optional - Array of PERMISSION values that are from Auth0's jwt
 *
 * @example
 * // returns true
 *  hasPermission({ permission: { or: [PERMISSION.ACCESS_FORMS, PERMISSION.ARCHIVE_FROM] }, userRole: AUTH_ROLES.ADMINISTRATOR_GROUP }),
 *
 * // returns false
 * hasPermission({ permission: { and: [PERMISSION.ACCESS_FORMS, PERMISSION.ARCHIVE_FROM] }, userRole: AUTH_ROLES.ADMINISTRATOR_GROUP }),
 *
 * // returns true
 *  hasPermission({ permission: PERMISSION.ACCESS_FORMS, userRole: AUTH_ROLES.ADMINISTRATOR_GROUP }),
 *
 * @return {boolean}
 *
 */
export function hasPermission(params) {
  const { permission, userRole, authPermissions } = params;

  if (isString(permission) || isEmpty(permission)) {
    return hasPermissionItem(params);
  }

  if (has(permission, "or") && has(permission, "and")) {
    throw Error("'permission' object must have only one of 'or' or 'and' properties and not both.");
  }

  if (has(permission, "or")) {
    if (!isArray(permission.or)) {
      throw Error("'or' property in permission object must be an array of permission items.");
    }
    return some(permission.or, permissionItem =>
      hasPermissionItem({ permission: permissionItem, userRole, authPermissions }),
    );
  }

  if (has(permission, "and")) {
    if (!isArray(permission.and)) {
      throw Error("'and' property in permission object must be an array of permission items.");
    }

    return every(permission.and, permissionItem =>
      hasPermissionItem({ permission: permissionItem, userRole, authPermissions }),
    );
  }

  throw Error("Invalid 'permission' type passed in parameters.");
}

/**
 * Returns whether the current user has access to a permission or not.
 * @param {string | Object} permission - one of PERMISSION values or an object having either 'or' or 'and' property containing an array of PERMISSION values
 *
 * @example
 * Assuming the current user has a group administrator role
 *
 * //returns true
 * useGetUserHasPermission({ or: [PERMISSION.ACCESS_FORMS, PERMISSION.ARCHIVE_FROM] }),
 *
 * // returns false
 * useGetUserHasPermission({ and: [PERMISSION.ACCESS_FORMS, PERMISSION.ARCHIVE_FROM] }),
 *
 * // returns true
 *  useGetUserHasPermission(PERMISSION.ACCESS_FORMS),
 *
 * @return {boolean}
 *
 */
export const useGetUserHasPermission = permission => {
  const { role: userRole, authPermissions } = useAuth0();

  return useMemo(() => hasPermission({ permission, userRole, authPermissions }), [
    userRole,
    permission,
    authPermissions,
  ]);
};
