import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import toInteger from "lodash/toInteger";
import isBlank from "../../utils/isBlank";
import EmptyValueText from "../EmptyValueText";

function getDateFromDateString(date) {
  const dateParams = date.split("-");

  if (isEmpty(dateParams)) {
    return null;
  }

  return new Date(toInteger(dateParams[0]), toInteger(dateParams[1]) - 1, toInteger(dateParams[2]));
}

/**
 * See https://stackoverflow.com/a/7091965/1374827.
 *
 * @param dateOfBirth The person's date of birth as a string in YYYY-MM-DD format.
 * @param asAtDate The calculation date as a string in YYYY-MM-DD format, or `null` to calculate age as of right now.
 * @returns {null|number}
 */
export function getAgeFromDob(dateOfBirth, asAtDate = null) {
  if (isBlank(dateOfBirth)) {
    return null;
  }

  let calculationDate;

  if (isBlank(asAtDate)) {
    calculationDate = new Date();
  } else {
    calculationDate = getDateFromDateString(asAtDate);
  }

  const birthDate = getDateFromDateString(dateOfBirth);

  if (isNil(birthDate) || isNil(calculationDate)) {
    return null;
  }

  const monthDiff = calculationDate.getMonth() - birthDate.getMonth();

  let age = calculationDate.getFullYear() - birthDate.getFullYear();

  if (monthDiff < 0 || (monthDiff === 0 && calculationDate.getDate() - birthDate.getDate() < 0)) {
    age -= 1;
  }

  age = toInteger(age);

  if (age < 0) {
    return null;
  }

  return age;
}

function PersonAge({ dob, showYears, showUnknownExplicit }) {
  const age = getAgeFromDob(dob);

  if (isNil(age)) {
    if (showUnknownExplicit) {
      return <span>Unknown Age</span>;
    }

    return <EmptyValueText />;
  }

  return (
    <span>
      {age}
      {showYears && " yrs"}
    </span>
  );
}

PersonAge.propTypes = {
  dob: PropTypes.string,
  showYears: PropTypes.bool,
  showUnknownExplicit: PropTypes.bool,
};

PersonAge.defaultProps = {
  dob: null,
  showYears: false,
  showUnknownExplicit: false,
};

export default PersonAge;
