import first from "lodash/first";
import flatMap from "lodash/flatMap";
import get from "lodash/get";
import has from "lodash/has";
import isString from "lodash/isString";
import sortBy from "lodash/sortBy";

export function getFlattenedOptions(options = []) {
  const areThereOptionGroups = has(first(options), "children");

  if (areThereOptionGroups) {
    return flatMap(options, "children");
  }

  return options;
}

export function orderOptionsByLabel(options = []) {
  return sortBy(options, option => {
    if (isString(option.label)) {
      return option.label;
    }

    return get(option, "title", "");
  });
}
