import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { COMPETITION_SCORE_TYPE_OPTIONS } from "../../constants/competitionConstants";
import FormFieldSelect from "./FormFieldSelect";

function FormFieldCompetitionScoreType(props) {
  const { meta } = props;

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Select a type";
  }

  return <FormFieldSelect {...props} meta={{ ...meta, options: COMPETITION_SCORE_TYPE_OPTIONS, placeholder }} />;
}

FormFieldCompetitionScoreType.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default FormFieldCompetitionScoreType;
