import { useMemo } from "react";
import * as ROUTES from "../constants/routeConstants";
import { useAuth0 } from "../components/Auth0";
import { PERMISSION, hasPermission } from "./useGetUserHasPermission";

function useDefaultHomePath() {
  // using original authRole rather than role so it can work even when user is in self registration page.
  const { authRole: userRole } = useAuth0();

  const defaultHomePath = useMemo(() => {
    const canAccessDashboard = hasPermission({ permission: PERMISSION.ACCESS_DASHBOARD, userRole });
    const canAccessPeople = hasPermission({ permission: PERMISSION.ACCESS_PEOPLE, userRole });

    if (canAccessDashboard) {
      return ROUTES.DASHBOARD;
    }

    if (canAccessPeople) {
      return ROUTES.PEOPLE;
    }

    return null;
  }, [userRole]);

  return defaultHomePath;
}

export default useDefaultHomePath;
