import { chain } from "lodash";
import toInteger from "lodash/toInteger";
import toNumber from "lodash/toNumber";

const stripNonDigits = str => {
  return str.replace(/\D/g, "");
};

const parseLowerLimit = limit => {
  return stripNonDigits(limit);
};

const parseUpperLimit = limit => {
  const upperLimitExclusive = toInteger(stripNonDigits(limit));

  // Handle upper bound being exclusive rather than inclusive of the upper bound number.
  return upperLimitExclusive - 1;
};

export function parseAgeGroup(ageGroup) {
  const ageGroupParts = ageGroup.split(",");
  const lowerLimit = parseLowerLimit(ageGroupParts[0]);
  const upperLimit = parseUpperLimit(ageGroupParts[1]);

  return `${lowerLimit}-${upperLimit}`;
}

export function parseAgeGroups(ageGroups) {
  return ageGroups.map(parseAgeGroup).join(", ");
}

export const formatAgeGroups = value => {
  const valueSplitIntoArrayOfArrays = chain(value)
    .replace(/\s/g, "")
    .split(",")
    .map(group => group.split("-"))
    .value();

  return valueSplitIntoArrayOfArrays.map(ageGroupAsArrayOfStrings => {
    return ageGroupAsArrayOfStrings.map(eachValue => toNumber(eachValue));
  });
};
