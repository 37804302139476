import React from "react";
import { Alert } from "antd";

function RegistrationClosedAlert() {
  return (
    <div style={{ padding: 24 }}>
      <Alert
        message="Registration is not open"
        description="Unfortunately, this registration is currently not open. Please try again later when the registration is open, or contact an administrator for more information."
        type="warning"
        showIcon
      />
    </div>
  );
}

export default RegistrationClosedAlert;
