import React from "react";
import PropTypes from "prop-types";
import "./SectionHeaderContainer.scss";

function SectionHeaderContainer({ children, style }) {
  return (
    <div className="section-header-container" style={style}>
      {children}
    </div>
  );
}

SectionHeaderContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  style: PropTypes.any,
};

SectionHeaderContainer.defaultProps = {
  style: {},
};

export default SectionHeaderContainer;
