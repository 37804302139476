import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert } from "antd";
import get from "lodash/get";
import useCurrentUser from "../../utils/useCurrentUser";
import PersonDetailRegistrations from "../MenuPeople/Registrations";
import SpinPageContent from "../SpinPageContent";
import RegistrationUserSubmitCreate from "./RegistrationUserSubmitCreate";
import { GET_PERSON_REG_SUBMISSION } from "./selfRegistrationGql";

function RegistrationUserSubmit({ regId }) {
  const currentUser = useCurrentUser();

  const personId = get(currentUser, "personId", null);

  const skip = !personId;

  const { loading: regSubmissionLoading, error: regSubmissionError, data: regSubmissionData } = useQuery(
    GET_PERSON_REG_SUBMISSION,
    {
      variables: { regId, personId },
      skip,
    },
  );

  const regSubmission = useMemo(() => get(regSubmissionData, "RegSubmission.0", null), [regSubmissionData]);

  if (skip || (regSubmissionLoading && !regSubmissionData)) {
    return <SpinPageContent style={{ height: "100%" }} />;
  }

  if (regSubmissionError) {
    return (
      <div style={{ padding: 24 }}>
        <Alert
          message="Registration failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      </div>
    );
  }

  const hasRegSubmission = !!regSubmission;

  return (
    <>
      {hasRegSubmission && (
        <div
          style={{
            display: "flex",
            height: "calc(100% - 63px)",
            overflow: "hidden",
          }}
        >
          <PersonDetailRegistrations PersonId={personId} RegId={regId} displayAvatarUpload />
        </div>
      )}

      {!hasRegSubmission && <RegistrationUserSubmitCreate RegId={regId} PersonId={personId} />}
    </>
  );
}

RegistrationUserSubmit.propTypes = {
  regId: PropTypes.string.isRequired,
};

export default RegistrationUserSubmit;
