export const STEP_TYPE = {
  INFO: "Info",
  LEGAL: "Legal",
  PROFILE: "Profile",
  MEMBERSHIP: "Group",
  PARTICIPATION: "Participation",
  FORM: "Form",
  SPORTS: "Sports",
  COMPETITIONS: "Competitions",
  ALTERNATE: "Alternate",
  FEE: "Fee",

  // Note: "Events" is an old type which is not currently in use.
  EVENTS: "Events",
};

export const STEP_TYPE_LABEL = {
  [STEP_TYPE.INFO]: "Info",
  [STEP_TYPE.LEGAL]: "Legal",
  [STEP_TYPE.PROFILE]: "Profile",
  [STEP_TYPE.MEMBERSHIP]: "Membership",
  [STEP_TYPE.PARTICIPATION]: "Participation",
  [STEP_TYPE.FORM]: "Form",
  [STEP_TYPE.SPORTS]: "Sports",
  [STEP_TYPE.COMPETITIONS]: "Competitions",
  [STEP_TYPE.ALTERNATE]: "Alternate",
  [STEP_TYPE.EVENTS]: "Events",
  [STEP_TYPE.FEE]: "Fee",
};

// Note: options sorted alphabetically by their label.
export const STEP_TYPE_OPTIONS = [
  {
    value: STEP_TYPE.ALTERNATE,
    label: STEP_TYPE_LABEL[STEP_TYPE.ALTERNATE],
  },
  {
    value: STEP_TYPE.COMPETITIONS,
    label: STEP_TYPE_LABEL[STEP_TYPE.COMPETITIONS],
  },
  {
    value: STEP_TYPE.FORM,
    label: STEP_TYPE_LABEL[STEP_TYPE.FORM],
  },
  {
    value: STEP_TYPE.INFO,
    label: STEP_TYPE_LABEL[STEP_TYPE.INFO],
  },
  {
    value: STEP_TYPE.LEGAL,
    label: STEP_TYPE_LABEL[STEP_TYPE.LEGAL],
  },
  {
    value: STEP_TYPE.MEMBERSHIP,
    label: STEP_TYPE_LABEL[STEP_TYPE.MEMBERSHIP],
  },
  {
    value: STEP_TYPE.PARTICIPATION,
    label: STEP_TYPE_LABEL[STEP_TYPE.PARTICIPATION],
  },
  {
    value: STEP_TYPE.PROFILE,
    label: STEP_TYPE_LABEL[STEP_TYPE.PROFILE],
  },
  {
    value: STEP_TYPE.SPORTS,
    label: STEP_TYPE_LABEL[STEP_TYPE.SPORTS],
  },
  {
    value: STEP_TYPE.FEE,
    label: STEP_TYPE_LABEL[STEP_TYPE.FEE],
  },
];

export const STEP_TYPE_HELP_MESSAGE = {
  [STEP_TYPE.INFO]: `An ${
    STEP_TYPE_LABEL[STEP_TYPE.INFO]
  } step shows content that should be read as part of filling out the registration submission.`,
  [STEP_TYPE.LEGAL]: `A ${
    STEP_TYPE_LABEL[STEP_TYPE.LEGAL]
  } step shows content that should be read as part of filling out the registration submission, and requires
     the user to click "Agree and Continue" to proceed.`,
  [STEP_TYPE.PROFILE]: `A ${
    STEP_TYPE_LABEL[STEP_TYPE.PROFILE]
  } step shows a form for filling out or updating the person's profile.`,
  [STEP_TYPE.MEMBERSHIP]: `A ${
    STEP_TYPE_LABEL[STEP_TYPE.MEMBERSHIP]
  } step shows a form for adding the person as a member of a group, with a particular role for that new
     group membership. If you do not need people to join new groups while filling out the registration submission,
     use the "${STEP_TYPE_LABEL[STEP_TYPE.PARTICIPATION]}" step type instead.`,
  [STEP_TYPE.PARTICIPATION]: `A ${
    STEP_TYPE_LABEL[STEP_TYPE.PARTICIPATION]
  } step shows a form for selecting the person's delegation group and role for this registration.`,
  [STEP_TYPE.FORM]: `A ${STEP_TYPE_LABEL[STEP_TYPE.FORM]} step shows a form that you have created.`,
  [STEP_TYPE.SPORTS]: `A ${
    STEP_TYPE_LABEL[STEP_TYPE.SPORTS]
  } step shows a form for choosing which sports the person is participating in.`,
  [STEP_TYPE.COMPETITIONS]: `A ${
    STEP_TYPE_LABEL[STEP_TYPE.COMPETITIONS]
  } step shows a form for choosing which competitions the person is participating in for a particular
     sport.`,
  [STEP_TYPE.ALTERNATE]: `An ${
    STEP_TYPE_LABEL[STEP_TYPE.ALTERNATE]
  } step shows a form for choosing whether the person is an alternate
     for this registration. If a person is an alternate they will not get added as a member of any
     teams they choose, and they will not get added as a competitor in any competitions they choose.`,
  [STEP_TYPE.FEE]: `A ${
    STEP_TYPE_LABEL[STEP_TYPE.FEE]
  } provides a method to charge registrants for this registration. The Fee, Tax, and which conditions apply for the Fee can be configured within the step.`,
};

export const STEP_TYPES_WITH_FORM = [
  STEP_TYPE.FORM,
  STEP_TYPE.MEMBERSHIP,
  STEP_TYPE.PROFILE,
  STEP_TYPE.SPORTS,
  STEP_TYPE.COMPETITIONS,
  STEP_TYPE.PARTICIPATION,
  STEP_TYPE.ALTERNATE,
];
