import { useEffect } from "react";
import { useAuth0 } from "../components/Auth0";

function useAuthenticatedRoute(location) {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: location.pathname + location.search },
      });
    };

    fn();
  }, [loading, isAuthenticated, loginWithRedirect, location]);
}

export default useAuthenticatedRoute;
