import React from "react";
import PropTypes from "prop-types";
import { List, Checkbox as CheckboxAntD } from "antd";
import classnames from "classnames";
import { connect } from "formik";
import { Checkbox } from "formik-antd";
import first from "lodash/first";
import get from "lodash/get";
import has from "lodash/has";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { formFieldIsInErrorState } from "../../utils/formikUtils";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldReadOnlyContent from "./FormFieldReadOnlyFields/FormFieldReadOnlyContent";
import ReadOnlyList from "./FormFieldReadOnlyFields/ReadOnlyList";
import "./FormFieldCheckboxGroup.scss";

function renderOptionsList(options, fieldValue) {
  return (
    <List
      itemLayout="horizontal"
      dataSource={options}
      renderItem={option => {
        const checked = includes(fieldValue, option.value);

        return (
          <List.Item>
            <CheckboxAntD value={option.value} disabled={!!option.disabled && !checked}>
              {option.label}
            </CheckboxAntD>
          </List.Item>
        );
      }}
    />
  );
}

function FormFieldCheckboxGroup(props) {
  const { name, meta, disabled, style, formik, readOnly } = props;

  const options = get(meta, "options", []);
  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);

  const { values, errors, touched, submitCount } = formik;

  const fieldValue = get(values, name, null);

  const isInErrorState = formFieldIsInErrorState(name, errors, touched, submitCount);

  const checkboxGroupClassName = classnames("form-field-checkbox-group__checkbox-group", {
    "form-field-checkbox-group__checkbox-group--error": isInErrorState,
  });

  function renderOptions() {
    const renderOptionGroups = has(first(options), "children");

    if (!renderOptionGroups) {
      return renderOptionsList(options, fieldValue);
    }

    return options.map(optionGroup => {
      return (
        <div key={optionGroup.key}>
          <div className="ant-select-dropdown-menu-item-group-title">{optionGroup.label}</div>
          {renderOptionsList(optionGroup.children, fieldValue)}
        </div>
      );
    });
  }

  function getReadOnlyContent() {
    if (isEmpty(fieldValue)) {
      return null;
    }
    return <ReadOnlyList options={options} selectedValues={fieldValue} />;
  }

  if (readOnly) {
    return <FormFieldReadOnlyContent name={name} meta={meta} content={getReadOnlyContent()} />;
  }

  return (
    <FormFieldFormItem
      {...omit(props, ["formik"])}
      className="form-field-checkbox-group"
      displayDefaultLabel={displayDefaultLabel}
      displayForInput={false}
    >
      <Checkbox.Group name={name} disabled={disabled} className={checkboxGroupClassName} style={style}>
        {renderOptions()}
      </Checkbox.Group>
    </FormFieldFormItem>
  );
}

FormFieldCheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  formik: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

FormFieldCheckboxGroup.defaultProps = {
  disabled: false,
  readOnly: false,
  style: {},
};

export default connect(FormFieldCheckboxGroup);
