import React from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import omit from "lodash/omit";
import FormFieldFileBuilder from "./FormFieldFileBuilder";
import FormFieldFileReal from "./FormFieldFileReal";
import "./FormFieldFile.scss";

function FormFieldFile(props) {
  const { builder } = props;

  if (builder) {
    return <FormFieldFileBuilder {...omit(props, ["formik"])} disabled />;
  }

  return <FormFieldFileReal {...props} />;
}

FormFieldFile.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  builder: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  relatedRecordsData: PropTypes.object,
};

FormFieldFile.defaultProps = {
  disabled: false,
  readOnly: false,
  builder: false,
  relatedRecordsData: null,
};

export default connect(FormFieldFile);
