import React from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import get from "lodash/get";
import isNull from "lodash/isNull";
import omit from "lodash/omit";
import toString from "lodash/toString";
import InputNumber from "../AntD/InputNumber";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldReadOnlyContent from "./FormFieldReadOnlyFields/FormFieldReadOnlyContent";

function FormFieldNumber(props) {
  const { name, meta, disabled, formik, readOnly } = props;

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const addonAfter = get(meta, "addonAfter", undefined);
  const style = get(meta, "style", undefined);

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Enter a number";
  }

  function getReadOnlyContent() {
    const value = get(formik, `values.${name}`, null);

    if (isNull(value)) {
      return null;
    }

    return toString(value);
  }

  if (readOnly) {
    return <FormFieldReadOnlyContent name={name} meta={meta} content={getReadOnlyContent()} />;
  }

  return (
    <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel}>
      <InputNumber name={name} placeholder={placeholder} disabled={disabled} style={style} addonAfter={addonAfter} />
    </FormFieldFormItem>
  );
}

FormFieldNumber.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    placeholder: PropTypes.string,
    displayDefaultLabel: PropTypes.bool,
    addonAfter: PropTypes.string,
    style: PropTypes.any,
  }).isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

FormFieldNumber.defaultProps = {
  disabled: false,
  readOnly: false,
};

export default connect(FormFieldNumber);
