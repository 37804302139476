import { useEffect, useRef } from "react";

/**
 * A custom useEffect hook that only triggers on updates, not on initial mount.
 *
 * See: https://stackoverflow.com/a/57632587/1374827
 *
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */
function useUpdateEffect(effect, dependencies = []) {
  const isInitialMount = useRef(true);

  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        return effect();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies,
  );
}

export default useUpdateEffect;
