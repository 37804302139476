import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Typography, Select, Col, Row } from "antd";
import gql from "fraql";
import moment from "moment-timezone";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import uniq from "lodash/uniq";
import { renderOptions } from "../../../utils/selectUtils";
import SectionHeaderContainer from "../../SectionHeaderContainer";
import SpinPageContent from "../../SpinPageContent";
import MyRegistrationItem from "./MyRegistrationItem";
import { REGISTRATION_LIST_MODE, LIST_REG_SUBMISSION_STATUSES } from "./MyRegistrationsConstants";
import "./MyRegistrationsList.scss";

const GET_REG_SUBMISSIONS = gql`
  query RegistrationsListPage_GetReg($PersonId: String!, $regSubmissionStatuses: [String!]!) {
    RegSubmission(
      where: {
        PersonId: { _eq: $PersonId }
        status: { _in: $regSubmissionStatuses }
        Reg: { archived: { _eq: false } }
      }
      order_by: { Reg: { closeAt: desc } }
    ) {
      id
      status
      lastSubmittedAt
      percentComplete
      Reg {
        id
        name
        openAt
        closeAt
        timezoneName
      }
    }
  }
`;

function MyRegistrationsList({ headerTitle, listMode, PersonId }) {
  const [yearFilter, setYearFilter] = useState("all");

  const { loading: regSubmissionsLoading, error: regSubmissionsError, data: regSubmissionsData } = useQuery(
    GET_REG_SUBMISSIONS,
    {
      variables: { PersonId, regSubmissionStatuses: LIST_REG_SUBMISSION_STATUSES[listMode] },
      skip: isNil(PersonId),
    },
  );

  const { yearsOption, regSubmissionsList } = useMemo(() => {
    const result = { yearsOption: [{ label: "All", value: "all" }], regSubmissionsList: [] };

    if (!regSubmissionsData) {
      return result;
    }

    result.regSubmissionsList = regSubmissionsData.RegSubmission.map(regSubmission => ({
      ...regSubmission,
      closeAtYear: `${moment.tz(regSubmission.Reg.closeAt, regSubmission.Reg.timezoneName).year()}`,
    }));

    result.yearsOption = result.yearsOption.concat(
      uniq(result.regSubmissionsList.map(({ closeAtYear }) => closeAtYear)).map(year => ({
        label: `${year}`,
        value: `${year}`,
      })),
    );

    return result;
  }, [regSubmissionsData]);

  const handleYearSelect = useCallback(
    value => {
      setYearFilter(value);
    },
    [setYearFilter],
  );

  const renderContent = () => {
    if (regSubmissionsLoading && !regSubmissionsData) {
      return <SpinPageContent style={{ height: "100%" }} />;
    }

    if (regSubmissionsError || !regSubmissionsData) {
      return (
        <Alert
          message="Registrations failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      );
    }

    if (isEmpty(regSubmissionsList)) {
      return (
        <Alert message="No registrations" description="There are no registrations to show." type="warning" showIcon />
      );
    }

    return regSubmissionsList
      .filter(regSubmission => yearFilter === "all" || regSubmission.closeAtYear === yearFilter)
      .map(regSubmission => (
        <MyRegistrationItem
          key={regSubmission.id}
          name={regSubmission.name}
          regSubmission={regSubmission}
          listMode={listMode}
        />
      ));
  };
  return (
    <div className="my-registrations-list">
      <SectionHeaderContainer>
        <Typography.Title level={3}>{headerTitle}</Typography.Title>
      </SectionHeaderContainer>
      <div className="my-registrations-list__content">
        <Row>
          <Col xl={{ span: 5 }}>
            <div className="my-registrations-list__left-sidebar">
              <div className="my-registrations-list__left-sidebar-label">Registration close date</div>

              <Select value={yearFilter} onSelect={handleYearSelect} style={{ minWidth: 160 }}>
                {renderOptions(yearsOption)}
              </Select>
            </div>
          </Col>
          <Col xl={{ span: 12 }}>
            <div className="my-registrations-list__right-content">{renderContent()}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

MyRegistrationsList.propTypes = {
  PersonId: PropTypes.string,
  headerTitle: PropTypes.string.isRequired,
  listMode: PropTypes.oneOf(Object.values(REGISTRATION_LIST_MODE)).isRequired,
};

MyRegistrationsList.defaultProps = {
  PersonId: null,
};

export default MyRegistrationsList;
