import React from "react";
import each from "lodash/each";
import find from "lodash/find";
import first from "lodash/first";
import get from "lodash/get";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import { SELECT_FIELD_MODES } from "../constants/fieldConstants";
import Select from "../components/FormikAntD/Select";

export function parseFormFieldChoicesAsOptions(choices, mode = SELECT_FIELD_MODES.default) {
  const options = [];

  if (isEmpty(choices)) {
    return options;
  }

  each(choices, choice => {
    if (!isString(choice)) {
      options.push(choice);

      return;
    }

    // Check if we are in configuration mode.
    if (mode === "tags") {
      options.push({ value: choice, label: choice });

      return;
    }

    const choiceParts = choice.trim().split(":");
    const [value, label] = choiceParts;

    let optionValue = "";
    let optionTitle = "";

    // Check for invalid value.
    if (!value.trim() || value === ":") {
      return;
    }

    if (choiceParts.length === 2) {
      optionValue = value.trim();
      optionTitle = label.trim();
    } else {
      optionValue = value.trim();
      optionTitle = value.trim();
    }

    options.push({ value: optionValue, label: optionTitle });
  });

  return options;
}

export function getOptionLabelFromValue(optionValue, choices) {
  const producedOptions = parseFormFieldChoicesAsOptions(choices);
  const option = find(producedOptions, { value: optionValue });

  return get(option, "label", null);
}

export function filterOption(input, option) {
  const { title } = option.props;

  if (!isString(input) || !isString(title)) {
    return false;
  }

  return title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

/**
 * Note: when an option has a `label` value which is a React node, you should always include an additional `title`
 * attribute for the option which has a string value that represents the title/label of the option, so that the search
 * functionality still works for this option. When an option has a `label` value which is a string, there is no need to
 * include a `title` attribute, as the `label` value will automatically be used as the `title` value. The search
 * functionality will always operate based on the `title` value, as seen above in the `filterOption` function.
 */
function renderSelectOptions(selectOptions) {
  return selectOptions.map((option, index) => {
    const key = option.value + index;
    const title = option.title ? option.title : option.label;

    if (!isString(title)) {
      console.error(`Select option with value "${option.value}" is missing a title attribute which is a string.`);
    }

    return (
      <Select.Option key={key} value={option.value} disabled={!!option.disabled} title={title}>
        {option.label}
      </Select.Option>
    );
  });
}

export function renderOptions(selectOptions) {
  const renderOptionGroups = has(first(selectOptions), "children");

  if (!renderOptionGroups) {
    return renderSelectOptions(selectOptions);
  }

  return selectOptions.map(optionGroup => {
    return (
      <Select.OptGroup key={optionGroup.key} label={optionGroup.label}>
        {renderSelectOptions(optionGroup.children)}
      </Select.OptGroup>
    );
  });
}
