import React from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import isEqual from "react-fast-compare";
import debounce from "lodash/debounce";

class FormikPersist extends React.Component {
  saveForm = debounce(data => {
    const { isSessionStorage, uuid, prefix } = this.props;
    if (isSessionStorage) {
      window.sessionStorage.setItem(prefix + uuid, JSON.stringify(data));
    } else {
      window.localStorage.setItem(prefix + uuid, JSON.stringify(data));
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, this.props.debounce);

  componentDidMount = () => {
    const { setFormikState, isSessionStorage, uuid, formik, prefix } = this.props;
    const state = isSessionStorage
      ? window.sessionStorage.getItem(prefix + uuid)
      : window.localStorage.getItem(prefix + uuid);
    if (setFormikState && state && state !== null) {
      formik.setFormikState(JSON.parse(state));
    }
  };

  componentDidUpdate = prevProps => {
    const { formik } = this.props;
    if (!isEqual(prevProps.formik, formik)) {
      this.saveForm(formik);
    }
  };

  render() {
    return null;
  }
}

FormikPersist.propTypes = {
  formik: PropTypes.object.isRequired,
  uuid: PropTypes.string.isRequired,
  isSessionStorage: PropTypes.bool,
  setFormikState: PropTypes.bool,
  debounce: PropTypes.number,
  prefix: PropTypes.string,
};

FormikPersist.defaultProps = {
  isSessionStorage: false,
  prefix: "_formik-persist-",
  setFormikState: true,
  debounce: 300,
};

export default connect(FormikPersist);
