import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Icon, Typography } from "antd";
import classnames from "classnames";
import gql from "fraql";
import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../../../StatusText";
import { getTeamConditionStatusMessage } from "./teamConditionsUtils";
import "./TeamConditions.scss";

const GET_TEAM_CONDITION_STATUSES = gql`
  query TeamConditions_GetTeamConditionStatuses($teamId: ID!, $regId: ID!) {
    getTeamConditionsStatuses(TeamId: $teamId, RegId: $regId) {
      teamCondition
      isSatisfied
      currentCount
    }
  }
`;

const GET_TEAM_CONDITION_ROLES = gql`
  query TeamConditions_GetTeamConditionRoles($roleIds: [String!]!) {
    Role(where: { id: { _in: $roleIds } }) {
      id
      name
    }
  }
`;

function TeamConditions({ teamId, regId }) {
  const { loading: teamConditionsLoading, error: teamConditionsError, data: teamConditionsData } = useQuery(
    GET_TEAM_CONDITION_STATUSES,
    {
      variables: { teamId, regId },
      skip: isNil(teamId) || isNil(regId),
    },
  );

  const teamConditionRoleIds = useMemo(() => {
    if (teamConditionsLoading && !teamConditionsData) {
      return [];
    }

    return get(teamConditionsData, "getTeamConditionsStatuses", [])
      .map(conditionStatus => get(conditionStatus, "teamCondition.roleId"))
      .filter(roleId => !isNil(roleId));
  }, [teamConditionsLoading, teamConditionsData]);

  const { loading: teamConditionRolesLoading, error: teamConditionRolesError, data: teamConditionRolesData } = useQuery(
    GET_TEAM_CONDITION_ROLES,
    {
      variables: { roleIds: teamConditionRoleIds },
      skip: isEmpty(teamConditionRoleIds),
    },
  );

  // Normalise teamConditionRoles since it's possible that there are no team conditions with roles.
  const teamConditionRoles = useMemo(() => get(teamConditionRolesData, "Role", []), [teamConditionRolesData]);

  const conditionStatuses = get(teamConditionsData, "getTeamConditionsStatuses", []);

  const allConditionsSatisfied = isNil(
    find(conditionStatuses, conditionStatus => get(conditionStatus, "isSatisfied") === false),
  );

  const loading =
    (teamConditionsLoading && !teamConditionsData) || (teamConditionRolesLoading && !teamConditionRolesData);

  const className = classnames({
    "team-conditions-alert": true,
    "team-conditions-alert--warning": !loading && !allConditionsSatisfied,
    "team-conditions-alert--success": !loading && allConditionsSatisfied,
  });

  function renderTeamConditionsAlert(description) {
    return (
      <Alert
        className={className}
        type="info"
        message={<Typography.Text strong>Team conditions</Typography.Text>}
        description={description}
      />
    );
  }

  if (loading) {
    return renderTeamConditionsAlert(
      <div className="team-condition team-condition--default">
        <Icon type="clock-circle" /> <StatusText status={STATUS_TEXT_STATUS_OPTIONS.DEFAULT}>Loading...</StatusText>
      </div>,
    );
  }

  if (teamConditionsError || teamConditionRolesError) {
    return (
      <Alert
        message="Team conditions failed to load"
        description="Sorry, there was an issue loading this data."
        type="error"
        showIcon
        style={{ marginBottom: 24 }}
      />
    );
  }

  return renderTeamConditionsAlert(
    <>
      {conditionStatuses.map((conditionStatus, index) => {
        return getTeamConditionStatusMessage(conditionStatus, index, teamConditionRoles);
      })}
    </>,
  );
}

TeamConditions.propTypes = {
  teamId: PropTypes.string,
  regId: PropTypes.string,
};

TeamConditions.defaultProps = {
  teamId: null,
  regId: null,
};

export default TeamConditions;
