import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import FormField from "./FormFields/FormField";

function ActionFormField({
  name,
  meta,
  submitCount,
  builder,
  showFormFieldContentAsPreview,
  relatedRecordsData,
  readOnly,
}) {
  const type = get(meta, "type", null);

  return (
    <FormField
      name={name}
      type={type}
      meta={meta}
      submitCount={submitCount}
      builder={builder}
      showFormFieldContentAsPreview={showFormFieldContentAsPreview}
      relatedRecordsData={relatedRecordsData}
      readOnly={readOnly}
    />
  );
}

ActionFormField.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  submitCount: PropTypes.number,
  builder: PropTypes.bool,
  showFormFieldContentAsPreview: PropTypes.bool,
  relatedRecordsData: PropTypes.object,
  readOnly: PropTypes.bool,
};

ActionFormField.defaultProps = {
  submitCount: 0,
  builder: false,
  showFormFieldContentAsPreview: false,
  relatedRecordsData: null,
  readOnly: false,
};

export default ActionFormField;
