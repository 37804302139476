import moment from "moment-timezone";
import isNil from "lodash/isNil";
import {
  DATE_TIME_COMPARABLE_FORMAT,
  DATE_TIME_INPUT_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
} from "../constants/dateTimeConstants";

// parseDateTime can be used to parse 'date','time' or 'date time' string and returns a moment object.
// in case of no explicit format passed as parameters we have 2 parsing options set
// DATE_TIME_FORMAT
// In the past, FormFieldDatetime saved values according to the user's local timezone, but without including
// timezone information, e.g. "2020-05-29 11:56 am". We have no real way to know the 'real' date/time value
// with the timezone information being missing, so instead we just pretend it's in UTC so that we can at least
// treat them consistently.

// DATE_TIME_INPUT_FORMAT
// Handle the current format for how date/time values are saved by FormFieldDatetime, which does include timezone
// information, e.g. "2020-05-29 11:57:00 +10:00".

// it can implicitly handle ISO 8601 format YYYY-MM-DDThh:mm:ss.sTZD (eg 2020-07-16T19:20:30.45+01:00)
export function parseDateTime(dateTime, format = [DATE_TIME_INPUT_FORMAT, DATE_TIME_FORMAT]) {
  return moment.parseZone(dateTime, format);
}

function getComparableDate(dateTime, format) {
  if (format === DATE_TIME_FORMAT) {
    return parseDateTime(dateTime, format).format(DATE_TIME_COMPARABLE_FORMAT);
  }

  if (format === TIME_FORMAT) {
    return parseDateTime(dateTime, format);
  }

  return dateTime;
}

export function isSameOrBefore(dateTime, maxDateTime, format) {
  return parseDateTime(dateTime, format).isSameOrBefore(getComparableDate(maxDateTime, format));
}

export function isSameOrAfter(dateTime, minDateTime, format) {
  return parseDateTime(dateTime, format).isSameOrAfter(getComparableDate(minDateTime, format));
}

export function isBefore(dateTime, maxDateTime, format) {
  return parseDateTime(dateTime, format).isBefore(getComparableDate(maxDateTime, format));
}

export function isAfter(dateTime, minDateTime, format) {
  return parseDateTime(dateTime, format).isAfter(getComparableDate(minDateTime, format));
}

export function hasTimezoneAbbreviation(dateTimeWithTZ) {
  if (isNil(dateTimeWithTZ)) {
    return false;
  }
  return !Number.isInteger(Number.parseInt(dateTimeWithTZ.format("z"), 10));
}
