import get from "lodash/get";
import isBlank from "./isBlank";
import useUpdateEffect from "./useUpdateEffect";

function useClearFieldValueWhenDependentOnFieldValueChanges({
  fieldName,
  dependentOnFieldName,
  formik: { values, setFieldValue },
}) {
  const dependentOnFieldValue = get(values, dependentOnFieldName, null);

  useUpdateEffect(() => {
    // Reset this field's value whenever the dependentOnFieldName's value changes and it's not blank
    if (!isBlank(dependentOnFieldValue)) {
      setFieldValue(fieldName, null);
    }
  }, [dependentOnFieldValue]);
}

export default useClearFieldValueWhenDependentOnFieldValueChanges;
