import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import get from "lodash/get";
import FormItem from "../FormItem";
import "./FormFieldFormItem.scss";

function FormFieldFormItem({
  name,
  meta,
  submitCount,
  className,
  displayDefaultLabel,
  displayForInput,
  style,
  children,
}) {
  const helpText = get(meta, "helpText", "");
  const required = get(meta, "required", false);
  const validate = get(meta, "validate", undefined);

  let label = get(meta, "label", "");

  if (!label && displayDefaultLabel) {
    label = "[No label]";
  }

  const combinedClassName = classnames(className, {
    "form-field-form-item--non-input": !displayForInput,
  });

  return (
    <FormItem
      name={name}
      label={label}
      required={required}
      help={helpText}
      style={style}
      submitCount={submitCount}
      validate={validate}
      className={combinedClassName}
      showValidateError={displayForInput}
    >
      {children}
    </FormItem>
  );
}

FormFieldFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired,
  displayDefaultLabel: PropTypes.bool,
  displayForInput: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

FormFieldFormItem.defaultProps = {
  displayDefaultLabel: true,
  displayForInput: true,
  className: "",
  style: null,
};

export default FormFieldFormItem;
