import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { Alert, message } from "antd";
import gql from "fraql";
import SpinPageContent from "../SpinPageContent";
import { GET_PERSON_REG_SUBMISSION } from "./selfRegistrationGql";

const CREATE_REG_SUBMISSION = gql`
  mutation RegistrationUserSubmitCreate_createRegSubmissions($regSubmissions: [RegSubmissionInput!]!) {
    createRegSubmissions(regSubmissions: $regSubmissions) {
      created {
        id
        RegId
        PersonId
      }
    }
  }
`;

function RegistrationUserSubmitCreate({ RegId, PersonId }) {
  const [createRegSubmission, { error: regSubmissionError }] = useMutation(CREATE_REG_SUBMISSION, {
    refetchQueries: [{ query: GET_PERSON_REG_SUBMISSION, variables: { regId: RegId, personId: PersonId } }],
    awaitRefetchQueries: true,
  });

  useEffect(
    () => {
      const initialiseRegistrationSubmission = async () => {
        try {
          await createRegSubmission({
            variables: {
              regSubmissions: [
                {
                  RegId,
                  PersonId,
                },
              ],
            },
          });

          message.success("Registration submission started.");
        } catch (submitError) {
          console.error(submitError);
          message.error("Failed to create registration submission.");
        }
      };

      initialiseRegistrationSubmission();
    },
    // TODO: look into this dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (regSubmissionError) {
    return (
      <div style={{ padding: 24 }}>
        <Alert
          message="Failed to create registration submission"
          description="Sorry, there was an issue creating your registration submission."
          type="error"
          showIcon
        />
      </div>
    );
  }

  return <SpinPageContent style={{ height: "100%" }} />;
}

RegistrationUserSubmitCreate.propTypes = {
  RegId: PropTypes.string.isRequired,
  PersonId: PropTypes.string.isRequired,
};

export default RegistrationUserSubmitCreate;
