import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import { connect } from "formik";
import gql from "fraql";
import find from "lodash/find";
import get from "lodash/get";
import omit from "lodash/omit";
import { NO_ACCESS_TITLE } from "../../constants/labelConstants";
import isBlank from "../../utils/isBlank";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../StatusText";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";

const GET_TEAMS_FOR_COMPETITION = gql`
  query FormFieldTeam_GetTeams($CompetitionId: String!) {
    Team(where: { CompetitionId: { _eq: $CompetitionId } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;

function FormFieldTeam(props) {
  const {
    meta,
    name,
    disabled,
    hideOnEmpty,
    hideOnLoading,
    formik: { values },
    query,
    queryOptions,
  } = props;

  const { loading: teamsLoading, error: teamsError, data: teamsData } = useQuery(query, queryOptions);

  const fieldValue = get(values, name, undefined);

  const teamOptions = useMemo(() => {
    const teams = get(teamsData, "Team", []).map(team => ({
      value: team.id,
      label: team.name,
    }));

    if (!isBlank(fieldValue) && !find(teams, { value: fieldValue })) {
      teams.unshift({
        value: fieldValue,
        label: <StatusText status={STATUS_TEXT_STATUS_OPTIONS.WARNING}>{NO_ACCESS_TITLE}</StatusText>,
        title: NO_ACCESS_TITLE,
      });
    }

    return teams;
  }, [teamsData, fieldValue]);

  if (teamsLoading && !teamsData) {
    if (hideOnLoading) {
      return null;
    }

    return (
      <FormFieldFormItem {...props} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (teamsError) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayForInput={false}>
        <Alert message="Teams failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  if (hideOnEmpty && teamOptions.length === 0) {
    return null;
  }

  return (
    <FormFieldSelect
      {...omit(props, ["formik"])}
      disabled={disabled || teamsLoading}
      loading={teamsLoading}
      meta={{ ...meta, options: teamOptions }}
    />
  );
}

FormFieldTeam.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
    allowClear: PropTypes.bool,
    required: PropTypes.bool,
    helpText: PropTypes.node,
    displayDefaultLabel: PropTypes.bool,
    displayMode: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  hideOnEmpty: PropTypes.bool,
  hideOnLoading: PropTypes.bool,
  formik: PropTypes.shape({
    values: PropTypes.object,
  }).isRequired,
  readOnly: PropTypes.bool,
  query: PropTypes.any,
  queryOptions: PropTypes.object,
};

FormFieldTeam.defaultProps = {
  readOnly: false,
  query: GET_TEAMS_FOR_COMPETITION,
  queryOptions: {},
  hideOnEmpty: false,
  hideOnLoading: false,
};

export default connect(FormFieldTeam);
