import React from "react";
import PropTypes from "prop-types";
import isBlank from "../../utils/isBlank";

export function getFullName(firstName, lastName) {
  if (isBlank(firstName) && isBlank(lastName)) {
    return "Unknown Name";
  }

  const nameParts = [];

  if (lastName) {
    nameParts.push(lastName);
  }

  if (firstName) {
    nameParts.push(firstName);
  }

  return nameParts.join(", ");
}

function PersonName({ firstName, lastName }) {
  return <span>{getFullName(firstName, lastName)}</span>;
}

PersonName.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

PersonName.defaultProps = {
  firstName: null,
  lastName: null,
};

export default PersonName;
