import React from "react";
import { Alert } from "antd";

export default function RegistrationLoadingError() {
  return (
    <div style={{ padding: 24 }}>
      <Alert
        message="Registration failed to load"
        description="Sorry, there was an issue loading the data for this page."
        type="error"
        showIcon
      />
    </div>
  );
}
