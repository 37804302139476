import * as yup from "yup";
import isNull from "lodash/isNull";
import isBlank from "./isBlank";
import { PHONE_ALLOWED_CHARACTERS_REGEX } from "./phoneUtils";

export function schemaNullable(fieldSchema) {
  return fieldSchema
    .default(null)
    .nullable(true)
    .transform((currentVal, originalVal) => {
      if (originalVal === "") {
        return null;
      }

      return currentVal;
    });
}

/**
 * Transform blank values into `undefined` so that `yup.default()` will be used for *any* blank values. As per the
 * documentation at https://github.com/jquense/yup#mixeddefaultvalue-any-schema, `yup.default()` "sets a default value
 * to use when the value is undefined".
 *
 * If the `fieldSchema` passed to this function uses `yup.string()`, you should also include `yup.trim()` to
 * automatically trim whitespace characters from the input value.
 *
 * @param fieldSchema Base schema to use. This schema is expected to include a `.default(...)`.
 * @returns {*}
 */
export function schemaTransformBlankToUndefined(fieldSchema) {
  return fieldSchema.transform(currentVal => {
    if (isBlank(currentVal)) {
      return undefined;
    }

    return currentVal;
  });
}

/**
 * Transform blank values to `null`. Sometimes required on the back-end.
 * @param {*} fieldSchema
 * @returns
 */
export function schemaTransformBlankToNull(fieldSchema) {
  return fieldSchema.transform(currentVal => {
    if (isBlank(currentVal)) {
      return null;
    }

    return currentVal;
  });
}

const PHONE_VALIDATION_REGEX = /^\+\d{5,30}$/;

export function schemaPhone() {
  return yup
    .string()
    .trim()
    .nullable(true)
    .default("")
    .transform(currentVal => {
      if (isNull(currentVal)) {
        return "";
      }

      return currentVal.replace(PHONE_ALLOWED_CHARACTERS_REGEX, "");
    })
    .test("phoneWithMinDigits", "Please enter a valid phone number.", function checkPhoneWithMinDigits(value) {
      if (!value) {
        return true;
      }

      return PHONE_VALIDATION_REGEX.test(value);
    });
}
