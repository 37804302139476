import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import { TEAM_QUALIFICATION_SCORE_DETERMINATION_LABEL } from "../../../../../constants/competitionConstants";

function TeamQualificationScoreCalculated({ teamQualificationScoreDetermination }) {
  return (
    <Alert type="info" message={TEAM_QUALIFICATION_SCORE_DETERMINATION_LABEL[teamQualificationScoreDetermination]} />
  );
}

TeamQualificationScoreCalculated.propTypes = {
  teamQualificationScoreDetermination: PropTypes.string.isRequired,
};

export default TeamQualificationScoreCalculated;
