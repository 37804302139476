import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Icon } from "antd";
import { connect } from "formik";
import gql from "fraql";
import get from "lodash/get";
import { FIELD_TYPES_VALID_FOR_CONDITION } from "../../../constants/conditionConstants";
import { FIELD_TYPES, SELECT_FIELD_MODES } from "../../../constants/fieldConstants";
import { getConditionFieldValueComponent } from "../../../utils/formConditionUtils";
import FormFieldField from "../../FormFields/FormFieldField";
import FormFieldGroup, { DISPLAY_MODES as FORM_FIELD_GROUP_DISPLAY_MODES } from "../../FormFields/FormFieldGroup";
import FormFieldRole, { DISPLAY_MODES as FORM_FIELD_ROLE_DISPLAY_MODES } from "../../FormFields/FormFieldRole";
import FormFieldSelect from "../../FormFields/FormFieldSelect";
import FormFieldSport, { DISPLAY_MODES as FORM_FIELD_SPORT_DISPLAY_MODES } from "../../FormFields/FormFieldSport";
import FormFieldText from "../../FormFields/FormFieldText";

const GET_FIELD_LIST = gql`
  query RegistrationCondition_GetFieldList($where: Field_bool_exp) {
    Field(where: $where, order_by: { name: asc }) {
      id
      name
      type
      meta
    }
  }
`;

export const CONDITION_TYPES = {
  selectedGroups: "selectedGroups",
  selectedRoles: "selectedRoles",
  selectedSports: "selectedSports",
  fieldValue: "fieldValue",
  ageRange: "ageRange",
  participationDelegation: "participationDelegation",
  participationRole: "participationRole",
};

const conditionTypeList = [
  { value: CONDITION_TYPES.selectedGroups, label: "Group membership" },
  { value: CONDITION_TYPES.selectedRoles, label: "Group membership role" },
  { value: CONDITION_TYPES.selectedSports, label: "Sport selected" },
  { value: CONDITION_TYPES.fieldValue, label: "Custom field value" },
  { value: CONDITION_TYPES.ageRange, label: "Age requirement" },
  { value: CONDITION_TYPES.participationDelegation, label: "Participation delegation" },
  { value: CONDITION_TYPES.participationRole, label: "Participation role" },
];

const GET_FIELD_LIST_QUERY_OPTIONS = {
  variables: {
    where: { type: { _in: FIELD_TYPES_VALID_FOR_CONDITION }, archived: { _eq: false } },
  },
};

function RegistrationCondition(props) {
  const {
    index,
    stepIndex,
    condition,
    submitCount,
    handleClickRemoveCondition,
    handleChangeConditionType,
    formik: { setFieldValue },
  } = props;

  const { data: fieldsData } = useQuery(GET_FIELD_LIST, GET_FIELD_LIST_QUERY_OPTIONS);

  const fields = useMemo(() => {
    return get(fieldsData, "Field", []);
  }, [fieldsData]);

  const namePrefix = useMemo(() => `Steps.${stepIndex}.meta.conditions.${index}`, [stepIndex, index]);
  const nameFieldType = useMemo(() => `${namePrefix}.fieldType`, [namePrefix]);
  const nameFieldValue = useMemo(() => `${namePrefix}.fieldValue`, [namePrefix]);

  const handleChangeField = useCallback(
    fieldId => {
      if (!fieldId) {
        setFieldValue(nameFieldType, null);
        setFieldValue(nameFieldValue, undefined);

        return;
      }

      const selectedField = fields.find(field => fieldId === field.id);

      if (!selectedField) {
        throw new Error(`Unknown field: ${selectedField}`);
      }

      const newFieldValue = selectedField.type === FIELD_TYPES.bool ? false : undefined;

      setFieldValue(nameFieldType, selectedField.type);
      setFieldValue(nameFieldValue, newFieldValue);
    },
    [setFieldValue, nameFieldType, nameFieldValue, fields],
  );

  const getChoices = useCallback(
    fieldId => {
      const field = fields.find(fieldItem => fieldItem.id === fieldId);

      return get(field, "meta.choices", []);
    },
    [fields],
  );

  const conditionFieldValueComponent = useMemo(
    () => getConditionFieldValueComponent(condition, nameFieldValue, submitCount, getChoices, false),
    [condition, nameFieldValue, submitCount, getChoices],
  );

  function renderConditionOptions() {
    switch (condition.type) {
      case CONDITION_TYPES.selectedRoles:
      case CONDITION_TYPES.participationRole: {
        return (
          <FormFieldRole
            name={`Steps.${stepIndex}.meta.conditions.${index}.roles`}
            meta={{
              displayDefaultLabel: false,
              placeholder: "Select a role",
              displayMode: FORM_FIELD_ROLE_DISPLAY_MODES.select,
              mode: SELECT_FIELD_MODES.multiple,
            }}
            submitCount={submitCount}
          />
        );
      }
      case CONDITION_TYPES.selectedGroups:
      case CONDITION_TYPES.participationDelegation: {
        return (
          <FormFieldGroup
            name={`Steps.${stepIndex}.meta.conditions.${index}.groups`}
            meta={{
              displayDefaultLabel: false,
              multiple: true,
              displayMode: FORM_FIELD_GROUP_DISPLAY_MODES.treeSelect,
              placeholder: "Select a group",
            }}
            submitCount={submitCount}
          />
        );
      }
      case CONDITION_TYPES.selectedSports: {
        return (
          <FormFieldSport
            name={`Steps.${stepIndex}.meta.conditions.${index}.sports`}
            meta={{
              displayDefaultLabel: false,
              displayMode: FORM_FIELD_SPORT_DISPLAY_MODES.select,
              mode: SELECT_FIELD_MODES.multiple,
              placeholder: "Select a sport",
            }}
            submitCount={submitCount}
          />
        );
      }
      case CONDITION_TYPES.fieldValue: {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%", marginRight: 12 }}>
              <FormFieldField
                name={`Steps.${stepIndex}.meta.conditions.${index}.field`}
                meta={{
                  displayDefaultLabel: false,
                  placeholder: "Select a field",
                  handleSelectChange: handleChangeField,
                }}
                submitCount={submitCount}
                query={GET_FIELD_LIST}
                queryOptions={GET_FIELD_LIST_QUERY_OPTIONS}
              />
            </div>

            <div style={{ width: "50%" }}>{conditionFieldValueComponent}</div>
          </div>
        );
      }
      case CONDITION_TYPES.ageRange: {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%", marginRight: 12 }}>
              <FormFieldText
                name={`Steps.${stepIndex}.meta.conditions.${index}.minAge`}
                meta={{ displayDefaultLabel: false, placeholder: "Minimum age" }}
                submitCount={submitCount}
              />
            </div>

            <div style={{ width: "50%" }}>
              <FormFieldText
                name={`Steps.${stepIndex}.meta.conditions.${index}.maxAge`}
                meta={{ displayDefaultLabel: false, placeholder: "Maximum age" }}
                submitCount={submitCount}
              />
            </div>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  }

  function renderConditionAlert() {
    let message = null;

    if (condition.type === CONDITION_TYPES.selectedRoles) {
      message = (
        <span>
          Show this step if the person is a member of a group with <i>any</i> of the roles which are selected in this
          condition (with the membership being for <i>any</i> group).
        </span>
      );
    } else if (condition.type === CONDITION_TYPES.selectedGroups) {
      message = (
        <span>
          Show this step if the person is a member of <i>any</i> of the groups which are selected in this condition
          (with the membership being for <i>any</i> role).
        </span>
      );
    } else if (condition.type === CONDITION_TYPES.selectedSports) {
      message = (
        <span>
          Show this step if the person has filled out a Sports step and selected <i>any</i> of the sports which are
          selected in this condition.
        </span>
      );
    } else if (condition.type === CONDITION_TYPES.fieldValue) {
      message = (
        <span>
          Show this step if the person has filled out a form that contains the selected Custom Field, and the value the
          person entered/selected matches the value specified in this condition.
        </span>
      );
    } else if (condition.type === CONDITION_TYPES.ageRange) {
      message = (
        <span>
          Show this step if the person&rsquo;s age is within the age range specified in this condition, based on the
          date of birth provided in the person&rsquo;s profile. The person&rsquo;s age is calculated based on how old
          they would be on the date specified in the registration&rsquo;s &ldquo;Age as at date&rdquo; field. If this
          field is empty, the person&rsquo;s age is calculated based on the current date at the time of filling out the
          registration.
        </span>
      );
    }

    if (!message) {
      return null;
    }

    return (
      <div style={{ marginTop: 12 }}>
        <Alert type="info" message={message} showIcon />
      </div>
    );
  }

  return (
    <div style={{ padding: 12, borderBottom: "1px solid #d9d9d9", width: "100%" }}>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ marginRight: 12 }}>
          <FormFieldSelect
            name={`Steps.${stepIndex}.meta.conditions.${index}.type`}
            meta={{
              placeholder: "Select a type",
              style: { minWidth: 200 },
              handleSelectChange: handleChangeConditionType,
              options: conditionTypeList,
              displayDefaultLabel: false,
            }}
            submitCount={submitCount}
          />
        </div>

        <div style={{ flexGrow: 1, display: "flex" }}>
          <div style={{ flexGrow: 1 }}>{renderConditionOptions()}</div>

          <Icon
            type="close"
            style={{ fontSize: 18, marginLeft: 5, cursor: "pointer", height: 32, padding: "7px 0 7px 7px" }}
            onClick={handleClickRemoveCondition}
          />
        </div>
      </div>

      {renderConditionAlert()}
    </div>
  );
}

RegistrationCondition.propTypes = {
  index: PropTypes.number.isRequired,
  stepIndex: PropTypes.number.isRequired,
  condition: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired,
  handleClickRemoveCondition: PropTypes.func.isRequired,
  handleChangeConditionType: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
};

export default connect(RegistrationCondition);
