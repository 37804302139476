import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Avatar, Button, message, Spin, Upload } from "antd";
import classnames from "classnames";
import gql from "fraql";
import get from "lodash/get";
import includes from "lodash/includes";
import { IMAGE_TYPES } from "../../constants/fileConstants";
import { getAcceptOptions } from "../../utils/fileUtils";
import useCurrentUser from "../../utils/useCurrentUser";
import useFileUploader from "../../utils/useFileUploader";
import "./AvatarUpload.scss";

const IMAGE_ACCEPT_OPTIONS = getAcceptOptions(IMAGE_TYPES);
const ACCEPT = IMAGE_ACCEPT_OPTIONS.join(",");

function handleBeforeUpload(file) {
  return includes(IMAGE_ACCEPT_OPTIONS, file.type);
}

const PERSON_UPDATE = gql`
  mutation AvatarUpload_PersonUpdate($PersonId: ID!, $AvatarFileId: ID!) {
    updateAvatar(PersonId: $PersonId, AvatarFileId: $AvatarFileId) {
      returning {
        id
        AvatarFile {
          id
          url
        }
      }
    }
  }
`;

const PERSON_AVATAR_FILE_URL = gql`
  query AvatarUpload_PersonAvatarFileUrl($PersonId: String!) {
    Person_by_pk(id: $PersonId) {
      id
      AvatarFile {
        id
        url
      }
    }
  }
`;

const DELETE_FILE = gql`
  mutation AvatarUpload_DeleteFile($FileId: String!) {
    update_File_by_pk(_set: { deleted: true }, pk_columns: { id: $FileId }) {
      id
    }
  }
`;

function AvatarUpload({ PersonId, size, shape, disableUpload }) {
  const currentUser = useCurrentUser();

  const [personUpdate] = useMutation(PERSON_UPDATE);
  const [deleteFile] = useMutation(DELETE_FILE);

  const { uploader } = useFileUploader({ PersonId });

  const [updateAvatarLoading, setUpdateAvatarLoading] = useState(false);

  const {
    loading: personAvatarFileUrlLoading,
    error: personAvatarFileUrlError,
    data: personAvatarFileUrlData,
  } = useQuery(PERSON_AVATAR_FILE_URL, {
    variables: { PersonId },
  });

  const avatarFileUrl = useMemo(() => get(personAvatarFileUrlData, "Person_by_pk.AvatarFile.url", null), [
    personAvatarFileUrlData,
  ]);

  const oldPhotoFileId = useMemo(() => get(personAvatarFileUrlData, "Person_by_pk.AvatarFile.id", null), [
    personAvatarFileUrlData,
  ]);

  const handleUpdate = useCallback(
    async ({ file }) => {
      try {
        setUpdateAvatarLoading(true);

        const uploadFileData = await uploader(file, true);

        await personUpdate({
          variables: { PersonId, AvatarFileId: uploadFileData.id },
        });

        if (oldPhotoFileId) {
          deleteFile({ variables: { FileId: oldPhotoFileId } }).catch(deleteFileError => {
            console.error(deleteFileError);
          });
        }

        message.success("Profile picture updated.");

        setUpdateAvatarLoading(false);
      } catch (error) {
        console.error(error);

        setUpdateAvatarLoading(false);

        message.error("Failed to update profile picture.");

        Sentry.captureException(error);
      }
    },
    [PersonId, setUpdateAvatarLoading, uploader, personUpdate, deleteFile, oldPhotoFileId],
  );

  const className = classnames("avatar-upload", {
    "avatar-upload--circle": shape === "circle",
  });

  if ((personAvatarFileUrlLoading && !personAvatarFileUrlData) || !currentUser) {
    return (
      <div className={className}>
        <Avatar className="avatar-upload-image" icon="user" src={avatarFileUrl} shape={shape} size={size} />

        <Spin />
      </div>
    );
  }

  if (personAvatarFileUrlError) {
    return null;
  }
  return (
    <div className={className}>
      <Avatar className="avatar-upload-image" icon="user" src={avatarFileUrl} shape={shape} size={size} />

      {!disableUpload && (
        <Upload
          accept={ACCEPT}
          beforeUpload={handleBeforeUpload}
          customRequest={handleUpdate}
          disabled={updateAvatarLoading}
          showUploadList={false}
        >
          <Button className="avatar-upload-upload" size="large" icon="upload" type="link" />
        </Upload>
      )}

      {updateAvatarLoading && <Spin />}
    </div>
  );
}

AvatarUpload.propTypes = {
  PersonId: PropTypes.string.isRequired,
  size: PropTypes.number,
  shape: PropTypes.string,
  disableUpload: PropTypes.bool,
};

AvatarUpload.defaultProps = {
  size: 80,
  shape: "circle",
  disableUpload: false,
};

export default AvatarUpload;
