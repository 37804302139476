export const Person = `
  Person(where: $wherePerson, order_by: $orderPerson, limit: $limit) {
    id
    firstName
    lastName
    email
    phone
  }
`;

export const Field = `
  Field(where: $whereField, order_by: $orderField, limit: $limit) {
    id
    name
  }
`;

export const Role = `
  Role(where: $whereRole, order_by: $orderRole, limit: $limit) {
    id
    name
  }
`;
// TODO: Whilst this does "appear" in the search bar, the search function does not work.
// Review and find out why the search feature does not work.
export const Country = `
  Country(where: $whereCountry, order_by: $orderCountry, limit: $limit) {
    id
    name
  }
`;

export const GroupType = `
  GroupType(where: $whereGroupType, order_by: $orderGroupType, limit: $limit) {
    id
    name
  }
`;

export const Group = `
  Group(where: $whereGroup, order_by: $orderGroup, limit: $limit) {
    id
    name
  }
`;

export const Sport = `
  Sport(where: $whereSport, order_by: $orderSport, limit: $limit) {
    id
    name
  }
`;

export const Format = `
  Format(where: $whereFormat, order_by: $orderFormat, limit: $limit) {
    id
    name
  }
`;

export const ScoreType = `
 ScoreType(where: $whereScoreType, order_by: $orderScoreType, limit: $limit) {
    id
    name
  }
`;

export const Form = `
  Form(where: $whereForm, order_by: $orderForm, limit: $limit) {
    id
    name
  }
`;

export const Reg = `
  Reg(where: $whereReg, order_by: $orderReg, limit: $limit) {
    id
    name
  }
`;

export const ResultStatus = `
  ResultStatus(where: $whereResultStatus, order_by: $orderResultStatus, limit: $limit) {
    id
    name
  }
`;
