import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
// eslint-disable-next-line import/order
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";
// eslint-disable-next-line import/order
import localforage from "localforage";
import ENV from "./constants/envConstants";
import isBlank from "./utils/isBlank";
import ApolloProviderWrapper from "./components/ApolloProviderWrapper";
import App from "./components/App";
import { Auth0Provider } from "./components/Auth0";
import { GroupPickerProvider } from "./components/GroupPickerContext";
import { getUserConfirmation } from "./components/PromptDirtyForm";
import { TenantPickerProvider } from "./components/TenantPickerContext";
import { ThemeContextProvider } from "./components/Theme/ThemeContext";
import * as serviceWorker from "./serviceWorker";
import "./styles/config/ant.less";
import "./styles/styles.scss";
// eslint-disable-next-line import/order
import "./config/sentry";

localforage.config({
  name: "feact",
});

if (!isBlank(ENV.GA_MEASUREMENT_ID)) {
  ReactGA.initialize(ENV.GA_MEASUREMENT_ID);
  ReactGA.send("pageview");
}

const render = Component => {
  ReactDOM.render(
    <BrowserRouter getUserConfirmation={getUserConfirmation}>
      <Auth0Provider
        domain={ENV.AUTH0_DOMAIN}
        client_id={ENV.AUTH0_CLIENT_ID}
        audience={ENV.AUTH0_AUDIENCE}
        redirect_uri={window.location.origin}
        scope="openid email profile"
      >
        <ApolloProviderWrapper>
          <TenantPickerProvider>
            <GroupPickerProvider>
              <ThemeContextProvider>
                <Component />
              </ThemeContextProvider>
            </GroupPickerProvider>
          </TenantPickerProvider>
        </ApolloProviderWrapper>
      </Auth0Provider>
    </BrowserRouter>,
    document.getElementById("root"),
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./components/App", () => {
    // eslint-disable-next-line global-require
    const NextApp = require("./components/App").default;

    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
