import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import gql from "fraql";
import get from "lodash/get";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";
import getActiveArchivedOptions from "./getActiveArchivedOptions";

const GET_FORMS = gql`
  query FormFieldForm_GetForms {
    Form(order_by: { name: asc }) {
      id
      name
      archived
    }
  }
`;

function FormFieldForm(props) {
  const { meta, query, queryOptions } = props;

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);

  const { loading, error, data } = useQuery(query, queryOptions);

  const options = useMemo(() => getActiveArchivedOptions({ allRecords: get(data, "Form", []) }), [data]);

  if (loading && !data) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (error) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Forms failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  return <FormFieldSelect {...props} loading={loading} meta={{ ...meta, options }} />;
}

FormFieldForm.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
    helpText: PropTypes.node,
  }).isRequired,
  query: PropTypes.any,
  queryOptions: PropTypes.object,
};

FormFieldForm.defaultProps = {
  query: GET_FORMS,
  queryOptions: {},
};

export default FormFieldForm;
