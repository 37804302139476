import isNil from "lodash/isNil";

// See https://stackoverflow.com/a/17369384/1374827.
function getDecimalsCount(value) {
  if (isNil(value)) {
    return 0;
  }

  if (value % 1 !== 0) {
    return value.toString().split(".")[1].length;
  }

  return 0;
}

export default getDecimalsCount;
