import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Button, Spin } from "antd";
import { Link } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { REG_SUBMISSION_STATUSES } from "../../constants/regStatusConstants";
import * as ROUTES from "../../constants/routeConstants";
import useCurrentUser from "../../utils/useCurrentUser";
import { useAuth0 } from "../Auth0";
import { GET_PERSON_REG_SUBMISSION } from "./selfRegistrationGql";

function RegistrationUserDetailActions({ regId, buttonSize }) {
  const { loading: authLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const currentUser = useCurrentUser();
  const personId = get(currentUser, "personId", null);
  const skip = isNil(personId);

  const { loading: regSubmissionLoading, error: regSubmissionError, data: regSubmissionData } = useQuery(
    GET_PERSON_REG_SUBMISSION,
    {
      variables: { regId, personId },
      skip,
    },
  );

  const registrationSubmitUrl = `${ROUTES.USER_REGISTRATIONS}/${regId}/submit`;

  if (authLoading) {
    return <Spin size="small" style={{ marginTop: 5, marginBottom: 5 }} />;
  }

  function renderAuthenticatedRegisterButton() {
    if (skip) {
      return null;
    }

    if (regSubmissionLoading && !regSubmissionData) {
      return <Spin size="small" style={{ marginTop: 5, marginBottom: 5 }} />;
    }

    if (regSubmissionError) {
      return null;
    }

    const regSubmission = get(regSubmissionData, "RegSubmission.0", null);
    const hasExistingRegSubmission = !isEmpty(regSubmission);
    const regArchived = get(regSubmission, "Reg.archived", false);
    const submissionStatus = get(regSubmission, "status", null);
    const isDeregistered = submissionStatus === REG_SUBMISSION_STATUSES.deregistered;

    if (regArchived || isDeregistered) {
      return null;
    }

    let buttonText;
    let buttonIcon;

    if (hasExistingRegSubmission) {
      buttonText = "Continue Registration";
      buttonIcon = "right";
    } else {
      buttonText = "Start Registration";
      buttonIcon = "plus";
    }

    return (
      <Link style={{ color: "inherit", display: "block" }} to={registrationSubmitUrl}>
        <Button type="primary" icon={buttonIcon} size={buttonSize}>
          {buttonText}
        </Button>
      </Link>
    );
  }

  return (
    <>
      {isAuthenticated && renderAuthenticatedRegisterButton()}

      {!isAuthenticated && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() =>
              loginWithRedirect({
                appState: { targetUrl: window.location.pathname },
              })
            }
            size={buttonSize}
            style={{ marginRight: 12 }}
          >
            Log In
          </Button>

          <Button
            onClick={() =>
              loginWithRedirect({
                appState: { targetUrl: registrationSubmitUrl },
                screen_hint: "signup",
              })
            }
            type="primary"
            icon="plus"
            size={buttonSize}
          >
            Register
          </Button>
        </div>
      )}
    </>
  );
}

RegistrationUserDetailActions.propTypes = {
  regId: PropTypes.string.isRequired,
  buttonSize: PropTypes.string,
};

RegistrationUserDetailActions.defaultProps = {
  buttonSize: "default",
};

export default RegistrationUserDetailActions;
