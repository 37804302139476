import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import { message } from "antd";
import get from "lodash/get";
import TextEditorPreview from "../../../TextEditor/TextEditorPreview";
import FormBottomComponent from "../FormBottomComponent";

const RegistrationStepInfo = ({
  step,
  stepIndex,
  updateRegStepStatus,
  nextStep,
  prevStep,
  regIsReadOnly,
  showFormBottomComponent,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);

      await updateRegStepStatus(step);

      setIsSubmitting(false);

      nextStep(true);
    } catch (submitError) {
      setIsSubmitting(false);

      message.error("Failed to submit step.");
      console.error(submitError);

      Sentry.captureException(submitError);
    }
  }, [step, updateRegStepStatus, nextStep, setIsSubmitting]);

  const content = get(step, "meta.content", "");

  return (
    <>
      <TextEditorPreview defaultValue={content} />

      {showFormBottomComponent && (
        <FormBottomComponent
          step={step}
          stepIndex={stepIndex}
          prevStep={prevStep}
          nextStep={nextStep}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          disableSubmit={regIsReadOnly}
        />
      )}
    </>
  );
};

RegistrationStepInfo.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      content: PropTypes.string.isRequired,
    }),
  }).isRequired,
  stepIndex: PropTypes.number.isRequired,
  updateRegStepStatus: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  regIsReadOnly: PropTypes.bool.isRequired,
  showFormBottomComponent: PropTypes.bool.isRequired,
};

export default RegistrationStepInfo;
