import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import get from "lodash/get";
import { GENDER_TYPE, GENDER_TYPE_DISPLAY_LABEL } from "../../constants/personConstants";
import EmptyValueText from "../EmptyValueText";

export function getGenderLabel(gender, verbose, showUnknownExplicit) {
  let label = get(GENDER_TYPE_DISPLAY_LABEL, gender, "");

  if (!verbose) {
    if (label === GENDER_TYPE_DISPLAY_LABEL[GENDER_TYPE.male]) {
      label = "M";
    } else if (label === GENDER_TYPE_DISPLAY_LABEL[GENDER_TYPE.female]) {
      label = "F";
    }
  }

  if (!label) {
    if (showUnknownExplicit) {
      return <span>Unknown Gender</span>;
    }

    return <EmptyValueText />;
  }

  return <span>{label}</span>;
}

export function getGenderIcon(gender) {
  if (gender === GENDER_TYPE.male) {
    return (
      <span style={{ lineHeight: "17px" }}>
        <Icon type="man" style={{ marginRight: 5 }} />
      </span>
    );
  }

  if (gender === GENDER_TYPE.female) {
    return <Icon type="woman" style={{ marginRight: 5 }} />;
  }

  if (gender === GENDER_TYPE.x) {
    return <Icon type="user" style={{ marginRight: 5 }} />;
  }

  if (gender === GENDER_TYPE.prefer_not_to_answer) {
    return (
      <span
        style={{
          height: 17,
          display: "flex",
          alignItems: "center",
          marginRight: 5,
        }}
      >
        <Icon
          type="stop"
          style={{
            // Line the icon up nicely with the gender label text.
            marginTop: 2,
          }}
        />
      </span>
    );
  }

  return null;
}

function PersonGender({ gender, verbose, showUnknownExplicit }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
      }}
    >
      {getGenderIcon(gender)}
      {getGenderLabel(gender, verbose, showUnknownExplicit)}
    </div>
  );
}

PersonGender.propTypes = {
  gender: PropTypes.string,
  verbose: PropTypes.bool,
  showUnknownExplicit: PropTypes.bool,
};

PersonGender.defaultProps = {
  gender: null,
  verbose: true,
  showUnknownExplicit: false,
};

export default PersonGender;
