import includes from "lodash/includes";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { COMPETITION_GENDER_FORMAT, GENDER_TYPE } from "../constants/personConstants";
import { getAgeRequirementIsMetFromDob } from "./ageUtils";
import { parseAgeGroups, formatAgeGroups } from "./parseAgeGroups";

function checkGenderEligibility(competitionGender, personGender) {
  if (includes([COMPETITION_GENDER_FORMAT.open, COMPETITION_GENDER_FORMAT.mixed], competitionGender)) {
    return true;
  }

  if (includes([GENDER_TYPE.prefer_not_to_answer, GENDER_TYPE.x], personGender)) {
    return true;
  }

  return competitionGender === personGender;
}

function checkAgeEligibility(competitionAgeGroups, ageCutoffAt, personDateOfBirth) {
  if (isEmpty(competitionAgeGroups)) {
    return true;
  }

  const parsedAgeGroups = formatAgeGroups(parseAgeGroups(competitionAgeGroups));

  return parsedAgeGroups.some(ageGroup =>
    getAgeRequirementIsMetFromDob(personDateOfBirth, ageCutoffAt, ageGroup[0], ageGroup[1]),
  );
}

/**
 * Returns whether a person is eligible to be registered in a competition based on gender and age requirements.
 *
 * @param {Object}   competitionData
 * @param {string[]} competitionData.ageGroups - Array of eligible age groups
 * @param {string}   competitionData.ageCutoffAt - Age cut off date in YYYY-MM-DD format
 * @param {string}   competitionData.gender - Eligible gender
 *
 * @param {Object} personData
 * @param {string?} personData.dob - Person's date of birth
 * @param {string?} personData.gender - Person's gender
 *
 *
 * @return {boolean}
 *
 */
// eslint-disable-next-line import/prefer-default-export
export function isPersonEligibleForCompetition(competitionData, personData) {
  if (isEmpty(competitionData) || isEmpty(personData)) {
    throw Error("Invalid parameters provided.");
  }

  if (
    isNil(competitionData.gender) ||
    !includes(Object.values(COMPETITION_GENDER_FORMAT), competitionData.gender) ||
    (!isNil(personData.gender) && !includes(Object.values(GENDER_TYPE), personData.gender))
  ) {
    throw Error("Either competition or person gender information is not provided correctly.");
  }

  if (!isArray(competitionData.ageGroups) || isNil(competitionData.ageCutoffAt)) {
    throw Error("Competition age requirement information is not provided correctly.");
  }

  return (
    checkGenderEligibility(competitionData.gender, personData.gender) &&
    checkAgeEligibility(competitionData.ageGroups, competitionData.ageCutoffAt, personData.dob)
  );
}
