import React from "react";
import { Typography, Form } from "antd";
import get from "lodash/get";
import { FIELD_TYPES } from "../../constants/fieldConstants";
import FormFieldCountry from "./FormFieldCountry";
import FormFieldLanguage from "./FormFieldLanguage";

const FormFieldBlacklist = props => {
  const { Text } = Typography;
  const bindType = get(props, "meta.bindType", "");
  switch (bindType) {
    case FIELD_TYPES.country:
      return <FormFieldCountry {...props} />;

    case FIELD_TYPES.language:
      return <FormFieldLanguage {...props} />;

    default:
      return (
        <Form.Item label=" ">
          <Text type="danger">Sorry: This field type not supported in blacklist.</Text>
        </Form.Item>
      );
  }
};

export default FormFieldBlacklist;
