import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../../constants/routeConstants";
import { REGISTRATION_LIST_MODE } from "./MyRegistrationsConstants";

function MyRegistrationItemAction({ regId, regIsClosed, listMode }) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(`${ROUTES.USER_REGISTRATIONS}/${regId}/submit`);
  }, [regId, history]);

  if (regIsClosed) {
    return null;
  }

  if (listMode === REGISTRATION_LIST_MODE.CURRENT) {
    return (
      <Button icon="edit" type="primary" onClick={handleClick}>
        Continue Registration
      </Button>
    );
  }

  return <Button onClick={handleClick}>View Registration</Button>;
}

MyRegistrationItemAction.propTypes = {
  regId: PropTypes.string.isRequired,
  regIsClosed: PropTypes.bool.isRequired,
  listMode: PropTypes.oneOf(Object.values(REGISTRATION_LIST_MODE)).isRequired,
};

export default MyRegistrationItemAction;
