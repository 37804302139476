import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import gql from "fraql";
import get from "lodash/get";
import { useTenantPicker } from "../TenantPickerContext";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";

export const GET_GROUP_TYPES = gql`
  query GetGroupTypes {
    GroupType(order_by: { name: asc }) {
      id
      name
      tenantId
    }
  }
`;

function FormFieldGroupType(props) {
  const { meta } = props;
  const { tenantFilter } = useTenantPicker();

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);

  const { loading, error, data } = useQuery(GET_GROUP_TYPES);

  const options = useMemo(() => {
    let groupTypes = get(data, "GroupType", []);

    if (tenantFilter){
      groupTypes = groupTypes.filter(x => x.tenantId === tenantFilter);
    }

    return groupTypes.map(groupType => ({ value: groupType.id, label: groupType.name }));
  }, [data, tenantFilter]);

  if (loading && !data) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (error) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Group Types failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  return <FormFieldSelect {...props} loading={loading} meta={{ ...meta, options }} />;
}

FormFieldGroupType.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({ displayDefaultLabel: PropTypes.bool }).isRequired,
};

export default FormFieldGroupType;
