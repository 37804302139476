import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Icon, Menu, Select, Typography } from "antd";
import classnames from "classnames";
import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { DEFAULT_CONFIRMATION_MSG } from "../../../constants/messageConstants";
import { REG_STEP_STATUSES, SUBMISSION_STATUS_STEP_ID } from "../../../constants/regStatusConstants";
import { STEP_TYPES_WITH_FORM } from "../../../constants/stepsConstants";
import { filterOption } from "../../../utils/selectUtils";
import { useGetUserHasPermission, PERMISSION } from "../../../utils/useGetUserHasPermission";
import { getUserConfirmation } from "../../PromptDirtyForm";
import RegistrationStatus from "../../RegistrationStatus";
import SectionHeaderContainer from "../../SectionHeaderContainer";
import ModalAddRegsToPerson from "./ModalAddRegsToPerson";

function RegistrationsAside({
  PersonId,
  RegId,
  regs,
  steps,
  activeStepId,
  setActiveStepId,
  setActiveRegId,
  refetchRegList,
}) {
  const [modalNewRegVisible, setModalNewRegVisible] = useState(false);

  const canAddRegistration = useGetUserHasPermission(PERMISSION.ADD_REGISTRATION);

  function handleChangeRegistration(selectedRegId) {
    const activeStep = find(steps, stepItem => stepItem.id === activeStepId);
    const activeStepType = get(activeStep, "meta.type", "");

    const moveToStep = callback => {
      setActiveRegId(selectedRegId);

      return callback && callback();
    };

    if (selectedRegId !== RegId) {
      if (STEP_TYPES_WITH_FORM.includes(activeStepType)) {
        const storageFormName = `_formik-persist-${activeStep.id}`;
        const state = JSON.parse(window.localStorage.getItem(storageFormName) || null);
        const removeFormContext = () => window.localStorage.removeItem(storageFormName);

        if (state && state.dirty && state.submitCount === 0) {
          const callback = result => result && moveToStep(removeFormContext);

          getUserConfirmation(DEFAULT_CONFIRMATION_MSG, callback);
        } else {
          moveToStep(removeFormContext);
        }
      } else {
        moveToStep();
      }
    }
  }

  function handleChangeStep(menuItem) {
    setActiveStepId(menuItem.key);
  }

  function handleClickAddNewRegistration() {
    setModalNewRegVisible(true);
  }

  const canSetRegistration = !!setActiveRegId;
  const hasRegs = !isNil(regs) && !isEmpty(regs);
  const hasSteps = !isNil(steps) && !isEmpty(steps);

  const content = (
    <>
      <SectionHeaderContainer>
        <Typography.Title level={3}>Registration</Typography.Title>
      </SectionHeaderContainer>

      {canSetRegistration && !hasRegs && (
        <div style={{ marginBottom: 24 }}>
          <Alert
            message="No registrations"
            description={
              <span>
                There are currently no registrations for this person. Click <strong>Add Registration</strong> to add
                one.
              </span>
            }
            type="info"
            showIcon
          />
        </div>
      )}

      {canSetRegistration && hasRegs && (
        <Select
          style={{ width: "100%", marginBottom: 24 }}
          onSelect={handleChangeRegistration}
          placeholder="Select a registration"
          dropdownMatchSelectWidth={false}
          value={RegId}
          filterOption={filterOption}
          showSearch
        >
          {regs.map(reg => {
            const regName = get(reg, "name", "");

            return (
              <Select.Option key={reg.id} value={reg.id} title={regName}>
                <div style={{ display: "flex" }}>
                  <RegistrationStatus size="small" reg={reg} />
                  {regName}
                </div>
              </Select.Option>
            );
          })}
        </Select>
      )}

      {hasSteps && (
        <Menu
          onClick={handleChangeStep}
          style={{ width: "100%", marginBottom: canSetRegistration ? 48 : 0 }}
          selectedKeys={[activeStepId]}
          mode="inline"
          inlineIndent={0}
        >
          {steps.map(step => {
            const stepStatus = get(step, "RegStatus.status", "");
            const isStepCompleted = stepStatus === REG_STEP_STATUSES.completed;
            const isSubmissionStatusStep = get(step, "id", "") === SUBMISSION_STATUS_STEP_ID;

            return (
              <Menu.Item
                key={step.id}
                style={{
                  height: "auto",
                  whiteSpace: "normal",
                  lineHeight: "24px",
                  backgroundColor: "transparent",
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: isSubmissionStatusStep ? 17 : 0,
                  marginBottom: 0,
                  overflow: "visible",
                }}
                className={isSubmissionStatusStep ? "menu-item-submission-status" : ""}
              >
                <div style={{ display: "flex", paddingTop: 8, paddingBottom: 8 }}>
                  <div style={{ marginRight: 10 }}>{step.name}</div>

                  <div style={{ height: 24, display: "flex", alignItems: "center", marginLeft: "auto" }}>
                    {isStepCompleted && (
                      <Icon
                        type="check"
                        style={{
                          fontSize: "14px",
                          color: "#b3d235",
                        }}
                      />
                    )}
                  </div>
                </div>
              </Menu.Item>
            );
          })}
        </Menu>
      )}

      {!!RegId && !hasSteps && <Alert message="No steps" type="warning" />}

      {canSetRegistration && canAddRegistration && (
        <Button
          onClick={handleClickAddNewRegistration}
          block={hasRegs}
          size={hasRegs ? "default" : "large"}
          type={hasRegs ? "default" : "primary"}
          icon={hasRegs ? undefined : "plus"}
        >
          Add Registration
        </Button>
      )}

      {modalNewRegVisible && (
        <ModalAddRegsToPerson
          PersonId={PersonId}
          regIds={regs.map(reg => reg.id)}
          setVisible={setModalNewRegVisible}
          refetchPersonRegList={refetchRegList}
        />
      )}
    </>
  );

  const className = classnames("person-registrations-aside", {
    "person-registrations-aside--full-width": !hasRegs,
  });

  return <div className={className}>{content}</div>;
}

RegistrationsAside.propTypes = {
  PersonId: PropTypes.string.isRequired,
  RegId: PropTypes.string,
  regs: PropTypes.arrayOf(PropTypes.object).isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeStepId: PropTypes.string,
  setActiveStepId: PropTypes.func.isRequired,
  setActiveRegId: PropTypes.func,
  refetchRegList: PropTypes.func.isRequired,
};

RegistrationsAside.defaultProps = {
  RegId: undefined,
  activeStepId: null,
  setActiveRegId: null,
};

export default RegistrationsAside;
