import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Editor } from "slate-react";
import isBlank from "../../utils/isBlank";
import { renderMark, renderBlock, renderInline } from "./TextEditorRenders";
import { handleParseValues } from "./TextEditorUtils";

// The value stored in the DB when the field has the default content, i.e. the content which appears automatically when
// a step is first created, and the user has not made any edits to the content form field. This default content is just
// a new line with no text.
const DEFAULT_CONTENT = `{"object":"value","document":{"object":"document","data":{},"nodes":[{"object":"block","type":"line","data":{},"nodes":[{"object":"text","text":"","marks":[]}]},{"object":"block","type":"paragraph","data":{},"nodes":[{"object":"text","text":"","marks":[]}]}]}}`;

// The value stored in the DB when the field has empty content, i.e. the content after the user manually clears out the
// default content from the content form field.
const EMPTY_CONTENT = `{"object":"value","document":{"object":"document","data":{},"nodes":[{"object":"block","type":"paragraph","data":{},"nodes":[{"object":"text","text":"","marks":[]}]}]}}`;

export function getRichTextContentIsEmpty(value) {
  return isBlank(value) || value === DEFAULT_CONTENT || value === EMPTY_CONTENT;
}

const TextEditorPreview = ({ defaultValue, style }) => {
  const initialValue = handleParseValues(defaultValue);
  const [value, onChange] = useState(initialValue);

  useEffect(() => {
    onChange(handleParseValues(defaultValue));
  }, [defaultValue]);

  // Don't render anything when the value is the default content or empty content, since in either case we have no real
  // content to show.
  if (getRichTextContentIsEmpty(defaultValue)) {
    return null;
  }

  return (
    <div className="text-editor" style={style}>
      <Editor renderBlock={renderBlock} renderMark={renderMark} renderInline={renderInline} value={value} readOnly />
    </div>
  );
};

TextEditorPreview.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  style: PropTypes.any,
};

TextEditorPreview.defaultProps = {
  style: undefined,
};

export default TextEditorPreview;
