import React from "react";
import PropTypes from "prop-types";
import { IMAGE_TYPES } from "../../constants/fileConstants";
import FormFieldFile from "./FormFieldFile/FormFieldFile";

function FormFieldImage(props) {
  const { meta } = props;

  return (
    <FormFieldFile
      {...props}
      meta={{
        ...meta,
        fileTypes: IMAGE_TYPES,
      }}
    />
  );
}

FormFieldImage.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default FormFieldImage;
