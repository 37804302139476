import React, { useContext, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import gql from "fraql";
import { isNil, get } from "lodash";
import { useAuth0 } from "./Auth0";

export const TenantPickerContext = React.createContext();

export const useTenantPicker = () => useContext(TenantPickerContext);

const GET_TENANT_BY_KEY = gql`
  query TenantPickerContext_GetTenant_By_Key($tenantKey: String!) {
    Tenant(where: { key: { _eq: $tenantKey } }) {
      id
      key
      name
    }
  }
`;

const GET_DOMAIN_TENANT = gql`
  query Get_Tenant_Via_Domain($domain: String = "") {
    getTenantViaDomain(domain: $domain) {
      id
      name
      key
    }
  }
`;

export function TenantPickerProvider({ children }) {
  const [tenant, setTenant] = useState(null);
  const [tenantFilter, setTenantFilter] = useState(null);
  const { tenantKey, isAuthenticated } = useAuth0();

  // Getting Tenant details based on hostname/domain of website.
  const { hostname } = window.location;
  const { loading: domainTenantLoading, data: domainTenantData } = useQuery(GET_DOMAIN_TENANT, {
    variables: { domain: hostname },
  });

  const { loading, data: tenantData } = useQuery(GET_TENANT_BY_KEY, {
    variables: { tenantKey },
    skip: !isAuthenticated,
  });

  // Set Tenant based on domain of the site AND If user is not logged in. For unauthenticated/not logged in users.
  // If no reliable tenant is found, set Tenant Id and name to "unknown".
  useEffect(() => {
    if (!isAuthenticated && !domainTenantLoading) {
      const domainTenant = get(domainTenantData, "getTenantViaDomain", null);
      if (!isNil(domainTenant)) {
        setTenant({
          id: domainTenant.id,
          name: domainTenant.name,
          key: domainTenant.key,
        });
      } else {
        setTenant({ id: "unknown", name: "unknown", key: "unknown" });
      }
    }
  }, [domainTenantLoading, domainTenantData, isAuthenticated]);

  // Set Tenant based on Auth0 token of the user once a user logs in.
  useMemo(() => {
    if (isAuthenticated && !loading && tenantData) {
      setTenant({
        id: get(tenantData, "Tenant.0.id", "unknown"),
        name: get(tenantData, "Tenant.0.name", "unknown"),
        key: get(tenantData, "Tenant.0.key", "unknown"),
      });
    }
  }, [isAuthenticated, tenantData, loading]);

  useEffect(() => {
    if (tenantFilter) {
      console.log(`changing tenant filter: ${tenantFilter}`);
    }
  }, [tenantFilter]);

  return (
    <TenantPickerContext.Provider
      value={{
        tenantId: tenant ? tenant.id : undefined,
        tenantName: tenant ? tenant.name : undefined,
        tenantKey: tenant ? tenant.key : undefined,
        tenantFilter,
        setTenantFilter,
      }}
    >
      {children}
    </TenantPickerContext.Provider>
  );
}

TenantPickerProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
