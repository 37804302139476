import React from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router-dom";
import * as ROUTES from "../constants/routeConstants";
import useDocumentTitles from "../utils/useDocumentTitle";
import { PERMISSION } from "../utils/useGetUserHasPermission";
import Confirm from "../auth/Confirm";
import Login from "../auth/Login";
import Reset from "../auth/Reset";
import { useAuth0 } from "./Auth0";
import NavHeader from "./GlobalNav/NavHeader";
import PrivateRoute from "./PrivateRoute";
import RegistrationUser from "./SelfRegistration/RegistrationUser";
import SpinFullScreen from "./SpinFullScreen";
import UnsupportedBrowserAlert from "./UnsupportedBrowserAlert";
import "./App.scss";

const CompetitionManagement = React.lazy(() => import("./CompetitionManagement/CompetitionManagement"));
const CountryListPage = React.lazy(() => import("./MenuAdmin/Countries/CountryListPage"));
const Dashboard = React.lazy(() => import("../container/Dashboard/Dashboard"));
const FieldListPage = React.lazy(() => import("./MenuAdmin/Fields/FieldListPage"));
const FormatListPage = React.lazy(() => import("./MenuAdmin/Formats/FormatListPage"));
const FormDetailPage = React.lazy(() => import("./MenuAdmin/Forms/FormDetailPage"));
const FormFieldTestPage = React.lazy(() => import("./FormFields/FormFieldTestPage"));
const FormListPage = React.lazy(() => import("./MenuAdmin/Forms/FormListPage"));
const GroupListPage = React.lazy(() => import("./MenuAdmin/Groups/GroupListPage"));
const GroupTypeListPage = React.lazy(() => import("./MenuAdmin/GroupTypes/GroupTypeListPage"));
const HomePage = React.lazy(() => import("./HomePage"));
const LogOutPage = React.lazy(() => import("./LogOutPage"));
const NoMatchPage = React.lazy(() => import("./NoMatchPage"));
const PersonListPage = React.lazy(() => import("./MenuPeople/PersonList/PersonListPage"));
const PersonProfilePage = React.lazy(() => import("./MenuPeople/PersonProfilePage"));
const RegistrationDetailPage = React.lazy(() => import("./MenuAdmin/Registrations/RegistrationDetailPage"));
const RegistrationListPage = React.lazy(() => import("./MenuAdmin/Registrations/RegistrationListPage"));
const ResultStatusListPage = React.lazy(() => import("./MenuAdmin/ResultStatuses/ResultStatusListPage"));
const RoleListPage = React.lazy(() => import("./MenuAdmin/Roles/RoleListPage"));
const ScoreTypeListPage = React.lazy(() => import("./MenuAdmin/ScoreTypes/ScoreTypeListPage"));
const SportListPage = React.lazy(() => import("./MenuAdmin/Sports/SportListPage"));
const SummaryReportPage = React.lazy(() => import("./Reports/SummaryReportPage"));
const UserGroupRolePermissionPage = React.lazy(() => import("./MenuAdmin/Permissions/UserGroupPermissionRolePage"));
const TenantListPage = React.lazy(() => import("./MenuAdmin/FuseSportAdmin/TenantManagement/TenantListPage"));
const SystemSettingsTheme = React.lazy(() => import("./MenuAdmin/SystemSettings/SystemSettingsTheme"));
const DownloadExportedFilesPage = React.lazy(() => import("./Downloads/DownloadExportedFilesPage"));
const PrivateFilePage = React.lazy(() => import("./PrivateFile/PrivateFilePage"));

function getPath(routeList, routeCreate) {
  return [routeCreate, `${routeList}/:id`, routeList];
}

function App() {
  useDocumentTitles();
  const { loading: authLoading } = useAuth0();

  return (
    <Layout className="app">
      {!authLoading && <NavHeader authLoading={authLoading} />}

      <main style={{ marginTop: 64, flex: 1, flexShrink: 0, display: "flex", flexDirection: "column" }}>
        {authLoading && <SpinFullScreen />}

        {!authLoading && (
          <>
            <React.Suspense fallback={<SpinFullScreen />}>
              <Layout.Content style={{ flex: 1, flexShrink: 0, display: "flex", flexDirection: "column" }}>
                <UnsupportedBrowserAlert />

                <Switch>
                  <Route exact path="/" component={HomePage} />

                  {/* Login, New User Registration, Reset Password */}
                  <Route exact path={ROUTES.LOGIN} component={() => <Login pagemode="LOGIN" />} />
                  <Route exact path={ROUTES.REGISTER_NEW_USER} component={() => <Login pagemode="REGISTER" />} />
                  <Route exact path="/logout" component={LogOutPage} />
                  <Route exact path="/reset" component={Reset} />
                  <Route exact path="/confirm" component={Confirm} />

                  {/* Open Route to Registration Form for Users */}
                  <Route path={ROUTES.USER_REGISTRATIONS} component={RegistrationUser} />

                  {/* Dashboard */}
                  <PrivateRoute
                    path={ROUTES.DASHBOARD}
                    component={Dashboard}
                    permission={PERMISSION.ACCESS_DASHBOARD}
                  />

                  {/* Profile  */}
                  <PrivateRoute
                    path={ROUTES.PROFILE}
                    component={PersonProfilePage}
                    permission={PERMISSION.ACCESS_PROFILE}
                  />

                  {/* People tab */}
                  <PrivateRoute
                    path={[`${ROUTES.PEOPLE}/:id`, ROUTES.PEOPLE]}
                    component={PersonListPage}
                    permission={PERMISSION.ACCESS_PEOPLE}
                  />

                  {/* Registrations tab  */}
                  <PrivateRoute
                    exact
                    path={ROUTES.REGISTRATIONS}
                    permission={PERMISSION.ACCESS_REGISTRATIONS}
                    component={RegistrationListPage}
                  />
                  <PrivateRoute
                    path={`${ROUTES.REGISTRATIONS}/:id`}
                    permission={PERMISSION.ACCESS_REGISTRATIONS}
                    component={RegistrationDetailPage}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.FORMS}
                    component={FormListPage}
                    permission={PERMISSION.ACCESS_FORMS}
                  />
                  <PrivateRoute
                    path={`${ROUTES.FORMS}/:id`}
                    component={FormDetailPage}
                    permission={PERMISSION.ACCESS_FORMS}
                  />

                  {/* Competition Management */}
                  <PrivateRoute
                    path={ROUTES.COMPETITION_MANAGEMENT}
                    component={CompetitionManagement}
                    permission={PERMISSION.ACCESS_COMPETITION_MANAGEMENT}
                  />

                  {/* Admin Menus */}
                  <PrivateRoute
                    path={getPath(ROUTES.FIELDS, ROUTES.FIELD_CREATE)}
                    component={FieldListPage}
                    permission={PERMISSION.TENANT_SYSTEM_ADMIN}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.COUNTRIES, ROUTES.COUNTRY_CREATE)}
                    component={CountryListPage}
                    permission={PERMISSION.TENANT_SYSTEM_ADMIN}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.ROLES, ROUTES.ROLE_CREATE)}
                    component={RoleListPage}
                    permission={PERMISSION.TENANT_SYSTEM_ADMIN}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.GROUP_TYPES, ROUTES.GROUP_TYPE_CREATE)}
                    component={GroupTypeListPage}
                    permission={PERMISSION.TENANT_SYSTEM_ADMIN}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.GROUPS, ROUTES.GROUP_CREATE)}
                    component={GroupListPage}
                    permission={PERMISSION.TENANT_SYSTEM_ADMIN}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.SPORTS, ROUTES.SPORT_CREATE)}
                    component={SportListPage}
                    permission={PERMISSION.TENANT_SYSTEM_ADMIN}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.RESULT_STATUSES, ROUTES.RESULT_STATUS_CREATE)}
                    component={ResultStatusListPage}
                    permission={PERMISSION.TENANT_SYSTEM_ADMIN}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.FORMATS, ROUTES.FORMAT_CREATE)}
                    component={FormatListPage}
                    permission={PERMISSION.TENANT_SYSTEM_ADMIN}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.SCORE_TYPES, ROUTES.SCORE_TYPE_CREATE)}
                    component={ScoreTypeListPage}
                    permission={PERMISSION.TENANT_SYSTEM_ADMIN}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.USER_GROUP_ROLE_PERMISSIONS}
                    component={UserGroupRolePermissionPage}
                    permission={PERMISSION.TENANT_SYSTEM_ADMIN}
                  />

                  {/* System / Tenant Settings */}
                  <PrivateRoute
                    exact
                    path={ROUTES.SYSTEM_THEME}
                    component={SystemSettingsTheme}
                    permission={PERMISSION.TENANT_SYSTEM_ADMIN}
                  />
                  {/* Global Admin / FuseSport Employee Pages only */}
                  <PrivateRoute
                    path={getPath(ROUTES.TENANT, ROUTES.TENANT_CREATE)}
                    component={TenantListPage}
                    permission={PERMISSION.FUSESPORT_ADMIN}
                  />

                  {/* Reports */}
                  <PrivateRoute
                    exact
                    path={ROUTES.SUMMARY_REPORT}
                    component={SummaryReportPage}
                    permission={PERMISSION.ACCESS_SUMMARY_REPORT}
                  />

                  {/* Async downloads */}
                  <PrivateRoute
                    exact
                    path={ROUTES.DOWNLOAD}
                    component={DownloadExportedFilesPage}
                    permission={PERMISSION.DOWNLOAD_EXPORTED_FILES}
                  />

                  {/* File download */}
                  <PrivateRoute
                    path={ROUTES.PRIVATE_FILE}
                    component={PrivateFilePage}
                    permission={PERMISSION.DOWNLOAD_PRIVATE_FILES}
                  />
                  {/* Hidden Routes (which don't have any navigation link directing users to them) */}
                  <Route exact path="/test/form-field" component={FormFieldTestPage} />

                  {/* 404 */}
                  <Route component={NoMatchPage} />
                </Switch>
              </Layout.Content>
            </React.Suspense>
          </>
        )}
      </main>
    </Layout>
  );
}

export default App;
