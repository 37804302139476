import { bignumber, round, unit } from "mathjs";
import moment from "moment";
import "moment-duration-format";
import * as yup from "yup";
import get from "lodash/get";
import includes from "lodash/includes";
import isNil from "lodash/isNil";
import isNull from "lodash/isNull";
import isNumber from "lodash/isNumber";
import toInteger from "lodash/toInteger";
import trim from "lodash/trim";
import { COMPETITION_SCORE_TYPE, COMPETITION_SCORE_TYPES_NUMERIC } from "../constants/competitionConstants";
import isBlank from "./isBlank";
import { schemaNullable } from "./yupUtils";

/**
 * Format an "ELAPSED_TIME" score value, which is stored as an integer of milliseconds, into a string showing hours,
 * minutes, seconds, centiseconds which can be used for display purposes or inputs.
 *
 * See https://github.com/jsmreese/moment-duration-format.
 */
export function getElapsedTimeFormattedFromMilliseconds(elapsedTimeMilliseconds) {
  return moment.duration(elapsedTimeMilliseconds).format("h:mm:ss.SS", {
    trim: false,
    useToLocaleString: false,
    groupingSeparator: "",
  });
}

/**
 * Convert a formatted "ELAPSED_TIME" string into its corresponding number of milliseconds, which can be used for
 * storing the value in the database.
 */
export function getMillisecondsFromElapsedTimeFormatted(elapsedTimeFormatted) {
  return moment.duration(elapsedTimeFormatted).asMilliseconds();
}

/**
 * Given a saved competition score value, format it for display.
 *
 * @param competitionScore A saved competition score value. The value should either be `null`, or an object with the
 * following structure: `{ type: <score type>, value: <score value>, rawValue: <raw score value> }`.
 * @returns `null` if the value of the score is `null`. Otherwise returns the formatted score.
 */
export const formatCompetitionScoreForDisplay = competitionScore => {
  const competitionScoreValue = get(competitionScore, "value", null);
  const competitionScoreType = get(competitionScore, "type", null);

  if (isNil(competitionScore) || isNil(competitionScoreValue)) {
    return null;
  }

  switch (competitionScoreType) {
    case COMPETITION_SCORE_TYPE.ELAPSED_TIME:
      // Format our competition score saved as an integer of milliseconds back into a string matching the format of
      // our Qualification Score input. See https://github.com/jsmreese/moment-duration-format.
      return getElapsedTimeFormattedFromMilliseconds(competitionScoreValue);
    case COMPETITION_SCORE_TYPE.LENGTH_METRES:
      // Convert from millimetres to metres for display.
      return round(unit(bignumber(competitionScoreValue), "millimeter").toNumber("meter"), 2);
    case COMPETITION_SCORE_TYPE.MASS_KILOGRAMS:
      // Convert from milligrams to kilograms for display.
      return round(unit(bignumber(competitionScoreValue), "milligram").toNumber("kilogram"), 2);
    case COMPETITION_SCORE_TYPE.NUMBER:
    case COMPETITION_SCORE_TYPE.NUMBER_INTEGER:
      // Just return the saved value, no need to do any conversion.
      return competitionScoreValue;
    default:
      throw new Error(`Unknown score type: ${competitionScoreType}`);
  }
};

/**
 * Check if the given score value has the intended score type.
 *
 * @param competitionScore A saved competition score value. The value should either be `null`, or an object with the
 * following structure: `{ type: <score type>, value: <score value>, rawValue: <raw score value> }`.
 * @param intendedScoreType The intended score type.
 * @returns `true` if the score has the intended score type, `false` otherwise.
 */
export const checkCompetitionScoreHasIntendedScoreType = (competitionScore, intendedScoreType) => {
  const scoreType = get(competitionScore, "type", null);

  if (isNil(competitionScore) || isNil(intendedScoreType)) {
    return false;
  }

  if (intendedScoreType !== scoreType) {
    return false;
  }

  return true;
};

/**
 * Given a saved competition score value, format it for use as the value of an input.
 *
 * @param competitionScore A saved competition score value. The value should either be `null`, or an object with the
 * following structure: `{ type: <score type>, value: <score value>, rawValue: <raw score value> }`.
 * @param intendedScoreType The competition score type which we *expect* to display, e.g. the qualification score type
 * configured for a particular competition. Note that in some cases this may be a different competition score type
 * than the saved competition score value uses.
 * @returns `null` if the score is not for the intended type or if the value of the score is `null`. Otherwise returns
 * the formatted score.
 */
export const formatCompetitionScoreForEdit = (competitionScore, intendedScoreType) => {
  if (!checkCompetitionScoreHasIntendedScoreType(competitionScore, intendedScoreType)) {
    return null;
  }

  return formatCompetitionScoreForDisplay(competitionScore);
};

/**
 * Given a raw competition score value (e.g. the value entered in an input), format it for saving.
 *
 * Note that if we use floating point numbers for any part of the unit conversion mathematics, we will experience
 * round-off errors. For example, an input of 13.46 kilograms might become 13459999 milligrams, when the correct value
 * is 13460000. We use `mathjs.bignumber` to resolve this.
 *
 * See:
 * - https://mathjs.org/docs/datatypes/numbers.html#roundoff-errors
 * - https://mathjs.org/docs/datatypes/bignumbers.html
 * - https://mathjs.org/docs/datatypes/units.html
 *
 * @param rawScore The raw competition score value.
 * @param competitionScoreType The type of competition score value that `rawScore` is.
 * @returns The competition score converted, according to its type, into a format in which it can be saved. `null` if
 * the competition score is invalid.
 */
export const formatCompetitionScoreForSaving = (rawScore, competitionScoreType) => {
  if (isNil(rawScore) || isNil(competitionScoreType)) {
    return null;
  }

  switch (competitionScoreType) {
    case COMPETITION_SCORE_TYPE.ELAPSED_TIME:
      if (!trim(rawScore)) {
        // If the user did not provide a value for the Qualification Score input, we save a `null` value to the
        // database to represent this, rather than saving a value of `0`. This allows us to differentiate between
        // when a user actually entered a Qualification Score value of 00:00:00.000, and when a user simply left
        // the input empty.
        return null;
      }

      // Convert our Qualification Score input string into an integer of milliseconds that we will save in the
      // database. This gives us the flexibility to use this value wherever we need it without other code
      // needing to know about the specific format we're using for a Qualification Score input, and also
      // the flexibility to change the string format we're using for a Qualification Score input at any
      // time without this causing problems for existing saved data, we can always just convert from a milliseconds
      // value into whatever string format we might want to use.
      return getMillisecondsFromElapsedTimeFormatted(trim(rawScore));
    case COMPETITION_SCORE_TYPE.LENGTH_METRES:
      if (!isNumber(rawScore)) {
        return null;
      }

      // Convert to millimetres for saving. Ensure that we can only ever save an integer of millimetres.
      return toInteger(unit(bignumber(rawScore), "meter").toNumber("millimeter"));
    case COMPETITION_SCORE_TYPE.MASS_KILOGRAMS:
      if (!isNumber(rawScore)) {
        return null;
      }

      // Convert to milligrams for saving. Ensure that we can only ever save an integer of milligrams.
      return toInteger(unit(bignumber(rawScore), "kilogram").toNumber("milligram"));
    case COMPETITION_SCORE_TYPE.NUMBER:
      if (!isNumber(rawScore)) {
        return null;
      }

      return rawScore;
    case COMPETITION_SCORE_TYPE.NUMBER_INTEGER:
      if (!isNumber(rawScore)) {
        return null;
      }

      // Ensure that we can only ever save an integer.
      return toInteger(rawScore);
    default:
      throw new Error(`Unknown score type: ${competitionScoreType}`);
  }
};

/**
 * Generate the validation schema for the qualification score field of a competition dynamically based on the
 * parameters which determine whether the value is valid (i.e. based on the configuration of the competition).
 */
export function schemaQualificationScoreValue(
  fieldSchema,
  isCompetitionSelectedFieldName = "selected",
  qualificationScoreRequiredFieldName = "qualificationScoreRequired",
  qualificationScoreLabelFieldName = "qualificationScoreLabel",
  qualificationScoreTypeFieldName = "qualificationScoreType",
  qualificationScoreLimitMinFieldName = "qualificationScoreLimitMin",
  qualificationScoreLimitMaxFieldName = "qualificationScoreLimitMax",
  qualificationScoreInputDisplayedFieldName = "qualificationScoreInputDisplayed",
) {
  return fieldSchema.when(
    [
      isCompetitionSelectedFieldName,
      qualificationScoreRequiredFieldName,
      qualificationScoreLabelFieldName,
      qualificationScoreTypeFieldName,
      qualificationScoreLimitMinFieldName,
      qualificationScoreLimitMaxFieldName,
      qualificationScoreInputDisplayedFieldName,
    ],
    (
      isCompetitionSelected,
      qualificationScoreRequired,
      qualificationScoreLabel,
      qualificationScoreType,
      qualificationScoreLimitMin,
      qualificationScoreLimitMax,
      qualificationScoreInputDisplayed,
      _schema,
    ) => {
      if (!isCompetitionSelected || !qualificationScoreType || !qualificationScoreInputDisplayed) {
        return _schema;
      }

      let testSchema = _schema;

      if (qualificationScoreRequired) {
        testSchema = testSchema.test(
          "qualificationScoreIsRequired",
          `${qualificationScoreLabel} is required.`,
          function checkQualificationScoreIsRequired(qualificationScoreValue) {
            return !isBlank(qualificationScoreValue);
          },
        );
      }

      if (!isNull(qualificationScoreLimitMin) && !isNull(qualificationScoreLimitMax)) {
        testSchema = testSchema.test(
          "qualificationScoreValueWithinLimits",
          // Note: this message would only be shown if our test function returns `false`, which it shouldn't.
          "The qualification score entered is not within the limits.",
          function checkQualificationScoreValueWithinLimits(qualificationScoreValue) {
            if (isBlank(qualificationScoreValue)) {
              return true;
            }

            const qualificationScoreLimitMinFormatted = formatCompetitionScoreForDisplay({
              type: qualificationScoreType,
              value: qualificationScoreLimitMin,
            });

            const qualificationScoreLimitMaxFormatted = formatCompetitionScoreForDisplay({
              type: qualificationScoreType,
              value: qualificationScoreLimitMax,
            });

            if (qualificationScoreType === COMPETITION_SCORE_TYPE.ELAPSED_TIME) {
              const qualificationScoreValueFormattedForSaving = formatCompetitionScoreForSaving(
                qualificationScoreValue,
                qualificationScoreType,
              );

              if (qualificationScoreValueFormattedForSaving < qualificationScoreLimitMin) {
                return this.createError({
                  message: `${qualificationScoreLabel} for this competition cannot be below "${qualificationScoreLimitMinFormatted}".`,
                });
              }

              if (qualificationScoreValueFormattedForSaving > qualificationScoreLimitMax) {
                return this.createError({
                  message: `${qualificationScoreLabel} for this competition cannot be above "${qualificationScoreLimitMaxFormatted}".`,
                });
              }

              return true;
            }

            if (includes(COMPETITION_SCORE_TYPES_NUMERIC, qualificationScoreType)) {
              const qualificationScoreValueFormattedForSaving = formatCompetitionScoreForSaving(
                qualificationScoreValue,
                qualificationScoreType,
              );

              if (qualificationScoreValueFormattedForSaving < qualificationScoreLimitMin) {
                let messagePostText = "";

                if (qualificationScoreType === COMPETITION_SCORE_TYPE.LENGTH_METRES) {
                  messagePostText = qualificationScoreLimitMinFormatted === 1 ? " metre" : " metres";
                } else if (qualificationScoreType === COMPETITION_SCORE_TYPE.MASS_KILOGRAMS) {
                  messagePostText = qualificationScoreLimitMinFormatted === 1 ? " kilogram" : " kilograms";
                }

                return this.createError({
                  message: `${qualificationScoreLabel} for this competition cannot be below ${qualificationScoreLimitMinFormatted}${messagePostText}.`,
                });
              }

              if (qualificationScoreValueFormattedForSaving > qualificationScoreLimitMax) {
                let messagePostText = "";

                if (qualificationScoreType === COMPETITION_SCORE_TYPE.LENGTH_METRES) {
                  messagePostText = qualificationScoreLimitMaxFormatted === 1 ? " metre" : " metres";
                } else if (qualificationScoreType === COMPETITION_SCORE_TYPE.MASS_KILOGRAMS) {
                  messagePostText = qualificationScoreLimitMaxFormatted === 1 ? " kilogram" : " kilograms";
                }

                return this.createError({
                  message: `${qualificationScoreLabel} for this competition cannot be above ${qualificationScoreLimitMaxFormatted}${messagePostText}.`,
                });
              }

              return true;
            }

            throw new Error(`Unknown score type: ${qualificationScoreType}`);
          },
        );
      }

      return testSchema;
    },
  );
}

const elapsedTimeValueCentisecondsRegex = /^(\d+):([0-5][0-9]):([0-5][0-9])\.([0-9][0-9])$/;
const numberWithOptionalDecimalPlacesRegex = /^\d+(\.\d{1,2})?$/;
const numberIntegerRegex = /^\d+$/;

/**
 * Generate the validation schema for a score field dynamically based on the `scoreType` value.
 *
 * Note that we *do not* pass a Yup schema as a parameter to this function like we do in most other similar functions.
 * This is because the top-level schema (e.g. `yup.string()` or `yup.number()`) needs to be dynamic depending on the
 * competition score type, so this cannot be passed as a parameter to this function. Instead, this function is
 * responsible for producing the schema that is appropriate based on the competition score type. It is okay for other
 * functions to 'wrap' the schema produced by this function and add further validation logic.
 */
export function schemaCompetitionScoreValue(competitionScoreTypeFieldName) {
  return yup.mixed().when(competitionScoreTypeFieldName, (competitionScoreType, _schema) => {
    if (!competitionScoreType) {
      // Note: we don't expect there to be a case where a user is able to provide a competition score value when there
      // is no competition score type specified. This test is just to catch the theoretical edge case where some issue
      // has led to this happening.
      return _schema.test({
        test: competitionScoreValue => {
          // Treat blank/empty values as valid.
          return isBlank(competitionScoreValue);
        },
        message: "A valid score type must be provided in order to set a score value.",
      });
    }

    switch (competitionScoreType) {
      case COMPETITION_SCORE_TYPE.ELAPSED_TIME:
        return schemaNullable(
          yup.string().test({
            test: competitionScoreValue => {
              // Treat blank/empty values as valid.
              if (isBlank(competitionScoreValue)) {
                return true;
              }

              return !!elapsedTimeValueCentisecondsRegex.exec(competitionScoreValue);
            },
            message: "Value must be entered in the format hh:mm:ss.SS (e.g. 11:55:24.81).",
          }),
        );
      case COMPETITION_SCORE_TYPE.LENGTH_METRES:
      case COMPETITION_SCORE_TYPE.MASS_KILOGRAMS:
      case COMPETITION_SCORE_TYPE.NUMBER:
        // Note: for very large numbers entered by a user, it seems that the use of `yup.number()` which converts the
        // input from a string into a number causes the value to change. For example, an input of "9999999999999999"
        // ends up becoming the number `10000000000000000`. If this ends up causing any real-world problems, we may
        // need to stop using `yup.number` and achieve the desired result in a different way.
        return schemaNullable(
          yup
            .number()
            .typeError("Value must be a number.")
            .positive("Value must be a positive number.")
            .test({
              test: competitionScoreValue => {
                // Treat blank/empty values as valid.
                if (isBlank(competitionScoreValue)) {
                  return true;
                }

                return !!numberWithOptionalDecimalPlacesRegex.exec(competitionScoreValue);
              },
              message: "Value must be a number, optionally with up to 2 decimal places (e.g. 100 or 100.99).",
            }),
        );
      case COMPETITION_SCORE_TYPE.NUMBER_INTEGER:
        return schemaNullable(
          yup
            .number()
            .typeError("Value must be a number.")
            .positive("Value must be a positive number.")
            .test({
              test: competitionScoreValue => {
                // Treat blank/empty values as valid.
                if (isBlank(competitionScoreValue)) {
                  return true;
                }

                return !!numberIntegerRegex.exec(competitionScoreValue);
              },
              message: "Value must be a number without decimal places (e.g. 100).",
            }),
        );
      default:
        throw new Error(`Unknown score type: ${competitionScoreType}`);
    }
  });
}
