import React, { useMemo } from "react";
import * as Sentry from "@sentry/browser";
import { message, Modal, Typography } from "antd";
import { Formik } from "formik";
import { Form, SubmitButton } from "formik-antd";
import * as yup from "yup";
import get from "lodash/get";
import has from "lodash/has";
import sortBy from "lodash/sortBy";
import { FORM_ITEM_LAYOUT_PROPS_BY_FORM_LAYOUT } from "../constants/formConstants";
import {
  ALL_REG_SUBMISSION_STATUS_TRANSITIONS_OPTIONS,
  LIMITED_REG_SUBMISSION_STATUS_TRANSITIONS_OPTIONS,
  REG_SUBMISSION_STATUSES,
} from "../constants/regStatusConstants";
import { getContentFromServerErrorDescription, serverErrorStatus } from "../utils/graphqlErrors";
import { PERMISSION, useGetUserHasPermission } from "../utils/useGetUserHasPermission";
import { useRegContext } from "../utils/useRegContext";
import Select from "./FormikAntD/Select";
import FormStatusMessage from "./FormStatusMessage";
import { preparedStepsWithoutSubmissionsStatusStep } from "./MenuPeople/Registrations/PersonDetailRegistrationsConstants";

const validationSchema = yup.object({
  status: yup.string().required("Please select a status."),
});

const initialValues = {
  status: undefined,
};

const updateStatus = async (values, actions, updateRegSubmissionStatus, RegSubmission, steps) => {
  try {
    const { status } = values;

    const stepIds = preparedStepsWithoutSubmissionsStatusStep(steps).map(step => get(step, "id"));

    await updateRegSubmissionStatus({
      variables: { RegSubmissionId: get(RegSubmission, "id", null), status, stepIds },
    });

    message.success("Registration submission status updated.");

    actions.resetForm();
  } catch (error) {
    console.error(error);

    const errorStatus = serverErrorStatus(error);

    const description = get(
      errorStatus,
      "description",
      "There was an error processing your request. Please try again later.",
    );

    const content = getContentFromServerErrorDescription(description);

    const title = "Registration submission status not updated";

    Modal.error({
      title,
      content,
      style: { minWidth: 500 },
    });

    actions.setStatus({ type: "error", message: title, description: content });
    actions.setSubmitting(false);

    if (!has(errorStatus, "hasValidationError") && !has(errorStatus, "hasPermissionsError")) {
      Sentry.captureException(error);
    }
  }
};

const showConfirmDeregisterModal = (values, actions, updateRegSubmissionStatus, RegSubmission, steps) => {
  const modal = Modal.confirm({
    title: "Confirm deregistration",
    content:
      "Deregistration will hide this registration's details and may remove the person from teams they have joined. Are you sure you want to continue?",
    async onOk() {
      modal.update({
        okButtonProps: {
          loading: true,
        },
        cancelButtonProps: {
          disabled: true,
        },
      });

      actions.setSubmitting(true);

      await updateStatus(values, actions, updateRegSubmissionStatus, RegSubmission, steps);
    },
    onCancel() {},
  });
};

function RegSubmissionStatusSelect() {
  const regContext = useRegContext();

  const showRegStatusSelect = useGetUserHasPermission(PERMISSION.SHOW_REG_STATUS_SELECT);
  const canChangeSubmissionStatusOfClosedRegistration = useGetUserHasPermission(
    PERMISSION.CHANGE_SUBMISSION_STATUS_OF_CLOSED_REGISTRATION,
  );
  const showLimitedSubmissionStatusTransitionOptions = useGetUserHasPermission(
    PERMISSION.SHOW_LIMITED_SUBMISSION_STATUS_TRANSITIONS_OPTIONS,
  );
  const showAllSubmissionStatusTransitionOptions = useGetUserHasPermission(
    PERMISSION.SHOW_ALL_SUBMISSION_STATUS_TRANSITIONS_OPTIONS,
  );

  const { updateRegSubmissionStatus, steps, RegSubmission, reg, regIsClosed } = get(regContext, "regContextData", {});

  const selectOptions = useMemo(() => {
    let options = [];

    const regSubmissionStatus = get(RegSubmission, "status");

    if (!showRegStatusSelect) {
      return options;
    }

    if (showAllSubmissionStatusTransitionOptions) {
      options = ALL_REG_SUBMISSION_STATUS_TRANSITIONS_OPTIONS[regSubmissionStatus];
    } else if (showLimitedSubmissionStatusTransitionOptions) {
      options = LIMITED_REG_SUBMISSION_STATUS_TRANSITIONS_OPTIONS[regSubmissionStatus];
    } else {
      return options;
    }

    return sortBy(options, ["label"]);
  }, [
    showAllSubmissionStatusTransitionOptions,
    showLimitedSubmissionStatusTransitionOptions,
    RegSubmission,
    showRegStatusSelect,
  ]);

  const handleSubmit = async (values, actions) => {
    const { status } = values;

    if (status === REG_SUBMISSION_STATUSES.deregistered) {
      actions.setSubmitting(false);

      showConfirmDeregisterModal(values, actions, updateRegSubmissionStatus, RegSubmission, steps);

      return;
    }

    await updateStatus(values, actions, updateRegSubmissionStatus, RegSubmission, steps);
  };

  const regArchived = get(reg, "archived", false);

  const disableForm = regArchived || (regIsClosed && !canChangeSubmissionStatusOfClosedRegistration);

  if (selectOptions.length === 0) {
    return null;
  }

  return (
    <>
      <Typography.Title level={4}>Update status</Typography.Title>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ status, isValid }) => {
          return (
            <Form {...FORM_ITEM_LAYOUT_PROPS_BY_FORM_LAYOUT.vertical}>
              <Form.Item name="status">
                <Select name="status" placeholder="Select a status" style={{ width: 180 }} disabled={disableForm}>
                  {selectOptions.map(({ value, text }) => (
                    <Select.Option key={value} value={value}>
                      {text}
                    </Select.Option>
                  ))}
                </Select>

                <SubmitButton type="primary" style={{ marginLeft: 10 }} disabled={disableForm || !isValid}>
                  Set Status
                </SubmitButton>
              </Form.Item>

              <div style={{ marginTop: 15 }}>
                <FormStatusMessage status={status} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default RegSubmissionStatusSelect;
