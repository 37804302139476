import React from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import { Field } from "formik";
import get from "lodash/get";
import { formFieldHasError, formFieldIsInErrorState } from "../utils/formikUtils";

const FormItem = ({
  name,
  showValidateSuccess,
  showValidateError,
  children,
  help,
  submitCount,
  validate,
  ...restProps
}) => (
  <Field name={name} validate={validate}>
    {formProps => {
      const {
        form: { errors = {}, touched = {} },
      } = formProps;

      const error = get(errors, name, undefined);
      const isTouched = !!get(touched, name, false);
      const hasError = formFieldHasError(name, errors);
      const isInErrorState = formFieldIsInErrorState(name, errors, touched, submitCount);
      const isInSuccessState = !hasError && isTouched;

      let validateStatus = null;

      if (isInErrorState) {
        validateStatus = "error";
      } else if (isInSuccessState && showValidateSuccess) {
        validateStatus = "success";
      }

      return (
        <Form.Item
          validateStatus={validateStatus}
          hasFeedback={isInErrorState && showValidateError}
          help={isInErrorState ? <li>{error}</li> : help}
          colon={false}
          {...restProps}
        >
          {children}
        </Form.Item>
      );
    }}
  </Field>
);

FormItem.propTypes = {
  name: PropTypes.string.isRequired,
  showValidateSuccess: PropTypes.bool,
  showValidateError: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  help: PropTypes.string,
  submitCount: PropTypes.number,
  validate: PropTypes.func,
};

FormItem.defaultProps = {
  showValidateSuccess: false,
  help: "",
  submitCount: 0,
  validate: undefined,
};

export default FormItem;
