import React from "react";
import PropTypes from "prop-types";
import { TEAM_QUALIFICATION_SCORE_DETERMINATION_OPTIONS } from "../../constants/competitionConstants";
import FormFieldSelect from "./FormFieldSelect";

function FormFieldTeamQualificationScoreDetermination(props) {
  const { meta } = props;

  return <FormFieldSelect {...props} meta={{ ...meta, options: TEAM_QUALIFICATION_SCORE_DETERMINATION_OPTIONS }} />;
}

FormFieldTeamQualificationScoreDetermination.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default FormFieldTeamQualificationScoreDetermination;
