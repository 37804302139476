import { useMemo } from "react";
import isUndefined from "lodash/isUndefined";
import isBlank from "../../../utils/isBlank";

function useRegTravelGroupsEnabled(reg) {
  return useMemo(() => {
    if (isBlank(reg)) {
      return false;
    }

    if (
      isUndefined(reg.travelOpenAt) ||
      isUndefined(reg.travelCloseAt) ||
      isUndefined(reg.ArrivalFormId) ||
      isUndefined(reg.DepartureFormId)
    ) {
      throw new Error("Missing fields for reg which are required for useRegTravelGroupsEnabled.");
    }

    return !!reg.travelOpenAt && !!reg.travelCloseAt && !!reg.ArrivalFormId && !!reg.DepartureFormId;
  }, [reg]);
}

export default useRegTravelGroupsEnabled;
