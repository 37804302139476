// Inspired by https://github.com/ant-design/ant-design/issues/6576#issuecomment-479445435.

import React from "react";
import PropTypes from "prop-types";
import { Menu, Icon } from "antd";

const renderMenuItem = item => {
  const { key, icon, title } = item;

  return (
    <Menu.Item key={key}>
      {!!icon && <Icon type={icon} />}
      <span>{title}</span>
    </Menu.Item>
  );
};

function LayoutTabsState({ items, currentItemKey, handleClick }) {
  return (
    <div style={{ padding: "0 24px" }}>
      <Menu
        theme="light"
        mode="horizontal"
        style={{ padding: 0, lineHeight: "36px", borderBottom: "none", background: "transparent", fontWeight: 400 }}
        onClick={handleClick}
        selectedKeys={[currentItemKey]}
      >
        {items.map(renderMenuItem)}
      </Menu>
    </div>
  );
}

LayoutTabsState.propTypes = {
  items: PropTypes.array.isRequired,
  currentItemKey: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default LayoutTabsState;
