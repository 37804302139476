import imageExtensions from "image-extensions";
import { isKeyHotkey } from "is-hotkey";
import { Block, Value } from "slate";
import Plain from "slate-plain-serializer";
import isJSON from "../../utils/isJson";

const isBoldHotkey = isKeyHotkey("mod+b");
const isItalicHotkey = isKeyHotkey("mod+i");
const isUnderlinedHotkey = isKeyHotkey("mod+u");
const isCodeHotkey = isKeyHotkey("mod+`");

export const getExtension = url => {
  return new URL(url).pathname.split(".").pop();
};

export const isImage = url => {
  return imageExtensions.includes(getExtension(url));
};

export const hasMark = (type, value) => value.activeMarks.some(mark => mark.type === type);

export const hasBlock = (type, value) => {
  return value.blocks.some(node => node.type === type);
};

export const hasLinks = value => {
  return value.inlines.some(inline => inline.type === "link");
};

export const insertImage = (editor, src, target) => {
  if (target) {
    editor.select(target);
  }

  editor.insertBlock({
    type: "image",
    data: { src },
  });
};

// eslint-disable-next-line consistent-return
export const onKeyDown = (event, editor, next) => {
  let mark;

  if (isBoldHotkey(event)) {
    mark = "bold";
  } else if (isItalicHotkey(event)) {
    mark = "italic";
  } else if (isUnderlinedHotkey(event)) {
    mark = "underlined";
  } else if (isCodeHotkey(event)) {
    mark = "code";
  } else {
    return next();
  }

  event.preventDefault();
  editor.toggleMark(mark);
};

export const wrapLink = (editor, href) => {
  editor.wrapInline({
    type: "link",
    data: { href },
  });

  editor.moveToEnd();
};

export const unwrapLink = editor => {
  editor.unwrapInline("link");
};

export const handleParseValues = string => {
  const json = isJSON(string) ? JSON.parse(string) : Plain.deserialize(string, { toJSON: true });

  return Value.fromJSON(json);
};

export const schema = {
  document: {
    last: { type: "paragraph" },
    normalize: (editor, { code, node }) => {
      let result = editor;
      switch (code) {
        case "last_child_type_invalid": {
          const paragraph = Block.create("paragraph");
          result = editor.insertNodeByKey(node.key, node.nodes.size, paragraph);
          break;
        }
        default:
      }
      return result;
    },
  },
  blocks: {
    image: {
      isVoid: true,
    },
  },
};
