import React from "react";
import { Alert } from "antd";
import LayoutPageHeader from "./LayoutPageHeader";

function UnauthorisedPage() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#fafafa",
          borderBottom: "2px solid #e8e8e8",
          padding: "15px 24px",
        }}
      >
        <LayoutPageHeader title="Access Denied" />
      </div>

      <div style={{ padding: 24 }}>
        <Alert
          type="error"
          message="Not authorised"
          description="Sorry, you are not authorised to access the page you requested."
          showIcon
        />
      </div>
    </>
  );
}

export default UnauthorisedPage;
