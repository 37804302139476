import React, { useState, useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import ENV from "../constants/envConstants";
import { createCookie, getCookieValue, removeCookie } from "../utils/cookies";

export const GET_USER = `
  query GetUser ($auth0Id: String!) {
    User(where: {auth0Id: {_eq: $auth0Id}}) {
      Person {
        firstName
        lastName
      }
      auth0Id
      email
    }
  }
`;

export const Auth0Context = React.createContext();

export const useAuth0 = () => useContext(Auth0Context);

const Auth0ProviderComponent = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [authRole, setAuthRole] = useState();
  const [activeRole, setActiveRole] = useState(null);
  const [authPermissions, setAuthPermissions] = useState([]);
  const [authTenantKey, setAuthTenantKey] = useState(null);
  const [authTenantId, setAuthTenantId] = useState(null);

  const [loading, setLoading] = useState(true);

  const role = useMemo(() => (!isEmpty(activeRole) ? activeRole : authRole), [authRole, activeRole]);

  const getUser = (auth0Id, tok) => {
    let responseCode = 200;

    fetch(ENV.API_GRAPHQL_HTTP_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tok}`,
      },
      body: JSON.stringify({
        query: GET_USER,
        variables: {
          auth0Id,
        },
      }),
    })
      .then(response => {
        responseCode = response.status;
        return response.json();
      })
      .then(
        data => {
          if (responseCode === 200) {
            if (data) {
              setUser({
                sub: auth0Id,
                email: data.data.User[0].email,
                email_verified: true,
                name: `${data.data.User[0].Person.firstName} ${data.data.User[0].Person.lastName}`,
                picture: null,
                nickname: `${data.data.User[0].Person.firstName} ${data.data.User[0].Person.lastName}`,
                updated_at: moment(new Date()),
              });
            }
          }
        },
        error => {
          console.log(error);
          alert("GET USER FAILED!\n[error code: 864003]");
        },
      );
  };

  useEffect(() => {
    const cookieToken = getCookieValue("token");

    let isCurrentlyAuthenticated = false;
    let currentToken = "";

    if (cookieToken && cookieToken.length > 0) {
      isCurrentlyAuthenticated = true;
      currentToken = cookieToken;
    }

    setIsAuthenticated(isCurrentlyAuthenticated);

    if (isCurrentlyAuthenticated) {
      setToken(currentToken);

      const decodedToken = jwtDecode(currentToken);
      const currentAuthRole = get(decodedToken, ["https://hasura.io/jwt/claims", "x-hasura-default-role"], null);
      const authPermissionsFromToken = get(decodedToken, ["https://hasura.io/jwt/claims", "x-auth0-permissions"], []);
      const currentTenantId = get(decodedToken, ["https://hasura.io/jwt/claims", "x-hasura-tenant-id"], null);
      const currentTenantKey = get(decodedToken, ["https://hasura.io/jwt/claims", "x-hasura-tenant-key"], null);
      const currentUser = get(decodedToken, ["https://hasura.io/jwt/claims", "x-hasura-user-id"], null);

      if (currentAuthRole) {
        setAuthRole(currentAuthRole);
      }

      if (currentTenantId) {
        setAuthTenantId(currentTenantId);
      }

      if (currentTenantKey) {
        setAuthTenantKey(currentTenantKey);
      }

      if (!isEmpty(authPermissionsFromToken)) {
        setAuthPermissions(authPermissionsFromToken);
      }

      getUser(currentUser, currentToken);

      if (ENV.IS_DEVELOPMENT) {
        console.log(decodedToken);
      }
    }

    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginCompleted = authToken => {
    createCookie("token", authToken, 86400000);

    setToken(authToken);
    setIsAuthenticated(true);
    setLoading(false);

    window.location = "/";
  };

  const logout = () => {
    removeCookie("token");
    window.location = "/";
  };

  const loginWithRedirect = e => {
    window.location = `/login${e.screen_hint ? `?action=${e.screen_hint}` : ""}`;
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        token,
        role,
        tenantId: authTenantId,
        tenantKey: authTenantKey,
        authRole,
        loading,
        authPermissions,
        loginCompleted,
        loginWithRedirect,
        logout,
        setActiveRole: r => setActiveRole(r),
        clearActiveRole: () => setActiveRole(null),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0ProviderComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export const Auth0Provider = withRouter(Auth0ProviderComponent);
