import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Alert, Button, message, Modal, Pagination, Table } from "antd";
import gql from "fraql";
import each from "lodash/each";
import get from "lodash/get";
import has from "lodash/has";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { serverErrorStatus } from "../../../utils/graphqlErrors";
import { getTotal } from "../../../utils/tableUtils";
import { PERSON_DETAIL_INFO } from "../../../schemas/personGql";
import { GET_REG_LIST_WITH_STEPS } from "../../../schemas/registrationGql";
import ColumnValueText from "../../ColumnValueText";
import NoAccessStatusText from "../../NoAccessStatusText";
import RegistrationStatus from "../../RegistrationStatus";
import SpinPageContent from "../../SpinPageContent";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: "270px",
    render: (text, record) => (
      <div style={{ display: "flex" }}>
        <RegistrationStatus size="small" reg={record} />
        <ColumnValueText>{text}</ColumnValueText>
      </div>
    ),
  },
  {
    title: "Group",
    dataIndex: "group",
    render: (text, record) => <ColumnValueText>{record.Group?.name ?? <NoAccessStatusText />}</ColumnValueText>,
  },
];

const CREATE_REG_SUBMISSIONS = gql`
  mutation ModalNewRegistration_createRegSubmissions($regSubmissions: [RegSubmissionInput!]!) {
    createRegSubmissions(regSubmissions: $regSubmissions) {
      created {
        id
        RegId
        PersonId
      }
    }
  }
`;

function mapRegDataToList(data) {
  if (!data) {
    return undefined;
  }

  return get(data, "Reg", []).map(regData => {
    const reg = omit(regData, ["__typename"]);

    return {
      ...reg,
      Steps: reg.Steps.map(step => omit(step, ["__typename"])),
      Group: omit(reg.Group, ["__typename"]),
    };
  });
}

function ModalAddRegsToPerson({ PersonId, regIds, setVisible, refetchPersonRegList }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);

  const hasPagination = useMemo(() => count > pageSize, [count, pageSize]);

  const { loading, error, data, refetch: refetchAvailablePersonRegList } = useQuery(GET_REG_LIST_WITH_STEPS, {
    variables: {
      offset,
      limit: pageSize,
      where: { id: { _nin: regIds }, archived: { _eq: false } },
    },
    onCompleted: res => {
      if (!res) {
        return;
      }

      setCount(get(res, "Reg_aggregate.aggregate.count"));
    },
  });

  const [createRegSubmissions, { loading: loadingCreateRegSubmissions }] = useMutation(CREATE_REG_SUBMISSIONS, {
    refetchQueries: [{ query: PERSON_DETAIL_INFO, variables: { id: PersonId } }],
    awaitRefetchQueries: true,
  });

  const registrations = useMemo(() => mapRegDataToList(data), [data]);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleSubmit = useCallback(async () => {
    const RegSubmissions = [];

    each(selectedRowKeys, RegId => {
      RegSubmissions.push({
        RegId,
        PersonId,
      });
    });

    const multiple = RegSubmissions.length > 1;

    try {
      await createRegSubmissions({ variables: { regSubmissions: RegSubmissions } });

      message.success(`Registration submission${multiple ? "s" : ""} added.`);

      setSelectedRowKeys([]);
      refetchPersonRegList();
      refetchAvailablePersonRegList();
    } catch (submitError) {
      console.error(submitError);

      const errorStatus = serverErrorStatus(submitError);

      const description = get(
        errorStatus,
        "description",
        "There was an error processing your request. Please try again later.",
      );

      const title = `Registration submission${multiple ? "s" : ""} not added`;

      Modal.error({ title, content: description });

      if (!has(errorStatus, "hasValidationError") && !has(errorStatus, "hasPermissionsError")) {
        Sentry.captureException(submitError);
      }
    }
  }, [selectedRowKeys, PersonId, refetchPersonRegList, refetchAvailablePersonRegList, createRegSubmissions]);

  function renderModal(content, style = {}) {
    return (
      <Modal
        title="Add Registration"
        visible
        onCancel={handleCancel}
        style={{ minWidth: 600 }}
        bodyStyle={{ padding: 0 }}
        footer={null}
      >
        <>
          <div style={{ padding: "24px 24px 0 24px", ...style }}>{content}</div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderTop: "1px solid #e8e8e8",
              padding: "16px 24px",
            }}
          >
            <Button onClick={handleCancel}>Cancel</Button>

            <Button
              htmlType="submit"
              type="primary"
              disabled={loading || error || isEmpty(selectedRowKeys)}
              loading={loadingCreateRegSubmissions}
              onClick={handleSubmit}
            >
              Add
            </Button>
          </div>
        </>
      </Modal>
    );
  }

  if (loading && !data) {
    return renderModal(<SpinPageContent style={{ height: "100%" }} />, { padding: 24 });
  }

  if (error) {
    return renderModal(
      <Alert
        message="Registrations failed to load"
        description="Sorry, there was an issue loading the data for this window."
        type="error"
        showIcon
      />,
      { padding: 24 },
    );
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: newSelectedRowKeys => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  function handleChangePage(newPage) {
    setOffset(newPage * pageSize - pageSize);
    setPage(newPage);
  }

  function onRow(record) {
    return {
      onClick: () => {
        const isSelected = includes(selectedRowKeys, record.id);
        const newSelectedRowKeys = isSelected
          ? selectedRowKeys.filter(id => id !== record.id)
          : [...selectedRowKeys, record.id];

        setSelectedRowKeys(newSelectedRowKeys);
      },
    };
  }

  const content = (
    <>
      <div style={{ marginBottom: 14, color: "grey" }}>Registrations currently available for this person:</div>

      <Table
        scroll={{ y: 379 }}
        dataSource={registrations}
        rowKey="id"
        className="person-detail-modal-reg-table"
        loading={loading}
        pagination={false}
        rowSelection={rowSelection}
        rowClassName="cursor-pointer"
        onRow={onRow}
        columns={columns}
        size="small"
        bordered={false}
      />

      <div className="pagination-one-page-total">
        {hasPagination ? (
          <Pagination
            size="small"
            pageSize={pageSize}
            current={page}
            total={count}
            showSizeChanger={false}
            showQuickJumper={false}
            showTotal={getTotal}
            onChange={handleChangePage}
          />
        ) : (
          getTotal(count)
        )}
      </div>
    </>
  );

  return renderModal(content);
}

ModalAddRegsToPerson.propTypes = {
  PersonId: PropTypes.string.isRequired,
  regIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setVisible: PropTypes.func.isRequired,
  refetchPersonRegList: PropTypes.func.isRequired,
};

export default ModalAddRegsToPerson;
