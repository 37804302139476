import React from "react";
import PropTypes from "prop-types";
import { Icon, Input, AutoComplete, Tag } from "antd";
import classnames from "classnames";

const { Option, OptGroup } = AutoComplete;

RegExp.escape = s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");

const HeaderAutocomplete = React.forwardRef(
  // Warning: forwardRef render functions accept exactly two parameters: props and ref.
  // Did you forget to use the ref parameter?
  // eslint-disable-next-line no-unused-vars
  ({ value, loading, error, dataSource, onChange, onSelect, onSelectMore, setQuery, query }, ref) => {
    function renderTitle(title, link) {
      const className = classnames("header-search-group-line", { "--disabled": !value });
      return (
        <button className={className} disabled={!value} type="button" onClick={() => onSelectMore(link)}>
          {title}
          <span>more</span>
        </button>
      );
    }

    const matchReplace = string => {
      const regex = new RegExp(RegExp.escape(value).replace(" ", "|"), "gi");
      return string.replace(regex, str => `<strong>${str}</strong>`);
    };

    const displayMatches = (title, subtitle) => (
      <>
        {/*  Matching text results in the query */}
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: matchReplace(title) }} />
        {/*  Matching text results in the query */}
        {/* eslint-disable-next-line react/no-danger */}
        {subtitle && <small dangerouslySetInnerHTML={{ __html: matchReplace(subtitle) }} />}
      </>
    );

    const options = dataSource.map(group => (
      <OptGroup key={group.title} label={renderTitle(group.title, group.moreLink)}>
        {group.children.map(opt => {
          const { uuid, title, subtitle } = opt;
          return (
            <Option key={title} value={uuid} obj={opt}>
              {displayMatches(title, subtitle)}
            </Option>
          );
        })}
      </OptGroup>
    ));

    const AutoCompleteIcon = () => {
      let icon = <Icon type="search" />;
      if (loading) {
        icon = <Icon type="loading" spin />;
      } else if (error) {
        icon = <Icon type="frown-o" />;
      }
      return <Input suffix={icon} />;
    };

    return (
      <>
        <AutoComplete
          defaultOpen={false}
          dropdownClassName="header-search-dropdown"
          className={classnames("header-search-field", { "--tags": query })}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ width: 300 }}
          style={{ width: "100%" }}
          dataSource={options}
          optionLabelProp="value"
          placeholder="Search..."
          onSearch={onChange}
          onSelect={onSelect}
          value={value}
          size="large"
          ref={ref}
        >
          {AutoCompleteIcon()}
        </AutoComplete>
        {query && (
          <div className="header-search-tags">
            <Tag color="volcano" onClose={() => setQuery(null)} closable>
              {query.groupname}
            </Tag>
          </div>
        )}
      </>
    );
  },
);

HeaderAutocomplete.propTypes = {
  // Warning: `getFieldDecorator` will override `value`,
  // so please don't set `value` directly and use `setFieldsValue` to set it.

  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
  dataSource: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onSelectMore: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

HeaderAutocomplete.defaultProps = {
  query: null,
  onChange: null,
  loading: false,
  error: false,
};

export default HeaderAutocomplete;
