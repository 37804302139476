import React from "react";
import { Spin } from "antd";

function SpinFullScreen() {
  return (
    <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Spin size="large" />
    </div>
  );
}

export default SpinFullScreen;
