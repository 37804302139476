import React from "react";
import { Typography } from "antd";
import classnames from "classnames";

export const renderMark = (props, editor, next) => {
  const { children, mark, attributes } = props;
  // Function components cannot be given refs. Attempts to access this ref will fail.
  delete attributes.ref;

  switch (mark.type) {
    case "bold":
      return (
        <Typography.Text {...attributes} strong>
          {children}
        </Typography.Text>
      );
    // This type is not used
    case "code":
      return (
        <Typography.Text {...attributes} code>
          {children}
        </Typography.Text>
      );
    case "italic":
      return <em {...attributes}>{children}</em>;
    case "underlined":
      return (
        <Typography.Text {...attributes} underline>
          {children}
        </Typography.Text>
      );
    default:
      return next();
  }
};

export const renderBlock = (props, editor, next) => {
  const { attributes, children, node, isFocused } = props;
  // Function components cannot be given refs. Attempts to access this ref will fail.
  delete attributes.ref;

  switch (node.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return (
        <Typography.Title {...attributes} level={1}>
          {children}
        </Typography.Title>
      );
    case "heading-two":
      return (
        <Typography.Title {...attributes} level={2}>
          {children}
        </Typography.Title>
      );
    case "heading-three":
      return (
        <Typography.Title {...attributes} level={3}>
          {children}
        </Typography.Title>
      );
    case "heading-four":
      return (
        <Typography.Title {...attributes} level={4}>
          {children}
        </Typography.Title>
      );
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "image": {
      const src = node.data.get("src");
      return <img {...attributes} src={src} className={classnames({ "is-focused": isFocused })} alt={src} />;
    }

    default:
      return next();
  }
};

export const renderInline = (props, editor, next) => {
  const { attributes, children, node } = props;
  delete attributes.ref;

  switch (node.type) {
    case "link": {
      const { data } = node;
      let href = data.get("href");
      if (href.search(/^https?:\/\//i) === -1) {
        href = `https://${href}`;
      }
      return (
        <a {...attributes} href={href} target="_blank" rel="noopener noreferrer" title={href}>
          {children}
        </a>
      );
    }

    default: {
      return next();
    }
  }
};
