import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import keys from "lodash/keys";
import startCase from "lodash/startCase";
import isBlank from "../utils/isBlank";

export function renderErrorFieldNames(fields, errors, getErrorFieldNames) {
  let errorFieldsNamesDisplay;

  if (isFunction(getErrorFieldNames)) {
    errorFieldsNamesDisplay = getErrorFieldNames(errors);
  } else {
    errorFieldsNamesDisplay = keys(errors).map(fieldName => {
      const field = get(fields, fieldName, null);
      const label = get(field, "label", "");

      if (!isBlank(label)) {
        return label;
      }

      return startCase(fieldName);
    });
  }

  return errorFieldsNamesDisplay.map((fieldName, index) => {
    const isLast = index === errorFieldsNamesDisplay.length - 1;

    return (
      <span key={fieldName}>
        <strong>{fieldName}</strong>
        {!isLast && ", "}
      </span>
    );
  });
}

function FormValidationMessage({ fields, errors, submitCount, alertContainerStyle, getErrorFieldNames }) {
  const showValidationError = !isEmpty(errors) && !!submitCount;

  if (!showValidationError) {
    return null;
  }

  const errorFieldsNames = isFunction(getErrorFieldNames) ? getErrorFieldNames(errors) : keys(errors);
  const hasMultipleFieldsWithErrors = errorFieldsNames.length > 1;

  let message;
  let description;

  if (hasMultipleFieldsWithErrors) {
    message = "Invalid fields";

    description = (
      <span>
        The form cannot be submitted due to validation errors with the following fields:{" "}
        {renderErrorFieldNames(fields, errors, getErrorFieldNames)}. Please resolve these errors and then try again.
      </span>
    );
  } else {
    message = "Invalid field";

    description = (
      <span>
        The form cannot be submitted due to a validation error with the following field:{" "}
        {renderErrorFieldNames(fields, errors, getErrorFieldNames)}. Please resolve this error and then try again.
      </span>
    );
  }

  return (
    <div style={alertContainerStyle}>
      <Alert message={message} description={description} type="error" showIcon />
    </div>
  );
}

FormValidationMessage.propTypes = {
  fields: PropTypes.object,
  errors: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired,
  alertContainerStyle: PropTypes.object,
  getErrorFieldNames: PropTypes.func,
};

FormValidationMessage.defaultProps = {
  fields: null,
  alertContainerStyle: { marginBottom: 16 },
  getErrorFieldNames: null,
};

export default FormValidationMessage;
