import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import isNull from "lodash/isNull";
import isBlank from "../utils/isBlank";
import { getOptionLabelFromValue } from "../utils/selectUtils";
import ColumnValueText from "./ColumnValueText";
import EmptyValueText from "./EmptyValueText";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "./StatusText";

function ColumnValueTeamPosition({ position, availableTeamPositions }) {
  if (isBlank(position)) {
    return <EmptyValueText />;
  }

  const teamPositionLabel = getOptionLabelFromValue(position, availableTeamPositions);

  if (isNull(teamPositionLabel)) {
    return (
      <Tooltip placement="topLeft" trigger="hover" title={`${position} [Invalid]`}>
        <ColumnValueText>
          <StatusText status={STATUS_TEXT_STATUS_OPTIONS.ERROR}>{position} [Invalid]</StatusText>
        </ColumnValueText>
      </Tooltip>
    );
  }

  return (
    <Tooltip placement="topLeft" trigger="hover" title={teamPositionLabel}>
      <ColumnValueText>{teamPositionLabel}</ColumnValueText>
    </Tooltip>
  );
}

ColumnValueTeamPosition.propTypes = {
  position: PropTypes.string.isRequired,
  availableTeamPositions: PropTypes.arrayOf(PropTypes.string),
};

ColumnValueTeamPosition.defaultProps = {
  availableTeamPositions: [],
};

export default ColumnValueTeamPosition;
