import isNil from "lodash/isNil";

/**
 * Copied from dotenv-parse-variables/lib/index.js#parseKey and updated to handle keys with nil values.
 */
const parseEnvironmentVariable = value => {
  if (isNil(value)) {
    return null;
  }

  // If the value is wrapped in backticks e.g. (`value`) then just return its value.
  if (value.toString().indexOf("`") === 0 && value.toString().lastIndexOf("`") === value.toString().length - 1) {
    return value.toString().substring(1, value.toString().length - 1);
  }

  // If the value ends in an asterisk then just return its value.
  if (value.toString().lastIndexOf("*") === value.toString().length - 1 && value.toString().indexOf(",") === -1) {
    return value.toString().substring(0, value.toString().length - 1);
  }

  // Boolean.
  if (value.toLowerCase() === "true" || value.toLowerCase() === "false") {
    return value === "true";
  }

  // Array.
  if (value.indexOf(",") !== -1) {
    return value.split(",").map(parseEnvironmentVariable);
  }

  // String.
  return value;
};

/**
 * Copied from dotenv-parse-variables and updated to return a new object instead of mutating the input object.
 */
const parseEnvironmentVariables = env => {
  const parsedEnv = {};

  Object.keys(env).forEach(key => {
    parsedEnv[key] = parseEnvironmentVariable(env[key]);
  });

  return parsedEnv;
};

export default parseEnvironmentVariables;
