import React from "react";
import { Icon, Typography } from "antd";
import classnames from "classnames";
import get from "lodash/get";
import isNil from "lodash/isNil";
import keyBy from "lodash/keyBy";
import { CONDITION_TYPES } from "../../../FormFields/FormFieldTeamConditions/TeamCondition";

function teamTooSmall(teamMin, teamSize) {
  if (isNil(teamMin)) {
    return false;
  }
  return teamSize < teamMin;
}

function teamTooLarge(teamMax, teamSize) {
  if (isNil(teamMax)) {
    return false;
  }
  return teamSize > teamMax;
}

function getTeamSizeAlertSuccessMessage(teamMin, teamMax, noun) {
  if (!isNil(teamMin) && isNil(teamMax)) {
    return `This team meets the minimum requirement of ${teamMin} ${noun}.`;
  }

  if (!isNil(teamMin) && !isNil(teamMax)) {
    return `This team meets the minimum requirement of ${teamMin} ${noun} and the maximum requirement of ${teamMax} ${noun}.`;
  }

  if (isNil(teamMin) && !isNil(teamMax)) {
    return `This team meets the maximum requirement of ${teamMax} ${noun}.`;
  }

  return `This team has an acceptable number of ${noun}.`;
}

function getMemberCountMessage(teamCondition, currentCount, noun) {
  const { minCount, maxCount } = teamCondition;

  if (isNil(currentCount)) {
    throw new Error("Team currentCount cannot be nil.");
  }

  let message;
  let messageType;
  if (teamTooSmall(minCount, currentCount)) {
    message = `This team has not yet reached the required minimum of ${minCount} ${noun}.`;
    messageType = "warning";
  } else if (teamTooLarge(maxCount, currentCount)) {
    message = `This team is above the maximum size of ${maxCount} ${noun}.`;
    messageType = "warning";
  } else {
    message = getTeamSizeAlertSuccessMessage(minCount, maxCount, noun);
    messageType = "";
  }

  return <Typography.Text type={messageType}>{message}</Typography.Text>;
}

function getParticipationRoleMessage(teamCondition, currentCount, rolesById) {
  const { roleId } = teamCondition;
  const roleName = get(rolesById, [roleId, "name"]);

  if (isNil(roleName)) {
    throw new Error(`Unknown role id: ${roleId}`);
  }

  return getMemberCountMessage(teamCondition, currentCount, `${roleName} member(s)`);
}

function getTeamPositionMandatoryMessage(teamCondition, currentCount) {
  if (isNil(currentCount)) {
    throw new Error("Team currentCount cannot be nil.");
  }

  let message;
  let messageType;
  if (currentCount > 0) {
    message =
      "This team does not meet the requirement that a position is set for every person who has joined this team. Please set the positions “Not set” in the table below in the competitor's registration submission.";
    messageType = "warning";
  } else {
    message = "This team meets the requirement that a position is set for a person who has joined this team.";
    messageType = "";
  }

  return <Typography.Text type={messageType}>{message}</Typography.Text>;
}

function getStatusMessage(teamCondition, currentCount, rolesById) {
  const { conditionType } = teamCondition;

  switch (conditionType) {
    case CONDITION_TYPES.PARTICIPATION_ROLE:
      return getParticipationRoleMessage(teamCondition, currentCount, rolesById);
    case CONDITION_TYPES.SIZE_LIMIT:
      return getMemberCountMessage(teamCondition, currentCount, "total member(s)");
    case CONDITION_TYPES.TEAM_POSITION_MANDATORY:
      return getTeamPositionMandatoryMessage(teamCondition, currentCount);
    default:
      throw new Error(`Unknown team condition type: ${conditionType}`);
  }
}

// eslint-disable-next-line import/prefer-default-export
export function getTeamConditionStatusMessage(conditionStatus, index, teamConditionRoles) {
  const { currentCount, isSatisfied, teamCondition } = conditionStatus;
  const rolesById = keyBy(teamConditionRoles, "id");

  const iconType = isSatisfied ? "check" : "warning";

  const className = classnames("team-condition", {
    "team-condition--success": isSatisfied,
    "team-condition--warning": !isSatisfied,
  });

  return (
    <div key={index} className={className}>
      <Icon type={iconType} /> {getStatusMessage(teamCondition, currentCount, rolesById)}
    </div>
  );
}
