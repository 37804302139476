import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Drawer, Alert, message } from "antd";
import gql from "fraql";
import * as yup from "yup";
import get from "lodash/get";
import includes from "lodash/includes";
import isNil from "lodash/isNil";
import isNull from "lodash/isNull";
import isBlank from "../utils/isBlank";
import { schemaNullable } from "../utils/yupUtils";
import ActionForm from "./ActionForm";
import { DISPLAY_MODES as FORM_FIELD_GROUP_DISPLAY_MODES } from "./FormFields/FormFieldGroup";
import SpinPageContent from "./SpinPageContent";

export const GET_TEAM = gql`
  query DrawerUpdateTeam_GetTeam($teamId: String!) {
    Team_by_pk(id: $teamId) {
      id
      name
      GroupId
    }
  }
`;

const UPDATE_TEAM = gql`
  mutation DrawerUpdateTeam_UpdateTeam($id: ID!, $name: String!, $GroupId: ID) {
    updateTeam(id: $id, name: $name, GroupId: $GroupId) {
      TeamId
    }
  }
`;

const TEAM_FIELDS = {
  name: {
    type: "text",
    label: "Team name",
    required: true,
  },
};

const GROUP_FIELDS = {
  GroupId: {
    type: "group",
    label: "Group",
    placeholder: "Select a group",
    required: false,
    displayMode: FORM_FIELD_GROUP_DISPLAY_MODES.treeSelect,
  },
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Please enter a team name.")
    // eslint-disable-next-line no-template-curly-in-string
    .max(255, "Name must be ${max} characters or fewer."),
  GroupId: schemaNullable(yup.string()),
});

const getErrorStatus = error => {
  const defaultStatus = { type: "error" };
  const errorMessage = get(error, "message", "");

  if (isBlank(errorMessage)) {
    return defaultStatus;
  }

  if (includes(errorMessage, "Uniqueness violation")) {
    return {
      ...defaultStatus,
      message: "Team already exists",
      description: "A team with this name already exists. Please select the existing team or choose a different name.",
    };
  }

  return defaultStatus;
};

function DrawerUpdateTeam({ isVisible, handleClose, handleSuccess, teamId, mutationOptions, showGroupField }) {
  const { loading: teamLoading, error: teamError, data: teamData } = useQuery(GET_TEAM, {
    variables: { teamId },
    skip: isNil(teamId),
  });

  const initialValues = useMemo(() => {
    return {
      name: get(teamData, "Team_by_pk.name", ""),
      GroupId: get(teamData, "Team_by_pk.GroupId", null),
    };
  }, [teamData]);

  const [updateTeam] = useMutation(UPDATE_TEAM, mutationOptions);

  const fields = useMemo(() => {
    let result;

    if (showGroupField) {
      result = { ...TEAM_FIELDS, ...GROUP_FIELDS };
    } else {
      result = TEAM_FIELDS;
    }

    return result;
  }, [showGroupField]);

  const handleSubmit = useCallback(
    async (values, actions) => {
      const { name, GroupId } = values;

      try {
        const result = await updateTeam({ variables: { name, id: teamId, GroupId } });

        const updatedTeamId = get(result, "data.updateTeam.TeamId", null);

        if (!isNull(updatedTeamId)) {
          message.success("Team saved.");

          if (handleSuccess) {
            handleSuccess(updatedTeamId);
          }

          actions.resetForm();
        } else {
          actions.setStatus({
            type: "error",
            message: "Permission denied",
            description: "You can only edit teams that you have created.",
          });

          actions.setSubmitting(false);
        }
      } catch (error) {
        console.error(error);

        actions.setSubmitting(false);
        actions.setStatus(getErrorStatus(error));
      }
    },
    [updateTeam, handleSuccess, teamId],
  );

  function renderContent() {
    if (teamLoading && !teamData) {
      return <SpinPageContent />;
    }

    if (teamError) {
      return (
        <Alert
          message="Team failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      );
    }

    return (
      <ActionForm
        fields={fields}
        initialValues={initialValues}
        validationSchema={validationSchema}
        handleSubmit={handleSubmit}
        submitText="Save"
        formLayout="vertical"
      />
    );
  }

  if (!isVisible) {
    return null;
  }

  return (
    <Drawer title="Edit Team" width={520} onClose={handleClose} visible={isVisible}>
      {renderContent()}
    </Drawer>
  );
}

DrawerUpdateTeam.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func,
  teamId: PropTypes.string,
  mutationOptions: PropTypes.object,
  showGroupField: PropTypes.bool,
};

DrawerUpdateTeam.defaultProps = {
  teamId: null,
  mutationOptions: {},
  handleSuccess: null,
  showGroupField: false,
};

export default DrawerUpdateTeam;
