//  Inspired by https://github.com/ant-design/ant-design/issues/6576#issuecomment-479445435.
// antd v2 icon names here: https://2x.ant.design/components/icon/

import React, { useContext, useMemo } from "react";
import { Menu, Icon } from "antd";
import { NavLink, withRouter } from "react-router-dom";
import get from "lodash/get";
import startsWith from "lodash/startsWith";
import { getGlobalNavMenuElevated, getGlobalNavMenuNormal } from "../../constants/globalNavMenuConstants";
import { useGetUserHasPermission, hasPermission, PERMISSION } from "../../utils/useGetUserHasPermission";
import { useAuth0 } from "../Auth0";
import { ThemeContext } from "../Theme/ThemeContext";
import "./GlobalNavMenu.scss";

/* eslint-disable react/prop-types */
const Item = ({ path, children, exact, usePath, ...restProps }) => {
  // TODO: Use the `usePath` prop, if necessary.
  // Previously it was not being used, even though it's defined for some nav items in GLOBAL_NAV_MENU.

  let link;

  if (startsWith(path, "https://") || startsWith(path, "http://")) {
    link = (
      <a
        className="global-nav-menu__link global-nav-menu__link--legal"
        href={path}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  } else {
    link = (
      <NavLink className="global-nav-menu__link" activeClassName="active" to={path} exact={exact}>
        {children}
      </NavLink>
    );
  }

  return <Menu.Item {...restProps}>{link}</Menu.Item>;
};

const renderMenuItemChildren = ({ icon, title }) => (
  <>
    {!!icon && <Icon type={icon} />}
    <span>{title}</span>
  </>
);

const renderMenuItem = (navItem, userRole) => {
  const navItemUsePath = get(navItem, "usePath", true);
  const navItemExact = get(navItem, "exact", false);
  const navItemDisabled = get(navItem, "disabled", false);
  const navItemPermission = get(navItem, "permission", null);
  const requiresAuthentication = get(navItem, "requiresAuthentication", true);

  const isAuthorized = requiresAuthentication ? hasPermission({ permission: navItemPermission, userRole }) : true;

  if (!isAuthorized) {
    return null;
  }

  if (navItem.submenu) {
    return (
      <Menu.SubMenu key={navItem.path} title={renderMenuItemChildren(navItem)}>
        {navItem.submenu.map(subMenuNavItem => renderMenuItem(subMenuNavItem, userRole))}
      </Menu.SubMenu>
    );
  }

  return (
    <Item
      key={navItem.path}
      path={navItem.path}
      usePath={navItemUsePath}
      exact={navItemExact}
      disabled={navItemDisabled}
    >
      {navItem.children ? navItem.children : renderMenuItemChildren(navItem)}
    </Item>
  );
};

function GlobalNavMenu({ location }) {
  const root = `/${location.pathname.split("/")[0]}`;
  const { role: userRole } = useAuth0();
  const hasElevatedAccess = useGetUserHasPermission(PERMISSION.ACCESS_ELEVATED_GLOBAL_NAVIGATION);
  const { privacyPageLink, termsPageLink } = useContext(ThemeContext) || "";

  const navMenu = useMemo(() => {
    if (hasElevatedAccess) {
      return getGlobalNavMenuElevated({ privacyPageLink, termsPageLink });
    }
    return getGlobalNavMenuNormal({ privacyPageLink, termsPageLink });
  }, [privacyPageLink, termsPageLink, hasElevatedAccess]);

  return (
    <Menu
      theme="light"
      mode="horizontal"
      style={{ padding: 0, marginLeft: 24, lineHeight: "62px", borderBottom: "none" }}
      defaultOpenKeys={[root]}
      selectedKeys={[root, location.pathname]}
    >
      {navMenu.map(navItem => renderMenuItem(navItem, userRole))}
    </Menu>
  );
}

// eslint-disable-next-line no-func-assign
GlobalNavMenu = withRouter(GlobalNavMenu);

export default GlobalNavMenu;
