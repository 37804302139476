import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "antd";
import classnames from "classnames";
import "./ButtonMore.scss";

function ButtonMore({ className, ...restProps }) {
  const combinedClassName = classnames(className, {
    "button-more": true,
  });

  return (
    <Button className={combinedClassName} {...restProps}>
      <Icon type="more" />
    </Button>
  );
}

ButtonMore.propTypes = {
  className: PropTypes.string,
};

ButtonMore.defaultProps = {
  className: null,
};

export default ButtonMore;
