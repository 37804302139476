import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { AUTH_ROLES } from "../../constants/authConstants";
import * as ROUTES from "../../constants/routeConstants";
import { PERMISSION } from "../../utils/useGetUserHasPermission";
import { useAuth0 } from "../Auth0";
import PrivateRoute from "../PrivateRoute";
import SpinFullScreen from "../SpinFullScreen";
import MyRegistrationsPage from "./MyRegistrations/MyRegistrationsPage";
import RegistrationUserDetailPage from "./RegistrationUserDetailPage";
import RegistrationUserSubmitPage from "./RegistrationUserSubmitPage";

function App() {
  const { setActiveRole, clearActiveRole, role } = useAuth0();

  useEffect(
    () => {
      setActiveRole(AUTH_ROLES.BASIC_USER);

      return () => {
        clearActiveRole();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (role !== AUTH_ROLES.BASIC_USER) {
    return <SpinFullScreen />;
  }

  return (
    <Switch>
      <PrivateRoute
        path={ROUTES.MY_REGISTRATIONS}
        component={MyRegistrationsPage}
        permission={PERMISSION.ACCESS_USER_REGISTRATIONS}
      />
      <PrivateRoute
        path={ROUTES.USER_REGISTRATION_SUBMIT_PATH}
        component={RegistrationUserSubmitPage}
        permission={PERMISSION.ACCESS_USER_REGISTRATIONS}
      />
      <Route exact path={ROUTES.USER_REGISTRATION_DETAIL_PATH} component={RegistrationUserDetailPage} />
    </Switch>
  );
}

export default App;
