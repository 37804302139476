// eslint-disable-next-line import/prefer-default-export
export const AUTH_ROLES = {
  GLOBAL_ADMIN: "global-administrator",
  ADMIN: "administrator",
  ANONYMOUS: "anonymous",
  BASIC_USER: "user",
  ADMINISTRATOR_GROUP: "administrator-group",
  MANAGER: "manager",
  MANAGER_READ_ONLY: "manager-read-only",
};

// Used for Tenant Administrators to configure Auth Roles for their own Tenancy's.
export const TENANT_AUTH_ROLES = {
  GLOBAL_ADMIN: "global-administrator",
  ADMIN: "administrator",
  ADMINISTRATOR_GROUP: "administrator-group",
  MANAGER: "manager",
  MANAGER_READ_ONLY: "manager-read-only",
};
