import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import get from "lodash/get";

const { Title } = Typography;

function FormFieldTitle(props) {
  const { meta } = props;

  const level = get(meta, "level", 3);
  const style = get(meta, "style", { marginTop: "45px" });

  let label = get(meta, "label", "");

  if (!label) {
    label = "[No title]";
  }

  return (
    <Title level={level} style={style}>
      {label}
    </Title>
  );
}

FormFieldTitle.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default FormFieldTitle;
