import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import classnames from "classnames";
import { connect } from "formik";
import filter from "lodash/filter";
import get from "lodash/get";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import omit from "lodash/omit";
import { SELECT_FIELD_MODES } from "../../constants/fieldConstants";
import { getFlattenedOptions, orderOptionsByLabel } from "../../utils/optionsUtils";
import { parseFormFieldChoicesAsOptions, filterOption, renderOptions } from "../../utils/selectUtils";
import Select from "../FormikAntD/Select";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldReadOnlyContent from "./FormFieldReadOnlyFields/FormFieldReadOnlyContent";
import ReadOnlyText from "./FormFieldReadOnlyFields/ReadOnlyText";
import "./FormFieldSelect.scss";

function FormFieldSelect(props) {
  const { name, meta, disabled, loading, readOnly, formik } = props;
  const { setFieldTouched, setFieldValue } = formik;

  const allowClear = get(meta, "allowClear", true);
  const options = get(meta, "options", []);
  const choices = get(meta, "choices", []);
  const containerId = get(meta, "containerId", undefined);
  const popupContainer = containerId ? document.getElementById(containerId) : document.body;
  const handleSelectChange = get(meta, "handleSelectChange", undefined);
  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const style = get(meta, "style", undefined);
  const value = get(formik, `values.${name}`, null);
  const allowSelectAll = get(meta, "allowSelectAll", false);
  const dropdownMatchSelectWidth = get(meta, "dropdownMatchSelectWidth", false);

  let mode = get(meta, "mode", null);

  if (!mode) {
    mode = SELECT_FIELD_MODES.default;
  }

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Select an option";
  }

  if (!isEmpty(options) && !isEmpty(choices)) {
    console.error("Error: The choices array and the options array both have content, while only one should.");
  }

  if (allowSelectAll && mode !== SELECT_FIELD_MODES.multiple) {
    console.error("Only mode multiple can display select all button.");
  }

  const showSelectAllButton = allowSelectAll && mode === SELECT_FIELD_MODES.multiple;

  const selectOptions = useMemo(() => {
    if (!isEmpty(options)) {
      return options;
    }

    return parseFormFieldChoicesAsOptions(choices, mode);
  }, [options, choices, mode]);

  const activeOptionValues = useMemo(() => {
    const flattenedOptions = getFlattenedOptions(selectOptions);
    return flattenedOptions.filter(option => !option.disabled).map(option => option.value);
  }, [selectOptions]);

  const handleSelectAllClick = useCallback(() => {
    setFieldTouched(name, true);
    if (value?.length === activeOptionValues.length) {
      setFieldValue(name, []);
    } else {
      setFieldValue(name, activeOptionValues);
    }
  }, [name, value, activeOptionValues, setFieldTouched, setFieldValue]);

  function renderSelectAllButton() {
    if (!showSelectAllButton) {
      return null;
    }

    let selectAllButtonLabel;

    if (value?.length > 0 && value?.length === activeOptionValues.length) {
      selectAllButtonLabel = "Deselect All";
    } else {
      selectAllButtonLabel = "Select All";
    }

    return (
      <Button
        onClick={handleSelectAllClick}
        disabled={activeOptionValues.length === 0}
        className="form-field-select__select-all-button"
      >
        {selectAllButtonLabel}
      </Button>
    );
  }

  const flattenedAndOrderedOptions = useMemo(() => {
    const flattenedOptions = getFlattenedOptions(selectOptions);
    return orderOptionsByLabel(flattenedOptions);
  }, [selectOptions]);

  function getReadOnlyContent() {
    if (isEmpty(value)) {
      return null;
    }

    const valuesArray = isArray(value) ? value : [value];

    const components = filter(flattenedAndOrderedOptions, option => includes(valuesArray, option.value))
      .map(option => get(option, "label", null))
      .filter(label => !isNull(label))
      .map((label, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {index > 0 && <span>, </span>}
          {label}
        </React.Fragment>
      ));

    return <ReadOnlyText>{components}</ReadOnlyText>;
  }

  if (readOnly) {
    return <FormFieldReadOnlyContent name={name} meta={meta} content={getReadOnlyContent()} />;
  }

  const className = classnames("form-field-select", {
    "form-field-select--with-select-all": showSelectAllButton,
  });

  return (
    <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} className={className}>
      <Select
        name={name}
        placeholder={placeholder}
        allowClear={allowClear}
        onChange={handleSelectChange}
        filterOption={filterOption}
        getPopupContainer={() => popupContainer}
        disabled={disabled}
        loading={loading}
        mode={mode}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        tokenSeparators={[","]}
        notFoundContent="No options available"
        style={style}
        showSearch
      >
        {renderOptions(selectOptions)}
      </Select>
      {renderSelectAllButton()}
    </FormFieldFormItem>
  );
}

FormFieldSelect.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

FormFieldSelect.defaultProps = {
  disabled: false,
  loading: false,
  readOnly: false,
};

export default connect(FormFieldSelect);
