import { REG_SUBMISSION_STATUSES } from "../../../constants/regStatusConstants";

export const REGISTRATION_LIST_MODE = {
  CURRENT: "CURRENT",
  COMPLETED: "COMPLETED",
};

export const LIST_REG_SUBMISSION_STATUSES = {
  [REGISTRATION_LIST_MODE.COMPLETED]: [
    REG_SUBMISSION_STATUSES.approved,
    REG_SUBMISSION_STATUSES.submitted,
    REG_SUBMISSION_STATUSES.rejected,
  ],
  [REGISTRATION_LIST_MODE.CURRENT]: [REG_SUBMISSION_STATUSES.notSubmitted, REG_SUBMISSION_STATUSES.reverted],
};
