/**
 * Determine whether the main submit button and the Save Draft submit button are enabled/disabled. If
 * `useFormValidityForSubmitDisabled` is true, then the logic we use is based primarily on whether the form's
 * current values are valid, based on `formValidationSchema`; if they are valid then the main submit button
 * should be enabled while the Save Draft submit button should be disabled, and if they are not valid then it
 * is the opposite. If `useFormValidityForSubmitDisabled` is false (the default), then the logic we use is based
 * on whether the form is dirty (and the `allowNonDirtySubmit` prop). Note that if `disabled` is true then
 * both the main submit button and the Save Draft submit button should be disabled regardless of any other
 * conditions.
 */
export function getSubmitDisabled(
  disabled,
  disableSubmit,
  useFormValidityForSubmitDisabled,
  disableSubmitFromFormValidity,
  disableSubmitFromFormDirty,
  handleSubmitDraft,
  hasUpdatingFields,
) {
  if (disabled || disableSubmit || hasUpdatingFields) {
    return true;
  }

  if (useFormValidityForSubmitDisabled) {
    if (handleSubmitDraft) {
      return disableSubmitFromFormValidity;
    }

    // If the "Save Draft" submit button is not going to be available, we need to ensure that the main submit button is
    // always enabled (except for when submission should be disabled based on external logic via the `disableSubmit`
    // prop), because if the current form values are not valid and they were populated from existing saved values, we
    // need the user to be able to attempt to submit the form so that they can then see the validation error messages
    // showing which form values are not valid. Otherwise, because the form fields are initially not touched and there
    // has not yet been an attempted submission of the form, all of the validation error messages will not be shown to
    // the user (making it very hard to know what values are not valid), and at the same time the user would not be able
    // to attempt to submit the form because it's not valid, leading to them being stuck in this scenario unless they
    // manage to figure out manually what form values are not valid.
    return false;
  }

  return disableSubmitFromFormDirty;
}

/**
 * If submission should be disabled based on external logic (i.e. logic not relating to this form's validity or
 * dirty status etc.), then draft submission should also be disabled. Otherwise, draft submission should be disabled
 * when normal submission is enabled (and vice versa), ensuring that both submit buttons are never enabled at the same
 * time.
 */
export function getSubmitDisabledDraft(
  disabled,
  disableSubmitDraft,
  useFormValidityForSubmitDisabled,
  disableSubmitFromFormValidity,
  disableSubmitFromFormDirty,
  hasUpdatingFields,
) {
  if (disabled || disableSubmitDraft || hasUpdatingFields) {
    return true;
  }

  if (useFormValidityForSubmitDisabled) {
    return !disableSubmitFromFormValidity;
  }

  return disableSubmitFromFormDirty;
}
