import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert } from "antd";
import gql from "fraql";
import get from "lodash/get";
import { REG_TRAVEL_STATUS, REG_TRAVEL_STATUS_LABEL } from "./travelGroupsConstants";
import useRegTravelGroupsEnabled from "./useRegTravelGroupsEnabled";

const GET_REGISTRATION = gql`
  query PersonRegistrationTravelStatus_GetRegistration($RegId: String!) {
    Reg_by_pk(id: $RegId) {
      id
      travelOpenAt
      travelCloseAt
      ArrivalFormId
      DepartureFormId
    }
  }
`;

export const PERSON_REGISTRATION_TRAVEL_STATUS_GET_REGISTRATION_SUBMISSION = gql`
  query PersonRegistrationTravelStatus_GetRegistrationSubmission($PersonId: String, $RegId: String!) {
    RegSubmission(where: { PersonId: { _eq: $PersonId }, RegId: { _eq: $RegId } }) {
      id
      travelInfoStatus
      PersonId
      RegId
    }
  }
`;

function PersonRegistrationTravelStatusAlert({ PersonId, RegId, showWhenTravelGroupsDisabled, style }) {
  const { loading: regLoading, error: regError, data: regData } = useQuery(GET_REGISTRATION, {
    variables: { RegId },
  });

  const { loading: regSubmissionLoading, error: regSubmissionError, data: regSubmissionData } = useQuery(
    PERSON_REGISTRATION_TRAVEL_STATUS_GET_REGISTRATION_SUBMISSION,
    {
      variables: { PersonId, RegId },
    },
  );

  const reg = useMemo(() => get(regData, "Reg_by_pk", {}), [regData]);

  const regSubmission = useMemo(() => get(regSubmissionData, "RegSubmission.0", {}), [regSubmissionData]);

  const regTravelGroupsEnabled = useRegTravelGroupsEnabled(reg);

  const alert = useMemo(() => {
    if ((regLoading && !regData) || (regSubmissionLoading && !regSubmissionData)) {
      return {
        description: "Loading...",
        type: "info",
      };
    }

    if (regError || regSubmissionError || !reg) {
      return {
        description: "Registration submission failed to load",
        type: "error",
      };
    }

    if (!regTravelGroupsEnabled) {
      return {
        description: "Not available",
        type: "info",
      };
    }

    const travelStatus = get(regSubmission, "travelInfoStatus", null);

    if (travelStatus === REG_TRAVEL_STATUS.IN_PROGRESS) {
      return {
        description: REG_TRAVEL_STATUS_LABEL[REG_TRAVEL_STATUS.IN_PROGRESS],
        type: "info",
      };
    }

    if (travelStatus === REG_TRAVEL_STATUS.COMPLETE) {
      return {
        description: REG_TRAVEL_STATUS_LABEL[REG_TRAVEL_STATUS.COMPLETE],
        type: "success",
      };
    }

    return {
      description: REG_TRAVEL_STATUS_LABEL[REG_TRAVEL_STATUS.INCOMPLETE],
      type: "info",
    };
  }, [
    regLoading,
    regError,
    regData,
    regSubmissionLoading,
    regSubmissionError,
    regSubmissionData,
    reg,
    regTravelGroupsEnabled,
    regSubmission,
  ]);

  if (!regTravelGroupsEnabled && !showWhenTravelGroupsDisabled) {
    return null;
  }

  return (
    <Alert
      message="Travel information"
      description={alert.description}
      type={alert.type}
      showIcon={false}
      className="ant-alert-with-description alert-registration-status"
      style={style}
    />
  );
}

PersonRegistrationTravelStatusAlert.propTypes = {
  PersonId: PropTypes.string.isRequired,
  RegId: PropTypes.string.isRequired,
  showWhenTravelGroupsDisabled: PropTypes.bool.isRequired,
  style: PropTypes.any,
};

PersonRegistrationTravelStatusAlert.defaultProps = {
  style: {},
};

export default PersonRegistrationTravelStatusAlert;
