import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { Formik } from "formik";
import { Form } from "formik-antd";
import each from "lodash/each";
import get from "lodash/get";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { FORM_ITEM_LAYOUT_PROPS_BY_FORM_LAYOUT } from "../constants/formConstants";
import { FormContext } from "../utils/contextUtils";
import FormikPersist from "../utils/formikPersist";
import { checkValuesValidForYupSchema } from "../utils/formikUtils";
import { getSubmitDisabled, getSubmitDisabledDraft } from "../utils/formUtils";
import useInitialValuesFromFields from "../utils/useInitialValuesFromFields";
import useSchemaFromFields, { isFieldVisibleFromCondition } from "../utils/useSchemaFromFields";
import ActionFormField from "./ActionFormField";
import FormStatusMessage from "./FormStatusMessage";
import FormValidationMessage, { renderErrorFieldNames } from "./FormValidationMessage";
import PromptDirtyForm from "./PromptDirtyForm";
import "./ActionForm.scss";

export function getFieldsWithConditionalVisibilityAndDisabled(fields, values, disabled) {
  const result = {};

  each(Object.keys(fields), fieldId => {
    let visible = true;

    const field = fields[fieldId];
    const conditions = get(field, "conditions", []);

    if (!isEmpty(conditions)) {
      visible = conditions.reduce((isVisible, condition) => {
        if (!isVisible) {
          return false;
        }
        const { field: conditionFieldId } = condition;
        return isFieldVisibleFromCondition(condition, fields[conditionFieldId], values[conditionFieldId]);
      }, true);
    }

    result[fieldId] = { ...field, visible };

    if (disabled) {
      result[fieldId].disabled = true;
    }
  });

  return result;
}

/**
 * This component is used as the basis for forms and form layouts. The submit button behaviour can be customised. The
 * default behaviour is to disable the submit button until a form value has changed (dirty becomes true). This behaviour
 * can be changed with props.
 *
 * Props:
 * - disabled - if true, the submit button(s) and all the fields will be disabled regardless any other conditions or props.
 * - allowNonDirtySubmit - only applies when `disabled` is false and `useFormValidityForSubmitDisabled` is false.
 *   If this prop is true, the submit button will be enabled regardless of whether the form is dirty or not.
 * - useFormValidityForSubmitDisabled - determine whether submit button(s) are disabled based primarily on the validity
 *   of the current form data, instead of based on whether the form is dirty (which is the default behaviour).
 * - renderFields - if a function is passed, then this function will be called to render *all* fields for the form.
 * - renderField - if a function is passed, then this function will be called to *optionally* override the render of
 *   particular fields in the form. If the `renderField` function returns `undefined`, then ActionForm will use the
 *   normal mechanism for rendering the form field. Otherwise, the return value from `renderField` will be used.
 */
const ActionForm = React.forwardRef((props, ref) => {
  const {
    fields,
    initialValues,
    validationSchema,
    validationSchemaDraft,
    handleSubmit,
    handleSubmitDraft,
    handleCancel,
    handleBack,
    submitText,
    saveDraftText,
    submitSize,
    showFormFieldContentAsPreview,
    disabled,
    disableSubmit,
    disableSubmitDraft,
    allowNonDirtySubmit,
    useFormValidityForSubmitDisabled,
    saveContext,
    persistId,
    FormBottomComponent,
    formLayout,
    renderFields,
    renderField,
    relatedRecordsData,
    showFormBottomComponent,
    readOnly,
    className,
  } = props;

  const initFields = useMemo(() => getFieldsWithConditionalVisibilityAndDisabled(fields, initialValues, disabled), [
    fields,
    initialValues,
    disabled,
  ]);

  const formInitialValues = useInitialValuesFromFields(initialValues, initFields);

  // Get the standard form validation schema and also the form validation schema for draft submission. Currently, the
  // only difference is that for the form validation schema for draft submission, we do not apply validation based on
  // the value configured for the `required` option for each field. This means that for draft submission purposes, any
  // field which would normally require a value will instead accept an empty value; however, all other validation rules
  // will still apply, so a user will not be able to submit a non-empty and invalid value (e.g. a value for a postal
  // code field which is is not blank but is less than 4 characters in length).
  const formValidationSchema = useSchemaFromFields(validationSchema, initFields, true);
  const formValidationSchemaDraft = useSchemaFromFields(validationSchemaDraft || validationSchema, initFields, false);

  const [isInitialValid, setIsInitialValid] = useState(undefined);

  useEffect(() => {
    let cancelled = false;

    async function checkIsInitialValid() {
      try {
        const { isValid } = await checkValuesValidForYupSchema(formInitialValues, formValidationSchema);

        if (!cancelled) {
          setIsInitialValid(isValid);
        }
      } catch (error) {
        console.error(error);
      }
    }

    checkIsInitialValid();

    return () => {
      cancelled = true;
    };
  }, [formValidationSchema, formInitialValues, setIsInitialValid]);

  const [updatingFieldNames, setUpdatingFieldNames] = useState([]);

  const setFieldAsUpdating = useCallback(
    fieldName => {
      setUpdatingFieldNames(prevState => {
        if (!includes(prevState, fieldName)) {
          return prevState.concat(fieldName);
        }
        return prevState;
      });
    },
    [setUpdatingFieldNames],
  );

  const setFieldAsNotUpdating = useCallback(
    fieldName => {
      setUpdatingFieldNames(prevState => {
        if (includes(prevState, fieldName)) {
          return prevState.filter(name => name !== fieldName);
        }
        return prevState;
      });
    },
    [setUpdatingFieldNames],
  );

  const hasUpdatingFields = useMemo(() => updatingFieldNames.length > 0, [updatingFieldNames]);

  function onSubmit(values, actions) {
    const submitValues = formValidationSchema ? formValidationSchema.cast(values) : values;

    return handleSubmit(submitValues, actions);
  }

  const renderBottomComponent = ({
    /* eslint-disable  react/prop-types */
    isSubmitting,
    isSubmitDisabledDraft,
    onSubmitDraft,
    isSubmitDisabled,
    submitForm,
    /* eslint-enable  react/prop-types */
  }) => {
    if (!showFormBottomComponent) {
      if (FormBottomComponent) {
        return null;
      }

      return (
        <div className="action-form__form-bottom-component">
          {!!handleBack && (
            <Button onClick={handleBack} className="action-form__button" disabled={isSubmitting} size={submitSize}>
              Back
            </Button>
          )}
        </div>
      );
    }

    if (!FormBottomComponent) {
      return (
        <div className="action-form__form-bottom-component">
          {!!handleBack && (
            <Button onClick={handleBack} className="action-form__button" disabled={isSubmitting} size={submitSize}>
              Back
            </Button>
          )}

          {!!handleCancel && (
            <Button onClick={handleCancel} className="action-form__button" disabled={isSubmitting} size={submitSize}>
              Cancel
            </Button>
          )}

          <div className="action-form__submit-buttons">
            {!!handleSubmitDraft && (
              <Button
                loading={isSubmitting}
                disabled={isSubmitDisabledDraft}
                onClick={onSubmitDraft}
                size={submitSize}
                type="primary"
                className="action-form__button"
              >
                {saveDraftText}
              </Button>
            )}

            <Button
              loading={isSubmitting}
              disabled={isSubmitDisabled}
              onClick={submitForm}
              size={submitSize}
              type="primary"
            >
              {submitText}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <FormBottomComponent
        handleSubmit={submitForm}
        handleSubmitDraft={handleSubmitDraft ? onSubmitDraft : null}
        disabled={disabled}
        disableSubmit={isSubmitDisabled}
        disableSubmitDraft={isSubmitDisabledDraft}
        submitText={submitText}
        saveDraftText={saveDraftText}
      />
    );
  };

  return (
    <FormContext.Provider value={{ setFieldAsUpdating, setFieldAsNotUpdating, updatingFieldNames }}>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={onSubmit}
        isInitialValid={isInitialValid}
        ref={ref}
        enableReinitialize
      >
        {({
          isSubmitting,
          isValid,
          dirty,
          values,
          status,
          errors,
          submitCount,
          submitForm,
          resetForm,
          setSubmitting,
          setStatus,
          setTouched,
        }) => {
          const objectFields = getFieldsWithConditionalVisibilityAndDisabled(fields, values, disabled);

          const isFormValid = isValid && isEmpty(errors);

          const disableSubmitFromFormValidity = !isFormValid;
          const disableSubmitFromFormDirty = !allowNonDirtySubmit && !dirty;

          const isSubmitDisabled = getSubmitDisabled(
            disabled,
            disableSubmit,
            useFormValidityForSubmitDisabled,
            disableSubmitFromFormValidity,
            disableSubmitFromFormDirty,
            handleSubmitDraft,
            hasUpdatingFields,
          );

          const isSubmitDisabledDraft = getSubmitDisabledDraft(
            disabled,
            disableSubmitDraft,
            useFormValidityForSubmitDisabled,
            disableSubmitFromFormValidity,
            disableSubmitFromFormDirty,
            hasUpdatingFields,
          );

          async function onSubmitDraft() {
            if (!handleSubmitDraft) {
              return;
            }

            setStatus(undefined);

            const { isValid: isValidDraft, errors: errorsDraft } = await checkValuesValidForYupSchema(
              values,
              formValidationSchemaDraft,
            );

            if (!isValidDraft) {
              const touched = {};

              Object.keys(errorsDraft).forEach(key => {
                touched[key] = true;
              });

              // Set all fields with errors as touched so that their validation errors will be shown to the user in
              // the form.
              setTouched(touched);

              const hasMultipleFieldsWithErrors = Object.keys(errorsDraft).length > 1;

              let description;

              if (hasMultipleFieldsWithErrors) {
                description = (
                  <span>
                    Sorry, the current values cannot be saved as a draft due to validation errors with the following
                    fields: {renderErrorFieldNames(fields, errorsDraft)}. Please resolve these errors and then try
                    again. Note that you can leave a field empty in order to resolve the validation error and save a
                    draft.
                  </span>
                );
              } else {
                description = (
                  <span>
                    Sorry, the current values cannot be saved as a draft due to a validation error with the following
                    field: {renderErrorFieldNames(fields, errorsDraft)}. Please resolve this error and then try again.
                    Note that you can leave a field empty in order to resolve the validation error and save a draft.
                  </span>
                );
              }

              setStatus({
                type: "error",
                message: "Cannot save draft",
                description,
              });
            } else {
              // Set all fields as not touched so that validation errors from the standard form validation schema
              // (non-draft) are no longer shown to the user while we're submitting the draft values (since those errors
              // might otherwise be confusing, given that the form is about to be submitted with its current values
              // regardless of those errors).
              setTouched({});

              const submitValuesDraft = formValidationSchemaDraft ? formValidationSchemaDraft.cast(values) : values;

              handleSubmitDraft(submitValuesDraft, { setSubmitting, setStatus, resetForm });
            }
          }

          return (
            <Form {...FORM_ITEM_LAYOUT_PROPS_BY_FORM_LAYOUT[formLayout]} className={className}>
              <div className="action-form__fields">
                {renderFields
                  ? renderFields({ submitCount })
                  : Object.keys(objectFields).map(name => {
                      if (!objectFields[name].visible) {
                        return null;
                      }

                      if (renderField) {
                        const renderedField = renderField({
                          name,
                          meta: objectFields[name],
                          disabled: !!objectFields[name].disabled,
                          submitCount,
                          readOnly,
                        });

                        if (!isUndefined(renderedField)) {
                          return renderedField;
                        }
                      }

                      return (
                        <ActionFormField
                          key={name}
                          name={name}
                          meta={objectFields[name]}
                          submitCount={submitCount}
                          showFormFieldContentAsPreview={showFormFieldContentAsPreview}
                          relatedRecordsData={relatedRecordsData}
                          readOnly={readOnly}
                        />
                      );
                    })}
                {formLayout === FORM_ITEM_LAYOUT_PROPS_BY_FORM_LAYOUT.inline.layout &&
                  renderBottomComponent({
                    isSubmitting,
                    isSubmitDisabledDraft,
                    onSubmitDraft,
                    isSubmitDisabled,
                    submitForm,
                  })}
              </div>

              <FormValidationMessage fields={fields} errors={errors} submitCount={submitCount} />

              <FormStatusMessage status={status} />

              {formLayout !== FORM_ITEM_LAYOUT_PROPS_BY_FORM_LAYOUT.inline.layout &&
                renderBottomComponent({
                  isSubmitting,
                  isSubmitDisabledDraft,
                  onSubmitDraft,
                  isSubmitDisabled,
                  submitForm,
                })}

              <PromptDirtyForm />

              {saveContext && persistId && <FormikPersist uuid={persistId} setFormikState={false} />}
            </Form>
          );
        }}
      </Formik>
    </FormContext.Provider>
  );
});

ActionForm.propTypes = {
  fields: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  validationSchemaDraft: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  handleSubmitDraft: PropTypes.func,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  saveDraftText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  submitSize: PropTypes.string,
  persistId: PropTypes.string,
  showFormFieldContentAsPreview: PropTypes.bool,
  disabled: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  disableSubmitDraft: PropTypes.bool,
  allowNonDirtySubmit: PropTypes.bool,
  useFormValidityForSubmitDisabled: PropTypes.bool,
  saveContext: PropTypes.bool,
  FormBottomComponent: PropTypes.func,
  formLayout: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  renderFields: PropTypes.func,
  renderField: PropTypes.func,
  relatedRecordsData: PropTypes.object,
  showFormBottomComponent: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
};

ActionForm.defaultProps = {
  initialValues: null,
  validationSchema: null,
  validationSchemaDraft: null,
  handleSubmitDraft: null,
  handleCancel: null,
  handleBack: null,
  submitText: "Submit",
  saveDraftText: "Save Draft",
  submitSize: "default",
  showFormFieldContentAsPreview: false,
  disabled: false,
  disableSubmit: false,
  disableSubmitDraft: false,
  allowNonDirtySubmit: false,
  useFormValidityForSubmitDisabled: false,
  saveContext: false,
  persistId: "",
  FormBottomComponent: null,
  formLayout: "horizontal",
  renderFields: null,
  renderField: null,
  relatedRecordsData: null,
  showFormBottomComponent: true,
  readOnly: false,
  className: undefined,
};

export default ActionForm;
