//= ==============================
// Cookie
//= ==============================
export const getCookieValue = name => {
  const value = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  // cookie expiry time is NUMBER OF MILLISECONDS e.g. 1 day = 86,400,000
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = `; expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value}${expires}; path=/`;
};

export const removeCookie = name => {
  const date = new Date();
  const expires = `; expires=${date.toUTCString()}`;
  document.cookie = `${name}=${expires}; path=/`;
};
