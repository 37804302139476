import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Alert, Typography, message, Modal } from "antd";
import gql from "fraql";
import get from "lodash/get";
import includes from "lodash/includes";
import isNil from "lodash/isNil";
import isNull from "lodash/isNull";
import {
  COMPETITION_SCORE_TYPE,
  TEAM_QUALIFICATION_SCORE_DETERMINATION_CALCULATED,
  TEAM_QUALIFICATION_SCORE_DETERMINATION,
} from "../../../../../constants/competitionConstants";
import { formatCompetitionScoreForSaving } from "../../../../../utils/scoreUtils";
import SpinPageContent from "../../../../SpinPageContent";
import TeamQualificationScoreNotProvidedAlert from "../../TeamQualificationScoreNotProvidedAlert";
import TeamQualificationScoreCalculated from "./TeamQualificationScoreCalculated";
import TeamQualificationScoreProvided from "./TeamQualificationScoreProvided";

const GET_TEAM_QUALIFICATION_SCORE_FIELDS = gql`
  query TeamQualificationScore_GetTeamQualificationScoreFields($teamId: String!) {
    Team_by_pk(id: $teamId) {
      id
      qualificationScore
      Competition {
        id
        qualificationScoreLabel
        qualificationScoreType
        qualificationScoreLimitMin
        qualificationScoreLimitMax
        qualificationScoreRequired
        teamCompetition
        teamQualificationScoreDetermination
      }
    }
  }
`;

const UPDATE_TEAM_QUALIFICATION_SCORE = gql`
  mutation TeamQualificationScore_UpdateTeamQualificationScore($TeamId: ID!, $qualificationScore: jsonb) {
    updateTeamQualificationScore(TeamId: $TeamId, qualificationScore: $qualificationScore) {
      TeamId
    }
  }
`;

function TeamQualificationScore({ teamId, showFormBottomComponent, readOnly }) {
  const {
    loading: teamQualificationScoreLoading,
    error: teamQualificationScoreError,
    data: teamQualificationScoreData,
  } = useQuery(GET_TEAM_QUALIFICATION_SCORE_FIELDS, {
    variables: { teamId },
    skip: isNil(teamId),
  });

  const [updateTeamQualificationScore] = useMutation(UPDATE_TEAM_QUALIFICATION_SCORE, {
    refetchQueries: [{ query: GET_TEAM_QUALIFICATION_SCORE_FIELDS, variables: { teamId } }],
    awaitRefetchQueries: true,
  });

  const team = useMemo(() => get(teamQualificationScoreData, "Team_by_pk", null), [teamQualificationScoreData]);

  const competition = useMemo(() => get(team, "Competition", null), [team]);

  const teamQualificationScore = useMemo(() => get(team, "qualificationScore", null), [team]);

  const teamCompetition = useMemo(() => get(competition, "teamCompetition", false), [competition]);

  const teamQualificationScoreDetermination = useMemo(
    () => get(competition, "teamQualificationScoreDetermination", ""),
    [competition],
  );

  const teamHasValidQualificationScoreDetermination = useMemo(
    () => includes(Object.values(TEAM_QUALIFICATION_SCORE_DETERMINATION), teamQualificationScoreDetermination),
    [teamQualificationScoreDetermination],
  );

  const competitionQualificationScoreType = useMemo(() => get(competition, "qualificationScoreType", null), [
    competition,
  ]);

  const competitionHasValidQualificationScoreType = useMemo(
    () => includes(Object.values(COMPETITION_SCORE_TYPE), competitionQualificationScoreType),
    [competitionQualificationScoreType],
  );

  const handleTeamQualificationScoreSubmit = useCallback(
    async (values, actions) => {
      const qualificationScoreValue = formatCompetitionScoreForSaving(
        values.qualificationScoreValue,
        values.qualificationScoreType,
      );

      let qualificationScore;

      if (isNull(qualificationScoreValue)) {
        qualificationScore = null;
      } else {
        qualificationScore = {
          type: values.qualificationScoreType,
          value: qualificationScoreValue,
          rawValue: values.qualificationScoreValue,
        };
      }

      try {
        await updateTeamQualificationScore({ variables: { TeamId: teamId, qualificationScore } });

        message.success("Team's qualification score saved.");

        actions.resetForm();
      } catch (submitError) {
        console.error(submitError);

        actions.setSubmitting(false);
        actions.setStatus({ type: "error" });

        Modal.error({
          title: "Something went wrong",
          content: "Failed to save team's qualification score.",
        });

        Sentry.captureException(submitError);
      }
    },
    [teamId, updateTeamQualificationScore],
  );

  if (teamQualificationScoreLoading && !teamQualificationScoreData) {
    return <SpinPageContent />;
  }

  if (teamQualificationScoreError) {
    return (
      <Alert
        message="Team qualification score failed to load"
        description="Sorry, there was an issue loading this data."
        type="error"
        showIcon
        style={{ marginTop: 24 }}
      />
    );
  }

  if (!teamCompetition || !teamHasValidQualificationScoreDetermination || !competitionHasValidQualificationScoreType) {
    return null;
  }

  const isTeamQualificationScoreCalculated = includes(
    TEAM_QUALIFICATION_SCORE_DETERMINATION_CALCULATED,
    teamQualificationScoreDetermination,
  );

  return (
    <div style={{ marginTop: 24 }}>
      <Typography.Title level={4}>Team qualification score</Typography.Title>

      {isTeamQualificationScoreCalculated && (
        <TeamQualificationScoreCalculated teamQualificationScoreDetermination={teamQualificationScoreDetermination} />
      )}

      {!isTeamQualificationScoreCalculated && (
        <>
          <TeamQualificationScoreProvided
            competition={competition}
            teamQualificationScore={teamQualificationScore}
            showFormBottomComponent={showFormBottomComponent}
            readOnly={readOnly}
            onTeamQualificationScoreSubmit={handleTeamQualificationScoreSubmit}
          />

          <TeamQualificationScoreNotProvidedAlert style={{ marginTop: 24 }} team={team} />
        </>
      )}
    </div>
  );
}

TeamQualificationScore.propTypes = {
  teamId: PropTypes.string,
  showFormBottomComponent: PropTypes.bool,
  readOnly: PropTypes.bool,
};

TeamQualificationScore.defaultProps = {
  teamId: undefined,
  showFormBottomComponent: true,
  readOnly: false,
};

export default TeamQualificationScore;
