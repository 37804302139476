import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import { connect } from "formik";
import gql from "fraql";
import get from "lodash/get";
import omit from "lodash/omit";
import useUpdateEffect from "../../utils/useUpdateEffect";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";

const GET_FORMATS = gql`
  query FormFieldFormat_GetFormats($SportId: String!) {
    Format(where: { Sport: { id: { _eq: $SportId } } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;

function FormFieldSportFormat(props) {
  const {
    name,
    disabled,
    meta,
    formik: { values, setFieldValue },
  } = props;

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);

  const formSportIdValue = get(values, meta.sportFieldId, null);

  const { loading, error, data } = useQuery(GET_FORMATS, {
    variables: { SportId: formSportIdValue },
    skip: !formSportIdValue,
  });

  useUpdateEffect(() => {
    // Reset this field's value whenever the Sport field's value changes, since the list of Formats (and the set of
    // valid Formats) is dependent on the Sport selected, and therefore the current value for this field may no longer
    // be valid for the new value of the Sport field.
    setFieldValue(name, null);
  }, [formSportIdValue]);

  const options = useMemo(() => {
    const formats = get(data, "Format", []);

    return formats.map(format => ({ value: format.id, label: format.name }));
  }, [data]);

  if (!formSportIdValue) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="A format can be selected after selecting a sport" type="info" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  if (loading && !data) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (error) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Formats failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  return (
    <FormFieldSelect
      {...omit(props, ["formik"])}
      disabled={disabled || loading}
      loading={loading}
      meta={{ ...meta, options }}
    />
  );
}

FormFieldSportFormat.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
    helpText: PropTypes.node,
    sportFieldId: PropTypes.string.isRequired,
  }).isRequired,
  formik: PropTypes.object.isRequired,
};

FormFieldSportFormat.defaultProps = {
  disabled: false,
};

export default connect(FormFieldSportFormat);
