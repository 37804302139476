import isInteger from "lodash/isInteger";
import { getAgeFromDob } from "../components/MenuPeople/PersonAge";
import isBlank from "./isBlank";

export function getAgeRequirementIsMetFromAge(age, minimumAge, maximumAge) {
  if (isBlank(age) || !isInteger(age)) {
    // Case where person's age is not known.
    return null;
  }

  if (
    (!isBlank(minimumAge) && isInteger(minimumAge) && age < minimumAge) ||
    (!isBlank(maximumAge) && isInteger(maximumAge) && age > maximumAge)
  ) {
    // Case where minimum or maximum age requirement is not met.
    return false;
  }

  // Case where age requirement is met.
  return true;
}

export function getAgeRequirementIsMetFromDob(dob, ageAsAtDate, minimumAge, maximumAge) {
  if (isBlank(dob)) {
    // Case where person's DOB is not yet known.
    return null;
  }

  const ageOnDate = getAgeFromDob(dob, ageAsAtDate);

  return getAgeRequirementIsMetFromAge(ageOnDate, minimumAge, maximumAge);
}
