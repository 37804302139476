import React from "react";
import PropTypes from "prop-types";
import { GENDER_TYPE_OPTIONS } from "../../constants/personConstants";
import FormFieldRadioButton from "./FormFieldRadioButton";

function FormFieldGender(props) {
  const { meta } = props;

  return <FormFieldRadioButton {...props} meta={{ ...meta, options: GENDER_TYPE_OPTIONS }} />;
}

FormFieldGender.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default FormFieldGender;
