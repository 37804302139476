import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import gql from "fraql";
import get from "lodash/get";
import { isRegistrationClosed } from "../../utils/registrationUtil";
import LayoutPageHeader from "../LayoutPageHeader";
import SpinPageContent from "../SpinPageContent";
import RegistrationArchivedAlert from "./RegistrationArchivedAlert";
import RegistrationClosedAlert from "./RegistrationClosedAlert";
import RegistrationLoadingError from "./RegistrationLoadingError";
import RegistrationUserSubmit from "./RegistrationUserSubmit";

const GET_REG = gql`
  query RegistrationUserSubmitPage_GetReg($id: String!) {
    Reg_by_pk(id: $id) {
      id
      name
      openAt
      closeAt
      archived
    }
  }
`;

function RegistrationUserSubmitPage({ match }) {
  const { id: regId } = match.params;

  const { loading: regLoading, error: regError, data: regData } = useQuery(GET_REG, {
    variables: { id: regId },
  });

  const reg = useMemo(() => get(regData, "Reg_by_pk", null), [regData]);

  function renderContent() {
    if (regLoading && !regData) {
      return <SpinPageContent style={{ height: "100%" }} />;
    }

    if (regError || !reg) {
      return <RegistrationLoadingError />;
    }

    if (isRegistrationClosed(reg.openAt, reg.closeAt)) {
      return <RegistrationClosedAlert />;
    }

    if (reg.archived) {
      return <RegistrationArchivedAlert style={{ margin: 24 }} />;
    }

    return <RegistrationUserSubmit regId={reg.id} />;
  }

  let subTitle;

  if (regLoading && !regData) {
    subTitle = <span>Loading...</span>;
  } else if (regLoading || !reg) {
    subTitle = null;
  } else {
    subTitle = reg.name;
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fafafa",
          borderBottom: "1px solid #e8e8e8",
        }}
      >
        <div style={{ padding: "15px 24px" }}>
          <LayoutPageHeader title="Registration" subTitle={subTitle} />
        </div>
      </div>

      {renderContent()}
    </>
  );
}

RegistrationUserSubmitPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default RegistrationUserSubmitPage;
