import React, { useEffect, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Alert, Button, message, Spin } from "antd";
import { connect } from "formik";
import gql from "fraql";
import debounce from "lodash/debounce";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { SELECT_FIELD_MODES } from "../../constants/fieldConstants";
import Select from "../FormikAntD/Select";
import FormFieldFormItem from "./FormFieldFormItem";
import "./FormFieldLocation.scss";

const GET_LOCATIONS_FOR_GROUP_AND_SEARCH = gql`
  query FormFieldLocation_GetLocationsForGroupAndSearch($groupId: String!, $namePattern: String!) {
    Location(where: { GroupId: { _eq: $groupId }, _and: { name: { _ilike: $namePattern } } }) {
      id
      name
    }
  }
`;

const GET_LOCATIONS_FROM_IDS = gql`
  query FormFieldLocation_GetLocationsFromIds($LocationsIds: [String!]!) {
    Location(where: { id: { _in: $LocationsIds } }) {
      id
      name
    }
  }
`;

const CREATE_LOCATION_FOR_GROUP = gql`
  mutation FormFieldLocation_CreateLocationForGroup($groupId: String!, $name: String!) {
    insert_Location_one(object: { GroupId: $groupId, name: $name, parentId: null, path: "{}" }) {
      id
      GroupId
      name
      parentId
      path
    }
  }
`;

function FormFieldLocation(props) {
  const { name, meta, submitCount, formik } = props;
  const { setFieldValue, setFieldTouched, values } = formik;

  const formGroupIdValue = get(values, meta.groupFieldId, null);
  const locationsValue = get(values, name, null);

  // True when typing is started. False when a location search returns.
  const [isSearching, setIsSearching] = useState(false);

  // True when an option is selected. False when typing is started.
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  const [selectOptions, setSelectOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [getLocationsForGroupAndSearch, getLocationsForGroupAndSearchQuery] = useLazyQuery(
    GET_LOCATIONS_FOR_GROUP_AND_SEARCH,
    {
      onCompleted: data => {
        if (data && data.Location) {
          const options = data.Location.map(location => {
            return { value: location.id, label: location.name };
          });

          setSelectOptions(options);
        }

        setIsSearching(false);
      },
    },
  );

  const [getLocationsFromIds, getLocationsFromIdsQuery] = useLazyQuery(GET_LOCATIONS_FROM_IDS, {
    onCompleted: data => {
      if (data && data.Location) {
        const options = data.Location.map(location => {
          return { value: location.id, label: location.name };
        });

        setSelectOptions(options);
      }
    },
  });

  useEffect(() => {
    if (isEmpty(locationsValue)) {
      return;
    }

    // On initial load, and any time this field's value changes, fetch the Location records that match the Location IDs
    // from the field's value. This ensures that the frontend is able to display the current field value correctly,
    // since without fetching these records (and setting the Select options via the `onCompleted` function), the Select
    // input would show the raw Location IDs rather than the names of the Locations.
    getLocationsFromIds({ variables: { LocationsIds: locationsValue } });
  }, [locationsValue, getLocationsFromIds]);

  const [createLocation, { loading: createLocationLoading }] = useMutation(CREATE_LOCATION_FOR_GROUP);

  const isOpen = useMemo(() => {
    return !isOptionSelected && searchValue !== "";
  }, [isOptionSelected, searchValue]);

  const isCreateDisabled = useMemo(() => {
    return isEmpty(searchValue) || createLocationLoading || isSearching;
  }, [searchValue, createLocationLoading, isSearching]);

  const searchLocations = useCallback(
    value => {
      getLocationsForGroupAndSearch({ variables: { groupId: formGroupIdValue, namePattern: `%${value}%` } });
    },
    [formGroupIdValue, getLocationsForGroupAndSearch],
  );

  const debounceSearch = useMemo(() => debounce(searchLocations, 800), [searchLocations]);

  const handleChange = () => {
    setIsOptionSelected(true);
  };

  const handleBlur = () => {
    setIsSearching(false);
    setIsOptionSelected(true);
  };

  const handleSelect = () => {
    setSearchValue("");
  };

  const handleSearch = value => {
    // Note: Antd calls this function directly in the render body rather than decoupling it
    // causing the React warning: "Cannot update a component from inside the function body of a different component."
    // as reported here: https://github.com/ant-design/ant-design/issues/22233
    // The following is a workaround - we use debounce in the call to this method to
    // decouple the call from the render method to avoid the warning. Then we debounce
    // again to avoid making multiple calls while typing happens.

    if (value === "") {
      return;
    }

    setIsSearching(true);
    setSelectOptions([]);
    setIsOptionSelected(false);
    setSearchValue(value);

    debounceSearch(value);
  };

  const handleCreateLocation = () => {
    if (isCreateDisabled || searchValue === "") {
      return;
    }

    createLocation({
      variables: {
        groupId: formGroupIdValue,
        name: searchValue,
      },
    })
      .then(createdLocationData => {
        const newLocation = createdLocationData.data.insert_Location_one;

        setSelectOptions(prevOptions => [...prevOptions, { value: newLocation.id, label: newLocation.name }]);

        setFieldValue(name, [...locationsValue, newLocation.id]);
        setFieldTouched(name);

        setIsOptionSelected(true);

        message.success("New location created.");
      })
      .catch(error => {
        console.error({ error });
        message.error("Failed to create location.");
        Sentry.captureException(error);
      });
  };

  if (getLocationsForGroupAndSearchQuery.error || getLocationsFromIdsQuery.error) {
    return <Alert message="Locations failed to load" type="error" className="form-field-alert" />;
  }

  return (
    <FormFieldFormItem name={name} meta={meta} submitCount={submitCount} displayDefaultLabel={false}>
      <div className="form-field-location">
        <div className="form-field-location__header-text">
          If you have specific locations you would like to allocate when scheduling the game you can select an existing
          location or create them here.
        </div>

        <div className="form-field-location__header-text">
          Select existing locations or type the name of a new location and click &quot;Create New&quot;.
        </div>

        <div className="form-field-location__controls">
          <div className="form-field-location__select-wrapper">
            <Select
              // allowClear
              name={name}
              open={isOpen}
              mode={SELECT_FIELD_MODES.multiple}
              filterOption={false}
              placeholder="Type to search"
              notFoundContent={
                isSearching || getLocationsForGroupAndSearchQuery.loading || createLocationLoading ? (
                  <Spin size="small" />
                ) : (
                  <span>Not Found</span>
                )
              }
              onSelect={handleSelect}
              onSearch={debounce(handleSearch, 0)}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ width: "100%" }}
            >
              {selectOptions.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="form-field-location__create-btn">
            <Button onClick={handleCreateLocation} disabled={isCreateDisabled}>
              Create New
            </Button>
          </div>
        </div>
      </div>
    </FormFieldFormItem>
  );
}

FormFieldLocation.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    groupFieldId: PropTypes.string.isRequired,
  }).isRequired,
  submitCount: PropTypes.number.isRequired,
  formik: PropTypes.object.isRequired,
};

export default connect(FormFieldLocation);
