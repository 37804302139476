import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { connect } from "formik";
import moment from "moment-timezone";
import first from "lodash/first";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isNil from "lodash/isNil";
import omit from "lodash/omit";
import { DATE_FORMAT, UTC_TIME_ZONE } from "../../constants/dateTimeConstants";
import { parseDateTime, hasTimezoneAbbreviation } from "../../utils/dateTimeUtils";
import isBlank from "../../utils/isBlank";
import { useCurrentUserPersonTimezone } from "../../utils/useCurrentUserPersonTimezone";
import DatePicker from "../AntD/DatePicker";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldReadOnlyContent from "./FormFieldReadOnlyFields/FormFieldReadOnlyContent";
import ReadOnlyText from "./FormFieldReadOnlyFields/ReadOnlyText";

function FormFieldDate(props) {
  const { name, meta, disabled, showTime, format, parsingFormat, formik, readOnly } = props;
  const { values } = formik;
  const value = get(values, name, "");
  const { referenceTimezoneFieldName } = meta;
  const currentUserPersonTimezone = useCurrentUserPersonTimezone();

  const referenceTimezoneFieldValue = useMemo(() => {
    if (isBlank(referenceTimezoneFieldName)) {
      return null;
    }

    return get(values, referenceTimezoneFieldName, null);
  }, [referenceTimezoneFieldName, values]);

  const effectiveTimezone = useMemo(() => {
    return referenceTimezoneFieldValue ?? currentUserPersonTimezone;
  }, [referenceTimezoneFieldValue, currentUserPersonTimezone]);

  const defaultValue = useMemo(() => {
    if (isBlank(value)) {
      return null;
    }

    const momentValue = parseDateTime(value, format);

    if (!showTime) {
      return momentValue;
    }

    if (!isBlank(effectiveTimezone)) {
      return moment.tz(momentValue, effectiveTimezone);
    }

    return momentValue.local();
  }, [value, format, showTime, effectiveTimezone]);

  const defaultPickerValue = useMemo(() => {
    if (!showTime) {
      return undefined;
    }

    const currentTime = moment();

    if (!isBlank(effectiveTimezone)) {
      return moment.tz(currentTime, effectiveTimezone);
    }

    return currentTime.local();
  }, [showTime, effectiveTimezone]);

  const addonAfter = useMemo(() => {
    if (!showTime) {
      return null;
    }

    let addonAfterText;
    let addonAfterTooltipTitle;

    const valueAddonIsBasedOn = defaultValue ?? defaultPickerValue;

    if (!isBlank(effectiveTimezone)) {
      if (hasTimezoneAbbreviation(valueAddonIsBasedOn)) {
        addonAfterText = valueAddonIsBasedOn.format("z");
      } else {
        addonAfterText = valueAddonIsBasedOn.format("Z");
      }

      if (effectiveTimezone === UTC_TIME_ZONE) {
        addonAfterTooltipTitle = "UTC (GMT)";
      } else {
        addonAfterTooltipTitle = `${effectiveTimezone} (UTC${valueAddonIsBasedOn.format("Z")})`;
      }
    } else {
      addonAfterText = valueAddonIsBasedOn.format("Z");
      addonAfterTooltipTitle = `My local time (UTC${valueAddonIsBasedOn.format("Z")})`;
    }

    return (
      <Tooltip title={addonAfterTooltipTitle}>
        <span className="text-with-tooltip">{addonAfterText}</span>
      </Tooltip>
    );
  }, [showTime, effectiveTimezone, defaultValue, defaultPickerValue]);

  const getValue = useCallback(
    momentObject => {
      if (!moment.isMoment(momentObject)) {
        return "";
      }

      if (!showTime) {
        return momentObject.format(format);
      }

      if (!isBlank(showTime.secondStep)) {
        return momentObject.set({ millisecond: 0 }).format(format);
      }

      return momentObject.set({ second: 0, millisecond: 0 }).format(format);
    },
    [showTime, format],
  );

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = showTime ? "Select a date/time" : "YYYY-MM-DD";
  }

  function handleChangeDate(momentObject) {
    formik.setFieldValue(name, getValue(momentObject));
    formik.setFieldTouched(name, true);
  }

  function handleOpenPanel() {
    formik.setFieldTouched(name, true);
  }

  // Set the parsing format different to the display format. The internal format we use should always have the timezone
  // attached.
  const formatProps = isNil(parsingFormat) ? { format } : { format: [parsingFormat, format] };

  function getReadOnlyContent() {
    const displayFormat = isArray(formatProps.format) ? first(formatProps.format) : formatProps.format;

    if (!moment.isMoment(defaultValue)) {
      return null;
    }

    return (
      <ReadOnlyText>
        <span>{defaultValue.format(displayFormat)}</span>
        {addonAfter && <span> {addonAfter}</span>}
      </ReadOnlyText>
    );
  }

  if (readOnly) {
    return <FormFieldReadOnlyContent name={name} meta={meta} content={getReadOnlyContent()} />;
  }

  return (
    <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel}>
      <DatePicker
        name={name}
        defaultPickerValue={defaultPickerValue}
        value={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        showTime={showTime}
        onChange={handleChangeDate}
        onOpenChange={handleOpenPanel}
        addonAfter={addonAfter}
        {...formatProps}
      />
    </FormFieldFormItem>
  );
}

FormFieldDate.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  showTime: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  format: PropTypes.string,
  parsingFormat: PropTypes.string,
  formik: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

FormFieldDate.defaultProps = {
  disabled: false,
  showTime: false,
  format: DATE_FORMAT,
  parsingFormat: null,
  readOnly: false,
};

export default connect(FormFieldDate);
