import { useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "fraql";
import get from "lodash/get";
import isBlank from "./isBlank";
import useCurrentUser from "./useCurrentUser";

const GET_PERSON = gql`
  query useCurrentUserPersonTimezone_GetPerson($PersonId: String!) {
    Person_by_pk(id: $PersonId) {
      id
      timezoneName
    }
  }
`;

export const EMPTY_TIMEZONE_NAME = "";

export function useCurrentUserPersonTimezone() {
  const currentUser = useCurrentUser();
  const currentUserPersonId = useMemo(() => get(currentUser, "personId"), [currentUser]);

  const { loading, error, data } = useQuery(GET_PERSON, {
    variables: { PersonId: currentUserPersonId },
    skip: isBlank(currentUserPersonId),
  });

  return useMemo(() => {
    if (isBlank(currentUserPersonId) || (loading && !data) || error) {
      return EMPTY_TIMEZONE_NAME;
    }

    return get(data, "Person_by_pk.timezoneName", EMPTY_TIMEZONE_NAME);
  }, [data, loading, error, currentUserPersonId]);
}
