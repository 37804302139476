import gql from "fraql";

export const GET_MEMBER = gql`
  query GetMember($personId: String!, $groupIds: [String!]) {
    Member(where: { PersonId: { _eq: $personId }, GroupId: { _in: $groupIds } }) {
      id
      group: GroupId
      person: PersonId
      role: RoleId
    }
  }
`;

export const CREATE_MEMBER = gql`
  mutation CreateMember($group: String!, $person: String!, $role: String!) {
    insert_Member(objects: [{ GroupId: $group, PersonId: $person, RoleId: $role }]) {
      returning {
        id
        group: GroupId
        person: PersonId
        role: RoleId
      }
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation UpdateMember($id: String!, $changes: Member_set_input!) {
    update_Member(where: { id: { _eq: $id } }, _set: $changes) {
      returning {
        id
        group: GroupId
        person: PersonId
        role: RoleId
      }
    }
  }
`;

export const GET_MEMBER_LIST = gql`
  query GetMemberList($id: String, $offset: Int!, $limit: Int!) {
    Member(limit: $limit, offset: $offset, where: { PersonId: { _eq: $id } }) {
      id
      created
      updated
      group: Group {
        id
        name
      }
      role: Role {
        id
        name
      }
    }

    Member_aggregate(where: { PersonId: { _eq: $id } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PERSON_MEMBERS = gql`
  query GetMemberList($personId: String) {
    Member(where: { PersonId: { _eq: $personId } }) {
      id
      GroupId
      RoleId
    }
  }
`;
