// This is a wrapper for InputNumber from `antd`, intended to be used as a drop-in replacement in cases where we have a
// need for the features/changes which this component includes.
//
// It includes the following changes:
// - Add support for the `addonAfter` prop. This is supported out of the box by Ant Design for the Input component, but
//   not for the InputNumber component.
//
// See:
// - https://github.com/ant-design/ant-design/issues/12794
// - https://github.com/ant-design/ant-design/issues/14284

import React from "react";
import PropTypes from "prop-types";
import { InputNumber as InputNumberAntD } from "formik-antd";
import "./InputNumber.scss";

function InputNumber(props) {
  const { addonAfter, style, ...restProps } = props;

  if (addonAfter) {
    return (
      <div className="ant-input-group-wrapper" style={style}>
        <span className="ant-input-wrapper ant-input-group input-number input-number--with-addon-after">
          <InputNumberAntD {...restProps} addonAfter={addonAfter} />
          {!!addonAfter && <span className="ant-input-group-addon">{addonAfter}</span>}
        </span>
      </div>
    );
  }

  return <InputNumberAntD {...restProps} style={style} />;
}

InputNumber.propTypes = {
  addonAfter: PropTypes.string,
  style: PropTypes.any,
};

InputNumber.defaultProps = {
  addonAfter: undefined,
  style: undefined,
};

export default InputNumber;
